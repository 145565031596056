<div class="user-profile" style="min-height: 600px;">
  <form [formGroup]="f" [autocomplete]="'on'">

    <!-- email zone -->
    <div #emailZone>

      <div class="entities p-0" *ngIf="!emailEditMode" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <span class="entity-label ">Sign-in email </span>
          <span class="entity-property">{{user.userEmail}}</span>
        </div>
        <!-- <button matSuffix mat-icon-button (click)="emailEdit(true)">
          <mat-icon class="material-icons-outlined">edit</mat-icon>
        </button> -->
      </div>

      <div #emailBlock>
        <mat-form-field appearance="fill" *ngIf="emailEditMode">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" name="email" autocomplete="email" email required>
          <button mat-button matSuffix mat-icon-button class="inlineIcon cool-primary-80-text" *ngIf="canUpdateEmail()"
            (click)="changeEmail()">
            <mat-icon>check</mat-icon>
          </button>
          <button mat-button matSuffix mat-icon-button class="inlineIcon">
            <mat-icon class="material-icons-outlined" (click)="cancelEmail()">clear</mat-icon>
          </button>
          <mat-error *ngIf="f.controls['email'].invalid">
            {{ getErrorMessage(f.controls['email']) }}</mat-error>
        </mat-form-field>
      </div>
      <div class="entities p-0" *ngIf="user.pendingUserEmail">
        <div class="mb-8">
          <span class="entity-label ">Pending email </span>
          <span class="entity-property">{{user.pendingUserEmail}}</span>
        </div>
        <mat-card-header>
          <mat-icon mat-card-avatar class="material-icons-outlined">info</mat-icon>
          <mat-card-subtitle>Your email is pending verification. We've sent you an email to confirm the address. You will continue to use
            your
            old email ({{user.userEmail}}) to sign-in until you complete the process.</mat-card-subtitle>
        </mat-card-header>
      </div>
    </div>
    <!-- //email zone -->

    <!-- password zone -->
    <div class="entities p-0" *ngIf="!passwordEditMode" fxLayout="row" fxLayoutAlign="space-between center" #passwordZone>
      <div>
        <span class="entity-label ">Password </span>
        <span class="entity-property">********</span>
      </div>
      <button matSuffix mat-icon-button (click)="passwordEdit(true)">
        <mat-icon class="material-icons-outlined">edit</mat-icon>
      </button>
    </div>

    <div #passwordBlock *ngIf="passwordEditMode">
      <mat-form-field appearance="fill">
        <mat-label>Current password</mat-label>
        <input matInput type="password" formControlName="oldPassword" name="oldPassword" required autocomplete="current-password">
        <mat-error *ngIf="f.controls['oldPassword'].invalid">
          {{ getErrorMessage(f.controls['oldPassword']) }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>New password</mat-label>
        <input matInput type="password" formControlName="password" name="password" autocomplete="off" required>
        <mat-error *ngIf="f.controls['password'].invalid">
          {{ getErrorMessage(f.controls['password']) }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Confirm new password</mat-label>
        <input matInput type="password" formControlName="confirmPassword" required autocomplete="new-password"
          [errorStateMatcher]="errorMatcher">
        <mat-error *ngIf="f.controls['confirmPassword'].invalid">
          {{ getErrorMessage(f.controls['confirmPassword']) }}
        </mat-error>
        <mat-error *ngIf="f.hasError('passwordsDoNotMatch')">
          Passwords do not match!
        </mat-error>
      </mat-form-field>

      <mat-error *ngIf="f.hasError('sameAsOld')">
        Can not use the same password!
      </mat-error>

      <button mat-button matSuffix mat-icon-button class="inlineIcon" (click)="changePassword()" [disabled]="!f.valid">
        <mat-icon>check</mat-icon>
      </button>
      <button mat-button matSuffix mat-icon-button class="inlineIcon">
        <mat-icon class="material-icons-outlined" (click)="passwordEdit(false)">clear</mat-icon>
      </button>
    </div>
    <!-- //password zone -->

    <div #restPropertyZone>

      <mat-form-field appearance="fill">
        <mat-label>First name</mat-label>
        <input matInput type="text" formControlName="firstName" name="fname" autocomplete="given-name" required>
        <mat-error *ngIf="f.controls['firstName'].invalid">
          {{ getErrorMessage(f.controls['firstName']) }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Middle name</mat-label>
        <input matInput type="text" formControlName="middleName" name="mname">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Last name</mat-label>
        <input matInput type="text" formControlName="lastName" name="lname" required>
        <mat-error *ngIf="f.controls['lastName'].invalid">
          {{ getErrorMessage(f.controls['lastName']) }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput type="tel" formControlName="phone" placeholder="Phone" name="tel">
        <mat-error *ngIf="f.controls['phone'].invalid">
          {{ getErrorMessage(f.controls['phone']) }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Date format</mat-label>
        <mat-select formControlName="datePattern" type="datetime-local" [disableOptionCentering]="true" required>
          <mat-option *ngFor="let datePattern of datePatterns " [value]="datePattern">{{getStamp(datePattern)}} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Time format</mat-label>
        <mat-select formControlName="timePattern" type="datetime-local" [disableOptionCentering]="true" required>
          <mat-option *ngFor="let timePattern of timePatterns" [value]="timePattern">{{ getStamp(timePattern) }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Timezone</mat-label>
        <mat-select formControlName="timezone" type="datetime-local" #timezoneSelect [disableOptionCentering]="true">
          <mat-option>
            <ngx-mat-select-search [formControl]="timezoneFilterCtrl" placeholderLabel="Find timezone..."
              noEntriesFoundLabel="'no matching timezone found'"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone.code">{{ timezone.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Department</mat-label>
        <input matInput type="text" formControlName="department" autocomplete="off" name="organization">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Job title</mat-label>
        <input matInput type="text" formControlName="role" autocomplete="organization-title" name="organization-title">
      </mat-form-field>
    </div>

  </form>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center" #actionZone class="mb-12">
    <button mat-button class="primary-800 medium" [dpBusy]="isBusy" [disabled]="!f.valid" (click)="saveProfile()">SAVE CHANGES</button>
  </div>
</div>

<!-- <pre>{{f.errors | json}}</pre> -->