<div #cardZone>
  <mat-card class="mb-8 pt-8 pb-8 user-card" [ngClass]="selected? 'mat-elevation-z6 itemSelected' : 'mat-elevation-z1'">
    <div fxLayout="row" class="" fxLayoutAlign="start center">
      <div class="status" style="background-color: #1565c0;" *ngIf="user.isAdmin">
        <span class="status-text">ADMIN</span>
      </div>
      <div class="profile-icon m-8">
        {{ user.initials }}
      </div>
      <div class="info ml-12" fxLayout="column" fxLayoutAlign="space-evenly start">
        <div class="subtitle-1 black-high-text"> {{ user.fullName || user.userEmail }}</div>
        <div class="caption system-primary-700-text" *ngIf="user.fullName"> {{ user.userEmail }}</div>
        <div class="caption system-primary-700-text" *ngIf="user.state=== 'NEW' && user.invitedAt" > Last invitation was sent on {{ user.invitedAt  }}</div>

        <div *ngIf="!user.lastLoginTime && user.state=== 'VERIFIED'" class="caption system-primary-700-text"> Has never logged in.</div>

        <div *ngIf="user.lastLoginTime && user.state=== 'VERIFIED'" class="caption system-primary-700-text"> Last login at {{
          user.lastLoginTime }}</div>

      </div>

      <!--<mat-icon class="material-icons-outlined delete" *ngIf="allowAction && user.state=== 'VERIFIED' && !user.isCurrentUser "
        (click)="remove($event)" matTooltip="Remove this user" [matTooltipPosition]="'above'">highlight_off</mat-icon>-->

      <div style="height: 100px;" fxLayoutAlign="start end">

        <button *ngIf="allowAction && user.state === 'NEW' && user.ageOfDays > 0" mat-raised-button color="primary" [dpBusy]="user.isBusy"
          class="resend" (click)="resend($event)">Resend invitation</button>
      </div>

      <div fxFlex style="height: 100px; padding: 20px;" fxLayoutAlign="end start">
        <a *ngIf="allowAction && user.state=== 'VERIFIED' && !user.isCurrentUser " sty le="margin-left:auto;" class="reset-filter"
          (click)="remove($event)">Remove
          User</a>
      </div>
    </div>
  </mat-card>
</div>