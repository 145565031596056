import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { VesselInfoComponent } from './components/container-tracking-info/vessel-info/vessel-info.component';
import { MaterialModule } from 'app/material.module';
import { ContainerTrackingInfoComponent } from './components/container-tracking-info/container-tracking-info.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DpComponentsModule } from '@dp/components/components.module';
import { ProductsTableComponent } from 'app/containers/products-table/products-table.component';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { CargoesFeaturesComponent } from './components/cargoes-features/cargoes-features.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DocumentPreviewDialogComponent } from './components/document-preview-dialog/document-preview-dialog.component';
import { DocumentUploadDialogComponent } from './components/document-upload-dialog/document-upload-dialog.component';
import { DocumentsUploadComponent } from './components/documents-upload/documents-upload.component';
import { SharingLinkComponent } from './components/sharing-link/sharing-link.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from 'environments/environment';
import { ShipmentTrackingInfoComponent } from './components/shipment-tracking-info/shipment-tracking-info.component';
import { VesselInfo2Component } from './components/container-tracking-info/vessel-info2/vessel-info2.component';

import { DpDirectivesModule } from '@dp/directives/directives.module';
import { InlineSVGModule } from 'ng-inline-svg';

// tracking-info widget components
import { TrackingSummaryComponent } from './tracking-info-widget/tracking-summary/tracking-summary.component';
import { ShipmentMapComponent } from './tracking-info-widget/shipment-map/shipment-map.component';
import { TrackingStepComponent } from './tracking-info-widget/tracking-step/tracking-step.component';
import { TrackingDetailsComponent } from './tracking-info-widget/tracking-details/tracking-details.component';
import { ContainerDetailsComponent } from './tracking-info-widget/container-details/container-details.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TemperatureComponent } from './components/temperature/temperature.component';
import { TrackingResultComponent } from './tracking-info-widget/_tracking-result/_tracking-result.component';
import { TrackingResultRoadTransportComponent } from './tracking-info-widget/_tracking-result-roadTransport/_tracking-result-road-transport.component';
import { ShipmentSegmentComponent } from './tracking-info-widget/shipment-segment/shipment-segment.component';

// Document cards
import { DocumentsUploadCardComponentDatachain } from './components/document-upload-dialog/documents-upload-card-datachain/documents-upload-card-datachain.component';
import { DocumentsUploadCardComponent } from './components/documents-upload/documents-upload-card/documents-upload-card.component';
import { DocumentsUploadCardComponent2 } from './components/documents-upload/documents-upload-card-2/documents-upload-card-2.component';
import { DocumentsUploadCardPublicComponent } from './components/documents-upload/documents-upload-card-public/documents-upload-card-public.component';
import { BrowserModule } from '@angular/platform-browser';
import { TrackingResultMblBkComponent } from './tracking-info-widget/tracking-result-mbl-bk/tracking-result-mbl-bk.component';
import { ContainerAccordionComponent } from './tracking-info-widget/tracking-result-mbl-bk/container-accordion/container-accordion.component';

@NgModule({
  declarations: [
    CargoesFeaturesComponent,
    ContainerTrackingInfoComponent,
    VesselInfoComponent,
    VesselInfo2Component,
    ProductsTableComponent,
    ConfirmDialogComponent,
    DocumentPreviewDialogComponent,
    DocumentsUploadComponent,

    DocumentUploadDialogComponent,
    SharingLinkComponent,
    ShipmentTrackingInfoComponent,
    TrackingSummaryComponent,
    TrackingStepComponent,
    ShipmentMapComponent,
    TrackingResultComponent,
    TrackingResultRoadTransportComponent,
    ShipmentSegmentComponent,
    TemperatureComponent,
    TrackingDetailsComponent,
    ContainerDetailsComponent,
    TemperatureComponent,
    DocumentsUploadCardComponentDatachain,
    DocumentsUploadCardComponent,
    DocumentsUploadCardComponent2,
    DocumentsUploadCardPublicComponent,
    TrackingResultMblBkComponent,
    ContainerAccordionComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FlexLayoutModule,
    AgmCoreModule.forRoot({ apiKey: environment.mapKey }),
    MaterialModule,
    DpComponentsModule,
    DpPipesModule,
    InlineSVGModule,
    PdfViewerModule,
    NgxFileDropModule,
    DpDirectivesModule,
    NgxChartsModule,
    //BrowserModule
  ],
  exports: [
    CargoesFeaturesComponent,
    ContainerTrackingInfoComponent,
    VesselInfoComponent,
    VesselInfo2Component,
    ProductsTableComponent,
    ConfirmDialogComponent,
    DocumentPreviewDialogComponent,
    DocumentsUploadComponent,
    DocumentsUploadCardComponent,
    DocumentsUploadCardComponent2,
    DocumentUploadDialogComponent,
    SharingLinkComponent,
    ShipmentTrackingInfoComponent,
    TrackingSummaryComponent,
    TrackingDetailsComponent,
    TrackingStepComponent,
    ShipmentMapComponent,
    TemperatureComponent,
    ContainerDetailsComponent,
    TrackingResultComponent,
    TrackingResultRoadTransportComponent,
    ShipmentSegmentComponent,
    DocumentsUploadCardPublicComponent,
    TrackingResultMblBkComponent,
    ContainerAccordionComponent,
  ],
})
//todo: store feature module here, move lib module to shared.module, rename this one to featuresModule
export class SharedCommonModule {}
