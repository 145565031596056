<div *ngIf="errorText" class="mt-12">
  <mat-error>{{errorText}}</mat-error>
</div>

<section class="tracking-info p-8">
  <div class="mt-12" *ngIf="payload">
    <dp-container-details [container]="payload"></dp-container-details>
  </div>

  <div *ngIf="showDocumentsInSharedLinks">
    <mat-tab-group style="overflow-y: auto;">

      <!-- Tracking Tab -->
      <mat-tab [label]="tabs[0]">
        <section class="tab-content mt-12">

          <div *ngIf="transportJourneys">
            <dp-tracking-details [transportJourneys]="transportJourneys"></dp-tracking-details>
          </div>

          <ng-container *ngIf="transportJourneys?.portToPort?.segmentData; let segmentData">
            <dp-shipment-segment [segmentData]="segmentData" heading="Shipping Segments"></dp-shipment-segment>
          </ng-container>

          <ng-container *ngIf="shipmentMap">
            <h3>Tracking Events</h3>
            <dp-shipment-map [shipmentDetail]="shipmentMap" [maxZoom]="12"></dp-shipment-map>
          </ng-container>

          <div *ngIf="transportJourneys?.portToPort?.statusSteps?.steps; let steps" class="mt-12 pl-12">
            <dp-tracking-step [steps]="steps"></dp-tracking-step>
          </div>
        </section>

      </mat-tab>

      <!-- Documents Tab -->
      <mat-tab [label]="tabs[1]">
        <section class="tab-content mt-12">

          <!-- No document cases -->
          <div *ngIf="payload.documents.length == 0" fxLayout="column" fxLayoutAlign="center center">
            <h2 class="text-muted">No documents available!</h2>
            <img src="assets/images/svg/no-result-found.svg" />
          </div>

          <ng-container *ngIf="payload.documents.length > 0">
            <dp-documents-upload-card-public *ngFor="let document of payload.documents" [document]="document"
              [entityType]="EntityType.TRACKING_SHIPMENT" [tokenId]="tokenId">
            </dp-documents-upload-card-public>
          </ng-container>

        </section>
      </mat-tab>


    </mat-tab-group>
  </div>

  <ng-container *ngIf="!showDocumentsInSharedLinks">
    <div *ngIf="transportJourneys">
      <dp-tracking-details [transportJourneys]="transportJourneys"></dp-tracking-details>
    </div>

    <ng-container *ngIf="transportJourneys?.portToPort?.segmentData; let segmentData">
      <dp-shipment-segment [segmentData]="segmentData" heading="Shipping Segments"></dp-shipment-segment>
    </ng-container>

    <ng-container *ngIf="shipmentMap">
      <h3>Tracking Events</h3>
      <dp-shipment-map [shipmentDetail]="shipmentMap" [maxZoom]="12"></dp-shipment-map>
    </ng-container>

    <!-- trackingUpdatedAt -->
    <!-- TODO: need a default datePattern for not-login user -->
    <p class="latest-updated" *ngIf="payload?.trackingUpdatedAt">
      <label class="text-muted">Last updated: </label>
      <strong><span class="cool-secondary-100-text">{{ payload.trackingUpdatedAt | dateAgo }}</span></strong>
    </p>

    <div *ngIf="transportJourneys?.portToPort?.statusSteps?.steps; let steps" class="mt-12 pl-12">
      <dp-tracking-step [steps]="steps"></dp-tracking-step>
    </div>

  </ng-container>
</section>