import { Component, OnInit, Input } from '@angular/core';
import { DocumentService, DocumentAction } from 'app/shared/document.service';
import { IDocument } from 'app/shipments2/shipments2.model';
import { User } from 'app/auth/user.model';
import { MatDialog } from '@angular/material/dialog';
import { DocumentPreviewDialogComponent } from '../../document-preview-dialog/document-preview-dialog.component';
import { UIService } from 'app/shared/ui.service';
@Component({
  selector: 'dp-documents-upload-card-public',
  templateUrl: './documents-upload-card-public.component.html',
  styleUrls: ['./documents-upload-card-public.component.scss'],
})
export class DocumentsUploadCardPublicComponent implements OnInit {
  @Input() document_types;
  @Input() document: IDocument;
  @Input() tokenId: string;
  DocumentAction = DocumentAction;
  user: User;

  constructor(private documentService: DocumentService, private dialog: MatDialog, private uiService: UIService) {}

  ngOnInit(): void {}

  previewDocument(document: IDocument) {

    console.log("DocumentsUploadCardPublicComponent.previewDocument;");

    const dialogRef = this.dialog.open(DocumentPreviewDialogComponent, {
      width: '75%',
      data: {
        document,
        tokenId: this.tokenId,
      },
      minHeight: '600px',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      console.log('Upload component closed preview dialog', dialogResult);
    });
  }

  downloadDocument(selectedDocument: IDocument) {
    console.log('download document: ', document);
    let documentId = selectedDocument.id;
    let fileName = selectedDocument.name || 'file';

    this.documentService.getPublicSharedDocument(this.tokenId, documentId).subscribe(
      (result) => {
        const csvDownloadAnchor: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        const templateURL = URL.createObjectURL(new Blob([result]));
        csvDownloadAnchor.href = templateURL;
        csvDownloadAnchor.download = fileName;
        csvDownloadAnchor.click();
        URL.revokeObjectURL(templateURL);
      },
      (error) => {
        console.error('Download file error', error);
        this.uiService.showSnackbar('There was an issue downloading the file. Please try again', null, {
          duration: 3000,
          panelClass: 'warn',
        });
      }
    );
  }
}
