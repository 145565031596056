<section class="container">
  <dp-notification-metrics></dp-notification-metrics>
</section>

<!-- <section class="page-layout simple right-sidebar" style="overflow-y: scroll;">
  <div class="page-content m-20">
    <h2>Tag</h2>

    <h3>Spec:</h3>
    <dl>
      <dt>0 tag mode vs contains tags mode</dt>
      <dd>Add tag button will show `Add tag` or `+` sign</dd>

      <dt>How to add a tag</dt>
      <dd>1. Type in the input, then hit enter key. </dd>
      <dd>2. if a dropdown item is highlighted and partially matched the input, dropdown item will be selected.</dd>
      <dd>3. Clicking outside the input will add the input content as a tag.</dd>

      <dt>ESC key</dt>
      <dd>ESC key will close the editing mode and the content in the input is cleaned up.</dd>

      <dt>Enter key</dt>
      <dd>Enter key will add the content as a tag, and also stay in the editing mode to allow entering a new tag again. This would make
        setting multiple tags like a breeze.</dd>

      <dt>Duplicated value</dt>
      <dd>If a duplicated value is entered, it will be ignored and won't show up as a newly added tag.</dd>

      <dt>Container width</dt>
      <dd>All cases below are setup with smaller container width.</dd>

    </dl>
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">

      <div class="tag-card small mat-elevation-z2">
        <h4>Test case 1, no pre-existing tags</h4>
        <p>Tag suggestions</p>
        <ul>
          <li *ngFor="let tag of tagSuggestions1"> {{tag.name}}</li>
        </ul>
        <p>Demo:</p>
        <mat-divider></mat-divider>
      </div>

      <div class="tag-card small mat-elevation-z2">
        <h4>Test case 2, with pre-existing tags</h4>
        <p>Pre-existing tags: <span *ngIf="let tag of tags2"></span> </p>
        <p>Tag suggestions</p>
        <ul>
          <li *ngFor="let tag of tagSuggestions2"> {{tag.name}}</li>
        </ul>
        <p>Demo:</p>
        <dp-tags [tags]="tags2" [tagSuggestions]="tagSuggestions2"></dp-tags>
        <mat-divider></mat-divider>
      </div>
      
      <div class="tag-card large mat-elevation-z2">
        <h4>Test case 3, no pre-existing tags, no suggestions</h4>
        <p>Pre-existing tags: null </p>
        <p>Tag suggestions: null</p>
        <p>Demo:</p>
        <mat-divider></mat-divider>
      </div>
    </div>

    <h2>Currency display</h2>

    <ng-container *ngFor="let currencyCode of currencyCodes">
      <dt>{{currencyCode.name}} -> {{currencyCode.code}}</dt>
      <dd>{{12345.678 |currency: currencyCode.code:"symbol-narrow"}}</dd>
    </ng-container>
  </div>
</section> -->