import { Component, OnInit } from '@angular/core';
import { dpAnimations } from '@dp/animations';

@Component({
  selector: 'dp-new-settings-shell',
  templateUrl: './new-settings-shell.component.html',
  styleUrls: ['./new-settings-shell.component.scss'],
  animations: dpAnimations,
})
export class NewSettingsShellComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
