import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import moment from 'moment';

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
  datePattern: string;
  timePattern: string;
  timezone: string;

  // datePipe: PipeTransform

  transform(value: any, format: 'date' | 'time' | 'date-time' | 'short-date' | 'local-date-time' = 'date'): any {
    let pattern =
      format === 'date' || format === 'short-date'
        ? this.datePattern
        : format === 'time'
        ? this.timePattern
        : this.datePattern + ' ' + this.timePattern;
    if (format === 'short-date') {
      pattern = pattern.replace('YYYY', 'YY');
    }
    let mo = moment(value);
    if (format === 'local-date-time') {
      mo = mo.tz(this.timezone);
    }
    return mo.format(pattern);
  }

  constructor(private authService: AuthService) {
    if (this.authService && this.authService.currentUserValue) {
      this.datePattern = this.authService.currentUserValue.datePattern;
      this.timePattern = this.authService.currentUserValue.timePattern;
      this.timezone = this.authService.currentUserValue.timezone;
    }
    // this.datePipe = new DatePipe(localeId);
  }
}
