<mat-toolbar id="app-toolbar" class="mat-elevation-z1" [hidden]="!visible" style="position: relative;height: 0">
  <a routerLink="/" *ngIf="!isAuth || isHandset" style="position: absolute;top:10px;left:10px;">
    <!-- <img src="assets/cargoflow-new-square.svg" width="35px" /> -->
    <mat-icon svgIcon="site_logo"></mat-icon>
  </a>

  <!-- <div fxFlex fxLayout fxLayoutAlign="flex-end"> -->
  <div style="position: absolute; top:15px; right:15px;z-index: 1000;">

    <ul fxLayout fxLayoutGap="10px" fxLayoutAlign="space-around center" class="top-nav-items">
      <!-- <li>
        <mat-select [(value)]="countrySelected"
          (selectionChange)="switchCountry()">
          <mat-option value="us">USA</mat-option>
          <mat-option value="gb">GB</mat-option>
        </mat-select>
      </li> -->
      <li style="padding-right: 2px;" [hidden]="!isAuth || currentUser.companyStatus !== LoginCompanyStatusType.VERIFIED">

        <button mat-icon-button aria-label="Super Admin Tool" *ngIf="showSAPageLinkBtn()" (click)="goToSAPage()">
          <mat-icon matTooltip="Goto Super-Admin page" matTooltipPosition="below" aria-label="goAdmin" class="material-icons-outlined"
            color="primary">supervisor_account</mat-icon>
        </button>
        <button mat-icon-button aria-label="Back to admin" *ngIf="showSABackBtn()" (click)="backToSA()">
          <mat-icon matTooltip="Go back to Super-Admin" matTooltipPosition="below" aria-label="goBack" class="material-icons-outlined"
            color="primary">supervised_user_circle</mat-icon>
        </button>
        <button mat-icon-button aria-label="Invite Users" [mdePopoverTriggerFor]="inviteUserPopover" mdePopoverTriggerOn="click"
          *ngIf="isAuth && currentUser.isAdmin">
          <mat-icon matTooltip="Add User" matTooltipPosition="below" aria-label="inviteUsers">person_add
          </mat-icon>
        </button>

        <button mat-icon-button aria-label="Notification" *ngIf="isAuth && environment.enableBeta"
          [mdePopoverTriggerFor]="notificationPopover" mdePopoverTriggerOn="click">
          <mat-icon matTooltip="Notification" matTooltipPosition="below" aria-label="notification">notifications_active</mat-icon>
        </button>

        <button mat-icon-button aria-label="Help Center" [mdePopoverTriggerFor]="helpPopover" mdePopoverTriggerOn="click" *ngIf="isAuth">
          <mat-icon matTooltip="Help Center" matTooltipPosition="below" aria-label="helpCenter">help
          </mat-icon>
        </button>

        <button mat-mini-fab class="ml-8 primary-800" style="box-shadow:none;" [mdePopoverTriggerFor]="profilePopover"
          mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" (opened)="popoverOpened()">
          <!-- MB -->
          {{initials}}
          <mat-icon aria-label="profile" *ngIf="!initials">person</mat-icon>
        </button>
      </li>
      <li fxHide>
        <mat-checkbox [(ngModel)]="applicationSettings.darkMode" (change)="ngDarkModeChange()">Dark</mat-checkbox>
        <br />
      </li>
      <li *ngIf="!isAuth"><a routerLink="/signup">Sign Up</a></li>
      <li *ngIf="!isAuth">
        <a style="margin-right: 15px;" routerLink="/login">Login</a>
      </li>
      <!-- <li *ngIf="isAuth"><a (click)="onLogout()">Logout</a></li> -->
    </ul>
  </div>
</mat-toolbar>

<mde-popover #profilePopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#1565C0'"
  [mdePopoverCloseOnClick]="true" [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="profile-popover-card" *ngIf="currentUser">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" class="system-primary-800 p-4">
      <mat-card-title class="m-0">{{currentUser.fullName}}</mat-card-title>
      <mat-icon class="material-icons closeBtn" (click)="closeProfile()">
        close
      </mat-icon>
    </mat-card-header>
    <mat-action-list dense>
      <button mat-list-item (click)="openSettingsDlg()">
        <mat-icon matListIcon>settings</mat-icon>
        <span matLine>User Settings</span>
      </button>

      <button mat-list-item (click)="openCompanySettingsDlg()">
        <mat-icon matListIcon>business</mat-icon>
        <span matLine>Company Settings</span>
      </button>
      <!-- <button mat-list-item (click)="openCompanyProfileDlg()">
        <mat-icon matListIcon>business</mat-icon>
        <span matLine>Company Profile</span>
      </button> -->
      <button mat-list-item (click)="navigateTo('users')">
        <mat-icon matListIcon>people_outline</mat-icon>
        <span matLine>Manage Users</span>
      </button>
      <button mat-list-item (click)="navigateTo('invoices')" *ngIf="currentUser?.accountCapabilities?.billableState === 'ACTIVE' ">
        <mat-icon matListIcon>assignment</mat-icon>
        <span matLine>Invoices</span>
      </button>
      <button mat-list-item (click)="logout()">
        <mat-icon matListIcon>exit_to_app</mat-icon>
        <span matLine>Log Out</span>
      </button>
    </mat-action-list>
  </mat-card>
</mde-popover>

<mde-popover #inviteUserPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#1565C0'"
  [mdePopoverCloseOnClick]="false" [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="pendingUser-popover-card">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" class="system-primary-800 p-4">
      <mat-card-title class="m-0">Invite Users</mat-card-title>
      <mat-icon class="material-icons closeBtn" (click)="closeInviteUsers()">
        close
      </mat-icon>
    </mat-card-header>
    <mat-card-content>
      <dp-invite-user-popover (inviteUserClose)="this.closeInviteUsers()"></dp-invite-user-popover>
    </mat-card-content>
  </mat-card>
</mde-popover>

<mde-popover #helpPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#1565C0'" [mdePopoverCloseOnClick]="true"
  [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="help-popover-card">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" class="system-primary-800 p-4">
      <mat-card-title class="m-0">Help Center</mat-card-title>
      <mat-icon class="material-icons closeBtn" (click)="closeHelp()">
        close
      </mat-icon>
    </mat-card-header>
    <div>
      <mat-action-list dense>
        <button mat-list-item (click)="contactUs()">
          <mat-icon matListIcon>headset_mic</mat-icon>
          <span matLine>Contact Us</span>
        </button>
        <button mat-list-item (click)="playVideo()">
          <mat-icon matListIcon>videocam</mat-icon>
          <span matLine>Tutorial Video (90s)</span>
        </button>
        <button mat-list-item (click)="openFAQ()">
          <mat-icon matListIcon>help</mat-icon>
          <span matLine>FAQ</span>
        </button>
      </mat-action-list>
    </div>
  </mat-card>
</mde-popover>

<mde-popover #notificationPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#1565C0'"
  [mdePopoverCloseOnClick]="false" [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="help-popover-card">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="p-16 notificationTitle">
      <div>Notifications</div>
      <mat-icon class="material-icons material-icons-outlined settings">settings</mat-icon>
    </div>
    <div>
      <dp-notification-dlg (dismiss)="closeNotification()"></dp-notification-dlg>
    </div>
  </mat-card>
</mde-popover>