import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { throttleTime, mergeMap, scan, map, tap } from 'rxjs/operators';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'dp-system-tab',
  templateUrl: './system-tab.component.html',
  styleUrls: ['./system-tab.component.scss'],
})
export class SystemTabComponent implements OnInit {
  batch = 5;

  theEnd = false;
  offset = new BehaviorSubject(0);
  infinite: Observable<any[]>;

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  constructor(private service: NotificationService) {}

  ngOnInit(): void {
    const batchMap = this.offset.pipe(
      throttleTime(500),
      mergeMap((n) => this.getBatch(n)),
      scan((acc, batch) => {
        return { ...acc, ...batch };
      }, {})
    );

    this.infinite = batchMap.pipe(map((v) => Object.values(v)));
  }
  getBatch(offset: number) {
    console.log(`getBatch, offset=${offset}`);
    return this.service.getSystemNotifications(offset, this.batch).pipe(
      tap((arr) => (arr.length ? null : (this.theEnd = true))),
      map((arr) => {
        return arr.reduce((acc, cur) => {
          const id = cur.id;
          const data = cur;
          return { ...acc, [id]: data };
        }, {});
      })
    );
  }

  nextBatch(e, offset) {
    if (this.theEnd) {
      return;
    }

    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      console.log(`${end}, '>=', ${total}, offset=${offset}`);
      this.offset.next(offset);
    }
  }

  trackByIdx(i) {
    return i;
  }
}
