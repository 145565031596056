<ng-container *ngIf="logoMode === LogoMode.compact">
  <img class="corp-logo" *ngIf="!loadLogoFailed" src="{{ 'assets/images/corp_logos/' + orgCode + '_square.png' }}"
    (error)="loadLogoFail($event)" />
  <mat-icon svgIcon="site_logo" *ngIf="loadLogoFailed"></mat-icon>
</ng-container>

<ng-container *ngIf="logoMode === LogoMode.wide">
  <img class="corp-logo" *ngIf="!loadLogoFailed" src="{{ 'assets/images/corp_logos/' + orgCode + '_long.png' }}"
    (error)="loadLogoFail($event)" />
  <img class="corp-logo" *ngIf="loadLogoFailed" src="assets/cargoflow-new-white.svg" />
</ng-container>

<ng-container *ngIf="logoMode === LogoMode.full">
  <img class="corp-logo-full" *ngIf="!loadLogoFailed" src="{{ 'assets/images/corp_logos/' + orgCode + '_full.png' }}"
    (error)="loadLogoFail($event)" />
  <img class="corp-logo-full" *ngIf="loadLogoFailed" src="assets/cargoflow-new.svg" />
</ng-container>