export namespace MapSpace {
  export enum ShipmentTypes {
    OCEAN_SHIPMENT = 'OCEAN_SHIPMENT',
    TRUCK_SHIPMENT = 'TRUCK_SHIPMENT',
    DRAYAGE_PICKUP = 'DRAYAGE_PICKUP',
    DRAYAGE_DELIVERY = 'DRAYAGE_DELIVERY',
    INTERMODAL_SHIPMENT = 'INTERMODAL_SHIPMENT',
  }
  export enum MapFocusModes {
    SHIP_TO_PORT = 'SHIP_TO_PORT',
    TOTAL_ROUTE = 'TOTAL_ROUTE',
    SHIP_TO_DESTINATION = 'SHIP_TO_DESTINATION',
  }

  export enum ShipmentLocationTypes {
    IN_TRANSIT = 'IN_TRANSIT',
    IN_PORT = 'IN_PORT',
    ON_WATER = 'ON_WATER',
  }
  export interface ShipmentLocation {
    latitude: number;
    longitude: number;
    locationLabel?: string;
    locationTimestamp?: string;
    locationType?: ShipmentLocationTypes;
  }

  export interface Stop {
    latitude: number;
    longitude: number;
    trackDistance?: number;
  }
  export interface Route {
    pastRoute?: Stop[];
    futureRoute?: Stop[];
    possibleRoute?: Stop[];
  }

  //copied from targaryen
  export enum SHIPMENT_LOCATION_TYPES {
    ORIGIN = 'ORIGIN',
    ORIGIN_HUB = 'ORIGIN_HUB',
    ORIGIN_PORT = 'ORIGIN_PORT',
    TRANSSHIPMENT_PORT = 'TRANSSHIPMENT_PORT',
    DESTINATION_PORT = 'DESTINATION_PORT',
    DESTINATION_HUB = 'DESTINATION_HUB',
    DESTINATION = 'DESTINATION',
  }

  export interface JourneyStop {
    lat: number;
    lon: number;
    name: string;
    type?: SHIPMENT_LOCATION_TYPES;
    journeySegment?: string;
    isFuture: boolean;
    displayNamePermanently: boolean;
  }
  export interface Routes {
    shipToPort?: Route[];
    portToPort?: Route[];
    shipToDestination?: Route[];
  }
  export interface ShipmentMap {
    hasValidData: boolean;
    journeyStops: JourneyStop[];
    mapFocusMode: MapFocusModes;
    routes: Routes;
    shipmentLocation: ShipmentLocation;
    shipmentType: ShipmentTypes;
  }
}
