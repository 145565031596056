<form [formGroup]="formGroup" class="px-8 py-16 mb-4">
  <!-- <p>Mode: {{currentMode}}</p> -->

  <mat-error *ngIf="formGroup.invalid">
  </mat-error>
  <!-- read-only mode -->
  <ng-container>
    <section class="d-flex justify-content-between align-items-center py-8">

      <label for="port" class="text-bold">
        {{ getPortName(formGroup.value) }}
      </label>

      <div>

        <button mat-button color="primary" (click)="dispatchAction('UPDATE')" [disabled]="!isFormValid()" *ngIf="isEditing">
          <mat-icon class="link">save</mat-icon>
          <span>Update</span>
        </button>

        <button mat-button color="accent" (click)="cancelEditing()" *ngIf="isEditing">
          <mat-icon class="link">clear</mat-icon>
          <span>Cancel</span>
        </button>

        <!-- should not be able to delete default port -->
        <!-- isDefaultMetric : {{ isDefaultMetric(formGroup) }} -->
        <button mat-button color="accent" (click)="dispatchAction('DELETE')"
          *ngIf="formGroup.value.id && !isEditing && !isDefaultMetric(formGroup) && options.isAdmin">
          <mat-icon class="link">clear</mat-icon>
          <span>Delete</span>
        </button>
      </div>
    </section>

    <section class="ml-20">
      <div class="row mb-12 d-flex align-items-center">
        <div class="col-4">
          Medium severity
        </div>
        <div class="col-5">
          Storage time more than
        </div>
        <div class="col-3 last">
          <div style="position: relative">
            <!-- <mat-error style="position: absolute; top: 0" *ngIf="mediumSeverityToleranceSecsCtrl.invalid">Valid value range 1-60</mat-error> -->
            <div style="width: 50px; padding: 5px; background-color: rgba(0, 0, 0, 0.04);">
              <input type="number" style="height: 100%; background-color: transparent;" min="1" max="60" class="form-control"
                formControlName="mediumSeverityToleranceSecs" [attr.disabled]="options.isAdmin ? null : ''">
            </div>
          </div>
          <div>
            Days
          </div>
        </div>

      </div>

      <div class="row mb-12 d-flex align-items-center">
        <div class="col-4">
          High severity
        </div>
        <div class="col-5">
          Storage time more than
        </div>
        <div class="col-3 last">
          <div>
            <div style="width: 50px; padding: 5px; background-color:rgba(0, 0, 0, 0.04);">
              <input type="number" style="height: 100%; background-color: transparent;" min="1" max="60" class="form-control"
                formControlName="highSeverityToleranceSecs" [attr.disabled]="options.isAdmin ? null : ''">
            </div>
            <!-- <mat-error *ngIf="highSeverityToleranceSecsCtrl.invalid">Valid value range 1-60</mat-error> -->
          </div>
          <div>
            Days
          </div>
        </div>
      </div>
    </section>
  </ng-container>



</form>
<!-- 
<div style="border: 0.2px grey dotted; margin-bottom: 18px; font-family: monospace;">
  valid {{ formGroup.valid }}
  {{ formGroup.value | json}}
</div> -->