import { Component, OnInit } from '@angular/core';
import { StaticDataService } from '@dp/services/static-data.service';
import { AuthService } from 'app/auth/auth.service';
import { UIService } from 'app/shared';
import { environment } from 'environments/environment';
import { ports } from './nm-model';
import { NmApiService } from './nm-service';
import { INmState } from './nm-service';

interface ICreateRequestPayload {
  mediumSeverityToleranceSecs: number | null;
  highSeverityToleranceSecs: number | null;
  toleranceSecs?: number | null;
  notificationCode?: 'CN_EXTENDED_POO_STORAGE' | 'CN_EXTENDED_POD_STORAGE' | string;
  portCode?: string;
}

@Component({
  selector: 'dp-notification-metrics',
  templateUrl: './notification-metrics.component.html',
  styleUrls: ['./notification-metrics.component.scss'],
})
export class NotificationMetricsComponent implements OnInit {
  isAdmin = this.authService.currentUserValue.isAdmin;

  vm$ = this.nmApiService.vm$;
  // ports = ports; // sample ports
  ports = [];
  deletedIds = new Set<number>();
  editingIds = new Set<number>();
  currentMode: 'ADD_POO' | 'ADD_POD' | '' = '';

  constructor(
    private nmApiService: NmApiService,
    private uiService: UIService,
    private staticDataService: StaticDataService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const staticData = this.staticDataService.getStaticDataDirect();
    console.log('static data: ', staticData);
    this.ports = staticData.ports;
    console.log('The ports: ', this.ports);
  }

  createDefaultFormData(notificationCode: string): ICreateRequestPayload {
    return {
      mediumSeverityToleranceSecs: null,
      highSeverityToleranceSecs: null,
      toleranceSecs: null,
      notificationCode: notificationCode,
      portCode: '',
    };
  }

  getNotificationMetric(vm: INmState, code: string): any {
    if (vm && Array.isArray(vm.notificationMetrics)) {
      return vm.notificationMetrics.find((item) => item.notificationCode === code);
    }
    return null;
  }

  createNotificationMetric(request): void {
    console.log('create');
    console.log(request);

    const { notificationCode } = request.options;
    const { mediumSeverityToleranceSecs, highSeverityToleranceSecs, toleranceSecs, portCode } = request.data;

    // let test = {
    //   mediumSeverityToleranceSecs: 518400,
    //   highSeverityToleranceSecs: 518400,
    //   defaultToleranceSecs: null,
    //   notificationCode: 'CN_EXTENDED_POD_STORAGE',
    //   portCode: 'AEYAS',
    // };

    let payload: ICreateRequestPayload = {
      notificationCode,
      mediumSeverityToleranceSecs: mediumSeverityToleranceSecs * 86400,
      highSeverityToleranceSecs: highSeverityToleranceSecs * 86400,
      toleranceSecs,
      portCode,
    };
    this.nmApiService.createNotificationMetrics(payload).subscribe(
      (result) => {
        console.log('created result: ', result);

        // error result null
        if (!result) {
          this.uiService.showSnackbar('Failed to created notification metric ', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
          return;
        }
        this.uiService.showSnackbar('Successfully created notification metric ', null, {
          duration: environment.snackBarDuration.success,
          panelClass: 'accent',
        });

        // update UI
        // TODO if default, don't update UI
        this.currentMode = '';
        let currentState = this.nmApiService.currentState();
        let metrics = this.getNotificationMetric(currentState, payload.notificationCode);
        if (metrics && metrics.notificationMetrics) {
          metrics.notificationMetrics.unshift(result);
          // this.nmApiService.update(currentState);
        }
      },
      (error) => {
        console.log('error: ', error);

        this.uiService.showSnackbar('Failed to created notification metric ', null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warning',
        });
      }
    );
  }

  updateNotificationMetric(request): void {
    console.log(request);
    // const { notificationCode } = request.options;
    const { id, mediumSeverityToleranceSecs, highSeverityToleranceSecs, portCode } = request.data;

    let payload: ICreateRequestPayload = {
      mediumSeverityToleranceSecs: mediumSeverityToleranceSecs * 86400,
      highSeverityToleranceSecs: highSeverityToleranceSecs * 86400,
      // portCode,
    };
    this.nmApiService.updateNotificationMetrics(id, payload).subscribe(
      (result) => {
        console.log('update result: ', result);

        // error result null
        if (!result) {
          this.uiService.showSnackbar('Failed to update notification metric ', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
          return;
        }
        this.uiService.showSnackbar('Successfully updated notification metric ', null, {
          duration: environment.snackBarDuration.success,
          panelClass: 'accent',
        });
      },
      (error) => {
        console.log('error: ', error);

        this.uiService.showSnackbar('Failed to update notification metric ', null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warning',
        });
      }
    );
  }

  deleteNotificationMetric(metricId: number): void {
    this.nmApiService.deleteNotificationMetric(metricId).subscribe((result) => {
      this.deletedIds.add(metricId);
      console.log('delete metric: ', result);
    });
  }

  getToleranceSecsInDay(metric): boolean {
    let hours = metric.toleranceSecs / 3600;
    metric.toleranceSecs = hours;
    return true;
  }

  onDispatch($event: any) {
    let mode = $event.options.mode; // 'CREATE' | 'UPDATE'
    console.log('mode: ', mode);

    switch (mode) {
      case 'CREATE':
        this.createNotificationMetric($event);
        break;
      case 'UPDATE':
        console.log('UPDATE', $event);
        // if no id, create a default
        if ($event.data.id === null) {
          // will create default metric
          this.createDefaultNotificationMetric($event);
        } else {
          this.updateNotificationMetric($event);
        }
        break;
      case 'DELETE':
        console.log('delete');
        this.deleteNotificationMetric($event.data.id);
        break;
      case 'CLEAR':
        this.currentMode = '';
        // this.editingIds.delete($event.data.id);
        break;
      case 'EDIT':
        this.editingIds.add($event.data.id);
        break;

      default:
        console.log('default action');
    }
  }
  createDefaultNotificationMetric(request): void {
    console.log('create default metric');
    console.log(request);

    const { notificationCode } = request.options;
    const { mediumSeverityToleranceSecs, highSeverityToleranceSecs, toleranceSecs, portCode } = request.data;

    let payload: ICreateRequestPayload = {
      notificationCode,
      mediumSeverityToleranceSecs: mediumSeverityToleranceSecs * 86400,
      highSeverityToleranceSecs: highSeverityToleranceSecs * 86400,
      toleranceSecs,
      portCode,
    };
    this.nmApiService.createNotificationMetrics(payload).subscribe(
      (result) => {
        console.log('created result: ', result);

        // error result null
        if (!result) {
          this.uiService.showSnackbar('Failed to created notification metric ', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
          return;
        }
        this.uiService.showSnackbar('Successfully created notification metric ', null, {
          duration: environment.snackBarDuration.success,
          panelClass: 'accent',
        });

        this.currentMode = '';
        let currentState = this.nmApiService.currentState();
        let metrics = this.getNotificationMetric(currentState, payload.notificationCode);
        if (metrics && metrics.notificationMetrics) {
          metrics.notificationMetrics.shift();
          metrics.notificationMetrics.unshift(result);
        }
      },
      (error) => {
        console.log('error: ', error);

        this.uiService.showSnackbar('Failed to created notification metric ', null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warning',
        });
      }
    );
  }

  // ARRIVING_SOON notification
  toleranceHoursChange($event, notificationMetrics) {
    if (!$event) return;

    if ($event.target.value) {
      console.log($event.target.value);
    }

    if (!notificationMetrics.id) {
      let request = {
        toleranceSecs: $event.target.value * 3600,
        notificationCode: 'ARRIVING_SOON',
      };
      // create notificationMetric for ARRIVING_SOON
      this.createToleranceNotificationMetric(request).subscribe(
        (result) => {
          console.log('created result: ', result);

          // error result null
          if (!result) {
            this.uiService.showSnackbar('Failed to created notification metric ', null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
            return;
          }
          this.uiService.showSnackbar('Successfully updated notification metric ', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });

          // update UI
          notificationMetrics.id = result.id;
        },
        (error) => {
          console.log('error: ', error);

          this.uiService.showSnackbar('Failed to created notification metric ', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warning',
          });
        }
      );
    } else if (notificationMetrics.id) {

      let request = {
        toleranceSecs: $event.target.value * 3600,
      };

      // update
      this.updateToleranceNotificationMetric(notificationMetrics.id, request).subscribe(
        (result) => {
          if (!result) {
            this.uiService.showSnackbar('Failed to created notification metric ', null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
            return;
          }
          this.uiService.showSnackbar('Successfully updated notification metric ', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });
        },
        (error) => {
          console.log('error: ', error);
          this.uiService.showSnackbar('Failed to created notification metric ', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warning',
          });
        }
      );
    }
    console.log('hour change: ', $event);
  }

  createToleranceNotificationMetric(request): any {
    return this.nmApiService.createNotificationMetrics(request);
  }

  updateToleranceNotificationMetric(id, request): any {
    return this.nmApiService.updateNotificationMetrics(id, request);
  }
}
