<div fxFlex class="content">
  <ng-container *ngIf="infinite | async as events">
    <cdk-virtual-scroll-viewport itemSize="100" minBufferPx="500" maxBufferPx="1000"
      (scrolledIndexChange)="nextBatch($event, events.length)" class="eventList">
      <!-- itemSize="100" is the height of row -->
      <div class="notificationItem" [ngClass]="{'read': event.read}" *cdkVirtualFor="let event of events; let i = index; let count= count;">
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="eventTitle">
              {{event.title}}
            </div>
            <div class="eventTime">
              {{event.timeLabel}} : {{ i }} / {{ count }}
            </div>
          </div>
        </div>
        <ul class="eventDetail">
          <li *ngFor="let update of event.updates">{{update}}</li>
        </ul>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>
</div>
<div fxFlex="none" class="footer">
  <a>Mark all read</a>
</div>