import { environment } from 'environments/environment';
const isProdOrStaging = environment.production;

export class NavItem {
  uri: string;
  label: string;
  svg: string;
  matIcon: string;
  showInProd: boolean;

  constructor(uri: string, label: string, svgPath: string, matIcon: string = null, isProd: boolean = false) {
    this.uri = uri;
    this.label = label;
    this.showInProd = isProd;
    if (svgPath) {
      this.svg = svgPath;
    }
    if (matIcon) {
      this.matIcon = matIcon;
    }
  }
}

export enum AddContainerByTypes {
  ByContainer,
  ByBooking,
  ByMBL,
}

const assets_location = 'assets/images/svg/side-nav/';
export const SVGIcons = {
  dashboard: assets_location + 'dashboard/dashboard.svg',
  container_delivery: assets_location + 'dashboard/ContainerDelivery-2.svg',
  business: assets_location + 'dashboard/business-stats.svg',
  insights: assets_location + 'dashboard/insights&reports.svg',
  calendar: assets_location + 'dashboard/calendar.svg',
  quotations: assets_location + 'explorer/quotation.svg',
  shipments: assets_location + 'explorer/shipments.svg',
  containers: assets_location + 'explorer/containers.svg',
  container_upload: assets_location + 'polished/container-upload.svg',
  products: assets_location + 'products.svg',
  product2: 'assets/images/svg/Shipment/product.svg',
  shipment_detail: 'assets/images/svg/Shipment/shipment_detail.svg',
  locations: assets_location + 'edit_location.svg',
  social: assets_location + 'social.svg',
  ship: 'assets/images/svg/ship.svg',
  truck: 'assets/svg/truck_shipment2.svg',
  setting: assets_location + 'configure.svg',
  tbg: 'assets/svg/tbg.svg',
  documents: 'assets/svg/documents.svg',
};

let NavItemsList: NavItem[] = [
  //new NavItem('/dashboard', 'Dashboard', SVGIcons.dashboard, null, true),
  // new NavItem('/dashboard', 'Dashboard', null, 'dashboard', true),
  new NavItem('/dashboard', 'Dashboard', null, 'dashboard', true),
  new NavItem('/container-shipments', 'Container Shipments', null, 'directions_boat', true),
  new NavItem('/road-shipments', 'Road Shipments', null, 'local_shipping', true),
  //new NavItem('/tracking-by-group', 'Tracking by Group', null, 'view_list', !environment.productionHosting),

  // new NavItem('/containers', 'Containers', SVGIcons.containers, null, isProdOrStaging),
  //new NavItem('/container-shipments', 'Container Shipments', SVGIcons.ship, null, true),

  // new NavItem('/shipments', 'Shipments', SVGIcons.shipments, null, isProdOrStaging),
  //new NavItem('/road-shipments', 'Road Shipments', SVGIcons.truck, null, true),
  //new NavItem('/tracking-by-group', 'Tracking by Group', SVGIcons.tbg, null, !environment.productionHosting),
  // new NavItem('/users', 'Users', null, 'person_outline', isProdOrStaging),
  // new NavItem('/setting', 'Setting', SVGIcons.setting, null, isProdOrStaging),
  // new NavItem('/contact-us', 'Contact US', null, 'perm_phone_msg', isProdOrStaging),
  new NavItem('/container-pickup', 'Inbound Container Pickup', SVGIcons.container_delivery, null, true),
  // new NavItem('/container-pickup2', 'Inbound Container Pickup', SVGIcons.container_delivery, null, !environment.productionHosting),
  // null,
  // new NavItem('/purchase-orders', 'Purchase Orders*', null, 'shopping_basket'),
  // new NavItem('/customer-orders', 'Customer Orders*', null, 'accessibility_outline'),
  // new NavItem('/shippingRequests', 'Quotations', SVGIcons.quotations),
  // new NavItem('/containers-table', 'Containers-table', SVGIcons.containers),
  // new NavItem('/shipments-old', 'Shipments', SVGIcons.shipments),
  // new NavItem('/products', 'Products', SVGIcons.products),
  // new NavItem('/relationship', 'Relationship*', null, 'wc'),
];

if (isProdOrStaging) {
  NavItemsList = NavItemsList.filter((item) => item && item.showInProd);
}

export { NavItemsList };

export enum TemplateFileExtensionTypes {
  csv,
  xlsx,
}
