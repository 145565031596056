import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

export interface IFormData {
  id?: number;
  port: string; // for UI
  countryCode: string; //for UI
  portCode: string; // use this to send to backend
  notificationCode: 'CN_EXTENDED_POO_STORAGE' | 'CN_EXTENDED_POD_STORAGE' | 'ARRIVING_SOON';
  defaultToleranceSecs: number;
  mediumSeverityToleranceSecs: number;
  highSeverityToleranceSecs: number;
}

interface IOption {
  isAdmin: boolean;
  mode: 'CREATE' | 'UPDATE' | 'DELETE' | 'CLEAR' | 'CANCEL' | '';
  notificationCode: string;
  ports: any[];
}
@Component({
  selector: 'dp-notification-metric-form',
  templateUrl: './metric-form.component.html',
  styleUrls: ['./metric-form.component.scss'],
})
export class MetricFormComponent implements OnInit {
  @Input() currentMode: 'ADD_POO' | 'ADD_POD' | '';
  @Output() currentModeChange = new EventEmitter();
  @Input() editingIds: Set<number>;

  isEditing: boolean = false;

  // option configuration
  private _options: IOption = {
    isAdmin: false,
    mode: '',
    notificationCode: '',
    ports: [],
  };

  @Input()
  get options() {
    return this._options;
  }
  set options(config: Partial<IOption>) {
    this._options = { ...this._options, ...config };
  }

  @Input() formData: IFormData;
  @Output() onDispatch = new EventEmitter();

  formGroup = this.fb.group({
    id: [null],
    port: [null],
    countryCode: [null],
    portCode: [''],
    notificationCode: ['', [Validators.required]],
    toleranceSecs: [null],
    mediumSeverityToleranceSecs: ['', [Validators.required, Validators.min(1), Validators.max(60)]],
    highSeverityToleranceSecs: ['', [Validators.required, Validators.min(1), Validators.max(60)]],
  });

  get mediumSeverityToleranceInDays() {
    const days = this.formGroup.get('mediumSeverityToleranceSecs').value;
    return days;
  }

  get highSeverityToleranceInDays() {
    return this.formGroup.get('highSeverityToleranceSecs').value;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.formData) {
      let transform = {
        ...this.formData,
        mediumSeverityToleranceSecs: this.formData.mediumSeverityToleranceSecs / 86400,
        highSeverityToleranceSecs: this.formData.highSeverityToleranceSecs / 86400,
      };
      this.formGroup.patchValue(transform);
    }

    this.formGroup.valueChanges.subscribe((value) => {
      this.isEditing = true;
    });
  }

  cancelEditing() {
    if (this.formData) {
      let transform = {
        ...this.formData,
        mediumSeverityToleranceSecs: this.formData.mediumSeverityToleranceSecs / 86400,
        highSeverityToleranceSecs: this.formData.highSeverityToleranceSecs / 86400,
      };
      // this.formGroup.patchValue(transform);
      this.formGroup.patchValue(transform, {
        emitEvent: false,
      });
    }
    this.isEditing = false;
  }

  dispatchAction(action: string) {
    console.log('action: ', action);

    let emitObject = {
      options: { ...this.options, mode: action },
      data: this.formGroup.value,
    };
    this.onDispatch.emit(emitObject);

    // optimistic assumption that API succeeds
    if (action === 'UPDATE') {
      this.isEditing = false;
      this.formData = {
        ...this.formData,
        mediumSeverityToleranceSecs: this.formGroup.value.mediumSeverityToleranceSecs * 86400,
        highSeverityToleranceSecs: this.formGroup.value.highSeverityToleranceSecs * 86400,
      };
    }
  }

  getPortName(form: IFormData) {
    let portName = '';
    if (form.port) {
      portName += form.port;
    }
    if (form.countryCode) {
      portName += ', ' + form.countryCode;
    }
    if (portName) return portName;
    if (form.portCode) return form.portCode;

    return 'Default for all ports';
  }

  // For CN_EXTENDED_POO_STORAGE and CN_EXTENDED_POD_STORAGE code
  isFormValid() {
    return this.formGroup.valid;
  }

  isDefaultMetric(formGroup): boolean {
    return !formGroup.value.id || !formGroup.value.portCode;
  }

  get mediumSeverityToleranceSecsCtrl() {
    return this.formGroup.get('mediumSeverityToleranceSecs');
  }

  get highSeverityToleranceSecsCtrl() {
    return this.formGroup.get('highSeverityToleranceSecs');
  }
}
