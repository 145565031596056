import { commonEnvironment } from './environment.common';

const specialEnvironmentStaging = {
  production: true,
  productionHosting: false,
  apiValue: '14049e80317242e0d6644f82f6e758eb',
  rootUrl: 'https://staging-flow-api.cargoes.com/api/',
  enableBeta: true,
  useMock: false,
  mapKey: 'AIzaSyDUByz-2dAS1RTR1JYitoj-P8YJ7-ppOmc',
  heartBeats: {
    containerDeliveryHeartBeat: 6, //every n second
    webUpdateHeartBeat: 6, //seconds
  },
  clientId: 'FL',
  baseUrl: 'https://staging-flow.cargoes.com/',
  ssoAuthServer: 'https://crrdev.uaenorth.cloudapp.azure.com/',
  ssoApp: 'dtworld'
};
const specialEnvironmentProd = {
  production: true,
  productionHosting: true,
  enableBeta: false,
  useMock: false,
  apiValue: '7cf0e70059e5bfb3c37171cc7cd229cc',
  rootUrl: 'https://flow-api.cargoes.com/api/',
  mapKey: 'AIzaSyB82TlGnPktPNIhNCFpspn-GWoezmKs8og',
  heartBeats: {
    containerDeliveryHeartBeat: 30, //every n second
    webUpdateHeartBeat: 6, //seconds
  },
  clientId: 'FL',
  baseUrl: 'http://flow.cargoes.com/',
  ssoAuthServer: 'https://staging-login.cargoes.com/',
  ssoApp: 'dtworld'
};

const SSOAuthRoutesProd = {
  SSORedirectUri: 'login',
  SSOLogoutUri: `auth/realms/${specialEnvironmentProd.ssoApp}/protocol/openid-connect/logout?post_logout_redirect_uri=`,
  SSOAuthUri: `auth/realms/${specialEnvironmentProd.ssoApp}/protocol/openid-connect/auth`
};


const SSOAuthRoutesStaging = {
  SSORedirectUri: 'login',
  SSOLogoutUri: `auth/realms/${specialEnvironmentStaging.ssoApp}/protocol/openid-connect/logout?post_logout_redirect_uri=`,
  SSOAuthUri: `auth/realms/${specialEnvironmentStaging.ssoApp}/protocol/openid-connect/auth`
};

let branch = 'master';

// @ts-ignore

const isProd = branch === 'master';

const specialEnvironment = isProd ? specialEnvironmentProd : specialEnvironmentStaging;

const SSOAuthRoutes = isProd ? SSOAuthRoutesProd : SSOAuthRoutesStaging

export const environment = {
  ...commonEnvironment,
  ...specialEnvironment,
  ...SSOAuthRoutes,
  isProd
};
