<button mat-button [dpBusy]="isBusy" color="accent" [mdePopoverTriggerFor]="addressCreatePopover" mdePopoverTriggerOn="none"
  (click)="openPopover(popoverTrigger)" #popoverTrigger="mdePopoverTrigger">
  Add Address
</button>

<mde-popover id="addressCreate-popover" #addressCreatePopover="mdePopover" [mdePopoverOverlapTrigger]="false"
  [mdePopoverCloseOnClick]="false" (close)="closePopover()">
  <mat-card style="width: 500px" class="mat-elevation-z12">
    <mat-card-header>
      <mat-card-title>Add {{ infoType }} To Address Book</mat-card-title>
    </mat-card-header>

    <form [formGroup]="locationContactForm" autocomplete="off">
      <mat-card-content fxLayout="row wrap" #filtersContent>
        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="50">
            <mat-form-field>
              <input matInput type="text" formControlName="locationName" placeholder="Company" />
              <mat-error>Company is required!</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" [formControl]="contact.get('firstName')" placeholder="First Name" />
              <mat-error>First name is required!</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" [formControl]="contact.get('lastName')" placeholder="Last Name" />
              <mat-error>Last name is required!</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Phone Number</mat-label>
              <input matInput type="phone" [formControl]="contact.get('phone')" placeholder="Area Code - Number" required />
              <mat-error>At least 6 digits</mat-error>
            </mat-form-field>
            <mat-form-field>
              <input type="email" matInput placeholder="{{ infoType }} email" [formControl]="contact.get('email')" required />
              <mat-error>E-Mail is invalid.</mat-error>
            </mat-form-field>
          </div>
          <agm-map fxFlex="50" [latitude]="latitude" [longitude]="longitude">
            <agm-marker [latitude]="latitude" [longitude]="longitude"> </agm-marker>
          </agm-map>
        </div>

        <div fxLayout="column" fxLayoutAlign="start stretch">
          <mat-form-field fxFlex="100">
            <input matInput type="text" [formControl]="address.get('streetLine1')" placeholder="Address" name="address" required />
            <mat-error>Address is required!</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" [formControl]="address.get('streetLine2')" placeholder="Address 2" name="address2" />
          </mat-form-field>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex="50">
              <input matInput type="text" [formControl]="address.get('city')" placeholder="City" />
              <mat-error>City is required!</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input matInput type="text" [formControl]="address.get('stateProv')" placeholder="State/Province" />
              <mat-error>State/Province is required!</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex="50">
              <input matInput type="text" [formControl]="address.get('postalCode')" placeholder="Zip Code" required />
              <mat-error>Zip Code is required!</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-select placeholder="Country" [formControl]="address.get('countryCode')">
                <mat-option *ngFor="let country of countryCode" required value="{{ country }}">{{ country }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions align="right">
        <button mat-button (click)="closePopover()">Cancel</button>
        <button mat-raised-button type="submit" (click)="onSubmit()" [disabled]="locationContactForm.invalid" color="primary-800"
          class="submit-button">
          Submit
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</mde-popover>