<section id="welcome-page">

  <div class="logo">
    <dp-logo [orgCode]="orgCode" [logoMode]="LogoMode.full"></dp-logo>
  </div>

  <div class="my-12" style="background-color: #fafafa;">
    <dp-cargoes-features></dp-cargoes-features>
  </div>

  <div class="container" style="margin: 12px auto;" fxLayoutAlign="center start" fxLayout="row wrap" *ngIf="!isAuth">

    <mat-button-toggle-group class="toggle-groups" [(ngModel)]="shipmentType" (change)="shipmentTypeChanged()">
      <mat-button-toggle [value]="ShipmentType.INTERMODAL_SHIPMENT">
        <label for="">Container</label>
      </mat-button-toggle>
      <mat-button-toggle [value]="ShipmentType.TRUCK_SHIPMENT">
        <span>Road</span>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <dp-search-box appearance="outline" (cancelClicked)="cancelClicked()" [value]="trackingNumber" (search)="onSearchEvent($event)"
      [placeholder]="shipmentType === ShipmentType.INTERMODAL_SHIPMENT ? 'Please enter the container #, or MBL #' : 'Please enter the tracking #'"
      [hasBorder]="true" (valueChange)="onValueChange($event)" (keyup)="keyup()" (cancelClicked)="onClear()">
    </dp-search-box>

    <form class="carrier-form" style="width: 220px" [hidden]="carrierControl.disabled || !trackingNumber">

      <div class="dropdown">
        <input type="text" placeholder="Choose a carrier" class="form-control" aria-label="carrier" [formControl]="carrierControl"
          [matAutocomplete]="auto">
      </div>

      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option value="Any carrier">
          Any carrier
        </mat-option>
        <ng-container *ngFor="let option of filteredOptions | async">
          <!-- Filter by shipmentType -->
          <mat-option [value]="option.carrier_name" *ngIf="option.shipment_type === this.shipmentType">
            {{option.carrier_name}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>

      <ng-container *ngIf="isDetectingCarrierName">
        <p class="text-black-50">Detecting the carrier...</p>
      </ng-container>

      <ng-container *ngIf="!isDetectingCarrierName">
        <p *ngIf="trackingNumber && (carrierName && carrierName !== DEFAULT_CARRIER) && showMessage">
          <mat-error class="text-warning">
            Please verify the carrier and correct it if needed
          </mat-error>
        </p>
        <p>
          <mat-error class="text-warning"
            *ngIf="!canDetectCarrierName && !(trackingNumber && carrierName && carrierName !== DEFAULT_CARRIER) && showMessage">
            We could not identify the carrier
            for this tracking number. Please select a carrier.
          </mat-error>
        </p>
      </ng-container>

      <ng-container *ngIf="filteredOptions | async as options">
        <p *ngIf="carrierName && options.length===0">
          <mat-error class="text-error">
            Invalid carrier. Please check carrier name again.
          </mat-error>
        </p>
      </ng-container>

    </form>

    <button mat-raised-button color="primary" (click)="onSearchEvent(trackingNumber)" style="min-width: 45px; height: 33px;"
      [disabled]="!trackingNumber || !carrierName">
      <mat-icon class="search-icon">search</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="isBusy; else elseBlock">

    <div fxLayoutAlign="center center" style="margin-top: 40px; font-size: 22.4px;">
      Searching... this may take a minute.
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="flex: 1; min-height: 300px;">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </ng-container>

  <ng-template #elseBlock>

    <div fxLayoutAlign="center center" style="margin-top: 40px; font-size: 1.25rem;" *ngIf="errorText !=='' ">
      <mat-error>{{errorText}}</mat-error>
    </div>

    <!-- Payload Result -->

    <div style="background-color: #fafafa; padding: 12px; min-height: 400px;" *ngIf="payload">

      <ng-container *ngIf="payload.trackingType === 'bl_number'">
        <!-- {{payload | json }} -->
        <!-- search by mbl #, or bk # -->
        <dp-tracking-result-mbl-bk [payload]="payload.data"></dp-tracking-result-mbl-bk>
      </ng-container>

      <!-- Container Type -->
      <ng-container *ngIf="payload.trackingType === 'container_number' || payload.trackingType === 'external_shipment_number'">
        <div *ngIf="!payload.data?.transportJourneys?.roadTransport" class="container tracking-result">
          <dp-tracking-result [payload]="payload.data" [errorText]="errorText"></dp-tracking-result>
        </div>

        <div *ngIf="payload.data?.transportJourneys?.roadTransport" class="container tracking-result-road-transport"
          style="background-color: white;">
          <dp-tracking-result-road-transport [payload]="payload.data" [showTemperature]="false" [errorText]="errorText">
          </dp-tracking-result-road-transport>
        </div>
      </ng-container>


    </div>

  </ng-template>

</section>