import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShipmentsComponent, CreateShipmentComponent, ShippingRequestsComponent } from '.';
import { AuthGuard } from 'app/auth/auth.guard';
import { ContainersComponent } from './containers/containers.component';
import { ContainerDetailComponent } from './containers/container-detail/container-detail.component';
import { ShipmentDetailComponent } from './ocean-shipments/shipment-detail/shipment-detail.component';
import { DocumentsComponent } from './documents/documents.component';

const routes: Routes = [
  { path: 'shipments-old', component: ShipmentsComponent,  },
  { path: 'shipments-old/create', component: CreateShipmentComponent,  },
  { path: 'shipments-old/:id', component: ShipmentDetailComponent,  },
  { path: 'containers-table', component: ContainersComponent,  },
  { path: 'containers-table/:id', component: ContainerDetailComponent,  },
  { path: 'shippingRequests', component: ShippingRequestsComponent,  },
  { path: 'documents', component: DocumentsComponent,  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [],
  providers: [],
})
export class ExplorerRoutingModule {}
