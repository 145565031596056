import { User, EMAIL_SIGNUP_STATES, STATES, CreateEmailStatus, LoginCompanyStatusType, AuthRole } from './user.model';
import { AuthData } from './auth-data.model';
import { NgForm } from '@angular/forms';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map, mergeMap, tap } from 'rxjs/operators';
import moment from 'moment';
import { Utility } from '@dp/utilities';
import { CookieService } from 'ngx-cookie-service';
import { Organization } from 'app/settings/users/users.model';
import { deepClone } from '../../@dp/utilities/deep-clone';

@Injectable()
export class AuthService {
  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public readonly currentUser: Observable<User> = this.currentUserSubject.asObservable();
  public token: string;
  private userLocale: string;
  private organizationUrl = environment.rootUrl + environment.urls.organization;

  constructor(private router: Router, private http: HttpClient, private cookieService: CookieService) {
    let user = JSON.parse(localStorage.getItem(environment.storage.currentUser));
    if (!user && environment.hasOwnProperty('mockUser')) {
      // tslint:disable-next-line: no-string-literal
      user = environment['mockUser'] as User;
      localStorage.setItem(environment.storage.currentUser, JSON.stringify(user));
    }
    this.handleNewUser(user);
  }

  handleNewUser(user: User) {
    if (user) {
      //moment.locale(user.locale);
      moment.defaultFormat = user.datePattern;
      this.setupCookies(user);
    }
    this.currentUserSubject.next(user);
  }

  setupCookies(user: User) {
    this.cookieService.set(environment.HEADER.X_USER_TOKEN, user.token, { domain: '.cargoes.com' });
    this.cookieService.set(environment.apiKey, environment.apiValue, { domain: '.cargoes.com' });
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  registerUser(authData: AuthData) {
    return this.http
      .post(
        environment.rootUrl + environment.urls.register,
        {},
        {
          headers: new HttpHeaders({
            // [environment.apiKey]: environment.apiValue,
            Authorization: 'Basic ' + btoa(authData.email + ':' + authData.password),
          }),
          observe: 'response',
        }
      )
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;
          return user;
        })
      );
  }

  loginAfterVerify(authData: AuthData): Observable<HttpResponse<User>> {
    return this.http.post<User>(
      environment.rootUrl + environment.urls.login,
      {},
      {
        headers: new HttpHeaders({
          [environment.apiKey]: environment.apiValue,
          Authorization: 'Basic ' + btoa(authData.email + ':' + authData.password),
        }),
        observe: 'response',
      }
    );
  }

  ssoLoginUser(params: any): Observable<HttpResponse<User>> {
    return this.http
      .post(environment.rootUrl + environment.urls.login, params, {
        headers: new HttpHeaders({
          [environment.apiKey]: environment.apiValue,
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<User>) => {
          const user = response.body as User;
          console.log(user);
          return user;
        })
      );
  }

  login(authData: AuthData): Observable<HttpResponse<User>> {
    return this.http
      .post(
        environment.rootUrl + environment.urls.login,
        {},
        {
          headers: new HttpHeaders({
            [environment.apiKey]: environment.apiValue,
            Authorization: 'Basic ' + btoa(authData.email + ':' + authData.password),
          }),
          observe: 'response',
        }
      )
      .pipe(
        tap((response: HttpResponse<User>) => {
          const user = response.body as User;
          this.setupNewUser(user, response); //this would set the header X-DPW-User-Token for further http traffic
        }),
        mergeMap(
          () => this.getOrganization(),
          (v1, v2) => {
            const user = v1.body as User;
            user.accountType = v2.accountType;
            user.accountCapabilities = v2.accountCapabilities;
            user.organizationPartnerships = v2.organizationPartnerships;
            this.setupNewUser(user, v1); //setupNewUser is called again here due to the getOrganization call. Will make this simple in the api code to get user with org info and make code much cleaner here.
            return v1;
          }
        )
      );
  }

  getOrganization(): Observable<Organization> {
    return this.http.get<Organization>(this.organizationUrl);
  }

  public setupNewUser(user: User, response: HttpResponse<User>) {
    this.setupNewUserDirect(user, response.headers.get(environment.HEADER.X_USER_TOKEN));
  }

  public setupNewUserDirect(user: User, token: string) {
    this.resetSessionData();
    user.fullName = Utility.getFullName(user);
    user.token = token;

    let newUser = { ...this.currentUserValue, ...user };
    console.log('new user: ', newUser);

    localStorage.setItem(environment.storage.currentUser, JSON.stringify(newUser));
    this.handleNewUser(newUser);
  }

  setupNewUserFromStorage(user: User) {
    this.resetSessionData();
    localStorage.setItem(environment.storage.currentUser, JSON.stringify(user));
    localStorage.removeItem(environment.storage.superAdminUser);
    this.handleNewUser(user);
  }

  loginWithToken(token: string, fromSuperAdmin: boolean = null): Observable<User> {
    return this.http
      .get(environment.rootUrl + environment.urls.loginWithToken, {
        headers: new HttpHeaders({
          [environment.apiKey]: environment.apiValue,
          [environment.HEADER.X_USER_TOKEN]: token,
          [environment.HEADER.IMPERSONATED_TOKEN]: 'true',
        }),
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;
          if (fromSuperAdmin === true) {
            let result = localStorage.getItem(environment.storage.currentUser);
            localStorage.setItem(environment.storage.superAdminUser, result);
          }
          // if (environment.SSO && !environment.isProd) {
          //   user.impersonated = true;
          // }
          user.fullName = Utility.getFullName(user);
          user.token = response.headers.get(environment.HEADER.X_USER_TOKEN);
          localStorage.setItem(environment.storage.currentUser, JSON.stringify(user));
          this.resetSessionData();
          //this.currentUserSubject.next(user);
          this.handleNewUser(user);
          return user;
        })
      );
  }

  public resetSessionData() {
    //reset new session data
    localStorage.removeItem(environment.storage.currentSession);
  }

  // new company case
  signupCompany(payload: Object): Observable<Object> {
    return of({
      result: 'success',
      company: {
        id: 1,
      },
    });
  }
  joinCompany(payload: Object): Observable<Object> {
    return of({});
  }

  private authSuccessfully() {
    // this.authChange.next(true);
    this.router.navigate(['/welcome']);
  }

  userUpdated(user: User) {
    const newUser = { ...this.currentUserValue, ...user };
    // console.log('old user', this.currentUserValue, 'new user', newUser);
    localStorage.setItem(environment.storage.currentUser, JSON.stringify(newUser));
    this.handleNewUser(newUser);
  }

  logout() {
    localStorage.removeItem(environment.storage.currentUser);
    localStorage.removeItem(environment.storage.currentSession);
    localStorage.removeItem(environment.storage.superAdminUser);

    this.cookieService.deleteAll();

    this.currentUserSubject.next(null);
    this.router.navigate(['/welcome']);
  }

  createEmail(email: string): Observable<CreateEmailStatus> {
    return this.http.post<CreateEmailStatus>(environment.rootUrl + environment.urls.register_email, {
      userEmail: email,
    });
  }

  // resendEmail(email: string): Observable<CreateEmailStatus> {
  //   //mock
  //   return of(true);
  // }

  resetPasswordEmail(email: string): Observable<Object> {
    return this.http.post<Object>(environment.rootUrl + environment.urls.forgot_password, {
      userEmail: email,
    });
  }
  resetPassword(payload: Object): Observable<Object> {
    return this.http.post<Object>(environment.rootUrl + environment.urls.forgot_password, payload);
  }

  changeEmailConfirm(verificationCode: string): Observable<Object> {
    const payload = { verificationCode };
    return this.http.post<Object>(environment.rootUrl + environment.urls.changeEmailConfirm, payload, {
      headers: { 'Content-Type': 'application/json' },
      observe: 'response',
    });
  }

  sa_getUserToken(payload): Observable<Object> {
    // const url = (environment.SSO && !environment.isProd) ? environment.urls.sa_getUserTokenSSO : environment.urls.sa_getUserToken;
    return this.http.post<Object>(environment.rootUrl + environment.urls.sa_getUserToken, payload);
  }

  isAuth() {
    return this.currentUserValue !== null && this.currentUserValue.companyStatus === LoginCompanyStatusType.VERIFIED;
  }

  isAdmin() {
    return this.currentUserValue && this.currentUserValue.isAdmin;
  }

  onSubmit(form: NgForm) {
    //console.log(form);
  }

  getUserLocale() {
    if (this.userLocale) {
      return this.userLocale;
    }

    let user = this.currentUserValue;
    let allowedLocales = ['en-US', 'en-GB', 'en-CA', 'en-AU', 'zh-CN'];
    if (user && user.locale) {
      if (allowedLocales.indexOf(user.locale) !== -1) {
        this.userLocale = user.locale;
        return user.locale;
      } else {
        console.warn('User Locale: ', user.locale);
      }
    }
    return 'en-US';
  }

  //#endregion
}
