<div class="search-wrapper" [ngClass]="{'mat-elevation-z1': hasBorder}">
  <div class="search">
    <mat-icon class="search-icon">search</mat-icon>
    <input matInput type="search" [placeholder]="placeholder" [(ngModel)]="value" (ngModelChange)="onModelChange($event)"
      (keyup)="onKeyUp($event)" />

    <button color="primary" mat-button [style.visibility]="value ? 'visible': 'hidden'" matSuffix mat-icon-button aria-label="Clear"
      (click)="clear()">
      <mat-icon class="no-hover-effect" style="margin-bottom: 5px;">close</mat-icon>
    </button>
  </div>
</div>