<button mat-mini-fab class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Upload documents</h1>

<div mat-dialog-content>
  <!-- <div *ngIf="uploadBusy" fxLayoutAlign="center center">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div> -->

  <div #contentZone class="p-4" style="min-height: 500px;">
    <ng-container *ngIf="channels && files">
      <ng-container *ngFor="let file of files">
        <dp-documents-upload-card-datachain [document]="file" [document_types]="document_types" [channels]="channels"
          [dataMap]="documentsDataMap[file.fileEntry.name]">
        </dp-documents-upload-card-datachain>
      </ng-container>

      <div class="text-right">
        <button mat-raised-button color="primary" (click)="uploadFiles()" [dpBusy]="uploadBusy" [disabled]="isFormInvalid()">UPLOAD</button>
      </div>
    </ng-container>

  </div>
</div>