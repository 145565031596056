<div class="tracking-details" *ngIf="transportJourneys; let t">
  <h3>Tracking Details</h3>

  <div class="line pb-18" style="border-bottom: 0.1px dashed rgb(231, 230, 230) ;">
    <!-- Left side -->
    <div class="col">

      <p *ngIf="t.shipToPort?.origin">
        <td class="label">Origin </td>
        <td class="value text-bold">
          {{t.shipToPort?.origin || ''}}
        </td>
      </p>

      <!-- Origin ATD/ETD -->
      <p *ngIf="t.shipToPort?.atd">
        <td class="label">Origin ATD </td>
        <td class="value text-bold">{{ t.shipToPort.atd }}</td>
      </p>
      <p *ngIf="!t.shipToPort?.atd && t.shipToPort.etd">
        <td class="label">Origin ETD </td>
        <td class="value text-bold">{{ t.shipToPort.etd}}</td>
      </p>
    </div>

    <!-- Right side -->
    <div class="right mr-12">
      <p *ngIf="t.shipToDestination?.destination">
        <span class="label">Destination</span>
        <span class="value">{{t.shipToDestination?.destination || ''}}</span>
      </p>

      <p *ngIf="t.shipToDestination?.ata">
        <span class="label">Destination ATA</span>
        <span class="value">{{ t.shipToDestination.ata }}</span>
      </p>
      <p *ngIf="!t.shipToDestination?.ata && t.shipToDestination?.eta">
        <span class="label">Destination ETA</span>
        <span class="value">{{ t.shipToDestination?.eta || '' }}</span>
      </p>

    </div>
  </div>


  <div class="line py-8">
    <div class="col">
      <p>
        <td class="label">Origin Port</td>
        <td class="value">
          <span *ngIf="t.portToPort?.originHub">
            {{t.portToPort.originHub || ''}}
          </span>
        </td>
      </p>

      <p>
        <td class="label">Port Gate in</td>
        <td class="value">
          <span *ngIf="t.portToPort.gateInTime">
            {{t.portToPort.gateInTime}}
          </span>
        </td>
      </p>


      <!-- Port ATD/ETD -->
      <p *ngIf="t.portToPort.hubAtd">
        <td class="label">Port ATD </td>
        <td class="value">{{t.portToPort.hubAtd }}</td>
      </p>
      <p *ngIf="!t.portToPort.hubAtd">
        <td class="label">Port ETD </td>
        <td class="value">{{ t.portToPort.hubEtd || '' }}</td>
      </p>

    </div>

    <!-- Right side -->
    <div class="right mr-12">
      <p>
        <span class="label">Destination Port</span>
        <span class="value" *ngIf="t.portToPort?.destinationHub">{{t.portToPort.destinationHub || ''}} </span>
      </p>
      <p *ngIf="t.portToPort.hubAta">
        <span class="label"> Port ATA </span>
        <span class="value"> {{ t.portToPort.hubAta }} </span>
      </p>
      <p *ngIf="!t.portToPort.hubAta">
        <span class="label"> Port ETA </span>
        <span class="value"> {{ t.portToPort.hubEta || '' }} </span>
      </p>

      <p *ngIf="t.portToPort.gateOutTime">
        <span class="label"> Port Gate out </span>
        <span class="value">{{t.portToPort.gateOutTime}}</span>
      </p>

    </div>
  </div>
</div>