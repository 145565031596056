import {
  OceanShipment,
  PageData,
  OceanShipmentFilters,
  ShipmentPageType,
  OceanShipmentDetail,
  OceanShipmentDetailItem,
  Product,
  ShipmentsAlert,
  ShipmentEventsSummary,
  MapPoint,
  VesselInfo,
  ShipmentContainer,
} from './shipments.model';
import { ContainerDetail, ALERT_STATE } from '../containers/containers.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MessageService } from '../../../app/shared/message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, publishReplay, refCount, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FILTER_FLAG, TRACKING_STATE, ContainersTracking } from '@dp/types/dp-model';
import moment from 'moment';
import { DpLocation } from 'app/network/locations/locations.model';

@Injectable()
export class ShipmentService {
  private oceanShipmentUrl = environment.rootUrl + environment.urls.oceanShipments;
  private alertsUrl = environment.rootUrl + environment.urls.alerts;
  private oceanShipmentContainersUrl = environment.rootUrl + environment.urls.oceanshipment_events;
  private oceanShipmentContainersSummaryUrl = environment.rootUrl + environment.urls.oceanShipments_events_summary;
  private oceanShipmentItemsUrl = environment.rootUrl + environment.urls.shipmentItems;
  private oceanShipmentFileUploadUrl = environment.rootUrl + environment.urls.oceanShipment_upload;
  private vesselUrl = environment.urls.vessel;
  private locationCreateUrl = environment.rootUrl + environment.urls.locationCreate;
  private templateFileUrl = environment.rootUrl + environment.urls.template_file;
  private trackingEventBaseUrl = environment.rootUrl + environment.urls.trackingEventService.baseUrl;

  constructor(private http: HttpClient, private messageService: MessageService) {}

  getOceanShipments(): Observable<OceanShipment[]> {
    return this.http.get<OceanShipment[]>(this.oceanShipmentUrl).pipe(
      tap((_) => this.log('fetched ocean shipments')),
      catchError(this.handleError<OceanShipment[]>('getOceanShipments', []))
    );
  }

  updateShipment(id: number, data: object): Observable<any> {
    return this.http.put<OceanShipment>(this.oceanShipmentUrl + '/' + id, data);
  }

  alertsAreRead(oceanShipmentId: number): Observable<any> {
    return this.http.put<any>(
      this.alertsUrl,
      {
        oceanShipmentId,
      },
      { responseType: 'text' as 'json' }
    );
  }

  getOceanShipmentAlerts(id: number): Observable<ShipmentsAlert[]> {
    return this.http.get<ShipmentsAlert[]>(this.oceanShipmentUrl + '/' + id + '/alerts').pipe(
      tap((alerts) => {
        alerts.map((alert) => {
          alert.updatedAt = moment(alert.updatedAt);
          alert.createdAt = moment(alert.createdAt);
        });
      })
    );
  }

  createAddress(location: DpLocation): Observable<object> {
    return this.http.post(this.locationCreateUrl, location, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getOceanShipmentLocations(id: string): Observable<MapPoint[]> {
    return this.http.get<MapPoint[]>(this.oceanShipmentUrl + '/' + id + '/locations').pipe(
      tap((_) => this.log('fetched MapPoints')),
      catchError(this.handleError<MapPoint[]>('getOceanShipmentLocations', null))
    );
  }
  getOceanShipmentDetail(id: number): Observable<OceanShipmentDetail> {
    return this.http.get<OceanShipmentDetail>(this.oceanShipmentUrl + '/' + id).pipe(
      tap((_) => this.log('fetched single shipment details')),
      catchError(this.handleError<OceanShipmentDetail>('getOceanShipmentDetail', null))
    );
  }

  getOceanShipmentContainers(shipnum: number): Observable<ShipmentContainer[]> {
    return this.http.get<ShipmentContainer[]>(this.oceanShipmentContainersUrl + '/' + shipnum).pipe(
      tap((_) => this.log('fetched OceanShipmentContainers')),
      catchError(this.handleError<ShipmentContainer[]>('getOceanShipmentContainers', null))
    );
  }

  getOceanShipmentEventsSummary(shipnum: number): Observable<ShipmentEventsSummary> {
    return this.http.get<ShipmentEventsSummary>(this.oceanShipmentContainersSummaryUrl + '/' + shipnum).pipe(
      tap((_) => this.log('fetched ShipmentEventsSummary')),
      catchError(this.handleError<ShipmentEventsSummary>('getOceanShipmentEventsSummary', null))
    );
  }

  getOceanShipmentItems(type: string, shipnum: string): Observable<Product[]> {
    return this.http.get<Product[]>(this.oceanShipmentItemsUrl + '/' + type + '/' + shipnum).pipe(
      tap((_) => this.log('fetched shipment Items')),
      catchError(this.handleError<Product[]>('getOceanShipmentItems', null))
    );
  }

  getOceanShipmentById(id: number): Observable<OceanShipmentDetailItem[]> {
    return this.http.get<OceanShipmentDetailItem[]>(this.oceanShipmentUrl + '/' + id);
  }

  uploadOceanShipmentFile(file: FormData): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    };

    return this.http.post(this.oceanShipmentFileUploadUrl, file);
  }

  shortDate(date: Date | moment.Moment): string {
    if (moment.isMoment(date)) {
      return date.format('YYYY-MM-DD');
    }
    return date.toISOString().substring(0, 10);
  }

  buildFilterParams(params: HttpParams, oceanShipmentFilters: OceanShipmentFilters): HttpParams {
    if (oceanShipmentFilters.flag !== FILTER_FLAG.ALL) {
      params = params.set('flagged', (oceanShipmentFilters.flag === FILTER_FLAG.FLAGGED).toString());
    }

    if (oceanShipmentFilters.departure && oceanShipmentFilters.departure !== 'All') {
      params = params.set('portOfDeparture', oceanShipmentFilters.departure);
    }

    if (oceanShipmentFilters.arrival && oceanShipmentFilters.arrival !== 'All') {
      params = params.set('portOfArrival', oceanShipmentFilters.arrival);
    }

    if (oceanShipmentFilters.vessel && oceanShipmentFilters.vessel !== 'All') {
      params = params.set('vessel', oceanShipmentFilters.vessel);
    }

    if (oceanShipmentFilters.voyage && oceanShipmentFilters.voyage !== 'All') {
      params = params.set('voyage', oceanShipmentFilters.voyage);
    }

    if (oceanShipmentFilters.carrier && oceanShipmentFilters.carrier !== 'All') {
      params = params.set('carrier', oceanShipmentFilters.carrier);
    }

    if (oceanShipmentFilters.state && oceanShipmentFilters.state !== TRACKING_STATE.ALL) {
      params = params.set('trackingState', oceanShipmentFilters.state.toString());
    }

    if (oceanShipmentFilters.alert && oceanShipmentFilters.alert !== ALERT_STATE.ALL) {
      params = params.set(
        'alertType',
        Object.keys(ALERT_STATE)
          .find((key) => ALERT_STATE[key] === oceanShipmentFilters.alert)
          .toString()
      );
    }

    if (oceanShipmentFilters.etaStart) {
      params = params.set('eta_gte', this.shortDate(oceanShipmentFilters.etaStart));
    }

    if (oceanShipmentFilters.etaEnd) {
      params = params.set('eta_lte', this.shortDate(oceanShipmentFilters.etaEnd));
    }

    if (oceanShipmentFilters.etdStart) {
      params = params.set('etd_gte', this.shortDate(oceanShipmentFilters.etdStart));
    }

    if (oceanShipmentFilters.etdEnd) {
      params = params.set('etd_lte', this.shortDate(oceanShipmentFilters.etdEnd));
    }

    return params;
  }

  findShipments(
    filter,
    sortColumn,
    sortOrder,
    page,
    limit,
    oceanShipmentFilters: OceanShipmentFilters,
    pageType: ShipmentPageType
  ): Observable<PageData> {
    page++;
    let params = new HttpParams()
      .set('q', filter)
      .set('_sort', sortColumn)
      .set('_order', sortOrder)
      .set('_page', page.toString())
      .set('_limit', limit.toString());

    params = this.buildFilterParams(params, oceanShipmentFilters);

    const emptyData = {
      total: 0,
      data: [],
    };

    return this.http
      .get<OceanShipmentDetail[]>(this.oceanShipmentUrl, {
        observe: 'response',
        params,
      })
      .pipe(
        map((res) => {
          const shipments = res.body as OceanShipmentDetail[];
          shipments.map((shipment) => (shipment.createdAt = moment(shipment.createdAt)));
          return {
            total: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
            data: shipments,
          };
        })
      );
  }

  getTemplateFile() {
    return this.http.get(this.templateFileUrl, { responseType: 'blob' });
  }

  downloadCSV(
    filter,
    sortColumn = 'containerNumber',
    sortOrder = 'asc',
    shipmentFilters = environment.emptyShipmentFilters as OceanShipmentFilters
  ) {
    let params = new HttpParams().set('q', filter).set('_sort', sortColumn).set('_order', sortOrder).set('maxPage', 'true');

    params = this.buildFilterParams(params, shipmentFilters);

    return this.http.get<OceanShipmentDetail[]>(this.oceanShipmentUrl, {
      params,
    });
  }

  getVesselLocation(vesselName: string): Observable<VesselInfo> {
    // const mockVesselInfo: VesselInfo = {
    //   lat: 42.259638,
    //   lng: -67.351456,
    //   mmsi: '477434200',
    //   poo: 'CAHAL',
    //   pod: '',
    //   atd: '2019-11-11T21:52:00+00:00',
    //   ata: '2019-11-20T19:00:00+00:00',
    //   position_recived: '',
    //   vessel_local_time: '',
    //   area: 'ECCAN - East Coast Canada',
    //   status: 'Underway Using Engine',
    //   speed: '10.8kn / 112&deg;',
    //   track_status: 'Success',
    //   track_status_detail: null
    // };
    // return of(mockVesselInfo).pipe(delay(4000));
    return this.http.post<VesselInfo>(
      this.vesselUrl,
      { vesselname: vesselName },
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'x-api-key': 'ODDSqJPEWv4zFjj9fCfCYCOstwfqHHt9jJ19oQ5f',
        }),
      }
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add(`ShipmentService: ${message}`);
  }

  /**
   * Get container tracking events by container number
   * @param containerNumber
   */
  getContainerTrackingEvents(containerNumber: string): Observable<ContainersTracking> {
    const url = this.trackingEventBaseUrl + environment.urls.trackingEventService.tracking + '?containerNumber=';
    return this.http.get<ContainersTracking>(url + containerNumber);
  }
}
