<mat-card class="mat-elevation-z1 mx-4 my-12 document-card">

  <section fxLayout="row wrap" fxLayoutAlign="space-between start">
    <div fxLayoutAlign="start center" class="mr-12" style="flex: 0 1 400px">
      <span class="material-icons mr-8" style="font-size: 3rem">
        description
      </span>
      <div>
        <p class="mb-0">
          {{document.name}}
        </p>
        <p class="text-muted mt-0">
          Uploaded {{ document.uploadedBy ? 'by ' + document.uploadedBy : '' }}
          on {{ document.createdAt | localizedDate:'local-date-time' }}
        </p>
      </div>
    </div>
    <form fxLayoutAlign="start center" [formGroup]="documentForm" style="flex: 1 1 auto; flex-wrap: wrap;">
      <div fxLayout="column" style="flex: 1 0 50%">
        <label for="document type">
          Document Type
        </label>
        <mat-form-field appearance="fill" class="mr-20" style="min-width: 160px">
          <mat-label>Choose an option</mat-label>
          <mat-select formControlName="documentType">
            <mat-option *ngFor="let item of document_types" [value]="item.code">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" style="flex: 1 0 50%">
        <label for="document ref">
          Document Reference
        </label>
        <mat-form-field appearance="fill">
          <input matInput placeholder="Type document reference" formControlName="documentReference">
        </mat-form-field>
      </div>

    </form>
  </section>


  <div class="action d-flex align-items-center">
    <button mat-button color="primary" (click)="previewDocument(document)">
      <span class="material-icons">
        remove_red_eye
      </span>
      Preview
    </button>

    <button mat-button color="primary" (click)="downloadDocument(document)" [disabled]="(documentService.loading$ | async) === document.id">
      <span class="d-flex align-items-center justify-content-between p-8">
        <span class="material-icons material-icons-outlined">
          cloud_download
        </span>
        <span class="ml-4">Download</span>
        <mat-spinner class="ml-8" [diameter]="15" *ngIf="(documentService.loading$ | async)  === document.id"></mat-spinner>
      </span>
    </button>


    <button mat-button color="warn" [hidden]="isReadOnlyDocument(document)" (click)="deleteDocument(document)" data-id="{{document.id}}">
      <span class="material-icons-outlined">
        delete
      </span>
      DELETE
    </button>
  </div>
</mat-card>