import { Component, OnInit, Input, SimpleChanges, HostBinding } from '@angular/core';
import { SHIPMENT_STATE_TYPE, Shipment } from '../shipments2.model';
import { TimeLine, ContainerBlock } from '../shipment-timeline/shipment-timeline.model';
import { TimelineSpot } from '@dp/types';

@Component({
  selector: 'dp-shipment-timeline-horizontal',
  templateUrl: './shipment-timeline-horizontal.component.svg',
  styleUrls: ['./shipment-timeline-horizontal.component.scss'],
})
export class ShipmentTimelineHorizontalComponent implements OnInit {
  timeLine: TimeLine;

  // for horizontal
  container: ContainerBlock = { w: 455, h: 54, padding: 3 };

  // for vertical
  containerVertical: ContainerBlock = { w: 190, h: 470, padding: 35 };

  // @Input() trackingTrace: TrackingTraceEntity[];
  @Input() shipment: Shipment;
  @Input() spots: TimelineSpot[];
  @Input() state: SHIPMENT_STATE_TYPE; //todo no longer need
  @Input() exceptionSeverity: number;
  @Input() vertical: boolean = false;
  @Input() showTitle = true;
  @Input() height: number = 54;
  @Input() tooltipEnabled: boolean = false;
  @Input() params: any = null;

  @Input() width: string;
  @HostBinding('style.width') get w() {
    return this.width ? this.width : '';
  }
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // if we are first getting our day to display
    if (changes['spots'] && changes['spots'].currentValue) {
      this.setup();
    }
  }

  setup() {
    this.timeLine = new TimeLine(this.container, this.spots, this.state, this.vertical, this.exceptionSeverity);
  }

  getExceptionSeverityClass(severity: number) {
    switch (severity) {
      case 0:
        return 'success-dark';
      case 1:
        return 'warning-dark';
      case 2:
        return 'error-dark';
      default:
        return 'success-dark';
    }
  }
}
