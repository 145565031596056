import { Component, OnInit, Output, EventEmitter, Renderer2, ViewChild, Input, ElementRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Observable, Subject } from 'rxjs';
import { map, withLatestFrom, filter, takeUntil, finalize } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { UIService } from 'app/shared/ui.service';
import { DpConfigService } from '@dp/services/config.service';
import { AuthService } from 'app/auth/auth.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faShip as falShip, faBoxAlt as falBoxAlt } from '@fortawesome/pro-light-svg-icons';
import { LogService } from '@dp/services/log.service';
import { AddContainerByTypes, NavItemsList, TemplateFileExtensionTypes } from '../nav.model';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AddContainerDlgComponent } from './add-container-dlg/add-container-dlg.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { ContainersService, SUPER_UPLOAD_COLOR } from 'app/explorer/containers';
import { dpAnimations } from '@dp/animations';
import { DpProgressBarService } from '@dp/components/progress-bar/progress-bar.service';
import { ACCOUNT_TYPES } from 'app/auth/user.model';
import { NavItem } from 'app/navigation/nav.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LogoMode } from '@dp/components/logo/logo.component';
import { IUploadResult } from 'app/upload-history/models/uh.model';
import { MilestoneService } from '@dp/services/milestone.service';

@Component({
  selector: 'dp-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
  animations: dpAnimations,
})
export class SidenavListComponent implements OnInit {
  LogoMode = LogoMode;
  loadSquareLogoFailed = false;
  loadLongLogoFailed = false;
  selectedAnchorBg = ' accent-500-bg';
  orgCode: string;
  NavItemsList = NavItemsList;
  isProdOrStaging = environment.production;
  isNotProd = environment.productionHosting;
  orgType: ACCOUNT_TYPES;
  ACCOUNT_TYPES = ACCOUNT_TYPES;
  TemplateFileExtensionTypes = TemplateFileExtensionTypes;
  AddContainerByTypes = AddContainerByTypes;

  // is the currently logged in user an admin?
  isAdmin = false;

  @Input() isExpanded: boolean;
  @Output() closeSidenav = new EventEmitter<void>();
  @ViewChild('navList', { static: true, read: ElementRef }) navList: ElementRef;
  selectedAnchor: HTMLElement;

  @ViewChild(MatMenuTrigger) contextMenu1: MatMenuTrigger;

  @ViewChild('globalFileInput') fileInput: ElementRef;

  dpConfig: any;
  private unsubscribeAll: Subject<any>;
  svgAttributes = {
    width: 25,
    height: 25,
    fill: '#000000',
  };

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));
  // .pipe(tap(result => //console.log(result)));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private renderer: Renderer2,
    private uiService: UIService,
    private authService: AuthService,
    private dpConfigService: DpConfigService,
    private library: FaIconLibrary,
    private addContainerDlg: MatDialog,
    private containersService: ContainersService,
    private dpProgressBarService: DpProgressBarService,
    private gaService: GoogleAnalyticsService,
    private milestone:MilestoneService,
  ) {
    this.router.events
      .pipe(
        withLatestFrom(this.isHandset$),
        filter(([a, b]) => b && a instanceof NavigationEnd)
      )
      .subscribe((_) => this.onClose());

    this.isHandset$.subscribe((data) => {
      if (data === true) {
        this.renderer.addClass(document.body, 'isHandset');
      } else {
        this.renderer.removeClass(document.body, 'isHandset');
      }
      this.uiService.isHandsetChanged.next(data);
      // //console.log('uiService is set to' + data);
    });

    this.unsubscribeAll = new Subject();

    library.addIcons(falShip, falBoxAlt);
  }

  ngOnInit(): void {

    this.dpConfigService.config.pipe(takeUntil(this.unsubscribeAll)).subscribe((config) => {
      this.dpConfig = config;
    });

    this.authService.currentUser.subscribe((user) => {
      this.orgCode = user ? user.orgCode : '';
      this.isAdmin = user ? user.isAdmin : false;
      this.orgType = user?.accountType;

      this.loadSquareLogoFailed = false;
      this.loadLongLogoFailed = false;
    });
    // console.warn('remove next line');
    // this.addContainer();
  }

  onClose() {
    this.closeSidenav.emit();
  }

  showMenu(item?: NavItem) {
    if (!item) return false;

    if (item.uri === '/container-pickup') {
      return this.orgType === ACCOUNT_TYPES.ENTERPRISE;
    }

    return true;
  }

  selectItem(event) {
    const clickedAnchor = event.path.find((path) => path.tagName === 'A') as HTMLElement;
    if (clickedAnchor !== this.selectedAnchor) {
      if (this.selectedAnchor) {
        this.selectedAnchor.className = this.selectedAnchor.className.replace(this.selectedAnchorBg, '');
      }
      this.selectedAnchor = clickedAnchor;
      this.selectedAnchor.className = this.selectedAnchor.className.concat(this.selectedAnchorBg);
    }
  }

  loadSquareLogoFail() {
    this.loadSquareLogoFailed = true;
  }

  loadLongLogoFail() {
    this.loadLongLogoFailed = true;
  }

  addContainer() {
    this.addContainerDlg.open(AddContainerDlgComponent, { maxHeight: 750, disableClose: true });
  }

  downloadTemplate(type: AddContainerByTypes, extType: TemplateFileExtensionTypes) {

    this.milestone.checkForEvent(environment.gaEvents.milestones.downloadTemplate);

    this.dpProgressBarService.show();
    this.containersService
      .downloadTemplateFile2(type, extType)
      .pipe(
        finalize(() => {
          this.dpProgressBarService.hide();
        })
      )
      .subscribe(
        (result: BlobPart) => {
          const csvDownloadAnchor: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
          const templateURL = URL.createObjectURL(new Blob([result], { type: `text/csv` }));
          csvDownloadAnchor.href = templateURL;
          csvDownloadAnchor.download = this.getTemplateName(type, extType);
          csvDownloadAnchor.click();
          URL.revokeObjectURL(templateURL);
        },
        (error) => {
          console.error('Download template file error', error);
          this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
        }
      );
  }

  getTemplateName(type: AddContainerByTypes, ext: TemplateFileExtensionTypes): string {
    const fileName =
      type === AddContainerByTypes.ByContainer
        ? 'CONTAINER_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByMBL
        ? 'UPLOAD_BY_MBL_NUMBER_TEMPLATE'
        : 'UPLOAD_BY_BOOKING_NUMBER_TEMPLATE';
    const fileExt = ext === TemplateFileExtensionTypes.csv ? 'csv' : 'xlsx';
    return `${fileName}.${fileExt}`;
  }

  handleFileInput(files: FileList) {

    this.milestone.checkForEvent(environment.gaEvents.milestones.uploadTemplate);

    if (!files.length || !files.item(0)) return;

    let fileToUpload = files.item(0);

    this.fileInput.nativeElement.value = null;
    const formData: FormData = new FormData();
    formData.append('fileName', fileToUpload, fileToUpload.name);
    //https://dpwhotfsonline.visualstudio.com/DTLP/_wiki/wikis/DTLP.wiki/32/File-uploads?anchor=import-data
    formData.append('uploadType', 'CONTAINER_SHEET');
    this.dpProgressBarService.show();
    this.containersService
      .uploadContainerFile(formData)
      .pipe(finalize(() => this.dpProgressBarService.hide()))
      .subscribe(
        (result) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.success}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.success);

          this.milestone.checkForEvent(environment.gaEvents.milestones.uploadSuccess);

          this.gotoUploadHistory(true, result);
        },
        (error) => {
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromFile}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
          this.uiService.showSnackbar(error.message, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }
  gotoUploadHistory(openReport: boolean, result: IUploadResult) {
    //note: a solution to force the url to reload, even if it is the current url.
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/upload-history'], {
        queryParams: {
          report: openReport,
          ...result,
        },
      });
    });
  }

  contextMenuPosition = { x: '0px', y: '0px' };
  showContextMenu(event: MouseEvent) {

    this.milestone.checkForEvent(environment.gaEvents.milestones.uploadDiscovered);

    event.preventDefault();
    this.contextMenuPosition.x = event.currentTarget['offsetLeft'] + event.currentTarget['offsetWidth'] - 10 + 'px';
    this.contextMenuPosition.y = event.currentTarget['offsetTop'] + 5 + 'px';
    this.contextMenu1.menu.focusFirstItem('mouse');
    this.contextMenu1.openMenu();
  }
}
