import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})

/**
 * tracks user milestones, how far they have gotten in the use of our service.
 */
export class MilestoneService {

  constructor(private gaService: GoogleAnalyticsService) { }

  checkForEvent(eventName: string) {
    console.log("checkForEvent; eventName: " + eventName);
    let event = localStorage.getItem(eventName);
    if (event) {
      if (eventName === environment.gaEvents.milestones.login) {
        let numSeen = parseInt(event);
        numSeen++;
        if (numSeen === 3) {
          this.gaService.event(environment.gaEvents.milestones.first3Logins);
        } else if (numSeen === 7) {
          this.gaService.event(environment.gaEvents.milestones.first7Logins);
        } else if (numSeen === 15) {
          this.gaService.event(environment.gaEvents.milestones.first15Logins);
        } else if (numSeen === 50) {
          this.gaService.event(environment.gaEvents.milestones.first50Logins);
        } else if (numSeen === 150) {
          this.gaService.event(environment.gaEvents.milestones.first150Logins);
        }
        localStorage.setItem(eventName, numSeen + "");
      }
    } else {
      // record that we've seen this event
      localStorage.setItem(eventName, "1");

      // send to backend
      this.gaService.event(eventName);
    }
  }
}
