<button mat-mini-fab class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 mat-dialog-title>Company settings</h1>
<div *ngIf="!isAdmin">Only the admin user can modify the company settings.</div>

<div mat-dialog-content>
  <div #contentZone class="p-4" style="min-width: 600px; min-height: 500px;">

    <div *ngIf="pageLoading" style="width: 100%;" fxLayoutAlign="center center">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>

    <!-- Account Type Section -->
    <ng-container *ngIf="organization">
      <section class="account-type">
        <h2 class="setting-section mb-12">Account type</h2>
        <div class="box p-12">
          <p>
            <span class="entity-label">Account type </span>
            <span class="entity-property"> {{ organization.accountType }} </span>
          </p>
          <p>
            <span class="entity-label">Monthly limit </span>
            <span class="entity-property"> {{ !organization.activeContainerLimit? 'Unlimited' : organization.activeContainerLimit }} active
              shipments </span>
          </p>
          <p>
            <span class="entity-label">No. of free users </span>
            <span class="entity-property"> Unlimited </span>
          </p>
        </div>
      </section>

      <section class="company-profile-form">
        <h2 class="setting-section mb-12">Company profile</h2>
        <div class="box p-12" *ngIf="!isAdmin">
          <p>
            <span class="entity-label">Company name </span>
            <span class="entity-property"> {{ organization.organizationName }} </span>
          </p>
          <p>
            <span class="entity-label">Business industry </span>
            <span class="entity-property"> {{ organization.industry }} </span>
          </p>
          <p>
            <span class="entity-label">Company phone </span>
            <span class="entity-property"> {{ organization.phone }} </span>
          </p>
          <p>
            <span class="entity-label">Company email </span>
            <span class="entity-property"> {{ organization.email }} </span>
          </p>
          <p>
            <span class="entity-label">Address line 1 </span>
            <span class="entity-property"> {{ organization.streetLine1 }} </span>
          </p>
          <p>
            <span class="entity-label">Address line 2 </span>
            <span class="entity-property"> {{ organization.streetLine2 }} </span>
          </p>
          <p>
            <span class="entity-label">City </span>
            <span class="entity-property"> {{ organization.city }} </span>
          </p>
          <p>
            <span class="entity-label">State </span>
            <span class="entity-property"> {{ organization.stateProv }} </span>
          </p>
          <p>
            <span class="entity-label">Zip </span>
            <span class="entity-property"> {{ organization.postalCode }} </span>
          </p>
          <p>
            <span class="entity-label">Country </span>
            <span class="entity-property"> {{ getCountryName() }} </span>
          </p>

        </div>
        <div class="box p-12" *ngIf="isAdmin">
          <form [formGroup]="f" [autocomplete]="'on'" (ngSubmit)="submit()">
            <div fxLayout="row" fxLayoutAlign="start start" #editableZone>
              <div class="company-setting-modify" style="margin-right: 20px;">
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Company name</mat-label>
                  <input matInput type="text" formControlName="organizationName" name="organizationName" required>
                  <mat-error *ngIf="f.controls['organizationName'].invalid">
                    {{ getErrorMessage(f.controls['organizationName']) }}</mat-error>
                </mat-form-field>
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Industry</mat-label>
                  <mat-select formControlName="industry" placeholder="Industry" #industrySelect [disableOptionCentering]="true">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="industryFilterCtrl" placeholderLabel="Find industry..."
                        noEntriesFoundLabel="'no matching industry found'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let industry of filteredIndustries | async" [value]="industry.code">{{ industry.name }}</mat-option>
                  </mat-select>

                </mat-form-field>
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Company phone</mat-label>
                  <input matInput placeholder="Phone No." type="tel" formControlName="phone" name="phone">
                  <mat-error *ngIf="f.controls['phone'].invalid">
                    {{ getErrorMessage(f.controls['phone']) }}</mat-error>
                </mat-form-field>
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Company email</mat-label>
                  <input matInput placeholder="Company Email" type="email" formControlName="email" name="email">
                  <mat-error *ngIf="f.controls['email'].invalid">
                    {{ getErrorMessage(f.controls['email']) }}</mat-error>
                </mat-form-field>
              </div>
              <div class="company-setting-modify">
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Street line 1</mat-label>
                  <input matInput type="text" formControlName="streetLine1" name="streetLine1">
                  <mat-error *ngIf="f.controls['streetLine1'].invalid">
                    {{ getErrorMessage(f.controls['streetLine1']) }}</mat-error>
                </mat-form-field>
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Street line 2</mat-label>
                  <input matInput type="text" formControlName="streetLine2" name="streetLine2">
                  <mat-error *ngIf="f.controls['streetLine2'].invalid">
                    {{ getErrorMessage(f.controls['streetLine2']) }}</mat-error>
                </mat-form-field>
                <div fxLayout="row">
                  <mat-form-field class="halfLeft" appearance="fill">
                    <mat-label>City</mat-label>
                    <input matInput type="text" formControlName="city" name="city">
                    <mat-error *ngIf="f.controls['city'].invalid">
                      {{ getErrorMessage(f.controls['city']) }}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="halfRight" appearance="fill">
                    <mat-label>State/Province</mat-label>
                    <input matInput type="text" formControlName="stateProv" name="stateProv">
                    <mat-error *ngIf="f.controls['stateProv'].invalid">
                      {{ getErrorMessage(f.controls['stateProv']) }}</mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row">
                  <mat-form-field class="halfLeft" appearance="fill">
                    <mat-label>Zip/Postal code</mat-label>
                    <input matInput type="text" formControlName="postalCode" name="postalCode">
                    <mat-error *ngIf="f.controls['postalCode'].invalid">
                      {{ getErrorMessage(f.controls['postalCode']) }}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="halfRight" appearance="fill">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="countryCode" #countrySelect [disableOptionCentering]="true">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="countryFilterCtrl" placeholderLabel="Find country..."
                          noEntriesFoundLabel="'no matching country found'"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let country of filteredCountries | async" [value]="country.country_code">{{ country.country_name
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <mat-dialog-actions>
              <div class="div-centered" style="margin-bottom: 15px;">
                <button mat-raised-button class="mr-20 " [mat-dialog-close]="true" *ngIf="isAdmin">
                  Cancel
                </button>

                <button type="submit" [dpBusy]="isFormBusy" mat-raised-button *ngIf="isAdmin" [disabled]="f.invalid"
                  class="submit-button  primary-800">
                  Submit
                </button>
                <p *ngIf="!isAdmin">You don't have permission to modify the content. Please contact the administrator.</p>
              </div>
            </mat-dialog-actions>

          </form>
        </div>
      </section>

      <!-- show to only enterprise customer -->
      <section class="blockchain">
        <h2 class="setting-section">Blockchain</h2>
        <!--<p class="text-muted">Send a support ticket to enable the Blockchain features for document sharing</p>-->
        <p class="text-muted">
          DP World CargoesDatachain© is a private permissioned blockchain network for organizations to form business consortia, and securely
          exchange trusted data among them. If your company is using CargoesDatachain©, you may connect CargoesFlow© with it to enable
          sharing of shipping documents and tracking data.
        </p>

        <!-- request not sent yet -->
        <ng-container *ngIf="checkDatachainStatusStatus('DISABLED')">
          <!-- <ng-container> -->
          <div class="box p-12" *ngIf="!isAdmin">
            <h3>CargoesDatachain© integration for data sharing hasn't been setup yet. </h3>
          </div>
          <div class="box p-12" *ngIf="isAdmin">
            <h3 fxLayoutAlign="start center">
              Enable CargoesDatachain© integration for data sharing within your business consortium
            </h3>

            <mat-error *ngIf="toggleDatachainError">
              {{ toggleDatachainError}}
            </mat-error>
            <form [formGroup]="blockChainForm" (submit)="submitBlockchainEnableRequest()" fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field class="form-field" appearance="fill" style="flex: 1; margin-right: 15px">
                <!-- <mat-label> E-mail
                  </mat-label> -->
                <input email type="email" matInput formControlName="email" placeholder="Cargoes Datachain account email address" required>
                <mat-error *ngIf="blockChainForm.controls.email.touched && blockChainForm.controls.email.invalid">
                  <span>Please type a valid email address</span>
                </mat-error>
              </mat-form-field>
              <button mat-flat-button color="primary" type="submit" [disabled]="!blockChainForm.valid">REQUEST</button>
            </form>

          </div>
        </ng-container>


        <!-- request was sent -->
        <div class="box p-12" *ngIf="!checkDatachainStatusStatus('DISABLED')">
          <h3 fxLayoutAlign="start center">
            <span>
              Blockchain integration for document sharing
            </span>
            <mat-icon class="ml-4 material-icons-outlined info-icon" matTooltip="More info" matTooltipPosition="above">
              info
            </mat-icon>
          </h3>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              <label class="text-muted">
                Datachain account
              </label>
              <p>XXXXXXX-XXXXXXXX-XXXXX</p>
            </div>
            <div>
              <label class="text-muted">
                Status
              </label>
              <p>{{ organization.accountCapabilities.orgDatachainStatus | replace }}</p>
            </div>
            <div>
              <label class="text-muted">
                Requested on
              </label>
              <p>&nbsp;</p>
            </div>
          </div>

          <!-- if status is pending -->
          <div class="text-right" *ngIf="checkDatachainStatusStatus('ENABLE_PENDING') || checkDatachainStatusStatus('DISABLE_PENDING')">
            <button mat-button color="primary" class="text-uppercase" (click)="revertRequest()">
              <mat-icon>
                close
              </mat-icon>
              Revert Request
            </button>
          </div>
        </div>
      </section>

      <section class="partners">
        <h2 class="setting-section">Business partners</h2>
        <p class="text-muted">Invite your business partners to CargoesFlow© and share your shipment data with their accounts.</p>

        <!-- <button type="button" mat-icon-button color="primary" aria-label="Add" (click)="openAddPartnerDialog()">
          <mat-icon>add_circle_outline</mat-icon>
          <span> Add a new partner</span>
        </button> -->

        <div class="box p-12">
          <ng-container *ngIf="partnersList?.length > 0">
            <table>
              <tr>
                <th>
                  Company name
                </th>
                <th>
                  Status
                </th>
              </tr>
              <tr *ngFor="let partner of partnersList;">
                <td>
                  {{ partner.organizationName }}
                </td>
                <td>
                  {{ partner.status }}
                </td>
              </tr>
            </table>
          </ng-container>
          <div *ngIf="!partnersList || partnersList.length === 0">
            <p>You don't have partners yet.</p>
            <p *ngIf="isAdmin">Please go to <a class="link" routerLink="/contact-us" [queryParams]="{qa: 'QA-6'}"
                [mat-dialog-close]="true">Contact Us</a>
              page and submit your
              request.</p>
          </div>

        </div>
      </section>

      <section>
        <h2 class="accessiblity-section">Preferences</h2>
        <p class="text-muted">Manage application preferences for your company.</p>

        <div class="box p-12">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              Show shipment documents in shared shipment links
            </div>
            <mat-slide-toggle [checked]="organization.accountPreferences?.showDocumentsInSharedLinks"
              (change)="documentsSliderChange($event)" *ngIf="isAdmin">
              {{ organization.accountPreferences?.showDocumentsInSharedLinks ? 'On' : 'Off'}}
            </mat-slide-toggle>
            <div *ngIf="!isAdmin">{{ organization.accountPreferences?.showDocumentsInSharedLinks ? 'On' : 'Off'}}</div>
          </div>

        </div>
      </section>

    </ng-container>

    <div class="pt-12">
      <ng-template adHost></ng-template>
    </div>

  </div>
</div>