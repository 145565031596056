import { Component, OnInit, Input } from '@angular/core';
import { DeliveryBoardModel2 } from 'app/delivery-board2/delivery-board.model';

@Component({
  selector: 'dp-flagged-time',
  templateUrl: './flagged-time.component.html',
  styleUrls: ['./flagged-time.component.scss'],
})
export class FlaggedTimeComponent implements OnInit {
  @Input() flaggedTime: DeliveryBoardModel2.FlaggedTime;
  @Input() maxWidth: string;
  constructor() {}

  ngOnInit(): void {}
}
