import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Port, Address } from 'app/explorer/po';

@Component({
  selector: 'dp-shipment-route',
  templateUrl: './shipment-route.component.html',
  styleUrls: ['./shipment-route.component.scss']
})
export class ShipmentRouteComponent implements OnInit, OnChanges {
  @Input()
  ports: Port[];
  @Input() 
  selectedOrigin: Address;
  @Input() 
  selectedDestination: Address;

  selectPOA: Port;
  selectPOD: Port;
  origin: Address;
  destination: Address;

  constructor() { }

  ngOnInit() { 
  }

  ngOnChanges() {
    this.origin = this.selectedOrigin;
    this.destination = this.selectedDestination;
  }
}
