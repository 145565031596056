import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DropdownFilterProps } from './dropdown-filter.model';
import { MdePopoverTrigger } from '@material-extended/mde';
import { R3TargetBinder } from '@angular/compiler';

@Component({
  selector: 'dp-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent implements OnInit {
  @Input() props: DropdownFilterProps;
  @Output() selectionChange = new EventEmitter();
  @ViewChild(MdePopoverTrigger) dropdownFilterPopover: MdePopoverTrigger;

  ngOnInit() {}

  openPopover(target: MdePopoverTrigger) {
    target.openPopover();
  }

  onApply() {
    
  }

  closePopover() {
    this.dropdownFilterPopover.togglePopover();
  }
}
