import { Component, OnInit, ViewChild, Output, ElementRef } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { Subject, forkJoin } from 'rxjs';
import { FILTER_FLAG, TRACKING_STATE } from '@dp/types';
import { environment } from 'environments/environment';
import { EventEmitter } from '@angular/core';
import deepEqual from 'deep-equal';

import { ProgressService, ProgressRef } from 'app/shared';
import moment from 'moment';
import { OceanShipmentFilters, ALERT_STATE } from '../shipments.model';
import { ContainersService } from 'app/explorer/containers/containers.service';


@Component({
  selector: 'dp-shipment-filters',
  templateUrl: './shipment-filters.component.html',
  styleUrls: ['./shipment-filters.component.scss']
})
export class ShipmentFiltersComponent implements OnInit {

  origins: string[];
  selectedOrigin: string;
  destinations: string[];
  selectedDestination: string;
  vessels: string[];
  selectedVessel: string;
  voyages: string[];
  selectedVoyage: string;
  carriers: string[];
  selectedCarrier: string;

  emptyFilters = environment.emptyShipmentFilters as OceanShipmentFilters;
  selectedState: TRACKING_STATE;
  states: string[];

  alerts: string[];
  selectedAlert: ALERT_STATE;

  selectedFlag: FILTER_FLAG;
  flags: string[];
  shipDateStart: moment.Moment;
  shipDateEnd: moment.Moment;
  deliveryDateStart: moment.Moment;
  deliveryDateEnd: moment.Moment;
  savedFilters: OceanShipmentFilters;
  progressRef: ProgressRef;
  isFilterLoaded = false;

  @ViewChild(MdePopoverTrigger, { static: true }) filterPopover: MdePopoverTrigger;
  @ViewChild('filtersContent', { static: true }) filtersContent: ElementRef;
  unsubscribeAll: Subject<any>;

  @Output()
  filterChange = new EventEmitter();

  get filters(): OceanShipmentFilters {
    return {
      flag: this.selectedFlag,
      state: this.selectedState,
      alert: this.selectedAlert,
      departure: this.selectedOrigin,
      arrival: this.selectedDestination,
      vessel: this.selectedVessel,
      voyage: this.selectedVoyage,
      carrier: this.selectedCarrier,
      etdStart: this.shipDateStart,
      etdEnd: this.shipDateEnd,
      etaStart: this.deliveryDateStart,
      etaEnd: this.deliveryDateEnd
    };
  }

  set filters(data: OceanShipmentFilters) {
    this.selectedOrigin = data.departure;
    this.selectedDestination = data.arrival;
    this.selectedVessel = data.vessel;
    this.selectedVoyage = data.voyage;
    this.selectedCarrier = data.carrier;
    this.selectedFlag = data.flag;
    this.selectedState = data.state;
    this.selectedAlert = data.alert;
    this.shipDateStart = data.etdStart;
    this.shipDateEnd = data.etdEnd;
    this.deliveryDateStart = data.etaStart;
    this.deliveryDateEnd = data.etaEnd;
  }

  constructor(private containerService: ContainersService, private progressService: ProgressService) {
    this.unsubscribeAll = new Subject();
    this.states = Object.keys(TRACKING_STATE).map(key => TRACKING_STATE[key].toString());
    this.flags = Object.keys(FILTER_FLAG).map(key => FILTER_FLAG[key].toString());
    this.alerts = Object.keys(ALERT_STATE).map(key => ALERT_STATE[key].toString());
  }

  ngOnInit() {
    this.initFilters();
  }

  closePopover() {
    this.filterPopover.togglePopover();
    this.applyFilters();
  }

  openPopover(target: MdePopoverTrigger) {
    target.openPopover();

    if (!this.isFilterLoaded) {
      this.progressRef = this.progressService.showProgress(this.filtersContent);
      this.isFilterLoaded = true;

      const observable = forkJoin([
        this.containerService.getOrigins(),
        this.containerService.getDestinations(),
        this.containerService.getVessels(),
        this.containerService.getVoyages(),
        this.containerService.getCarriers()
      ]);

      observable.subscribe({
        next: ([origins, destinations, vessels, voyages, carriers]: [string[], string[], string[], string[], string[]]) => {
          this.origins = [environment.uiSetting.select_All_DisplayText, ...origins];
          this.destinations = [environment.uiSetting.select_All_DisplayText, ...destinations];
          this.vessels = [environment.uiSetting.select_All_DisplayText, ...vessels];
          this.voyages = [environment.uiSetting.select_All_DisplayText, ...voyages];
          this.carriers = [environment.uiSetting.select_All_DisplayText, ...carriers];
        },
        complete: () => this.progressRef = this.progressService.detach(this.progressRef)
      });

    }
  }

  applyFilters() {
    if (!deepEqual(this.filters, this.savedFilters)) {
      this.saveFilters();
    }
  }

  saveFilters() {
    localStorage.setItem(environment.storage.oceanShipmentFilters, JSON.stringify(this.filters));
    this.savedFilters = this.filters;
    this.filterChange.emit(this.savedFilters);
  }

  initFilters() {
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    function reviver(value) {
      if (typeof value === 'string' && dateFormat.test(value)) {
        return moment(value);
      }

      return value;
    }

    this.savedFilters = {
      ...this.emptyFilters,
      ...(JSON.parse(localStorage.getItem(environment.storage.oceanShipmentFilters), reviver) as OceanShipmentFilters)
    };

    this.initSelectedValues();

    this.filterChange.emit(this.savedFilters);
  }

  getSupplierKey(supplier) {
    return Object.keys(supplier)[0];
  }

  initSelectedValues() {
    this.selectedOrigin = this.savedFilters.departure;
    this.selectedDestination = this.savedFilters.arrival;
    this.selectedVessel = this.savedFilters.vessel;
    this.selectedVoyage = this.savedFilters.voyage;
    this.selectedCarrier = this.savedFilters.carrier;
    this.selectedFlag = this.savedFilters.flag;
    this.selectedState = this.savedFilters.state;
    this.selectedAlert = this.savedFilters.alert;
    this.shipDateStart = this.savedFilters.etdStart;
    this.shipDateEnd = this.savedFilters.etdEnd;
    this.deliveryDateStart = this.savedFilters.etaStart;
    this.deliveryDateEnd = this.savedFilters.etaEnd;
  }

  onFiltersChange(event: OceanShipmentFilters) {
    this.filters = event;
    this.saveFilters();
  }

}
