import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Tag } from '../../types/dp-model';

@Component({
  selector: 'dp-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit, OnChanges {
  editingMode = false;
  showDots = false;

  tagEditingCtrl = new FormControl();
  tagFiltered$: Observable<Tag[]>;

  @Input() tags: Tag[];
  @Input() tagSuggestions: Tag[];
  @Input() busy: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() compactMode = false;
  @Input() searchTerm: string = '';
  @Output() deleteTag = new EventEmitter<Tag>();
  @Output() createTag = new EventEmitter<Tag>();

  @ViewChild('editTagInput') editTagInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  constructor() {}

  ngOnInit(): void {
    if (!this.tags) {
      this.tags = [];
    }
    // this.tagSuggestions = this.tagSuggestions.filter(
    //   (tagSuggestion) => !this.tags.find((tag) => tag.name.toLowerCase() === tagSuggestion.name.toLowerCase())
    // );
    // this.sortSuggestions();
    if (!this.readOnly) {
      this.tagFiltered$ = this.tagEditingCtrl.valueChanges.pipe(
        startWith({ name: '' }),
        map((value) => (typeof value === 'string' ? { name: value } : value)),
        map((value) => this._filter(value))
      );
    }

    if (this.readOnly && this.compactMode) {
      this.showDots = this.tags?.length > 2 ? true : false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.readOnly) {
      if (changes?.busy) {
        return;
      }
      this.tagSuggestions = this.tagSuggestions?.filter(
        (tagSuggestion) => !this.tags?.find((tag) => tag.name.toLowerCase() === tagSuggestion.name.toLowerCase())
      );
      this.sortSuggestions();

      if (changes.tags && changes.tagSuggestions && this.editingMode) {
        this.closeEditing();
      }
    } else if (this.compactMode) {
      this.showDots = this.tags?.length > 2 ? true : false;
    }
  }

  _filter(tagToFilter: Tag): Tag[] {
    const filteredValue = tagToFilter.name.toLowerCase();
    const filteredTags = this.tagSuggestions?.filter((tag) => (filteredValue ? tag.name.toLowerCase().startsWith(filteredValue) : true));
    return filteredTags;
  }

  tagSelected(event: MatAutocompleteSelectedEvent) {
    this.addTag(event.option.value, true);
  }

  closed() {}

  onEnter(name: string) {
    this.addTag({ name }, false);
  }
  addTag(tag: Tag, closeEditing = true) {
    if (this.editingMode) {
      if (tag.name) {
        if (!this.tags.find((item) => item.name.toLowerCase() === tag.name.toLowerCase())) {
          //this.tags.push(tag);
          this.createTag.emit(tag);
        }
        this.tagSuggestions = this.tagSuggestions.filter((tagSuggestion) => tagSuggestion.name.toLowerCase() !== tag.name.toLowerCase());
      }
      this.tagEditingCtrl.setValue(tag.name);
      if (closeEditing) {
        setTimeout(() => {
          this.autocomplete.closePanel();
        });
        this.closeEditing();
      } else {
        this.switchToEditing();
      }
    }
  }

  switchToEditing() {
    this.editingMode = true;
    this.tagEditingCtrl.setValue('');
    setTimeout(() => {
      this.editTagInput.nativeElement.focus();
      this.autocomplete.openPanel();
    }, 100);
  }

  closeEditing() {
    this.editingMode = false;
    this.tagEditingCtrl.setValue('');
    this.autocomplete.closePanel();
  }

  clickOutside() {
    if (this.editingMode) {
      const name = this.tagEditingCtrl.value;
      this.addTag({ name }, true);
    }
  }

  clearTag(tagClear: Tag) {
    // this.tags = this.tags.filter((tag) => tag.name !== tagClear.name);
    this.deleteTag.emit(tagClear);
    this.tagSuggestions.push(tagClear);
    this.sortSuggestions();
  }

  sortSuggestions() {
    this.tagSuggestions.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  getTagClass(i: number): string {
    if (!this.compactMode || !this.readOnly) return '';

    let tagClass = this.tags.length < 2 ? `contained${this.tags.length}` : 'containedN';
    return tagClass + ' ' + (i < 2 ? 'compactMode' : 'invisible');
  }

  getTitle(): string {
    const res = this.tags.reduce((acc, cur, index) => {
      return index < 2 ? acc : index === 2 ? cur.name : acc + '; ' + cur.name;
    }, '');
    return res;
  }
}
