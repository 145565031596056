import { NgModule } from '@angular/core';
import { ShipmentsComponent } from './ocean-shipments/shipment-list/shipments.component';
import { RouterModule } from '@angular/router';
import { ContainersComponent, ContainerFiltersComponent, ContainersService } from './containers';
import { ShipmentService } from './ocean-shipments/shipments.service';
import { ContainerDetailComponent } from './containers/container-detail/container-detail.component';
import { ContainerEventsComponent } from './ocean-shipments/container-events/container-events.component';
import { ShipmentDetailComponent } from './ocean-shipments/shipment-detail/shipment-detail.component';
import { DpSharedModule } from '@dp/shared.module';
import { MaterialModule } from 'app/material.module';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { CreateShipmentComponent } from './ocean-shipments/create-shipment/create-shipment.component';
import { DocumentsComponent } from './documents/documents.component';
import { ShippingRequestsComponent } from './shipping-requests/shipping-requests.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { DpDirectivesModule } from '@dp/directives/directives.module';
import { AddressMapModule } from '@dp/components/address-map/address-map.module';
import { ShipmentFiltersComponent } from './ocean-shipments/shipment-filters/shipment-filters.component';
import { IconValueCardModule } from '@dp/components/icon-value-card/icon-value-card.module';
import { AgmCoreModule } from '@agm/core';
import { AddressSelectModule } from '@dp/components/address-select/address-select.module';
import { ShipmentOriginDestinationComponent } from './ocean-shipments/create-shipment/shipment-origin-destination/shipment-origin-destination.component';
import { ShipmentRouteComponent } from './ocean-shipments/create-shipment/shipment-route/shipment-route.component';
import { CommonModule } from '@angular/common';
import { environment } from 'environments/environment';

@NgModule({
  imports: [
    DpSharedModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    DpPipesModule,
    FontAwesomeModule,
    MatProgressButtonsModule,
    DpDirectivesModule,
    AddressMapModule,
    AddressSelectModule,
    IconValueCardModule,
    AgmCoreModule.forRoot({ apiKey: environment.mapKey }),
  ],
  declarations: [
    ShipmentsComponent,
    ShipmentDetailComponent,
    ContainersComponent,
    ContainerFiltersComponent,
    ContainerDetailComponent,
    CreateShipmentComponent,
    DocumentsComponent,
    ShippingRequestsComponent,
    ContainerEventsComponent,
    ShipmentFiltersComponent,
    ShipmentOriginDestinationComponent,
    ShipmentRouteComponent,
  ],
  providers: [ContainersService, ShipmentService],
})
export class ExplorerModule {}
