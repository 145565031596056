import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dp-progress-container',
  templateUrl: './progress-container.component.html',
  styleUrls: ['./progress-container.component.scss']
})
export class ProgressContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
