import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { finalize, startWith, map } from 'rxjs/operators';
import { AuthService } from 'app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentMap } from 'app/shipments2/shipments2.model';
import { DpWidgetApiService } from 'app/dp-widget/dp-widget-api-service';
import { Observable, Subscription } from 'rxjs';
import { LogoMode } from '@dp/components/logo/logo.component';
import mblResponse from '../data/mbl-response2.json';
import { StaticDataService } from '@dp/services/static-data.service';
import { FormControl } from '@angular/forms';
import { ShipmentType } from 'app/shared/shared.model';

@Component({
  selector: 'dp-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: dpAnimations,
})
export class WelcomeComponent implements OnInit {
  LogoMode = LogoMode;
  isAuth: boolean;
  orgCode = '';
  token: string;
  containerNumber: string | number;

  ShipmentType = ShipmentType;
  shipmentType: ShipmentType = ShipmentType.INTERMODAL_SHIPMENT;

  trackingSearchSub: Subscription;

  isBusy: boolean = false;
  errorText: string = '';
  payload: any; //payload response
  shipmentMap: ShipmentMap;
  transportJourneys: any;
  // trackingNumber: string = 'AMFU8810207'; //test
  trackingNumber: string = '';

  source: string = ''; //user website source, i.e canada

  isDetectingCarrierName: boolean = false;
  canDetectCarrierName: boolean = true;
  showMessage: boolean = true;
  DEFAULT_CARRIER: string = 'Any carrier';
  carrierControl = new FormControl(this.DEFAULT_CARRIER);
  carriersListOptions: any[] = [];

  filteredOptions: Observable<string[]> = this.carrierControl.valueChanges.pipe(
    startWith(''),
    map((value) => this._filter(value))
  );

  get carrierName(): string {
    return this.carrierControl.value;
  }

  private _filter(value: string): string[] {
    // don't filter list
    if (value === this.DEFAULT_CARRIER) return this.carriersListOptions;
    let filterValue = '';
    if (value) {
      filterValue = value.toLowerCase();
    }
    return this.carriersListOptions.filter((option) => {
      if (option && option.carrier_name) {
        return option.carrier_name.toLowerCase().indexOf(filterValue) === 0;
      }
      return true;
    });
  }

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private dpWidgetApiService: DpWidgetApiService,
    private staticDataService: StaticDataService
  ) {}

  ngOnInit() {
    const staticData = this.staticDataService.getStaticDataDirect();
    this.carriersListOptions = staticData['carriers'];

    this.carrierControl.disable();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.containerNumber) {
        this.trackingNumber = params.containerNumber;
      }

      this.token = params['token'];
      if (this.token) {
        this.isAuth = false;
        this.authService.loginWithToken(this.token).subscribe(
          (user) => {
            this.router.navigate(['/']);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });

    this.authService.currentUser.subscribe((user) => {
      this.isAuth = this.authService.isAuth();
      this.orgCode = this.isAuth ? this.authService.currentUserValue.orgCode : '';
    });
  }

  clearData() {
    this.payload = null;
    this.errorText = '';
  }

  onClear() {
    this.trackingNumber = '';
    this.showMessage = false;
    this.carrierControl.reset(this.DEFAULT_CARRIER);
  }

  onSearchEvent(e: any) {
    let trackingNumber = '';
    if (typeof e === 'string') {
      trackingNumber = e.trim().toUpperCase();
    } else if (e instanceof KeyboardEvent) {
      let value = (e.target as HTMLInputElement).value;
      trackingNumber = value.trim().toUpperCase();
    }

    if (!trackingNumber || !this.carrierName) {
      return;
    }
    this.isBusy = true;
    this.showMessage = false;
    this.clearData();

    this.trackingSearchSub = this.dpWidgetApiService
      // test using a carrierName
      .searchTrackingNumber(trackingNumber, this.shipmentType, this.carrierName, this.source)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        (payload: any) => {
          if (payload.error) {
            this.errorText = payload.error;
            return;
          }
          this.payload = payload;
        },
        (error) => {
          this.clearData();
          if (typeof error === 'string') {
            this.errorText = error;
          } else if (error && error.status === 429) {
            this.errorText = 'Too many requests. Please wait a moment.';
          } else if (error) {
            this.errorText = 'Please provide a valid tracking number.';
          } else {
            this.errorText = "We couldn't find any information for this tracking number.";
          }
        }
      );
  }

  shipmentTypeChanged() {
    // console.log('shipmentType', this.shipmentType);
  }

  onValueChange(trackingNumber: string) {
    if (!trackingNumber || !trackingNumber.trim()) {
      this.carrierControl.reset(this.DEFAULT_CARRIER);
      // this.canDetectCarrierName = false;
      this.trackingNumber = '';
      this.carrierControl.disable();
      return;
    }

    this.carrierControl.enable();
    this.trackingNumber = trackingNumber.trim();
    this.showMessage = true;
    // Todo test more
    this.isDetectingCarrierName = true;

    // TODO: check carrier name with Tracking Number at least 5 characters
    this.dpWidgetApiService.getCarrierName(trackingNumber).subscribe(
      (payload: { carrierName: string }) => {
        if (payload) {
          if (payload.carrierName) {
            this.carrierControl.setValue(payload.carrierName);
            this.canDetectCarrierName = true;
          } else {
            // null
            this.carrierControl.setValue(this.DEFAULT_CARRIER);
            this.canDetectCarrierName = false;
          }
        }
        this.isDetectingCarrierName = false;
      },
      (error) => {
        this.carrierControl.reset(this.DEFAULT_CARRIER);
        this.isDetectingCarrierName = false;
        console.error('error: ', error);
      }
    );
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  cancelClicked() {
    console.log('cancelClicked;');
    if (this.trackingSearchSub) {
      this.trackingSearchSub.unsubscribe();
    }
  }

  keyup(): void {
    return;
  }
}
