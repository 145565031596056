<ng-container *ngIf="segmentDataTable">
  <h3 *ngIf="heading">{{heading}}</h3>
  <table mat-table [dataSource]="segmentDataTable.rows" class="full-width">
    <ng-container *ngFor="let col of segmentDataTable.header; let i = index ">
      <ng-container matColumnDef={{col}}>
        <th mat-header-cell *matHeaderCellDef style="padding: 0 5px; min-width: 60px;">
          {{segmentDataTable.header[i]}}
        </th>
        <td mat-cell *matCellDef="let element" style="padding: 0 5px; min-width: 60px;" [ngClass]="element[i].className">
          <label class="text-muted" *ngIf="element[i].time">{{element[i].time}}</label>
          {{element[i].data}}
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="segmentDataTable.header" class="compactRow"></tr>
    <tr mat-row *matRowDef="let row; columns: segmentDataTable.header;" class="compactRow"></tr>
  </table>
</ng-container>