import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  highlightSearchTerms(source: string, terms: string, patternStart = '<strong>', patternEnd = '</strong>'): string {

    if (!source || source === 'null') return '';
    if (!terms) return source;

    const words = terms.split(/[ ,]+/);
    const giReplaceTerms = words.join('|');

    const sourceWords = source.split(' ');
    return sourceWords.reduce((result, word) => {
      const re = new RegExp(giReplaceTerms, 'gi');
      const newWord = word.replace(re, (x) => {
        return patternStart + x + patternEnd;
      });

      return (result ? result + ' ' : '') + newWord;
    }, '');
  }

  transform(value: string, search: string): string {
    const valueStr = value + ''; // Ensure numeric values are converted to strings

    // for performance reason, we ignore the case one word contains multiple search terms!!!
    return this.highlightSearchTerms(valueStr, search);
  }
}
