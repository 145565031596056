// customDateAdapter.ts
import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AuthService } from 'app/auth/auth.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DpDateAdapter extends MomentDateAdapter {
  constructor(private auth: AuthService) {
    super('en-US');
  }

  public format(date: moment.Moment, displayFormat: string): string {
    const result = date.format(this.auth.currentUserValue.datePattern);

    return result;
  }
}
