<button mat-raised-button class="primary-800" [mdePopoverTriggerFor]="filterPopover" mdePopoverTriggerOn="none" (click)="openPopover(popoverTrigger)" #popoverTrigger="mdePopoverTrigger">Filters</button>
<dp-filter-label [(filters)]="filters" (filterChange)="onFiltersChange($event)"></dp-filter-label>

<mde-popover id="shipment-filters" #filterPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false" (close)="applyFilters()">
  <mat-card style="width: 500px" class="mat-elevation-z12">
    <mat-card-header>
      <mat-card-title>Filters</mat-card-title>
      <mat-card-subtitle>All filters you can change.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content fxLayout="row wrap" fxLayoutGap="20px" #filtersContent>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedFlag">
          <mat-option *ngFor="let flag of flags" value={{flag}}>{{flag}}</mat-option>
        </mat-select>
        <mat-hint>Flag</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedAlert">
          <mat-option *ngFor="let alert of alerts" value={{alert}}>{{alert}}</mat-option>
        </mat-select>
        <mat-hint>Alert</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedVessel">
          <mat-option *ngFor="let vessel of vessels" value={{vessel}}>{{vessel}}</mat-option>
        </mat-select>
        <mat-hint>Vessel</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedVoyage">
          <mat-option *ngFor="let voyage of voyages" value={{voyage}}>{{voyage}}</mat-option>
        </mat-select>
        <mat-hint>Voyage</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedOrigin">
          <mat-option *ngFor="let origin of origins" value={{origin}}>{{origin}}</mat-option>
        </mat-select>
        <mat-hint>Origin Port</mat-hint>
      </mat-form-field>


      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedDestination">
          <mat-option *ngFor="let destination of destinations" value={{destination}}>{{destination}}</mat-option>
        </mat-select>
        <mat-hint>Destination Port</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedState">
          <mat-option *ngFor="let state of states" value={{state}}>{{state}}</mat-option>
        </mat-select>
        <mat-hint>State</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedCarrier">
         <mat-option *ngFor="let carrier of carriers" value={{carrier}}>{{carrier}}</mat-option>
        </mat-select>
        <mat-hint>Carrier</mat-hint>
      </mat-form-field>


      <!-- shipDate start-->
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="containerShipStart" [(ngModel)]="shipDateStart">
        <mat-datepicker-toggle matSuffix [for]="containerShipStart"></mat-datepicker-toggle>
        <mat-datepicker #containerShipStart></mat-datepicker>
        <mat-hint>ETD From</mat-hint>
      </mat-form-field>
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="containerShipEnd" [(ngModel)]="shipDateEnd">
        <mat-datepicker-toggle matSuffix [for]="containerShipEnd"></mat-datepicker-toggle>
        <mat-datepicker #containerShipEnd></mat-datepicker>
        <mat-hint>ETD To</mat-hint>
      </mat-form-field>
      <!-- shipDate end -->
      <!-- date start-->
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="containerDeliveryStart" [(ngModel)]="deliveryDateStart">
        <mat-datepicker-toggle matSuffix [for]="containerDeliveryStart"></mat-datepicker-toggle>
        <mat-datepicker #containerDeliveryStart></mat-datepicker>
        <mat-hint>ETA From</mat-hint>
      </mat-form-field>
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="containerDeliveryEnd" [(ngModel)]="deliveryDateEnd">
        <mat-datepicker-toggle matSuffix [for]="containerDeliveryEnd"></mat-datepicker-toggle>
        <mat-datepicker #containerDeliveryEnd></mat-datepicker>
        <mat-hint>ETA To</mat-hint>
      </mat-form-field>
      <!-- date end -->

    </mat-card-content>
    <mat-card-actions align="right">
      <button (click)="closePopover()" mat-button>Close</button>
    </mat-card-actions>
  </mat-card>
</mde-popover>
