import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DpWidgetApiService } from 'app/dp-widget/dp-widget-api-service';

@Component({
  selector: 'dp-container-accordion',
  templateUrl: './container-accordion.component.html',
  styleUrls: ['./container-accordion.component.scss'],
})
export class ContainerAccordionComponent implements OnInit {
  @Input() container: any;
  shipmentDetailPayload: any = null;
  errorMsg: string = '';
  loading: boolean = false;

  constructor(private dpWidgetApiService: DpWidgetApiService) {}

  ngOnInit(): void {}

  onOpened(container) {
    this.loading = true;
    this.errorMsg = '';

    if (this.shipmentDetailPayload) return;

    const { id, shipmentId, shipmentGroupUploadId } = container;
    this.dpWidgetApiService.searchTrackingNumberDetail(id, shipmentGroupUploadId).subscribe(
      (payload: any) => {
        this.shipmentDetailPayload = payload?.data;
        this.loading = false;
      },
      (errorResponse: HttpErrorResponse) => {
        console.log('error: ', errorResponse);
        this.errorMsg = errorResponse.error;
        this.loading = false;
      }
    );
  }

  getStatusColor(status) {
    switch (status) {
      case 'ACTIVE':
        return 'cool-secondary-100';
      case 'COMPLETED':
        return 'cool-primary-100';
      case 'UNTRACKABLE':
        return 'warning-dark';
      case 'PENDING':
        return 'black-disabled-100';
      default:
        return '';
    }
  }
}
