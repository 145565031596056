import { commonEnvironment } from './environment.common';

const specialEnvironment = {
  production: false,
  mockUser: {
    userEmail: 'mjk@demoimporter.com',
    firstName: 'Magic',
    middleName: null,
    lastName: 'Jack',
    phone: '1-206-123-4567',
    role: null,
    locale: 'en-US',
    timezone: 'America/Los_Angeles',
    colorPreference: 'LT',
    state: 'VERIFIED',
    userCode: '6d6a6b4064656d6f696d706f727465722e636f6d',
    orgCode: '64656d6f696d706f727465722e636f6d'
  },
  apiValue: '859699906d5e7f76aa999b87980d41c9',

  rootUrl: 'http://localhost:3000/',
};

export const environment = {
  ...commonEnvironment,
  ...specialEnvironment
};
