<button mat-mini-fab class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>

<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content class="full-height">
  <p>{{message}}</p>
</div>

<div class="text-right">
  <button mat-button (click)="onDismiss()">No</button>
  <button mat-flat-button color="primary" (click)="onConfirm()">Yes</button>
</div>