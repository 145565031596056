<section class="notification-setting" style="min-height: 600px;">
  <div class="messageBox" *ngIf="authService.currentUserValue.pendingUserEmail">
    <p>
      Please confirm your email to enable notification!
    </p>
  </div>

  <section class="notification-settings">
    <h2 class="setting-section">Manage notifications</h2>
    <p class="text-muted">Manage your ocean and road shipment email notifications.</p>
  </section>

  <div class="p-12 ">
    <div fxLayout=" row" fxLayoutAlign="space-between center">
      <div>
        Enable email notifications
      </div>
      <mat-slide-toggle [checked]="currentUser.enableEmailNotifications" (change)="updateEmailNotification($event)">
        {{true ? 'On' : 'Off'}}
      </mat-slide-toggle>
    </div>
  </div>

  <div class="box p-12">

    <div *ngIf="isLoading" fxLayoutAlign="center center" style="height: 300px">
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <mat-accordion multi displayMode="flat" *ngFor="let setting of notificationSetting">
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ setting.section | titlecase }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngFor="let subscription of setting.subscriptions">
          <h3 class="sub-category">{{ subscription.type ? subscription.type : '&nbsp;' }}</h3>
          <dp-notification-slider *ngFor="let item of subscription.content" [item]="item" (onChange)="saveSetting(item)">
          </dp-notification-slider>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-expansion-panel>

    </mat-accordion>
  </div>

</section>