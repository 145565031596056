<mat-sidenav-container autosize>
  <mat-sidenav #drawer class="sidenav cool-primary-100" fixedInViewport 
  [attr.role]="isHandset ? 'dialog' : 'navigation'" 
  [mode]="isHandset ? 'over' : 'side'" role="navigation" [opened]="isHandset === false && isAuth && showSideNav">
    <dp-sidenav-list (closeSidenav)="drawer.close()" [isExpanded]="isExpanded"></dp-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-container">
    <dp-header (sidenavToggle)="drawer.toggle()"></dp-header>
    <ng-content></ng-content>
  </mat-sidenav-content>
  <div></div>
</mat-sidenav-container>