<div id="users-page" class="page-layout two-column simple left-sidebar inner-sidebar inner-scroll">

  <!-- HEADER -->
  <div class="header2 dp-white p-12 pl-24 mat-elevation-z1" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="logo-text h1">
      FAQ
    </span>
  </div>

  <!-- content area -->
  <div class="content px-12 fullHeight pt-12" style="margin-bottom: 10px;">
    <div class="center" dpPerfectScrollbar>
      <div class="fullHeight p-52" #contentZone fxLayout="row" fxLayoutAlign="center start">

        <dp-world-faq style="width: 1024px;" [questionsAndAnswers]="questionsAndAnswers">
        </dp-world-faq>

      </div>
    </div>
  </div>

</div>