import { Component, OnInit, Input, NgZone, AfterContentInit } from '@angular/core';
import { Address } from 'app/explorer/po';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'dp-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss']
})
export class AddressMapComponent implements OnInit {
  @Input() address: Address;
  private geoCoder;
  location: Location;
  longitude = -122.349358;
  latitude = 47.620422;

  constructor(private maps: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit() {
  }

}
