<mat-card class="mat-elevation-z1 my-12 document-card">

  <section>
    <div fxLayoutAlign="start center" class="mr-12" style="flex: 1 1 auto">
      <span class="material-icons mr-8" style="font-size: 3rem">
        description
      </span>
      <div>
        <p class="m-0">
          {{ document.name}}
        </p>
        <p class="my-4">
          <label for="Uploaded" class="text-muted">
            Uploaded
          </label>
          <span> {{ document.uploadedBy ? 'by ' + document.uploadedBy : '' }}
            on {{document.createdAt | localizedDate:'local-date-time'}}
          </span>
        </p>
        <p class="m-0">
          <label for="hash" class="text-muted">
            Hash
          </label>
          <span>{{document.documentHash || ''}}</span>
        </p>
      </div>
    </div>

    <div fxLayout="row" style="flex: 1 1 auto" fxLayoutAlign="space-between start">
      <div fxLayout="column">
        <label for="version" class="text-muted">
          Version
        </label>
        <p class="m-0">{{document.version || ''}}</p>
      </div>

      <div fxLayout="column">
        <label for="channel" class="text-muted">
          Channel
        </label>
        <p class="m-0">{{document.channelName || ''}}</p>
      </div>

      <div fxLayout="column">
        <label for="sharedTo" class="text-muted">
          Shared to
        </label>
        <p class="m-0">{{document.isPrivate ? 'Store privately' : 'All channel members'}}</p>
      </div>
    </div>
  </section>

  <br />
  <form fxLayoutAlign="start center" *ngIf="documentForm" [formGroup]="documentForm" style="flex: 1 1 auto; flex-wrap: wrap;">

    <div fxLayout="column" style="flex: 1 0 50%">
      <label for="document type">
        Document Type
      </label>
      <mat-form-field appearance="fill" class="mr-20" style="min-width: 160px">
        <mat-label>Choose an option</mat-label>
        <mat-select formControlName="documentType">
          <mat-option *ngFor="let item of document_types" [value]="item.code">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" style="flex: 1 0 50%">
      <label for="document ref">
        Document Reference
      </label>
      <mat-form-field appearance="fill">
        <input matInput placeholder="Type document reference" formControlName="documentReference">
      </mat-form-field>
    </div>

  </form>


  <div class="action d-flex align-items-center justify-content-between">
    <button mat-button color="primary" (click)="previewDocument(document)">
      <span class="material-icons">
        remove_red_eye
      </span>
      Preview
    </button>

    <button mat-button color="primary" (click)="downloadDocument(document)" [disabled]="documentService.loading$ | async">
      <span class="d-flex align-items-center p-8">
        <span class="material-icons">
          cloud_download
        </span>
        <span class="ml-4"> Download
        </span>
        <mat-spinner class="ml-8" [diameter]="15" *ngIf="documentService.loading$ | async as loading"></mat-spinner>
      </span>
    </button>

    <form>
      <button mat-button color="primary" (click)="fileInput.click()">
        <span class="material-icons-outlined">
          upgrade
        </span>
        Replace
      </button>
      <input #fileInput type="file" accept=".png, .jpg, .jpeg, .pdf" (change)="handleFileReplace(document.id, $event.target.files)"
        style="display: none;" />
    </form>

    <button mat-button color="warn" [hidden]="document.isReadOnly || document.isSystemGenerated" (click)="deleteDocument(document)"
      data-id="{{document.id}}">
      <span class="material-icons-outlined">
        delete
      </span>
      Delete
    </button>

    <button mat-button color="primary" (click)="toggleDocumentHistory(document.id)" data-id="{{document.id}}" class="ml-auto">
      <span class="material-icons" style="font-size: 20px">
        history
      </span>
      History
      <mat-icon style="vertical-align: middle;">{{showDocumentHistory? 'expand_less' : 'expand_more'}}</mat-icon>
    </button>
  </div>

  <div *ngIf="showDocumentHistory && documentsList" class="document-versions border mt-12">

    <div *ngFor="let item of documentsList" fxLayout="row wrap" fxLayoutAlign="space-between end" class="p-12 mb-8 document-item"
      fxLayoutGap="5px">
      <div>
        <label for="version">Version</label>
        <p>{{item.version}}</p>
      </div>
      <div style="width: 15%">
        <label for="Uploaded">File name</label>
        <p>{{item.name}}</p>
      </div>
      <div>
        <label for="Uploaded">Uploaded by</label>
        <p>{{item.uploadedBy || ''}}</p>
      </div>
      <div>
        <label for="Uploaded">Uploaded timestamp</label>
        <p>{{item.createdAt | localizedDate:'local-date-time'}}</p>
      </div>
      <div>
        <label for="Uploaded">Hash</label>
        <p>{{item.documentHash}}</p>
      </div>
      <!-- document use parent document -->
      <button mat-button color="primary" (click)="previewDocumentHelper(document, item)">
        <span class="material-icons">
          remove_red_eye
        </span>
        <span> Preview
        </span>

      </button>

      <button mat-button color="primary" (click)="downloadDocument(document.id, item.name, item.version)">
        <span class="d-flex align-items-center justify-content-between p-8">
          <span class="material-icons">
            cloud_download
          </span>
          <span class="ml-4">Download
          </span>
          <mat-spinner class="ml-8" [diameter]="15" *ngIf="(documentService.loading$ | async)  === document.id"></mat-spinner>
        </span>
      </button>
    </div>

  </div>
</mat-card>