<dp-progress-bar></dp-progress-bar>
<dp-main-nav>
  <router-outlet></router-outlet>

  <!-- turning this off until all pages have been revised to work with it.
  <div
    style="width: 100%; height: 20px; background-color: #F5F5F5; padding-right: 20px; color:gray; text-decoration: underline; cursor: pointer; font-size: 10px;"
    fxLayoutAlign="end end">
    <a (click)="openLink(PolicyTypes.TERMS_OF_SERVICE)" style="margin-right: 10px;">Terms of Service</a>
    <a (click)="openLink(PolicyTypes.COOKIE_POLICY)" style="margin-right: 10px;">Cookie Policy</a>
    <a (click)="openLink(PolicyTypes.PRIVACY_POLICY)">Privacy Notice</a>
  </div>-->
</dp-main-nav>
