import { ElementRef, Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { timer, Subscription } from 'rxjs';

import { DynamicOverlay } from './overlay/dynamic-overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { ProgressContainerComponent } from './progress-container.component';
import { BlankContainerComponent } from './blank-container.component';

@Injectable()
export class ProgressService {
  constructor(private dynamicOverlay: DynamicOverlay) {}

  public showProgress(elRef: ElementRef, hideBusy: boolean = false): ProgressRef {
    if (elRef) {
      const result: ProgressRef = { subscription: null, overlayRef: null };
      result.subscription = timer(100).subscribe(() => {
        this.dynamicOverlay.setContainerElement(elRef.nativeElement);
        const positionStrategy = this.dynamicOverlay.position().global().centerHorizontally().centerVertically();
        let config = {
          positionStrategy,
          hasBackdrop: true,
        };
        if (hideBusy) {
          config = { ...config, ...{backdropClass: 'panelDisabled'}};
        }
        result.overlayRef = this.dynamicOverlay.create(config);
        if (!hideBusy) {
          result.overlayRef.attach(new ComponentPortal(ProgressContainerComponent));
        } else {
          result.overlayRef.attach(new ComponentPortal(BlankContainerComponent));
        }
      });
      return result;
    } else {
      return null;
    }
  }

  public detach(result: ProgressRef) {
    if (result) {
      result.subscription.unsubscribe();
      if (result.overlayRef) {
        result.overlayRef.detach();
      }
      result = null;
    }
    return result;
  }
}

export declare interface ProgressRef {
  subscription: Subscription;
  overlayRef: OverlayRef;
}
