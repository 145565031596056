<div *ngIf="vm$ | async as vm">
  <h2 class="sub-header">Customize notification definitions</h2>
  <p class="text-muted">
    Configure the notification settings tailored for your business needs.
  </p>

  <mat-accordion multi displayMode="flat">
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title style="font-size: 1.3rem; padding: 5px 0;">
          Container Storage period in ports
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngIf="getNotificationMetric(vm, 'CN_EXTENDED_POO_STORAGE') as metrics">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="text-primary">Origin port</h2>
          <button mat-button color="primary" (click)="currentMode = 'ADD_POO'" *ngIf="isAdmin">
            <mat-icon>add</mat-icon>
            <span>Customize a new port</span>
          </button>
        </div>

        <div *ngIf="currentMode === 'ADD_POO'">
          <dp-notification-metric-form-add [(currentMode)]="currentMode" [formData]="createDefaultFormData('CN_EXTENDED_POO_STORAGE')"
            [options]="{notificationCode: metrics.notificationCode, ports: ports, isAdmin: isAdmin}" (onDispatch)="onDispatch($event)">
          </dp-notification-metric-form-add>
          <mat-divider></mat-divider>
        </div>

        <ng-container *ngFor="let item of metrics.notificationMetrics; let i = index">
          <div *ngIf="!item.id || !deletedIds.has(item.id)">
            <mat-divider *ngIf="i !== 0"></mat-divider>
            <dp-notification-metric-form [(currentMode)]="currentMode" [formData]="item"
              [options]="{notificationCode: metrics.notificationCode, ports: ports, isAdmin: isAdmin}" (onDispatch)="onDispatch($event)">
            </dp-notification-metric-form>
          </div>
        </ng-container>

        <mat-divider></mat-divider>
      </ng-container>

      <br>

      <ng-container *ngIf="getNotificationMetric(vm, 'CN_EXTENDED_POD_STORAGE') as metrics">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="text-primary">Destination port</h2>
          <button mat-button color="primary" (click)="currentMode = 'ADD_POD'" *ngIf="isAdmin">
            <mat-icon>add</mat-icon>
            <span>Customize a new port</span>
          </button>
        </div>

        <div *ngIf="currentMode === 'ADD_POD'">
          <dp-notification-metric-form-add [(currentMode)]="currentMode" [formData]="createDefaultFormData('CN_EXTENDED_POD_STORAGE')"
            [options]="{notificationCode: metrics.notificationCode, ports: ports}" (onDispatch)="onDispatch($event)">
          </dp-notification-metric-form-add>
          <mat-divider></mat-divider>
        </div>

        <ng-container *ngFor="let item of metrics.notificationMetrics; let i = index">
          <div *ngIf="!item.id ||  !deletedIds.has(item.id)">
            <mat-divider *ngIf="i!==0"></mat-divider>
            <dp-notification-metric-form [(currentMode)]="currentMode" [formData]="item" (onDispatch)="onDispatch($event)"
              [options]="{notificationCode: metrics.notificationCode, ports: ports, isAdmin: isAdmin}">
            </dp-notification-metric-form>
          </div>
        </ng-container>

      </ng-container>

    </mat-expansion-panel>
  </mat-accordion>

  <mat-card mat-card class="p-24 mat-elevation-z1" style="background-color: white; margin-top: 2px;"
    *ngIf="getNotificationMetric(vm, 'ARRIVING_SOON') as metrics">
    <div class="row m-0 d-flex align-items-center">
      <div class="col-9">
        <h2 class="text-primary">Hours before vessel arrive at destination port</h2>
      </div>
      <div class="pl-8 pr-0 col-3 last">
        <div>
          <div style="width: 60px; padding: 5px; background-color:rgba(0, 0, 0, 0.04);">

            <!-- <input type="number" style="height: 100%; background-color: transparent;" min="1" max="100"
              *ngIf="!metrics.notificationMetrics[0].id" [value]="metrics.notificationMetrics[0].toleranceSecs"
              (change)="toleranceHoursChange($event, metrics.notificationMetrics[0])"> -->

            <input type="number" style="height: 100%; background-color: transparent;" min="1" max="100"
              [value]="metrics.notificationMetrics[0].toleranceSecs / 3600"
              (change)="toleranceHoursChange($event, metrics.notificationMetrics[0])" [attr.disabled]="isAdmin ? null : ''">

            <!-- <input type="number" style="height: 100%; background-color: transparent;" min="1" max="30" value="3"> -->
          </div>
        </div>

        <div>
          Hours
        </div>
      </div>


    </div>
  </mat-card>

  <pre>
    <!-- {{ vm | json }} -->
  </pre>
</div>