import { Component, OnInit, ViewChild, Output, ElementRef } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { PurchaseOrderService } from '../po.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { POFilters, FILTER_STATE, Supplier } from '../po.model';
import { environment } from 'environments/environment';
import { EventEmitter } from '@angular/core';
import deepEqual from 'deep-equal';
import { FILTER_FLAG } from '@dp/types';
import { ProgressService, ProgressRef } from 'app/shared';
import moment from 'moment';

@Component({
  selector: 'dp-po-filters',
  templateUrl: './po-filters.component.html',
  styleUrls: ['./po-filters.component.scss']
})
export class PoFiltersComponent implements OnInit {
  divisionCodes: string[];
  suppliers: Supplier;
  selectedSupplier: Supplier;
  selectedSupplierKey: string;
  selectedDivisionCode = environment.uiSetting.select_All_DisplayText;
  emptyFilters = environment.emptyPOFilters as POFilters;

  selectedState: FILTER_STATE;
  states: string[];

  selectedFlag: FILTER_FLAG;
  flags: string[]; //  = [environment.uiSetting.select_All_DisplayText, 'flagged', 'not flagged'];

  dateStart: moment.Moment;
  dateEnd: moment.Moment;
  shipDateStart: moment.Moment;
  shipDateEnd: moment.Moment;
  deliveryDateStart: moment.Moment;
  deliveryDateEnd: moment.Moment;
  savedFilters: POFilters;
  progressRef: ProgressRef;
  isFilterLoaded = false;

  @ViewChild(MdePopoverTrigger, { static: true }) filterPopover: MdePopoverTrigger;
  @ViewChild('filtersContent', { static: true }) filtersContent: ElementRef;
  unsubscribeAll: Subject<any>;

  @Output()
  filterChange = new EventEmitter();

  get filters(): POFilters {
    return {
      flag: this.selectedFlag,
      state: this.selectedState,
      supplier: this.selectedSupplier,
      divisionCode: this.selectedDivisionCode,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      shipDateStart: this.shipDateStart,
      shipDateEnd: this.shipDateEnd,
      deliveryDateStart: this.deliveryDateStart,
      deliveryDateEnd: this.deliveryDateEnd
    };
  }

  set filters(data: POFilters) {
    this.selectedFlag = data.flag;
    this.selectedState = data.state;
    this.selectedSupplier = data.supplier;
    this.selectedSupplierKey = this.getSupplierKey(this.selectedSupplier);
    this.selectedDivisionCode = data.divisionCode;
    this.dateStart = data.dateStart;
    this.dateEnd = data.dateEnd;
    this.shipDateStart = data.shipDateStart;
    this.shipDateEnd = data.shipDateEnd;
    this.deliveryDateStart = data.deliveryDateStart;
    this.deliveryDateEnd = data.deliveryDateEnd;
  }

  constructor(private purchaseOrderService: PurchaseOrderService, private progressService: ProgressService) {
    this.unsubscribeAll = new Subject();
    this.states = Object.keys(FILTER_STATE).map(key => FILTER_STATE[key].toString());
    this.flags = Object.keys(FILTER_FLAG).map(key => FILTER_FLAG[key].toString());
    // this.states.unshift(environment.uiSetting.select_All_DisplayText);
  }

  ngOnInit() {
    this.initFilters();
  }

  supplierChanged(event) {
    this.selectedSupplier = { [event.value]: this.suppliers[event.value] };
    //console.log(this.selectedSupplier);
  }

  openPopover(target: MdePopoverTrigger) {
    target.openPopover();

    if (!this.isFilterLoaded) {
      this.progressRef = this.progressService.showProgress(this.filtersContent);
      this.isFilterLoaded = true;
      const observable = forkJoin([
        this.purchaseOrderService.getDivisionCodes(),
        this.purchaseOrderService.getSuppliers()
      ]);

      observable.subscribe({
        next: ([divisionCodes, suppliers]: [string[], object]) => {
          this.divisionCodes = [environment.uiSetting.select_All_DisplayText, ...divisionCodes];
          this.suppliers = {All: 0, ...suppliers};
        },
        complete: () => this.progressRef = this.progressService.detach(this.progressRef)
      });
  }

  }

  closePopover() {
    if (this.filterPopover.popoverOpen) {
      this.filterPopover.togglePopover();
      this.applyFilters();
    }
  }

  applyFilters() {
    if (!deepEqual(this.filters, this.savedFilters)) {
      this.saveFilters();
    }
  }

  saveFilters() {
    localStorage.setItem(environment.storage.poFilters, JSON.stringify(this.filters));
    this.savedFilters = this.filters;
    this.filterChange.emit(this.savedFilters);
  }

  initFilters() {
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    function reviver(key, value) {
      if (typeof value === 'string' && dateFormat.test(value)) {
        return moment(value);
      }

      return value;
    }

    this.savedFilters = {
      ...this.emptyFilters,
      ...(JSON.parse(localStorage.getItem(environment.storage.poFilters), reviver) as POFilters)
    };

    this.initSelectedValues();

    this.filterChange.emit(this.savedFilters);
  }

  getSupplierKey(supplier) {
    return Object.keys(supplier)[0];
  }

  initSelectedValues() {
    this.selectedDivisionCode = this.savedFilters.divisionCode;
    this.selectedFlag = this.savedFilters.flag;
    this.selectedState = this.savedFilters.state;
    this.selectedSupplier = this.savedFilters.supplier;
    this.selectedSupplierKey = this.getSupplierKey(this.selectedSupplier);
    this.dateStart = this.savedFilters.dateStart;
    this.dateEnd = this.savedFilters.dateEnd;
    this.shipDateStart = this.savedFilters.shipDateStart;
    this.shipDateEnd = this.savedFilters.shipDateEnd;
    this.deliveryDateStart = this.savedFilters.deliveryDateStart;
    this.deliveryDateEnd = this.savedFilters.deliveryDateEnd;
  }

  onFiltersChange(event: POFilters) {
    this.filters = event;
    this.saveFilters();
  }
}
