<div id="sidenav-list">
  <!-- TODO fixed logo width push other nav items due to Padding -->
  <div style="width:100%; height: 64px; display: flex; align-items: center; padding: 0 8px;">
    <a routerLink="/" class="nav-caption p-8 div-centered">
      <dp-logo [orgCode]="orgCode" [logoMode]="isExpanded? LogoMode.wide : LogoMode.compact"></dp-logo>
    </a>
  </div>

  <div style="height: 60px; border-bottom: 2px solid #0e233d;" class="nav-item">
    <a [routerLink]="" (click)="showContextMenu($event)" class="div-centered">
      <!--<span *ngIf="!isExpanded" style="margin-left: 4px;" matTooltip="Add Containers" matTooltipPosition="after" [inlineSVG]="'assets/images/svg/side-nav/polished/add-container.svg'" [setSVGAttributes]="svgAttributes" class="icons"></span>-->
      <mat-icon *ngIf="!isExpanded" style="margin-left: 3px; margin-top: 5px; font-size: 32px; color:lightgreen;" mat-list-icon
        class="icons">
        add
      </mat-icon>
      <button id="addContainer" *ngIf="isExpanded" mat-stroked-button color="warn"
        style="margin:auto;color:lightgreen; border-color: lightgreen;">Add Containers</button>
    </a>
  </div>
  <!--<mat-divider class="line"></mat-divider>-->

  <mat-nav-list #navList class="mb-60">
    <div *ngFor="let item of NavItemsList" class="nav-item">
      <a routerLink="{{ item.uri }}" *ngIf="showMenu(item)" routerLinkActive="active-link" class="link">
        <ng-container *ngIf="isExpanded">
          <span *ngIf="item.svg" style="margin-left: 4px;" [inlineSVG]="item.svg" [setSVGAttributes]="svgAttributes" class="icons"></span>
          <mat-icon *ngIf="item.matIcon" style="margin-left: 7px; margin-top: 5px; font-size: 24px;" mat-list-icon class="icons">{{
            item.matIcon }}</mat-icon>

          <span *ngIf="isExpanded">{{ item.label }}</span>
        </ng-container>
        <ng-container *ngIf="!isExpanded">
          <span *ngIf="item.svg" style="margin-left: 6px;" matTooltip="{{ item.label }}" matTooltipPosition="after" [inlineSVG]="item.svg"
            [setSVGAttributes]="svgAttributes" class="icons">
          </span>
          <mat-icon *ngIf="item.matIcon" style="margin-left: 7px; margin-top: 5px; font-size: 24px;" mat-list-icon class="icons"
            matTooltip="{{ item.label }}" matTooltipPosition="after">{{ item.matIcon }}</mat-icon>
        </ng-container>
      </a>
      <mat-divider *ngIf="!item" class="line"></mat-divider>
    </div>
    <!-- <div class="nav-item">
      <a *ngIf="!isProd" routerLink="/contact-us" routerLinkActive="active-link" class="link">
        <mat-icon fontSet="material-icons-outlined" mat-list-icon class="icons" matTooltip="Contact US" matTooltipPosition="after">contact_phone</mat-icon>
        <span *ngIf="isExpanded">Contact US</span>
      </a>
    </div> -->
    <!-- open close nav menu button -->
    <div class="open-close-btn">
      <mat-icon (click)="isExpanded = !isExpanded" [ngClass]="isExpanded ? 'close' : 'open'" mat-list-icon>double_arrow</mat-icon>
    </div>
  </mat-nav-list>
</div>

<input #globalFileInput type="file" accept=".csv,.xlsx" id="globalFileSelect" (change)="handleFileInput($event.target.files)"
  style="display: none;" />

<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="menu1">
</div>
<mat-menu #menu1="matMenu" class='addContainerMenu'>
  <button mat-menu-item (click)="addContainer()">
    <mat-icon class="material-icons-outlined">add</mat-icon>
    <span>Add Container Shipments</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="menuCSV">
    <mat-icon class="material-icons-outlined">cloud_download</mat-icon>
    <span>Download CSV Template</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="menuExcel">
    <mat-icon class="material-icons-outlined">cloud_download</mat-icon>
    <span>Download Excel Template</span>
  </button>
  <button mat-menu-item (click)="globalFileInput.click()">
    <mat-icon class="material-icons-outlined">upload_file</mat-icon>
    <span>Upload Shipments</span>
  </button>

  <mat-divider></mat-divider>
  <button mat-menu-item (click)="gotoUploadHistory(false, {})">
    <mat-icon class="material-icons-outlined">featured_play_list</mat-icon>
    <span>Uploads History</span>
  </button>
</mat-menu>
<mat-menu #menuCSV="matMenu" class='addContainerMenu'>
  <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByContainer, TemplateFileExtensionTypes.csv)">
    Upload container # template
  </button>
  <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByBooking, TemplateFileExtensionTypes.csv)">
    Upload booking # template
  </button>
  <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByMBL, TemplateFileExtensionTypes.csv)">
    Upload MBL # template
  </button>
</mat-menu>
<mat-menu #menuExcel="matMenu" class='addContainerMenu'>
  <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByContainer, TemplateFileExtensionTypes.xlsx)">
    Upload container # template
  </button>
  <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByBooking, TemplateFileExtensionTypes.xlsx)">
    Upload booking # template
  </button>
  <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByMBL, TemplateFileExtensionTypes.xlsx)">
    Upload MBL # template
  </button>
</mat-menu>