import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: '[dp-icon-value-card]',
  templateUrl: './icon-value-card.component.html',
  styleUrls: ['./icon-value-card.component.scss']
})
export class IconValueCardComponent implements OnInit {
  @Input() iconName: string;
  @Input() label: string;
  @Input() description: string;
  @HostBinding('class') class = 'p-16 mat-elevation-z4';
  constructor() {}

  ngOnInit() {}
}
