import { Component, OnInit, Input } from '@angular/core';
import { EntityType } from 'app/shared/components/documents-upload/documents-upload.component';
import { ShipmentMap } from 'app/shipments2/shipments2.model';

// for roadTransport

@Component({
  selector: 'dp-tracking-result-road-transport',
  templateUrl: './_tracking-result-road-transport.component.html',
  styleUrls: ['./_tracking-result-road-transport.component.scss'],
})
export class TrackingResultRoadTransportComponent implements OnInit {
  EntityType = EntityType;
  @Input() payload;
  @Input() isBusy: boolean = false;
  @Input() errorText: string = '';
  @Input() showTemperature: boolean = true;
  shipmentMap: ShipmentMap;
  transportJourneys: any;
  @Input() tabs: string[];
  @Input() showDocumentsInSharedLinks: boolean;
  @Input() tokenId: string;

  constructor() {}

  ngOnInit(): void {
    let payload = this.payload;
    if (payload) {
      if (payload && payload.mapsData) {
        this.shipmentMap = payload.mapsData;
      }
      if (payload && payload.transportJourneys) {
        this.transportJourneys = payload.transportJourneys;
      }

      if (payload && payload.error) {
        console.warn(payload.error);
        this.errorText = payload.error;
      }
    }
  }
}
