import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { tap } from 'rxjs/operators';
import { UIService } from 'app/shared';
import { Router } from '@angular/router';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private uiService: UIService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentUser = this.authenticationService.currentUserValue;
    const impersonated = (this.authenticationService.currentUserValue?.impersonated) ? "true" : "false";
    const isHandset = this.uiService.isHandsetChanged.getValue();
    // //console.log(request.url);
    if (request.url === environment.urls.vessel) {
      request = request.clone({
        setHeaders: {
          [environment.apiKeyVessel]: environment.apiValueVessel,
        },
      });
    } else if (request.url.indexOf(environment.urls.loginWithToken) > 0) {
      //no extra change
      // console.log("loginWithToken", request.headers);
    } else if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          [environment.HEADER.X_USER_TOKEN]: currentUser.token,
          [environment.apiKey]: environment.apiValue,
          [environment.HEADER.X_DISPLAY_MODE]: isHandset ? 'mobile' : 'web',
          [environment.HEADER.IMPERSONATED_TOKEN]: impersonated
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          [environment.apiKey]: environment.apiValue,
          [environment.HEADER.X_DISPLAY_MODE]: isHandset ? 'mobile' : 'web',
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const token = event.headers.get(environment.HEADER.X_USER_TOKEN);
            if (token && currentUser) {
              // //console.log(
              //   '%c token intercepted=' + token,
              //   'color: lightgreen'
              // );
              currentUser.token = token;
            }
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if ([440, 401].includes(error.status)) {
              // this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } });
              this.router.navigate(['login']);
            }
          }
        }
      )
    );
  }
}
