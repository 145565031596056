<section class="d-flex flex-column" style="position: relative;">

  <div class="processing mb-12" *ngIf="failedDocumentNames.length > 0 && !uploadBusy">
    <p>Files could not be uploaded.</p>
    <ul class="error-dark-text">
      <li *ngFor="let file of failedDocumentNames" fxLayoutAlign="start center">
        <span class="material-icons mr-12">
          description
        </span>
        <span>
          {{file.name}} - {{file.error}}
        </span>
      </li>
    </ul>
  </div>
  <div class="processing mb-12" *ngIf="files.length > 0 && uploadBusy">
    <p>Files being uploaded...</p>
    <ul class="text-muted">
      <li *ngFor="let file of files" fxLayoutAlign="start center">
        <span class="material-icons mr-12">
          description
        </span>
        <span>
          {{file.relativePath}}
        </span>
      </li>
    </ul>
  </div>

  <div class="center">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
      (onFileLeave)="fileLeave($event)" accept=".png, .jpg, .jpeg, .pdf" class="custom-file-drop">

      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" style="height: 100%; border: none">
        <div class="mr-4">
          <h2 class="m-0">Drag and drop files here</h2>
          <span class="text-muted">Max 5Mb for each file and up to 10 files for each upload.
            <br />
            Supported file formats: <span class="text-muted">jpg,
              jpeg, png, pdf</span></span>
        </div>
        <button mat-raised-button class="primary-800 mat-flat-button mat-button-base" (click)="openFileSelector()">
          Browse files
        </button>
        <!-- <button type="button" (click)="openFileSelector()">Browse files</button> -->
      </ng-template>
    </ngx-file-drop>
  </div>

  <!-- No document uploaded -->
  <div class="documents-list d-flex flex-column justify-content-center align-items-center mt-12" *ngIf="numOfDocuments === 0">
    <h2 class="text-muted">No documents available!</h2>
    <img src="assets/images/svg/no-result-found.svg" />
    <!-- <p class="text-muted">Easy drag and drop to upload your first document</p> -->
  </div>

  <!-- Blockchain enabled -->
  <ng-container *ngIf="isOrgDatachainStatusEnabled()">
    <div class="documents-list" *ngIf="numOfDocuments > 0">
      <ng-container *ngFor="let document of shipmentDetail.documents">
        <dp-documents-upload-card-2 [document]="document" [document_types]="document_types">
        </dp-documents-upload-card-2>
      </ng-container>
    </div>
  </ng-container>

  <!-- blockchain not enabled -->
  <ng-container *ngIf="!isOrgDatachainStatusEnabled()">
    <div class="documents-list" *ngIf="numOfDocuments > 0">
      <ng-container *ngFor="let document of shipmentDetail.documents">
        <dp-documents-upload-card [document]="document" [document_types]="document_types">
        </dp-documents-upload-card>
      </ng-container>
    </div>
  </ng-container>

</section>