<mat-card class="mat-elevation-z1 mx-4 my-12 document-card" *ngIf="document">

  <section fxLayout="row wrap" fxLayoutAlign="space-between start">
    <div fxLayoutAlign="start center" class="mr-12" style="flex: 0 1 400px">
      <span class="material-icons mr-8" style="font-size: 3rem">
        description
      </span>
      <div fxLayout="column">
        <span>
          {{ document.name || '' }}
        </span>
        <span class="text-muted">
          Uploaded {{ document.uploadedBy ? 'by ' + document.uploadedBy : '' }}
          on {{ document.createdAt | date }}
        </span>
      </div>
    </div>
    <form fxLayoutAlign="start center" style="flex: 1 1 auto; flex-wrap: wrap;">
      <div fxLayout="column" style="flex: 1 0 50%">
        <label for="document type" class="text-muted">
          Document Type
        </label>
        <span>
          {{document.documentType }}
        </span>
      </div>

      <div fxLayout="column" style="flex: 1 0 50%">
        <label for="document ref" class="text-muted">
          Document Reference
        </label>
        <span>
          {{document.documentReference }}
        </span>
      </div>
    </form>
  </section>


  <div class="action" fxLayout>
    <button mat-button color="primary" (click)="previewDocument(document)">
      <span class="material-icons">
        remove_red_eye
      </span>
      PREVIEW
    </button>

    <button mat-button color="primary" (click)="downloadDocument(document)" class="mr-8">
      <span class="material-icons">
        cloud_download
      </span>
      <span> Download
      </span>

    </button>

  </div>
</mat-card>