export const commonEnvironment = {
  production: false,
  productionHosting: false,
  title: 'CARGOES Flow',
  enableBeta: true, // setting to deal with any 'not-mature' feature
  useMock: false,
  recapchaKey: '6Ld0xfAUAAAAAEDEjVud1lFAivsQAMPeG0jFt5Qq',
  mapKey: 'AIzaSyAkEs40CRp76UFnU2xct8-PAzY5boSxES0',
  staticData_date: '2021.08.15', //change to current date if staticData is changed!!!
  ga: 'G-WT4L8VD6DP', //prod site
  SSO: true,

  heartBeats: {
    containerDeliveryHeartBeat: 6, //every n second
    webUpdateHeartBeat: 6, //seconds
  },

  noHeaderRoutes: [
    'login',
    'register',
    'verify',
    'signup',
    'signup-old',
    'new-user',
    'set-company',
    'forgot-password',
    'approval',
    'changeEmail',
    '_tracking',
    'documents',
    'widget',
  ],
  HEADER: {
    X_API_KEY: 'X-DPW-ApiKey',
    X_USER_TOKEN: 'X-DPW-User-Token',
    X_TRACE_CONTEXT: 'X-Trace-Context',
    X_CLIENT_SOURCE: 'X-Client-Source', // android-native, ios-native, android-web, ios-web web
    X_TOTAL_COUNT: 'X-Total-Count',
    X_RATELIMIT_REMAINING: 'X-RateLimit-Remaining',
    X_RATELIMIT_LIMIT: 'X-RateLimit-Limit',
    X_DISPLAY_MODE: 'X-Display-Mode', // web, mobile
    AUTHORIZATION: 'Authorization',
    IMPERSONATED_TOKEN: 'Impersonated-Token',
  },

  gaEvents: {
    actions: {
      tabChange: 'tab-change',
      login: 'login',
      addContainerFromDlg: 'add-container-via-dlg',
      addContainerFromFile: 'add-container-via-file-upload',
      updateDTUser: 'update-dt-user',
      exportCSVOcean: 'export-intermodal-csv',
      exportCSVTruck: 'export-truck-csv',
      exportPod: 'export-pod',
      sharePartner: 'share-partner',
      deleteShipments: 'delete',
      shareLink: 'share',
      loadLink: 'load',
    },
    categories: {
      containerPage: 'container-shipments',
      roadPage: 'road-shipments',
      login: 'login', //login page
      addContainer: 'add-container',
      dtUserWelcome: 'dt-user-welcome-dlg',
      exportCSVDlg: 'export-csv-pod-dlg',
      shareWithPartner: 'share-with-partner',
      deleteShipments: 'delete-shipments',
      shareLink: 'share-link',
    },
    /* tracks a users progress through the use of the site, from first login to veteran user.*/
    milestones: {
      startRegisterAccount: 'start_register_account',
      endRegisterAccount: 'end_register_account',
      verifiedEmail: 'verified_email',
      login: 'first_login',
      uploadDiscovered: 'upload_discovered', // when user first discovers the upload functionality
      downloadTemplate: 'download_template', //template they must use to upload container details.
      uploadTemplate: 'upload_template',
      uploadSuccess: 'upload_success', // when the upload succeeds
      firstViewShipment: 'first_view_shipment',
      first3Logins: 'first_3_logins',
      first7Logins: 'first_7_logins',
      first15Logins: 'first_15_logins',
      first50Logins: 'first_50_logins',
      first150Logins: 'first_150_logins',
    },
    labels: {
      success: 'success',
      failure: 'failure',
    },
  },

  urls: {
    purchaseOrders: 'po/purchaseOrders',
    shipments: 'shipment/shipments',
    shipmentExceptions: 'shipment/exceptions',
    oceanShipments: 'ocean_shipment/ocean_shipments',
    oceanshipment_events: 'te/oceanShipmentEvents',
    oceanShipments_events_summary: 'te/oceanShipmentEventSummary',
    shipmentItems: 'ocean_shipment/shipments_items',
    containers: 'container/containers',
    newContainers: 'new_container/containers',
    newContainersFilter: 'new_container/filterDropdown',
    containerEvnts: 'te/containerEvents',
    trackingEventService: {
      baseUrl: 'te/',
      tracking: 'tracking', //?containerNumber=TCLU4835069
    },
    containerCalendar: 'container/containerCalendar',
    container_shipmenttypes: 'container/shipmentTypes',
    container_origins: 'container/portsOfDeparture',
    container_destinations: 'container/portsOfArrival',
    customerPurchaseOrders: 'po/customerPOs',
    poSuppliers: 'po/suppliers',
    poDivisionCodes: 'po/divisioncodes',
    register: 'admin/register',
    login: 'admin/login',
    // ssoLogin: 'admin/sso-login',
    loginWithToken: 'admin/generateUserInformationWithToken',
    isEmailAlreadyUsed: 'admin/isEmailAlreadyUsed',
    containerExist: 'new_container/checkContainerNumber',
    groupUploadValidate: 'shipmentGroup/upload/validate',
    defaultUserPage: '', //default should be to the root
    files_upload: 'files/upload',
    files_download: 'files/download',
    files_template: 'files/download-template/containers',
    container_template: 'files/download-template/',
    files_document: 'files/document',
    containerStats: 'container/containerStats',
    containerSummary: 'container/containerStatsSummary',
    statistics1: 'insight/statistics',
    statistics2: 'insight/leadTimePortPerformance',
    partsOfArrival: 'shipment/portsOfArrival',
    partsOfDeparture: 'shipment/portsOfDeparture',
    carriers: 'shipment/carriers',
    shipmentFilter: 'shipment/filterDropdown',
    shipmentV2Filter: 'shipmentV2/filterDropdown',
    roadPerformanceDropdowns: 'shipmentV2/filterDropDown',
    container_vessel: 'container/vessels',
    container_voyages: 'container/voyages',
    oceanShipment_upload: 'files/upload',
    container_carriers: 'container/carriers',
    vessel: 'https://fosq22wld7.execute-api.eu-west-1.amazonaws.com/dev/vesseltracksingle',
    locationCreate: 'locations',
    alerts: 'alert/alerts',
    template_file: 'files/download-template/templateFile',
    // requests used by the calendar tab --------
    calendarDays: 'shipment/calendar',
    shippingDay: 'shipment/calendar/day',
    locations: 'shipment/calendar/locations',
    calendar_dropdowns: 'shipmentV2/calendar/filterDropDown',
    shipmentCalendar: 'shipmentV2/calendar',
    shipmentCalendarDay: 'shipmentV2/calendar/day',
    // requests used by the user management tab and processes ---------
    users_in_organization: 'admin/users',
    invite_users: 'admin/inviteUsers',
    remove_user: 'admin/users/:userCode/remove',
    change_user_admin: 'admin/users/:userCode/admin',
    get_user: 'admin/users',
    edit_user: 'admin/users/:userCode',
    change_password: 'admin/users/:userCode/password',
    verify_email: 'admin/verifyEmail',
    get_user_by_verification_code: 'admin/users/verificationCode',
    static_data_remote: 'admin/staticData',
    organization: 'admin/organizations',
    settings: 'settings',
    register_email: 'admin/registerUser',
    companySearch: 'admin/organizations/search',
    setAdminApprovalStatus: 'admin/setAdminApprovalStatus',
    forgot_password: 'admin/forgotPassword',
    contactUs: 'admin/contactUs',
    changeEmailConfirm: 'admin/users/changeUserEmail',
    webUpdates: 'admin/getUserWebUpdates',
    setWebUpdate: 'admin/upsertWebUpdateMetadata',
    getSA_OrganizationsUrl: 'admin/systemAdminSearch',
    sa_getUserToken: 'admin/loginUserUsingSystemAdmin',
    //sa_getUserTokenSSO: 'admin/loginUserUsingSystemAdminSSO',
    orgFlag: 'admin/userOrgFlag',
    shipmentV2: 'shipmentV2',
    deliveryBoard: 'shipmentV2/delivery/dashboard',
    deliveryMetrics: 'shipmentV2/delivery/metrics',
    invoices: 'invoices',
  },
  lineTrancateLimit: {
    poList: 200,
    poDetail: 200,
    shipmentList: 200,
    shipmentDetail: 200,
  },
  tablePageSize: 20,
  debounceTime: 450,
  alertRemoveTimer: 1500, // 1.5 seconds to mark alerts as read
  cacheMaxAge: {
    poList: 60000, // 60 seconds
    poSuppliers: 300000, // 5 minutes
  },

  //todo: move other constants into this group
  constants: {
    millisecondsInHour: 3600000,
    millisecondsInDay: 86400000,
    // datePatterns: [
    //   'YYYY-MM-DD',
    //   'YYYY-M-D',
    //   'YYYY/MM/DD',
    //   'YYYY/M/D',
    //   'YYYY.MM.DD',
    //   'YYYY.M.D',

    //   'YY-MM-DD',
    //   'YY-M-D',
    //   'YY/MM/DD',
    //   'YY/M/D',
    //   'YY.M.D',

    //   'MM/DD/YYYY',
    //   'MM/DD/YY',
    //   'M/D/YYYY',
    //   'M/D/YY',
    //   'D-M-YYYY',
    //   'D-M-YY',
    //   'DD-MM-YYYY',
    //   'DD-MM-YY',
    //   'DD-MMM-YY',
    //   'DD/MMM/YYYY',
    //   'DD/MMM/YY',

    //   'DD.MMM.YYYY',
    //   'DD.MM.YYYY',
    //   'DD.MM.YY',
    //   'DD.M.YY',
    //   'DD.MMM.YYYY',

    //   'D/MM/YY',
    //   'D/M/YYYY',
    //   'D/M/YY',

    //   'D.MM.YYYY',
    //   'D.M.YYYY',
    //   'D.M.YY',
    //   'D. M. YY',
    //   'D MMM YYYY',
    //   'D.MM.YYYY',
    // ],
    // timePatterns: [
    //   'h:mm A',
    //   'hh:mm A',
    //   'H:mm',
    //   'HH:mm',
    //   'HH.mm [Uhr]',
    //   'H.mm',
    //   'HH.mm',
    //   'HH[H]mm',
    //   'HH[ h ]mm',
    //   'HH[h]mm',
    //   'A h:mm',
    //   'A hh:mm',
    // ],
  },

  apiKey: 'X-DPW-ApiKey',
  apiKeyVessel: 'x-api-key',
  apiValueVessel: 'ODDSqJPEWv4zFjj9fCfCYCOstwfqHHt9jJ19oQ5f',
  uiSetting: {
    select_All_DisplayText: 'All',
  },
  storage: {
    currentUser: 'current-user',
    currentOrg: 'current-org',
    superAdminUser: 'super-admin-user',
    metrics: 'metrics',
    poFilters: 'po-filters',
    containerFilters: 'container-filters',
    oceanShipmentFilters: 'shipment-filters',
    productFilters: 'product-filters',
    locationFilters: 'location-filters',
    tpFilters: 'tp-filters',
    InlandShipmentFilters: 'inland-shipment-filters',
    ShipmentFilters: 'shipments-filters',
    applicationSettings: 'application-settings',
    currentSession: 'currentSession', // data for each login session
    webUpdatesHandled: 'webUpdatesHandled', //each login session
    filters: 'filters',
  },
  emptyPOFilters: {
    dateStart: undefined,
    dateEnd: undefined,
    deliveryDateStart: undefined,
    deliveryDateEnd: undefined,
    shipDateStart: undefined,
    shipDateEnd: undefined,
    divisionCode: 'All',
    flag: 'All',
    state: 'All',
    supplier: { All: 0 },
  },

  emptyContainerFilters: {
    departure: 'All',
    arrival: 'All',
    vessel: 'All',
    voyage: 'All',
    arrivalDateStart: undefined,
    arrivalDateEnd: undefined,
    departureDateStart: undefined,
    departureDateEnd: undefined,
    flag: 'All',
    state: 'All',
    alert: 'All',
  },

  emptyShipmentFilters: {
    departure: 'All',
    arrival: 'All',
    vessel: 'All',
    voyage: 'All',
    carrier: 'All',
    arrivalDateStart: undefined,
    arrivalDateEnd: undefined,
    departureDateStart: undefined,
    departureDateEnd: undefined,
    flag: 'All',
    state: 'All',
    alert: 'All',
  },

  emptyshipmentFilters: {},

  emptyProductFilters: {
    category: 'All',
    perishable: 'All',
  },

  emptyTPFilters: {
    partnerType: 'All',
  },

  emptyLocationFilters: {
    countryCode: 'All',
    locationType: 'All',
  },

  emptyInlandShipmentFilters: {
    flag: 'All',
    destination: 'All',
    origin: 'All',
    estimateDeliveryStart: undefined,
    estimateDeliveryEnd: undefined,
    estimateArrivalStart: undefined,
    estimateArrivalEnd: undefined,
    actualDeliveryStart: undefined,
    actualDeliveryEnd: undefined,
    actualArrivalStart: undefined,
    actualArrivalEnd: undefined,
  },

  API_RESPONSE_CODES: {
    STATUS_CODES: {
      200: 'Ok',
      201: 'Created',
      204: 'no content',
      400: 'Bad request',
      401: 'Unauthorized',
      403: 'Forbidden',
      404: 'Not found',
      409: 'Conflict',
      429: 'Too Many Requests',
      440: 'Login expired',
      500: 'Server error',
    },
    REASON_CODES: {
      // the first three digits are the corresponding status code; the text will be used in log
      20000: 'Success',
      20100: 'Created',
      20400: 'No content by current parameters',
      40010: 'Invalid request data',
      40020: 'Invalid email address',
      40030: 'Bad argument error',
      40110: 'No API Key provided',
      40120: 'Invalid API Key',
      40130: 'Blocked by access_control',
      40140: 'Blacklisted IP address',
      40150: 'Missing required header',
      40160: 'Invalid value in header',
      40310: 'Client not provisioned to access route',
      40320: 'Missing Authorization header or it is invalid',
      40330: 'Incorrect username or password',
      40340: 'Username in header does match login request parameter',
      40410: 'The requested object does not exist',
      40910: 'Conflict in creation',
      42910: 'The rate of requests is over provisioned limit',
      44010: 'User token expired',
      50090: 'Internal Sever Error',
    },
  },
  insights_reports: {
    MaxEntryCountForChart: 40,
  },
  map: {
    restriction: {
      latLngBounds: {
        north: 85.0,
        south: -85.0,
        west: -180.0,
        east: 180.0,
      },
      strictBounds: true,
    },
  },
  snackBarDuration: {
    success: 3000,
    warning: 5000,
    error: 8000,
  },

  // for dropdowns, we use enum type, it has limitation to deal with enum key
  // we are moving to basic json object as below
  filterTypeList: {
    ArrivalDateFilterTypeList: {
      CURRENT_MONTH: 'Current month',
      LAST_3_MONTH: 'Past 3 months',
      LAST_6_MONTH: 'Past 6 months',
      LAST_12_MONTH: 'Past 12 months',
      YEAR_TO_DATE: 'Year to date',
      LAST_CALENDAR_YEAR: 'Last calendar year',
      CUSTOM: 'Custom Range',
    },
    ScheduleEventTypeList: {
      arrivals: 'Arrival',
      departures: 'Departure',
      pickups: 'Pickup',
      deliveries: 'Delivery',
    },
  },
  dpwStorageKeys: {
    currentUser: 'current-user-cargoes-rates',
    actingUser: 'acting-user-cargoes-rates',
    token: 'Token',
    headerTokenKey: 'X-DPW-User-Token',
    commonData: 'DP-Common-Data',
    dpwUserType: 'dpwUserType',
    dpwUserOrg: 'dpwUserOrg',
    dpwOrgSelectedUser: 'dpwOrgSelectedUser',
    CrRegistrationClicked: 'CR-RegistrationClicked',
    CrRegistrationReturnUrl: 'CR-RegistrationReturnUrl',
    FrRegistrationReturnUrl: 'FR-RegistrationReturnUrl',
    UserRecognitionCookie: 'x-user-recognition-cookie',
  },
  validators: {
    phone: '^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\0-9]*$',
  },
};
