<div *ngFor="let port of this.journeyStopAndShipments | keyvalue" class="w-500">
  <h2> {{port.key}}</h2>
  <!-- <pre>{{port.value | json}}</pre> -->
  <ng-container *ngIf="port.value.shipments">
    <div fxLayout="row" class="shipmentHeader">
      <div fxFlex="{{this.flexWidth[0]}}">Tracking #</div>
      <div fxFlex="{{this.flexWidth[1]}}">Booking #</div>
      <div fxFlex="{{this.flexWidth[2]}}">{{getColumnTitle()}}</div>
      <div fxFlex="{{this.flexWidth[3]}}">Truck #</div>
      <div fxFlex="{{this.flexWidth[4]}}">Status</div>
    </div>

    <div *ngFor="let shipment of port.value.shipments" fxLayout="row" class="shipmentRow">
      <div fxFlex="{{this.flexWidth[0]}}">
        <a [routerLink]="[getTrackingPath()]" [queryParams]="{shipmentNumber: shipment.shipment_number, detailView:true}">
          {{shipment.shipment_number}}
        </a>
      </div>
      <div fxFlex="{{this.flexWidth[1]}}">{{shipment['truck_shipment.booking_number']}}</div>
      <div fxFlex="{{this.flexWidth[2]}}">{{getColumnData(shipment)}}</div>
      <div fxFlex="{{this.flexWidth[3]}}">{{shipment['truck_shipment.truck_number']}}</div>
      <div fxFlex="{{this.flexWidth[4]}}">{{shipment['status']}}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="!port.value.shipments">
    <div *ngFor="let vessel of port.value | keyvalue">
      <div *ngIf="vessel.key">
        <mat-icon class="material-icons-outlined" style="color:#173A64;">{{getPortIcon()}}</mat-icon>
        <span class="vessel">{{ ' ' + vessel.key }}</span>
      </div>
      <div fxLayout="row" class="shipmentHeader">
        <div fxFlex="{{this.flexWidth[0]}}">Tracking #</div>
        <div fxFlex="{{this.flexWidth[1]}}">Container #</div>
        <div fxFlex="{{this.flexWidth[2]}}">Reference #</div>
        <div fxFlex="{{this.flexWidth[3]}}">{{getColumnTitle()}}</div>
        <div fxFlex="{{this.flexWidth[4]}}">Status</div>
      </div>
      <div *ngFor="let shipment of vessel.value.shipments" fxLayout="row" class="shipmentRow">
        <div fxFlex="{{this.flexWidth[0]}}">
          <a class="link" [routerLink]="[getTrackingPath()]" [queryParams]="{shipmentNumber: shipment.shipment_number, detailView:true}">
            {{shipment.shipment_number}}
          </a>
        </div>
        <div fxFlex="{{this.flexWidth[1]}}">{{shipment.container_number}}</div>
        <div fxFlex="{{this.flexWidth[2]}}">{{shipment.reference_number}}</div>
        <div fxFlex="{{this.flexWidth[3]}}">{{getColumnData(shipment)}}</div>
        <div fxFlex="{{this.flexWidth[4]}}">{{shipment.status}}</div>
      </div>
    </div>
  </ng-container>

</div>