import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'environments/environment';
import { ShipmentMap, JourneyRoute } from 'app/shipments2/shipments2.model';
import { DateAgoPipe } from '@dp/pipes/date-ago/date-ago.pipe';
import { MapSpace } from 'app/shipments2/shipments3.model';

@Component({
  selector: 'dp-shipment-map',
  templateUrl: './shipment-map.component.html',
  styleUrls: ['./shipment-map.component.scss'],
})
export class ShipmentMapComponent implements OnInit {
  // previousPossiblePathWindow;

  restriction = environment.map.restriction;
  @Input() shipmentDetail: MapSpace.ShipmentMap;
  // @Input() vessel: string;
  @Input() maxZoom = 12; //truck shipment need to set to 8
  locationName: string = '';
  openInfoWindow = false;
  stopSmall = {
    scaledSize: {
      width: 14,
      height: 14,
    },
    anchor: {
      x: 7,
      y: 7,
    },
  };
  stopSmallest = {
    scaledSize: {
      width: 2,
      height: 2,
    },
    anchor: {
      x: 1,
      y: 1,
    },
  };
  stopLarge = {
    scaledSize: {
      width: 20,
      height: 20,
    },
    anchor: {
      x: 10,
      y: 10,
    },
  };
  stopSmallComplete = {
    url: 'assets/svg/cityMarkerComplete.svg',
    ...this.stopSmall,
  };
  stopSmallIncomplete = {
    url: 'assets/svg/cityMarkerIncomplete.svg',
    ...this.stopSmall,
  };
  stopLargeComplete = {
    url: 'assets/svg/cityMarkerComplete.svg',
    ...this.stopLarge,
  };
  stopLargeIncomplete = {
    url: 'assets/svg/cityMarkerIncomplete.svg',
    ...this.stopLarge,
  };
  stopCurrent = {
    url: 'assets/svg/cityMarkerCurrent.svg',
    ...this.stopLarge,
  };
  currentLocation = {
    url: 'assets/svg/currentLocation.svg',
    ...this.stopLarge,
  };
  missingPath = {
    url: 'assets/svg/circle_missing.svg',
    ...this.stopSmallest,
  };

  constructor(private dateAgoPipe: DateAgoPipe) {}

  ngOnInit(): void {
    //clean up
    this.shipmentDetail.journeyStops = this.shipmentDetail.journeyStops.filter((stop) => stop.lat && stop.lon);
  }

  onMouseOver(infoWindow, gm, locationName: string) {
    this.locationName = locationName;
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow?.open();
  }

  getStopIcon(journeyStop: MapSpace.JourneyStop) {
    const importantStops = [
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN,
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN_PORT,
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION,
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION_PORT,
    ];
    const important = importantStops.includes(journeyStop.type);
    return journeyStop.isFuture
      ? important
        ? this.stopLargeIncomplete
        : this.stopSmallIncomplete
      : important
      ? this.stopLargeComplete
      : this.stopSmallComplete;
  }

  getFitBounds(routeKey: string): boolean {
    let result =
      this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.TOTAL_ROUTE ||
      (this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.SHIP_TO_PORT && routeKey === 'shipToPort') ||
      (this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.SHIP_TO_DESTINATION && routeKey === 'shipToDestination')
        ? true
        : false;
    //console.log('FitBounds ', routeKey, result);
    return result;
  }

  getLocationLabel(locationLabel: string, locationTimestamp) {
    let label = '';
    if (locationLabel) {
      label += locationLabel;
    }

    if (locationTimestamp) {
      label += ' (Last updated: ' + this.dateAgoPipe.transform(locationTimestamp) + ')';
    }
    return label;
  }

  getLabelIcon(stop: MapSpace.JourneyStop): string {
    return stop.type === MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN
      ? 'assets/svg/map/map-origin.svg'
      : stop.type === MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION
      ? 'assets/svg/map/map-destination.svg'
      : 'assets/svg/map/map-port.svg';
  }

  getFitBoundsForStop(stop: MapSpace.JourneyStop): boolean {
    let origins = [
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN,
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN_HUB,
      MapSpace.SHIPMENT_LOCATION_TYPES.ORIGIN_PORT,
    ];
    let destinations = [
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION,
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION_HUB,
      MapSpace.SHIPMENT_LOCATION_TYPES.DESTINATION_PORT,
    ];
    let result =
      this.shipmentDetail.shipmentType === MapSpace.ShipmentTypes.TRUCK_SHIPMENT ||
      this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.TOTAL_ROUTE ||
      (this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.SHIP_TO_PORT && origins.includes(stop.type)) ||
      (this.shipmentDetail.mapFocusMode === MapSpace.MapFocusModes.SHIP_TO_DESTINATION && destinations.includes(stop.type))
        ? true
        : false;
    return result;
  }

  getPossibleRouteMiddle(stops: MapSpace.Stop[], pointType: 'lat' | 'lon'): number {
    if (stops.length === 1) {
      return stops[0][pointType];
    }
    if (pointType === 'lat') {
      const result = (stops[0][pointType] + stops[stops.length - 1][pointType]) / 2;
      return result;
    } else {
      const distance = stops[0][pointType] - stops[stops.length - 1][pointType];
      let result = (stops[0][pointType] + stops[stops.length - 1][pointType]) / 2;
      if (distance > 180 || distance < -180) {
        result += 180;
        if (result > 180) {
          result -= 360;
        }

        return result;
      }
    }
  }

  noData() {
    return !this.shipmentDetail?.hasValidData;
  }
}
