import { Component, OnInit } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthService } from '../auth.service';
import { UIService } from 'app/shared';
import { Router } from '@angular/router';
import { UsersService } from 'app/settings/users/users.service';
import { CaptchaBase } from '../captcha.base';
import { MilestoneService } from '@dp/services/milestone.service';

@Component({
  selector: 'dp-signup2',
  templateUrl: './signup2.component.html',
  styleUrls: ['./signup2.component.scss'],
  animations: dpAnimations,
})
export class Signup2Component extends CaptchaBase implements OnInit {
  //readonly siteKey = environment.recapchaKey;

  isFormBusy = false;
  showLogo = false;
  checkingEmail = false;
  waitingForVerify = false;
  resendUserBusy = false;
  resendUsed = false;
  f: FormGroup;
  email: string;
  orgDomain = '';

  // @ViewChild('emailInput', { static: true }) emailInput: NgModel;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private milestoneService: MilestoneService
  ) {
    super();
  }

  ngOnInit(): void {

    //console.log("signup2");
    this.milestoneService.checkForEvent(environment.gaEvents.milestones.startRegisterAccount);

    this.f = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      recaptcha: ['', Validators.required],
    });
    // this.emailInput.control.valueChanges.pipe(debounceTime(environment.debounceTime), distinctUntilChanged()).subscribe((email) => {
    //   this.orgDomain = email.split('@').pop();
    //   this.reset();

    //   if (this.orgDomain && this.emailInput.control.status === 'VALID' && this.orgDomain.lastIndexOf('.') > this.orgDomain.indexOf('@')) {
    //     this.verifyImageURL('//logo.clearbit.com/' + this.orgDomain, (imageExist) => {
    //       if (imageExist) {
    //         this.showLogo = true;
    //       }
    //     });
    //     this.email = email;
    //   }
    // });
  }

  submit() {
    this.isFormBusy = true;
    this.authService
      .createEmail(this.f.value.email)
      .pipe(finalize(() => (this.isFormBusy = false)))
      .subscribe(
        (result) => {
          if (result.status === 'EXISTING') {
            this.router.navigate(['/login'], { queryParams: { email: this.f.value.email, previousUrl: '/signup' } });
          } else if (result.status === 'NEW') {
            this.waitingForVerify = true;
            this.milestoneService.checkForEvent(environment.gaEvents.milestones.endRegisterAccount);
          }
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
        }
      );
  }

  resend() {
    this.resendUserBusy = true;

    this.authService
      .createEmail(this.f.value.email)
      .pipe(
        finalize(() => {
          this.resendUserBusy = false;
          this.resendUsed = true;
        })
      )
      .subscribe(
        () => {
          this.uiService.showSnackbar('The email was just sent!', null, { duration: 3000, panelClass: 'accent' });
        },
        () => {
          this.uiService.showSnackbar('We can not send email right now. Please try again later.', null, {
            duration: 6000,
            panelClass: 'warn',
          });
        }
      );
  }

  getErrorMessage(control: FormControl) {
    let msg = '';
    if (control.hasError('required')) {
      msg = 'Email is required';
    } else if (control.hasError('email')) {
      msg = 'This is not a valid email address.';
    }
    return msg;
  }



}
