<div #contentZone class="relative" style="height: 100%;min-width: 560px;width: 560px;min-height: 100%;display:inline;">
  <button mat-icon-button class="close-button small-icon-button" (click)="closeClicked()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <div class="p-12 dateCaption" *ngIf="calendarDayDetail">
    {{getDateStamp()}}
  </div>

  <div>
    <mat-accordion multi displayMode="flat" style="height: calc(100% - 44px); display: block; scroll-behavior: smooth;overflow-y: scroll;">
      <ng-container *ngIf="calendarDayDetail">

        <mat-expansion-panel expanded *ngFor="let eventType of this.eventTypes">
          <mat-expansion-panel-header *ngIf="this.calendarDayDetail[eventType]?.count > 0">
            <div class="eventBlock" [ngClass]="eventType" fxLayout="row" fxLayoutAlign="space-between stretch">
              <div class="text-capitalize">
                {{eventType}}
              </div>
              <div style="font-weight: 700;">
                {{ this.calendarDayDetail[eventType]?.count }}
              </div>
            </div>
          </mat-expansion-panel-header>
          <dp-schedule-detail-list [journeyStopAndShipments]="this.calendarDayDetail[eventType]?.journeyStopAndShipments"
            [eventType]="eventType" [pageType]="pageType"></dp-schedule-detail-list>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>