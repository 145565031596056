import { environment } from 'environments/environment';
import moment from 'moment';
import { FILTER_FLAG, TRACKING_STATE } from '@dp/types/dp-model';

export enum DATE_STATUS {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
  PAST = 'PAST',
}

export enum SUPER_UPLOAD_FLAG {
  PARTIAL_PASSED = 'PARTIAL PASSED',
  ALL_FAILED = 'ALL FAILED',
}

export enum SUPER_UPLOAD_COLOR {
  PRIMARY = 'primary',
  WARN = 'warn',
}

export enum ALERT_STATE {
  ALL = 'All',
  OS_ARRIVED = 'SHIPMENT ARRIVED',
  OS_ARRIVED_LATE = 'SHIPMENT ARRIVED LATE',
  OS_ARRIVING = 'SHIPMENT ARRIVING SOON',
  OS_ETA = 'SHIPMENT ETA CHANGE',
  OS_ETD = 'SHIPMENT ETD CHANGE',
  OS_LATE = 'SHIPMENT ARRIVING LATE',
  C_LEFT_POD = 'CONTAINER LEFT POD',
  NULL = 'WITHOUT ALERTS',
}

export interface Container {
  id: number;
  containerNumber: string;
  containerSize: string;
  containerType: string;
  sealNumber: string;
  flagged: boolean;
  alerts: number;
  totalAlerts: number;
  maxSeverity: number;
  trackingState: TRACKING_STATE;
  owner: string;
  bookingOceanCarrier: string;
  oceanShipment: OceanShipment;
  inlandShipments: InlandShipment[];
  createdAt: moment.Moment;
}

export enum CONTAINER_ALERT_STATE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export interface ContainerAlert {
  type: string;
  message: string;
  state: CONTAINER_ALERT_STATE;
  severity: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
}

export interface InlandShipment {
  id: number;
}
export interface OceanShipment {
  shipmentId: number;
  shipmentNumber: string;
  shipmentType: string;
  portOfDeparture: string;
  portOfArrival: string;
  voyage: string;
  vessel: string;
  carrierBookingRef?: string;
  carrier: string;
  mblNumber: string;
  originalEta?: string;
  originalEtd?: string;
  segments?: SegmentsEntity[];
  etd: string;
  eta: string;
  atd: string;
  ata: string;
}
export interface SegmentsEntity {
  portOfLoading: string;
  portOfDischarge: string;
  vessel: string;
  voyage: string;
  etd: string;
  atd: string;
  eta: string;
  ata: string;
}

//#region new
export interface ContainerDetail {
  id: number;
  containerNumber: string;
  containerSize: string;
  containerType: string;
  sealNumber: string;
  flagged: boolean;
  alerts: number;
  totalAlerts: number;
  maxSeverity: number;
  trackingState: TRACKING_STATE;
  owner: string;
  bookingOceanCarrier?: string;
  createdAt: string;
  oceanShipment: OceanShipment;
  inlandShipments?: InlandShipmentsEntity[];
}

export interface InlandShipmentsEntity {
  type: string;
  shipmentId: number;
  shipmentNumber: string;
  carrier: string;
  blNumber: string;
  origin: string;
  destination: string;
  etd: string;
  atd: string;
  originalEta: string;
  eta: string;
  ata: string;
  licensePlateNumber: string;
  driver: string;
}
//#endregion

export interface ContainerEvent {
  id: number;
  eventType: string;
  carrier: string;
  estimateTime: string;
  actualTime: string;
  location: string;
  containerId: number;
  dateStatus: DATE_STATUS;
  isCurrent?: boolean;
}

export enum EVENT_FIELDS {
  eventType = 'eventType',
  carrier = 'carrier',
  estimateTime = 'estimateTime',
  actualTime = 'actualTime',
  location = 'location',
}

export interface ContainerCalendarEvents {
  date: Date;
  events: ContainerCalendarEvent[];
}

export interface ContainerCalendarEvent {
  event: string;
  containerCount: number;
  isActual: boolean;
}

export interface PageData {
  total: number;
  data: Container[];
}

export enum POPageType {
  PO,
  CustomerPO,
}

export interface ContainerFilters {
  flag: FILTER_FLAG;
  state: TRACKING_STATE;
  alert: ALERT_STATE;
  departure: string;
  arrival: string;
  vessel: string;
  voyage: string;
  etdStart?: moment.Moment;
  etdEnd?: moment.Moment;
  etaStart?: moment.Moment;
  etaEnd?: moment.Moment;
}

export interface SuperUploadResult {
  overAll: string;
  flag: SUPER_UPLOAD_FLAG;
  color: SUPER_UPLOAD_COLOR;
  errorDetail: string[];
  totalError: number;
}
