import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaticDataService } from '@dp/services/static-data.service';
import { DocumentService } from 'app/shared/document.service';
import { UIService } from 'app/shared/ui.service';
import { environment } from 'environments/environment';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { finalize } from 'rxjs/operators';

interface IChannel {
  channelName: string;
  channelCode: string;
}
@Component({
  selector: 'dp-document-upload-dialog',
  templateUrl: './document-upload-dialog.component.html',
  styleUrls: ['./document-upload-dialog.component.scss'],
})
export class DocumentUploadDialogComponent implements OnInit {
  document_types = [];
  channels: IChannel[];
  files: NgxFileDropEntry[];
  documentsDataMap: {
    channelCode?: string;
    documentReference?: string;
    documentType?: string;
    isPrivate?: boolean;
  } = {};
  uploadBusy: boolean = false;

  constructor(
    private staticDataService: StaticDataService,
    public dialogRef: MatDialogRef<DocumentUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data,
    private documentService: DocumentService,
    private uiService: UIService
  ) {}

  ngOnInit() {
    this.documentService.getFileChannels().subscribe(
      (result: any) => {
        console.log('result: ', result);
        if (result && result.channels) {
          this.channels = result.channels;
        } else {
          console.error(result);
        }
      },
      (error) => {
        console.error(error);
      }
    );

    this.document_types = this.staticDataService.staticData.document_types;

    this.files = this.data.files;
    this.documentsDataMap = this.data.documentsDataMap;
  }

  onConfirm(): void {
    console.log('close doc upload dialog');
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    console.log('dismiss doc upload dialog');
    this.dialogRef.close(true);
  }

  uploadFiles() {
    // append documentsDataMap
    this.data.formData.append('documentsDataMap', JSON.stringify(this.documentsDataMap));
    this.uploadToDocumentService(this.data.formData);
  }

  uploadToDocumentService(formData) {
    this.uploadBusy = true;
    // this.failedDocumentNames = [];
    this.documentService
      .uploadShipmentDocument(formData, this.data.entityId, this.data.groupId)
      .pipe(
        finalize(() => {
          this.uploadBusy = false;
          // this.fileInput.nativeElement.value = null;
          // this.files = [];
        })
      )
      .subscribe(
        (result: any) => {
          // if there are any fail-to-upload files
          if (result && result.failedDocumentNames?.length > 0) {
            console.log('failed: ', result.failedDocumentNames);
            // this.failedDocumentNames = result.failedDocumentNames;
          }
          if (result && result.uploadedDocuments?.length > 0) {
            this.uiService.showSnackbar('File uploaded successfully!', null, {
              duration: environment.snackBarDuration.success,
              panelClass: 'accent',
            });
            // return uploadedDocuments back to document-uploads components
            this.dialogRef.close(result.uploadedDocuments);
            // this.shipmentDetail.documents = [...this.shipmentDetail.documents, ...result.uploadedDocuments];
          } else {
            let errorMessage = 'Failed to  upload!';
            if (Array.isArray(result.listOfErrors) && result.listOfErrors.length > 0) {
              errorMessage = 'You can only upload up to the maximum of 20 Documents';
            }
            this.uiService.showSnackbar(errorMessage, null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
          }
        },
        (error) => {
          let errorMessage = 'Failed to  upload document.';
          this.uiService.showSnackbar(errorMessage, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  isFormInvalid() {
    for (const key in this.documentsDataMap) {
      if (!this.documentsDataMap[key].channelCode) {
        return true;
      }
    }
    return false;
  }
}
