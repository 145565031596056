import { Component, OnInit, Input } from '@angular/core';
import { User } from 'app/auth/user.model';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faUserAltSlash as falUserAltSlash } from '@fortawesome/pro-light-svg-icons';
@Component({
  selector: 'dp-user-detail-empty',
  templateUrl: './user-detail-empty.component.html',
  styleUrls: ['./user-detail-empty.component.scss']
})
export class UserDetailEmptyComponent implements OnInit {
  @Input() user: User;
  constructor(private iconLibrary: FaIconLibrary) {
    this.iconLibrary.addIcons(falUserAltSlash);
  }

  ngOnInit(): void {}
}
