import { Component, Input, OnInit, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { NotificationEvent } from '../notification.model';

@Component({
  selector: 'dp-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit {
  conditions: boolean;
  conditionsBlock: TemplateRef<any> | null = null;
  @ViewChild('finishedBlock', { static: true }) finishedBlock: TemplateRef<any> | null = null;
  @ViewChild('upcomingBlock', { static: true }) upcomingBlock: TemplateRef<any> | null = null;
  @Input() event: NotificationEvent;
  @Output() dismiss = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {
    console.log(this.event);
    this.conditions = true;
    this.conditionsBlock = this.event.status === 'finished' ? this.finishedBlock : this.upcomingBlock;
  }

  getRouterLink(): string {
    return this.event.eventType === 'intermodal' ? '/container-shipments/tracking' : '/road-shipments/tracking';
  }

  redirect() {
    this.dismiss.emit();
  }
}
