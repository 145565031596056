<form [formGroup]="formGroup" class="px-8 py-16 mb-4">
  <!-- add mode -->

  <section class="d-flex justify-content-between align-items-center">
    <mat-form-field appearance="fill" class="p-0" style="width: 250px">
      <mat-label>Select port</mat-label>
      <mat-select formControlName="portCode">
        <mat-option>
          <ngx-mat-select-search [formControl]="portFilterCtrl" placeholderLabel="Find port..."
            noEntriesFoundLabel="no matching ports found">
          </ngx-mat-select-search>
        </mat-option>

        <mat-option [value]="item.location_code" *ngFor="let item of filteredPorts | slice:0:20; index as i">
          {{ item.port }}, {{item.country_code }} ({{item.location_code}})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <button mat-button color="primary" (click)="dispatchAction('CREATE')" [disabled]="!isFormValid()">
        <mat-icon class="link">save</mat-icon>
        <span>Save</span>
      </button>

      <button mat-button color="accent" (click)="dispatchAction('CLEAR')">
        <mat-icon class="link">clear</mat-icon>
        <span>Cancel</span>
      </button>

    </div>
  </section>

  <section class="ml-20">
    <div class="row mb-12 d-flex align-items-center">
      <div class="col-4">
        Medium severity
      </div>
      <div class="col-5">
        Storage time more than
      </div>
      <div class="col-3 last">
        <div>
          <div style="width: 50px; padding: 5px; background-color: rgba(0, 0, 0, 0.04);">
            <input type="number" style="height: 100%; background-color: transparent;" min="1" max="60"
              formControlName="mediumSeverityToleranceSecs">
          </div>
        </div>
        <div>
          Days
        </div>
      </div>

    </div>

    <div class="row mb-12 d-flex align-items-center">
      <div class="col-4">
        High severity
      </div>
      <div class="col-5">
        Storage time more than
      </div>
      <div class="col-3 last">
        <div>
          <div style="width: 50px; padding: 5px; background-color:rgba(0, 0, 0, 0.04);">
            <input type="number" style="height: 100%; background-color: transparent;" min="1" max="60"
              formControlName="highSeverityToleranceSecs">
          </div>
        </div>
        <div>
          Days
        </div>
      </div>
    </div>
  </section>
</form>