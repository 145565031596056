<p *ngIf="conditions; then conditionsBlock; else elseBlock">
</p>

<ng-template #finishedBlock>
  <p>
    Shipment
    <ng-container *ngFor="let shipment of event.shipments">
      <a [routerLink]="[getRouterLink()]" [queryParams]="{shipmentNumber: event.shipmentId}" class="link"
        (click)="redirect()">{{shipment.shipmentId}}</a>
      <span>{{shipment.containerId}}</span>
    </ng-container>
    have arrived to their destination port.
  </p>
</ng-template>
<ng-template #upcomingBlock>
  upcoming template here
</ng-template>
<ng-template #elseBlock>
  empty case here
</ng-template>