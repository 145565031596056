<button mat-flat-button class="primary-100 medium" [mdePopoverTriggerFor]="dropdownFilterPopover" mdePopoverTriggerOn="none" (click)="openPopover(popoverTrigger)" #popoverTrigger="mdePopoverTrigger">
  {{props.name}}
  <mat-icon>expand_more</mat-icon>
</button>

<mde-popover #dropdownFilterPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false" (close)="closePopover()">
  <mat-card>
    <mat-card-header class="dp-white border-bottom">
      <mat-card-title>{{props.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <dp-checkbox-tree></dp-checkbox-tree>
    </mat-card-content>
    <mat-card-actions align="right">
      <button mat-button class="primary-800 small" (click)="closePopover()">Clear all</button>
      <button mat-button class="primary-800 small" type="submit" (click)="onApply()" [disabled]="false">
        Apply
      </button>
    </mat-card-actions>
  </mat-card>
</mde-popover>
