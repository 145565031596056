import { Injectable } from '@angular/core';
import { ShippingFilters, FilterType } from '@dp/types/shipment_calendar.model';
import { StaticDataService } from './static-data.service';
import { StaticData } from './static-data.model';
import { User } from 'app/auth/user.model';
import { AuthService } from 'app/auth/auth.service';
import moment from 'moment';
import 'moment-timezone';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  staticData: StaticData;
  user: User;
  utc: string;
  monthDatePattern: string;

  constructor(private dataService: StaticDataService, private authService: AuthService, private router: Router) {
    this.staticData = this.dataService.getStaticDataDirect();
    this.authService.currentUser.subscribe((user) => {
      this.reset();
      this.user = user;
      // any number of non-word, 2-4 character Y, and any number of non word
      // https://regex101.com/r/CFIYX6/1

      this.monthDatePattern = this.user?.datePattern?.replace(/\W?Y{2,4}\W?/gm, '');
    });
  }

  //todo: move this call back to the component
  public getFilterValue(filters: Array<ShippingFilters>, type: FilterType): string {
    if (!filters) {
      return null;
    }
    let found = filters.find((filter: ShippingFilters) => {
      return filter.type == type;
    });

    if (!found) {
      return null;
    } else {
      return found.value;
    }
  }

  // private getUTC(): string | number {
  //   if (!this.utc) {
  //     let timezone = this.user.timezone || moment.tz.guess();
  //     let displayText = this.staticData.timezones[timezone];
  //     this.utc = displayText.replace(/[^\d.-]/g, '') || 0;
  //   }
  //   return this.utc;
  // }

  private isToday(isoString): boolean {
    return moment().isSame(moment(isoString), 'day');
  }

  //todo: get rid of isToday, use isTodayNew
  private isTodayNew(mo: moment.Moment, timezone: string): boolean {
    return moment().tz(timezone).isSame(mo, 'day');
  }

  //special way to show 'date,time'
  //if it is same day, do not show date
  public isoStringToLocalDateTimeStringShort(isoString: string): string {
    // console.log(`timezone ${isoString}, ${moment(isoString).utcOffset(this.getUTC()).format('LT')}`);
    if (this.isToday(isoString)) {
      // return moment(isoString).utcOffset(this.getUTC()).format('LT');
      return moment(isoString).tz(this.user.timezone).format('LT');
    } else {
      let mo = moment(isoString).tz(this.user.timezone);

      return mo.format('L').replace(new RegExp('[^.]?' + moment().format('YYYY') + '.?'), '') + ', ' + mo.format('LT');
    }
  }

  public isoStringToLocalDateTimeStringShort2(isoString: string, timezone:string): string {
    let mo = moment(isoString).tz(timezone);
    let result = '';
    if (this.isTodayNew(mo, timezone)) {
      result = mo.format(this.user.timePattern);
    } else {
      result = mo.format(this.monthDatePattern) + ', ' + mo.format(this.user.timePattern);
    }
    //console.log(`mike, ${isoString} -> ${result}`);
    return result;
  }

  public getClockDisplay(): string {
    let mo = moment().tz(this.user.timezone);
    return mo.format('L').replace(new RegExp('[^.]?' + moment().format('YYYY') + '.?'), '') + ', ' + mo.format('LTS');
  }

  //'2020-08-18T23:46:33.696Z' => '18/08/2020 16:46:33'
  public isoStringToLocalDateTimeString(isoString: string): string {
    if (!isoString) {
      return '';
    }
    let mo = moment(isoString).tz(this.user.timezone);
    return mo.format(this.user.datePattern) + ' ' + mo.format(this.user.timePattern);
  }

  public getDateWithPattern(date: Date | string) {
    if (typeof date === 'string') {
      return moment(date, 'YYYY-MM-DD').format(this.user.datePattern);
    } else {
      return moment(date).format(this.user.datePattern);
    }
  }

  // getStandardDate and getDateWithPattern reverse the values.
  public getStandardDate(date: string) {
    return moment(date, this.user.datePattern).format('YYYY-MM-DD');
  }

  public reset() {
    this.utc = null;
  }

  // navigate to container-shipments page, with shipmentNumber
  navigateToShipmentsPage(shipmentNumber) {
    this.router.navigate(['/container-shipments/tracking'], {
      queryParams: {
        shipmentNumber,
      },
    });
  }
}
