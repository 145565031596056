import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { throttleTime, mergeMap, scan, map, tap } from 'rxjs/operators';
import { NotificationEvent } from '../notification.model';
import { NotificationService } from '../notification.service';

const batchSize = 20;
@Component({
  selector: 'dp-notification-dlg',
  templateUrl: './notification-dlg.component.html',
  styleUrls: ['./notification-dlg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDlgComponent implements OnInit {
  // background: ThemePalette = undefined;
  batch = 5;

  //#region  events
  theEndEvents = false;
  offsetEvents = new BehaviorSubject(0);
  infiniteEvents: Observable<any[]>;
  //#endregion

  thenBlock: TemplateRef<any> | null = null;
  @ViewChild('block1', { static: true }) block1: TemplateRef<any> | null = null;
  @ViewChild('block2', { static: true }) block2: TemplateRef<any> | null = null;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  @Output() dismiss = new EventEmitter<void>();
  constructor(private service: NotificationService) {}

  getBatch(offset: number) {
    console.log(`getBatch, offset=${offset}`);
    return this.service.getEvents(offset, this.batch).pipe(
      tap((arr) => (arr.length ? null : (this.theEndEvents = true))),
      map((arr) => {
        return arr.reduce((acc, cur) => {
          const id = cur.id;
          const data = cur;
          return { ...acc, [id]: data };
        }, {});
      })
    );
  }

  nextBatch(e, offset) {
    if (this.theEndEvents) {
      return;
    }

    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      console.log(`${end}, '>=', ${total}, offset=${offset}`);
      this.offsetEvents.next(offset);
    }
  }

  trackByIdx(i) {
    return i;
  }

  ngOnInit(): void {
    //this.setup();
  }

  setup(): void {
    const batchMap = this.offsetEvents.pipe(
      throttleTime(500),
      mergeMap((n) => this.getBatch(n)),
      scan((acc, batch) => {
        return { ...acc, ...batch };
      }, {})
    );

    this.infiniteEvents = batchMap.pipe(map((v) => Object.values(v)));
  }

  dismissIt() {
    this.dismiss.emit();
  }
}
