import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dp-blank-container',
  templateUrl: './blank-container.component.html',
})
export class BlankContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
