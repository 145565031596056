<div class="mapContainer" *ngIf="shipmentDetail">

  <agm-map #gm [mapDraggable]="true" style="width:100%; height:590px;" [minZoom]=2 [maxZoom]="maxZoom" [disableDefaultUI]="false"
    [fitBounds]="true" [scrollwheel]="false" [restriction]="restriction">
    <ng-container *ngFor="let route of shipmentDetail.routes | keyvalue">
      <!-- pastRoute -->
      <ng-container *ngFor="let points of route.value['pastRoute']">
        <agm-polyline [strokeColor]="'#1976d2'">
          <agm-polyline-point *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lon"
            [agmFitBounds]="getFitBounds(route.key)">
          </agm-polyline-point>
        </agm-polyline>
      </ng-container>
      <!-- futureRoute -->
      <ng-container *ngFor="let points of route.value['futureRoute']">
        <agm-polyline [strokeColor]="'#1976d2'">
          <agm-polyline-point *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lon"
            [agmFitBounds]="getFitBounds(route.key)">
          </agm-polyline-point>
          <agm-icon-sequence [strokeColor]="'#1976d2'" [repeat]="'12px'" [offset]="0" [strokeWeight]="3" [scale]="2" [strokeOpacity]="100"
            [path]="'M 0,-1 0,1'">
          </agm-icon-sequence>
        </agm-polyline>
      </ng-container>
      <!-- possibleRoute -->
      <ng-container *ngFor="let points of route.value['missingRoute']">
        <agm-polyline [strokeColor]="'#FFA000'">
          <agm-polyline-point *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lon"
            [agmFitBounds]="getFitBounds(route.key)">
          </agm-polyline-point>
          <agm-icon-sequence [strokeColor]="'#FFA000'" [repeat]="'12px'" [offset]="0" [strokeWeight]="3" [scale]="2" [strokeOpacity]="100"
            [path]="'M 0,-1 0,1'">
          </agm-icon-sequence>
        </agm-polyline>
        <agm-marker *ngIf="route.value['missingRoute']?.length === 1" [latitude]="getPossibleRouteMiddle(points, 'lat')"
          [longitude]="getPossibleRouteMiddle(points, 'lon')" [iconUrl]="missingPath">
          <agm-info-window #possiblePathWindow [isOpen]="true">
            <span class="warning">This route hasn't been identified yet</span>
          </agm-info-window>
        </agm-marker>
      </ng-container>
    </ng-container>

    <!-- markers -->
    <agm-marker *ngFor="let journeyStop of shipmentDetail.journeyStops; let i = index" [latitude]="journeyStop.lat"
      [longitude]="journeyStop.lon" [agmFitBounds]="getFitBoundsForStop(journeyStop)" [iconUrl]="getStopIcon(journeyStop)" [zIndex]="1"
      (mouseOver)="onMouseOver(infoWindow,gm, journeyStop.name)">
      <agm-info-window [disableAutoPan]="true" [isOpen]="journeyStop.displayNamePermanently" [zIndex]="i">
        <span class="svg" [inlineSVG]="getLabelIcon(journeyStop)" [setSVGAttributes]="{width: 22, height: 22}"></span>
        <span class="infoLabel">{{journeyStop.name}}</span>
      </agm-info-window>
    </agm-marker>

    <!-- shipmentLocation -->
    <agm-marker *ngIf="shipmentDetail.shipmentLocation" [latitude]="shipmentDetail.shipmentLocation.lat"
      [longitude]="shipmentDetail.shipmentLocation.lon" [iconUrl]="currentLocation" [zIndex]="2"
      (mouseOver)="onMouseOver(infoWindow, gm, getLocationLabel(shipmentDetail.shipmentLocation.locationLabel, shipmentDetail.shipmentLocation.locationTimestamp ))">
      <agm-info-window [disableAutoPan]="false" #infoWindow>
        <div>
          {{locationName}}
        </div>
      </agm-info-window>
    </agm-marker>
  </agm-map>

  <div class="overlay centerChild" *ngIf="noData()">
    <div class="centerBox">The shipment routes haven't been identified yet</div>
  </div>

</div>