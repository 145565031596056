<button mat-raised-button class="primary-800" [mdePopoverTriggerFor]="eventsPopover" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger">Events</button>
<dp-filter-label></dp-filter-label>

<mde-popover id="container-events" #eventsPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
  <mat-card style="width: 500px" class="mat-elevation-z12">
    <mat-card-content>

        <div class="content-card">

              <mat-table *ngIf="!noData" [dataSource]="cntrevents" matSort class="mat-elevation-z2">


                <ng-container matColumnDef="EventType">
                  <mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.eventType || ' '}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Carrier">
                  <mat-header-cell *matHeaderCellDef> Carrier </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.carrier || ' '}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="EstimateTime">
                  <mat-header-cell *matHeaderCellDef> Estimated Time </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.estimateTime || ' '}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ActualTime">
                  <mat-header-cell *matHeaderCellDef> Actual Time </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.actualTime || ' '}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Location">
                  <mat-header-cell *matHeaderCellDef> Location </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.location || ' '}} </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumnsn ; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsn;"></mat-row>
              </mat-table>

              <div *ngIf="noData">
                <img class="center" src="assets/data_not_available.png" alt="">
              </div>
        </div>


    </mat-card-content>
    <mat-card-actions align="right">
      <button (click)="closePopover()" mat-button>Close</button>
    </mat-card-actions>
  </mat-card>
</mde-popover>
