export interface LocationFilters {
  countryCode: string;
  locationType: LocationType;
}

export interface Address {
  streetLine1: string;
  streetLine2?: string;
  streetLine3?: string;
  city: string;
  stateProv: string;
  postalCode: string;
  countryCode: string;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Contact {
  firstName: string;
  lastName: string;
  phone: string;
  fax: string;
  email: string;
}

export interface DpLocation {
  id: number;
  locationName: string;
  locationType: LocationType;
  address: Address;
  coordinates?: Coordinates;
  contact: Contact;
}

export enum LocationType {
  All = 'All',
  OF = 'Office',
  MF = 'Manufacturer/Supplier',
  SW = 'Supply Warehouse',
  LP = 'Loading Port',
  UP = 'Discharging Port',
  DC = 'Distribution Center',
  DP = 'Delivery Point',
  FC = 'FC'
}

export interface LocationFilters {
  countryCode: string;
  locationType: LocationType;
}
