import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthRole, LoginCompanyStatusType, STATES, User } from '../user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoginSsoService {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) { }


  authorizeLogin() {
    const state = this.route.snapshot.queryParamMap.get('state');
    const sessionState = this.route.snapshot.queryParamMap.get('session_state');
    const code = this.route.snapshot.queryParamMap.get('code');
    const redirectUrl = `${environment.baseUrl}${environment.SSORedirectUri}`;
    const params = {
      state,
      sessionState,
      code,
      redirectUrl
    };
    this.ssoLoginUserContext(params);
  }

  ssoLoginUserContext(params: any) {
    const state = params.state ? JSON.parse(atob(params.state)) : {};
    let { url: redirectUrl } = state;

    this.authService.ssoLoginUser(params)
      .subscribe(
        (response) => {
          let user = response.body as User;
          if (user.companyStatus != LoginCompanyStatusType.VERIFIED) {
            this.authService.setupNewUser(user, response);
            this.ssoLogoutUser('set-company')
          }
          else {
            this.authService.setupNewUser(user, response);
            if (user.authRole != AuthRole.SYSTEM_ADMIN && user.state != STATES.VERIFIED) {
              this.ssoLogoutUser('login');
            }
            this.authService.getOrganization()
              .subscribe((v2) => {
                const user = response.body as User;
                user.accountType = v2.accountType;
                user.accountCapabilities = v2.accountCapabilities;
                user.organizationPartnerships = v2.organizationPartnerships;
                this.authService.setupNewUser(user, response); //setupNewUser is called again here due to the getOrganization call. Will make this
                // return response;
                if (user.authRole === AuthRole.SYSTEM_ADMIN) {
                  this.router.navigate(['/super-admin']);
                } else if (user.state === STATES.VERIFIED) {
                  this.router.navigate(['/dashboard']);
                }
              })
          }
        },
        (errorResponse: HttpErrorResponse) => {
          console.log(errorResponse);
          this.ssoLogoutUser();
        }
      );
  }

  login() {
    const ssoUrl = new URL(`${environment.ssoAuthServer}${environment.SSOAuthUri}`);
    const state = '';

    ssoUrl.searchParams.append('state', state);
    ssoUrl.searchParams.append('client_id', environment.clientId);
    ssoUrl.searchParams.append('response_type', 'code');
    ssoUrl.searchParams.append('redirect_uri', `${environment.baseUrl}${environment.SSORedirectUri}`);

    window.location.href = ssoUrl.toString();
  }

  private ssoLogoutUser(redirectUri = ``): void {
    console.log('ssoLogoutUser', environment.baseUrl);
    window.location.href = `${environment.ssoAuthServer}${environment.SSOLogoutUri}${environment.baseUrl}${redirectUri}`;
  }


  ssoLogout(redirectUri = ``) {
    //sso logout
    console.log('logoutData');
    // localStorage.removeItem(environment.dpwStorageKeys.currentUser);
    // localStorage.removeItem(environment.dpwStorageKeys.dpwUserType);
    // localStorage.removeItem(environment.dpwStorageKeys.token);
    // if (localStorage.getItem(environment.dpwStorageKeys.actingUser)) {
    //   localStorage.removeItem(environment.dpwStorageKeys.actingUser);
    // }
    // if (localStorage.getItem(environment.dpwStorageKeys.dpwUserOrg)) {
    //   localStorage.removeItem(environment.dpwStorageKeys.dpwUserOrg);
    // }
    // if (localStorage.getItem(environment.dpwStorageKeys.dpwOrgSelectedUser)) {
    //   localStorage.removeItem(environment.dpwStorageKeys.dpwOrgSelectedUser);
    // }

    setTimeout(() => {
      this.ssoLogoutUser(redirectUri);
    }, 100);

  }

}
