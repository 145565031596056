import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { UsersService } from 'app/settings/users/users.service';
import { UIService, ProgressService, ProgressRef } from 'app/shared';
import { delay, finalize, take, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Country, Organization, Industry } from 'app/settings/users/users.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { StaticDataService } from '@dp/services/static-data.service';
import { AuthService } from 'app/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PartnersService } from 'app/shared/services/partners/partners.service';
import { MatDialog } from '@angular/material/dialog';
import { AddPartnerDialogComponent } from 'app/settings/add-partner-dialog/add-partner-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AdDirective } from '@dp/directives/ad.directive';
import { NotificationMetricsComponent } from 'app/settings/notification-metrics/notification-metrics.component';

enum PageModeType {
  loading,
  display,
  edit,
}

@Component({
  selector: 'dp-company-setting',
  templateUrl: './company-setting.component.html',
  styleUrls: ['./company-setting.component.scss'],
  animations: dpAnimations,
})
export class CompanySettingComponent implements OnInit, OnDestroy {
  showLogo = false;
  PageModeType = PageModeType;
  pageMode = PageModeType.display;
  pageLoading = true;
  isFormBusy = false;
  isAdmin: boolean;
  notEditableBlocker: ProgressRef;

  f: FormGroup;
  organization: Organization = null;
  partnersList = null;
  countries: Country[];
  industries: Industry[];
  public countryFilterCtrl: FormControl = new FormControl();
  public industryFilterCtrl: FormControl = new FormControl();

  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public filteredIndustries: ReplaySubject<Industry[]> = new ReplaySubject<Industry[]>(1);
  protected _onDestroy = new Subject<void>();

  @ViewChild('countrySelect', { static: true }) countrySelect: MatSelect;
  @ViewChild('industrySelect', { static: true }) industrySelect: MatSelect;

  @ViewChild('contentZone', { static: true }) contentZone?: ElementRef;
  @ViewChild('uploadZone') uploadZone?: ElementRef;
  @ViewChild('editableZone', { static: true }) editableZone?: ElementRef;

  toggleDatachainError = '';
  productionHosting = environment.productionHosting;

  // blockchain email form
  blockChainForm = this.formBuilder.group({
    email: ['', Validators.required],
  });

  @ViewChild(AdDirective, { static: true }) adHost!: AdDirective;
  constructor(
    private staticDataService: StaticDataService,
    private authService: AuthService,
    private usersService: UsersService,
    private uiService: UIService,
    private progressService: ProgressService,
    private formBuilder: FormBuilder,
    private partnersService: PartnersService,
    private dlg: MatDialog,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  loadComponent() {
    // if (!this.productionHosting) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationMetricsComponent);
    console.log('factory: ', componentFactory);
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<NotificationMetricsComponent>(componentFactory);
    // }
    return;
    // componentRef.instance.data = adItem.data;
  }

  ngOnDestroy(): void {
    if (this.notEditableBlocker) this.progressService.detach(this.notEditableBlocker);
  }

  ngOnInit(): void {
    console.log('company setting component productionHosting!', this.productionHosting);
    this.pageLoading = true;
    this.isAdmin = this.authService.currentUserValue.isAdmin;

    this.partnersList = this.authService.currentUserValue.organizationPartnerships;

    this.processStaticData();

    //let progressRef = this.progressService.showProgress(this.contentZone);
    this.authService
      .getOrganization()
      .pipe(
        // delay(200),
        finalize(() => {
          this.pageLoading = false;
          //this.progressService.detach(progressRef);
          this.loadComponent();
        })
      )
      .subscribe(
        (setting) => {
          this.organization = setting;
          this.buildForm();
        },
        () => {
          this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
        }
      );
  }

  editMode() {
    this.pageMode = PageModeType.edit;
  }

  getIndustryName(): string {
    const industries = this.staticDataService.getStaticDataDirect().industries;
    let industryName = industries.find((industry) => industry.code === this.organization.industry)?.['name'];
    return industryName || 'N/A';
  }
  getCountryName(): string {
    const countries = this.staticDataService.getStaticDataDirect()['countries'];
    let countryName = countries.find((country) => country['country_code'] === this.organization.countryCode)?.['country_name'];
    return countryName || 'N/A';
  }

  buildForm() {
    this.f = this.formBuilder.group({
      organizationName: [this.organization.organizationName, Validators.required],
      industry: [this.organization.industry],
      phone: [this.organization.phone, Validators.pattern(environment.validators.phone)],
      email: [this.organization.email, [Validators.email]],
      streetLine1: [this.organization.streetLine1],
      streetLine2: [this.organization.streetLine2],
      city: [this.organization.city],
      stateProv: [this.organization.stateProv],
      postalCode: [this.organization.postalCode],
      countryCode: [this.organization.countryCode],
    });

    if (!this.isAdmin) {
      this.f.disable();
      this.notEditableBlocker = this.progressService.showProgress(this.editableZone, true);
    }
  }

  getErrorMessage(control: FormControl) {
    return control.hasError('required') ? 'You must enter a value' : control.hasError('pattern') ? 'This is not a valid phone number' : '';
  }

  fileChangeEvent(event) {
    let progressRef = this.progressService.showProgress(this.uploadZone);

    const formData: FormData = new FormData();
    formData.append('fileName', event.target.files.item(0), event.target.files.item(0).name);
    formData.append('uploadType', 'company_logo');

    this.usersService
      .uploadLogoFile(formData)
      .pipe(
        delay(2000),
        finalize(() => this.progressService.detach(progressRef))
      )
      .subscribe(
        (result) => {
          //todo
          //this.organization.logo = result.url;
          console.log(result);
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
        }
      );
  }

  submit() {
    this.isFormBusy = true;
    const payload: Organization = {
      organizationName: this.f.value.organizationName,
      industry: this.f.value.industry,
      phone: this.f.value.phone,
      email: this.f.value.email,
      streetLine1: this.f.value.streetLine1,
      streetLine2: this.f.value.streetLine2,
      city: this.f.value.city,
      stateProv: this.f.value.stateProv,
      postalCode: this.f.value.postalCode,
      countryCode: this.f.value.countryCode,

      //todo:
      //logo: this.organization.logo,
    };

    this.usersService
      .updateOrganization(payload)
      .pipe(
        finalize(() => {
          this.isFormBusy = false;
        })
      )
      .subscribe(
        (result) => {
          this.organization = { ...this.organization, ...result };
          this.pageMode = PageModeType.display;
          this.uiService.showSnackbar('Setting is saved!', null, { duration: environment.snackBarDuration.success, panelClass: 'accent' });
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
        }
      );
  }

  /**
   * Sets the initial value after the filteredCountries are loaded initially
   */
  processStaticData() {
    this.countries = this.staticDataService.getStaticDataDirect()['countries'];
    this.filteredCountries.next(this.countries.slice());
    // listen for search field value changes
    this.countryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCountries();
    });

    this.industries = this.staticDataService.getStaticDataDirect()['industries'];
    this.filteredIndustries.next(this.industries.slice());
    // listen for search field value changes
    this.industryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterIndustries();
    });
  }

  protected filterCountries() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountries.next(this.countries.filter((country) => country.country_name.toLowerCase().indexOf(search) > -1));
  }
  protected filterIndustries() {
    if (!this.industries) {
      return;
    }
    // get the search keyword
    let search = this.industryFilterCtrl.value;
    if (!search) {
      this.filteredIndustries.next(this.industries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredIndustries.next(this.industries.filter((industry) => industry.name.toLowerCase().indexOf(search) > -1));
  }

  asIsOrder() {
    return 1;
  }

  submitBlockchainEnableRequest() {
    console.log('here');

    let payload = {
      enable: true,
      email: this.blockChainForm.get('email').value,
    };

    this.toggleDatachainError = '';
    this.usersService.toggleDatachain(payload).subscribe(
      (result) => {
        console.log('result: ', result);
        if (result && result.success) {
          this.uiService.showSnackbar('Successfully sent request.', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });

          // update new dataChainStatus
          if (result.orgDatachainStatus) {
            this.organization.accountCapabilities.orgDatachainStatus = result.orgDatachainStatus;
          }
        }
      },
      (httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse && httpErrorResponse.error && httpErrorResponse.error.errorCode == '40030') {
          this.toggleDatachainError = 'A request is already in Pending';
        } else {
          this.toggleDatachainError = 'There was something wrong. Please try again.';
        }
      }
    );
  }

  isOrgDatachainStatusEnabled(): boolean {
    if (this.organization && this.organization.accountCapabilities) {
      return (
        this.organization.accountCapabilities.orgDatachainStatus === 'ENABLED' ||
        this.organization.accountCapabilities.orgDatachainStatus === 'ENABLE_PENDING'
      );
    }
    // DISABLED | DISABLE_PENDING
    return false;
  }

  checkDatachainStatusStatus(status: string): boolean {
    if (this.organization && this.organization.accountCapabilities) {
      return this.organization.accountCapabilities.orgDatachainStatus == status;
    }
    // DISABLED | DISABLE_PENDING
    return false;
  }

  isEnterpriseCustomer() {
    return this.organization && this.organization.accountType === 'ENTERPRISE';
  }

  revertRequest() {
    console.log('revert request: backend not support yet');
    let payload = {
      enable: false,
      revertRequest: true,
    };

    this.toggleDatachainError = '';
    this.usersService.toggleDatachain(payload).subscribe(
      (result) => {
        console.log('result: ', result);
        if (result && result.success) {
          this.uiService.showSnackbar('Successfully revert request.', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });

          // update new dataChainStatus
          if (result.orgDatachainStatus) {
            this.organization.accountCapabilities.orgDatachainStatus = result.orgDatachainStatus;
          }
        }
      },
      (httpErrorResponse: HttpErrorResponse) => {
        console.log('revertRequest ~ httpErrorResponse', httpErrorResponse);
        this.uiService.showSnackbar('Failed to revert request', null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warn',
        });
      }
    );
  }

  getPartners(): Observable<any> {
    return this.partnersService.getPartners();
  }

  openAddPartnerDialog(): void {
    // close setting dialog ?
    this.dlg.open(AddPartnerDialogComponent, {
      width: '660px',
      height: '40%',
      data: {},
    });
  }

  documentsSliderChange(state: MatSlideToggleChange) {
    console.log('state: ', state.checked);

    this.organization.accountPreferences.showDocumentsInSharedLinks = state.checked;
    let payload = {
      showDocumentsInSharedLinks: state.checked,
    };
    this.usersService
      .toggleIncludeDocuments(payload)
      .pipe(finalize(() => (this.isFormBusy = false)))
      .subscribe(
        (result) => {
          console.log('result: ', result);
          this.uiService.showSnackbar('Setting is saved!', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
          this.organization.accountPreferences.showDocumentsInSharedLinks = !state.checked;
        }
      );
  }
}
