import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { StaticDataService } from '@dp/services/static-data.service';
import { AuthService } from 'app/auth/auth.service';
import { User, LoginCompanyStatusType } from 'app/auth/user.model';
import { StaticData } from '@dp/services/static-data.model';
import { UsersService } from 'app/settings/users/users.service';
import { Timezone, UpdateUserRequest } from 'app/settings/users/users.model';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { DpEmailValidator } from '@dp/validators/email.validator';
import { UIService } from 'app/shared';
import { environment } from 'environments/environment';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { PolicyTypes } from 'app/documents/documents.model';
import moment from 'moment';
import { DateTimePattern } from '@dp/helper/DateTimePattern';
import { ReplaySubject, Subject } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Utility } from '@dp/utilities';

@Component({
  selector: 'dp-welcome-profile-dlg',
  templateUrl: './welcome-profile-dlg.component.html',
  styleUrls: ['./welcome-profile-dlg.component.scss'],
})

/**
 * DEPRECATED????, no!
 */
export class WelcomeProfileDlgComponent extends DateTimePattern implements OnInit, OnDestroy {
  PolicyTypes = PolicyTypes;
  staticData: StaticData;

  timezones: Timezone[];
  public timezoneFilterCtrl: FormControl = new FormControl();
  public filteredTimezones: ReplaySubject<Timezone[]> = new ReplaySubject<Timezone[]>(1);
  protected _onDestroy = new Subject<void>();

  user: User;
  f: FormGroup = null;
  isBusy = false;
  constructor(
    public dialogRef: MatDialogRef<WelcomeProfileDlgComponent>,
    public formBuilder: FormBuilder,
    private staticDataService: StaticDataService,
    private authService: AuthService,
    private usersService: UsersService,
    private dpEmailValidator: DpEmailValidator,
    private uiService: UIService,
    private gaService: GoogleAnalyticsService
  ) {
    super(staticDataService);
  }

  ngOnInit(): void {
    this.staticData = this.staticDataService.getStaticDataDirect();
    this.timezones = this.staticData['timezones_v2'];
    this.filteredTimezones.next(this.timezones.slice());
    this.timezoneFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterTimezone();
    });

    this.user = { ...this.authService.currentUserValue, datePattern: this.datePatterns[0], timePattern: this.timePatterns[0] };

    this.user.timezone = Utility.detectedTimezone(this.staticData);

    this.f = this.formBuilder.group({
      // newEmail: ['', [Validators.required, Validators.email], this.dpEmailValidator.userValidator()],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      timezone: [this.user.timezone, Validators.required],
      locale: [this.user.locale, Validators.required],
      datePattern: [this.user.datePattern, Validators.required],
      timePattern: [this.user.timePattern, Validators.required],
    });
    this.triggerValidation();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  protected filterTimezone() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    if (!search) {
      this.filteredTimezones.next(this.timezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTimezones.next(this.timezones.filter((timezone) => timezone.name.toLowerCase().indexOf(search) > -1));
  }

  triggerValidation() {
    for (let i in this.f.controls) {
      this.f.controls[i].markAsTouched();
    }
  }

  createAccount() {
    this.isBusy = true;
    let payload = this.f.value as UpdateUserRequest;

    //mock
    // console.error('todo: remove the mock');
    // delete payload.email;
    //mock end

    this.usersService
      .updateUser(payload, this.user.userCode)
      .pipe(
        map((response: HttpResponse<User>) => {
          const user = response.body as User;
          //console.error('mock');
          //user.companyStatus = LoginCompanyStatusType.VERIFIED;
          //user.showUserInformationConfirmationDialog = true;
          this.authService.setupNewUser(user, response);
          this.authService.resetSessionData();
          return user;
        }),
        finalize(() => (this.isBusy = false))
      )
      .subscribe(
        (user: User) => {
          this.uiService.showSnackbar('We have sent you an email to verify your email address.', null, {
            duration: environment.snackBarDuration.success,
            panelClass: 'accent',
          });

          //todo: update user with new info
          this.authService.handleNewUser(user);
          this.dialogRef.close();
          const action = `${environment.gaEvents.categories.dtUserWelcome}_${environment.gaEvents.actions.updateDTUser}_${environment.gaEvents.labels.success}`;
          this.gaService.event(action, environment.gaEvents.categories.dtUserWelcome, environment.gaEvents.labels.success);
        },
        (error: HttpErrorResponse) => {
          const msg = 'Error: ' + error.message;
          this.uiService.showSnackbar(msg, null, {
            duration: environment.snackBarDuration.error,
            panelClass: 'warn',
          });
          const action = `${environment.gaEvents.categories.dtUserWelcome}_${environment.gaEvents.actions.updateDTUser}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(
            action,
            environment.gaEvents.categories.dtUserWelcome,
            environment.gaEvents.labels.failure
          );
        }
      );
  }

  getErrorMessage(control: FormControl) {
    const msg = control.hasError('required')
      ? 'You must enter a value'
      : control.hasError('email')
        ? 'This is not a valid email address.'
        : control.hasError('emailExists')
          ? 'This email has registered with another user. Please use an alternative email. '
          : '';
    return msg;
  }

  openLink(para: string) {
    window.open(`/documents/${para}`, '_blank');
  }

  asIsOrder() {
    return 1;
  }
}
