import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxFileDropEntry } from 'ngx-file-drop';

interface IChannel {
  channelName: string;
  channelCode: string;
}
@Component({
  selector: 'dp-documents-upload-card-datachain',
  templateUrl: './documents-upload-card-datachain.component.html',
  styleUrls: ['./documents-upload-card-datachain.component.scss'],
})
export class DocumentsUploadCardComponentDatachain implements OnInit {
  @Input() document_types;
  @Input() channels: IChannel[];
  @Input() document: NgxFileDropEntry;
  @Input() dataMap;

  constructor() {}

  ngOnInit(): void {}
}
