export enum ShipmentType {
  TRUCK_SHIPMENT = 'TRUCK_SHIPMENT',
  INTERMODAL_SHIPMENT = 'INTERMODAL_SHIPMENT',
}

export interface ITableColumns {
  createdAt: string;
  shipmentNumber: string;
  containerNumber: string;
  containerId: string;
  trackingState: string;
  referenceNumber: string;
  substatus_1: string;
  is: Object; //is : intermodal_shipment
}

export interface DeleteShipmentResponse {
  id: number;
  isDeleted: boolean;
  shipmentNumber: string;
}
