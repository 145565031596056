import { Tag, TRACKING_STATE } from '@dp/types';
import { Pagination } from '@dp/types';
import { KeyValueItem } from 'app/shipments2/shipments2.model';

// new container model
// todo: ITruck is out of sync with the real data from the api.
// please fix it
export interface ITruck {
  id: number; //shipmentId
  containerId: number;
  containerNumber: string;
  containerSize: string;
  containerType: string;
  totalProducts: number;
  flagged: boolean;
  trackingState: TRACKING_STATE;
  createdAt: string;
  shipmentNumber: string;
  shipmentRef: string;
  mbl: string;
  oceanLine: string;
  portOfDeparture: string;
  gateInTime: string;
  etd: string;
  atd: string;
  eta: string;
  ata: string;
  portOfArrival: string;
  gateOutTime: string;
  statusDuration: string;
  status: string;
  products: IProduct[];
  referenceNumber: string;
  transportJourneys?: TransportJourneys;
  totalDocuments?: number;
  temperatureInfo: TruckPage.TemperatureInfo;
  pod: Pod;

  shipmentTags?: Tag[];
}

export interface Pod {
  documentExtension: string;
  id: number;
  shipmentNumber: string;
}

export interface IProduct {
  poNumber: string;
  productNumber: string;
  productDescription: string;
  HSCode: string;
  productQuantity: number;
  unitPrice: number;
  symbol: string;
}

export interface ITrucksState {
  containers: ITruck[];
  pagination?: Pagination;
  totalContainers?: number;
  isLoading: boolean;
}

export enum ContainerFieldsEnum {
  createdAt = 'created_at',
  containerNumber = 'container_id',
  containerId = 'container_id',
  trackingState = 'tracking_state',
}

// No longer needed
export interface ITruckQuery {
  flagged?: string; //true|false
  status?: string; //active, completed
  shippingModes?: string;
  serviceModes?: string;
  subStatus1?: string;
  carriers?: string;
  hasPodDocument?: string; //true|false
  hasDocuments?: string; //true|false,
  temperature?: string;
}

export interface ITruckFilter {
  status: string;
  subStatus1: string[];
  shippingModes: string[];
  serviceModes: string[];
  carriers: string[];
  flagged: string;
  hasPodDocument: string;
  hasDocuments: string;
  _sort: string;
  _order: string;
  q?: string;
  temperature?: TruckPage.SHIPMENT_TEMPERATURE_STATES | '';
  hasProducts: string;
}
export const initialTruckFilter: ITruckFilter = {
  flagged: '',
  status: '',
  shippingModes: [],
  serviceModes: [],
  subStatus1: [],
  carriers: [],
  hasPodDocument: '',
  hasDocuments: '',
  _sort: 'created_at',
  _order: 'desc',
  q: '',
  hasProducts: '',
  temperature: '',
};

export interface TransportJourneys {
  roadTransport: Transport;
}

export interface Transport {
  origin: string;
  destination: string;
  etd: string;
  atd: string;
  eta?: any;
  ata?: any;
  originalEtd?: any;
  originalEta: string;
  carrier: string;
  truckNumber?: any;
  originFullAddress: string;
  destinationFullAddress: string;
  originAddressComponents: Address;
  destinationAddressComponents: Address;
  statusSteps: StatusSteps;
  segmentData: SegmentDatum[];
}

interface SegmentDatum {
  pickup: string;
  dropoff: string;
  carrier?: any;
  truckNumber?: any;
  etd: string;
  atd: string;
  eta: string;
  ata?: any;
  pickupFullAddress: string;
  dropoffFullAddress: string;
}

interface StatusSteps {
  steps: Step[];
}

interface Step {
  title: string;
  isCurrent: boolean;
  isFuture: boolean;
  address: string;
  dpwFlag: boolean;
  portActions: PortAction[];
  date: string;
}

interface PortAction {
  name: string;
  date: string;
  isCurrent: boolean;
  isFuture: boolean;
  approximatedFlag: boolean;
  duration?: string;
}

//todo: this interface could be used at other places. Move it to a common ts file.
export interface Address {
  streetLine1: string;
  streetLine2: string;
  streetLine3?: string;
  city: string;
  stateProv: string;
  postalCode?: any;
  countryName: string;
}

export interface IPodDocument {
  id: number;
  documentExtension: string;
}

//we will deprecate all interfaces above and use namespace to avoid collision of definitions in the whole site
export namespace TruckPage {
  export interface TruckDetail {
    id: number;
    landShipmentId: number;
    intermodalShipmentId?: any;
    organizationId: number;
    consignor: string;
    consignee: string;
    shipmentNumber: string;
    containerNumber?: any;
    referenceNumber: string;
    flagged: boolean;
    containerSealNumber?: any;
    containerSize?: any;
    containerType?: any;
    containerIso?: any;
    rawContainerSize?: any;
    rawContainerIso?: any;
    status: string;
    substatus_1: string;
    substatus_2?: any;
    shippingMode: string;
    blNumber?: any;
    bkNumber?: any;
    createdAt: string;
    externalShipmentNumber: string;
    serviceMode: string;
    invoiceNumber: string;
    incoterm?: any;
    totalWeight?: any;
    weightUom?: any;
    totalNumberOfPackages?: any;
    packageType?: any;
    commodity?: any;
    trackingUpdatedAt?: any;
    transportJourneys: TransportJourneys;
    documents: Document[];
    temperatureInfo: TemperatureInfo;
    shipmentTags?: Tag[];
  }

  export enum SHIPMENT_TEMPERATURE_STATES {
    // used for the temperature indicators, from API
    HIGH = 'HIGH',
    NORMAL = 'NORMAL',
    LOW = 'LOW',
  }
  export interface TemperatureInfo {
    minTolerantTemperature: string;
    maxTolerantTemperature: string;
    lastTemperature: string;
    lastAbnormalTemperature: string;
    uom: string;
    lastAbnormalTemperatureState: SHIPMENT_TEMPERATURE_STATES;
    lastTemperatureState: SHIPMENT_TEMPERATURE_STATES;
    lastTemperatureRecordedAt: string;
    lastAbnormalTemperatureRecordedAt: string;
    temperatureHistory?: TemperatureHistory[];
  }

  export interface TemperatureHistory {
    recordedAt: string;
    temperature: number;
  }

  export interface Document {
    id: number;
    organizationId: number;
    name: string;
    documentNumber: string;
    documentType: string;
    documentReference: string;
    documentExtension: string;
    isSystemGenerated: boolean;
    isReadOnly: boolean;
    entityId: number;
    entityType: string;
    userName: string;
    createdAt: string;
  }

  export interface TransportJourneys {
    roadTransport: RoadTransport;
  }

  export interface RoadTransport {
    origin: string;
    destination: string;
    etd: string;
    atd: string;
    eta: string;
    ata?: any;
    originalEtd?: any;
    originalEta: string;
    carrier: string;
    serviceProvider?: any;
    truckNumber: string;
    driverName?: any;
    driverPhone?: any;
    originFullAddress: string;
    originAddressComponents: OriginAddressComponents;
    destinationFullAddress: string;
    destinationAddressComponents: OriginAddressComponents;
    bookingNumber: string;
    statusSteps: StatusSteps;
    segmentData: SegmentDatum[];
  }

  export interface SegmentDatum {
    pickup: string;
    dropoff: string;
    carrier?: any;
    truckNumber: string;
    etd: string;
    atd: string;
    eta: string;
    ata?: any;
    pickupFullAddress: string;
    dropoffFullAddress: string;
  }

  export interface StatusSteps {
    steps: Step[];
  }

  export interface Step {
    title: string;
    isCurrent: boolean;
    isFuture: boolean;
    address: string;
    dpwFlag: boolean;
    portActions: PortAction[];
    date: string;
    locationActions: PortAction[];
  }

  export interface PortAction {
    name: string;
    date: string;
    isCurrent: boolean;
    isFuture: boolean;
    approximatedFlag: boolean;
    duration?: string;
  }

  export interface OriginAddressComponents {
    streetLine1: string;
    streetLine2: string;
    streetLine3?: string;
    city: string;
    stateProv: string;
    postalCode?: any;
    countryName: string;
  }
}
