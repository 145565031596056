export class FileUtils {
  static ALLOWED_DOCUMENT_FILE_EXTENSIONS: string[] = ['jpg', 'jpeg', 'png', 'pdf'];
  // the maximum size limit of uploaded document is 5 MB = 5 * 1,048,576 bytes
  static ALLOWED_DOCUMENT_FILE_SIZE_LIMIT: number = 5 * 1048576;
  static ALLOWED_DOCUMENTS_LIMIT_PER_UPLOAD = 10; // the maximum # documents that can be uploaded at a time

  // ACTIVE_CONTAINER_LIMIT_FOR_DT_ORGANIZATIONS = 500;
  // ACTIVE_CONTAINER_QUOTA_FOR_SELF_REGISTRATION = 20;
  // ALLOWED_DOCUMENTS_LIMIT_PER_SHIPMENT = 20; // the maximun # documents that can be uploaded for a shipment

  // constructor(ALLOWED_DOCUMENT_FILE_EXTENSIONS) {
  //   this.ALLOWED_DOCUMENT_FILE_EXTENSIONS = ALLOWED_DOCUMENT_FILE_EXTENSIONS;
  //   this.ALLOWED_DOCUMENT_FILE_SIZE_LIMIT = 5;
  // }

  public static isExtensionSupported(extension: string): boolean {
    return this.ALLOWED_DOCUMENT_FILE_EXTENSIONS.includes(extension.toLowerCase());
  }

  // file size is less ALLOWED_DOCUMENT_FILE_SIZE_LIMIT
  public static isFileSizeAllowed(fileSize: number): boolean {
    console.log('🚀 ~ file: file-utils.ts ~ line 22 ~ FileUtils ~ isFileSizeAllowed ~ fileSize', fileSize);
    return fileSize < this.ALLOWED_DOCUMENT_FILE_SIZE_LIMIT;
  }

  public static isFileExtensionAllowed(fileName) {
    const extension = this.getFileExtension(fileName);
    return this.isExtensionSupported(extension);
  }

  public static isFileExtensionAllowedFromProvidedList(fileName, fileExtensionList) {
    const extension = this.getFileExtension(fileName);
    return fileExtensionList.includes(extension);
  }

  public static isFileSizeAllowedWithGivenFileSize(fileSize: number, allowedFileSize): boolean {
    return fileSize < allowedFileSize;
  }

  public static getFileExtension(fileName) {
    return fileName.split('.').pop();
  }
}
