import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './landingPage/welcome.component';
import { DpSharedModule } from '@dp/shared.module';
import { MaterialModule } from 'app/material.module';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { SharedCommonModule } from 'app/shared/shared.common.module';
import { BrowserModule } from '@angular/platform-browser';

const routes: Routes = [
  { path: '', component: WelcomeComponent },
  { path: 'welcome', component: WelcomeComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes), DpSharedModule, RouterModule, MaterialModule, DpPipesModule, SharedCommonModule, BrowserModule],
  exports: [],
  declarations: [WelcomeComponent],
  providers: [],
})
export class WelcomeModule {}
