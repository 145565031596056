import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DocumentService, DocumentAction } from 'app/shared/document.service';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { IDocument } from 'app/shipments2/shipments2.model';
import { environment } from 'environments/environment';
import { UIService } from 'app/shared/ui.service';
// import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dp-documents-upload-card-2',
  templateUrl: './documents-upload-card-2.component.html',
  styleUrls: ['./documents-upload-card-2.component.scss'],
})
export class DocumentsUploadCardComponent2 implements OnInit, OnDestroy {
  @Input() document_types;
  @Input() document: IDocument;
  DocumentAction = DocumentAction;
  documentForm: FormGroup;
  documentsList: IDocument;
  showDocumentHistory = false;
  replaceBtnBusy = false;
  downloadBusy: boolean = false;

  constructor(public documentService: DocumentService, private uiService: UIService) {}

  sub1: Subscription;
  sub2: Subscription;

  ngOnInit(): void {
    this.documentForm = new FormGroup({
      documentType: new FormControl({ value: null, disabled: this.document.isSystemGenerated }),
      documentReference: new FormControl(''),
    });

    if (this.document.documentType) this.documentType.setValue(this.document.documentType);
    this.documentReference.setValue(this.document.documentReference || '');

    this.sub1 = this.documentType.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((documentType) => {
      this.documentForm.updateValueAndValidity();
      this.updateDocument(this.document.id, this.documentForm.value);
    });

    this.documentReference.valueChanges.pipe(debounceTime(1500), distinctUntilChanged()).subscribe((documentReference) => {
      this.documentForm.updateValueAndValidity();
      this.updateDocument(this.document.id, this.documentForm.value);
    });
  }

  ngOnDestroy() {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
  }

  get documentType() {
    return this.documentForm.get('documentType');
  }

  get documentReference() {
    return this.documentForm.get('documentReference');
  }

  updateDocument(documentId, formData) {
    this.documentService.actionEvent.emit({
      action: DocumentAction.UPDATE,
      documentId: documentId,
      formData,
    });
  }

  previewDocument(document: IDocument, documentVersion?: number) {
    const documentCode = this.documentForm.get('documentType').value;
    let lookupDocument = this.document_types.find((item) => item.code === documentCode);
    let documentName = lookupDocument ? lookupDocument.name : '';

    this.documentService.actionEvent.emit({
      action: DocumentAction.PREVIEW,
      document,
      documentName,
      documentVersion,
    });
  }

  previewDocumentHelper(document: IDocument, item) {
    let fileExtension = item.name.split('.').pop();
    let newDocument = { ...document, documentExtension: fileExtension };
    this.previewDocument(newDocument, item.version);
  }

  downloadDocument(document, documentVersion?) {
    this.documentService.actionEvent.emit({
      action: DocumentAction.DOWNLOAD,
      fileName: document.name,
      documentId: document.id,
      documentVersion,
    });
  }

  deleteDocument(document: IDocument) {
    this.documentService.actionEvent.emit({
      action: DocumentAction.DELETE,
      documentId: document.id,
      fileName: document.name,
    });
  }

  // Toggle document revisions
  toggleDocumentHistory(documentId: string | number) {
    this.showDocumentHistory = !this.showDocumentHistory;
    if (this.showDocumentHistory) {
      this.documentService.getDocumentRevisions(documentId).subscribe((documentsList) => {
        this.documentsList = documentsList;
      });
    }
  }

  // api: https://dev.azure.com/dpwhotfsonline/DTLP/_wiki/wikis/DTLP.wiki/32/File-uploads?anchor=update-document-data
  // Replace blockchain file
  handleFileReplace(documentId, files: FileList) {
    let fileToUpload;
    // this.uploadBusy = true;
    if (files.length > 0) {
      fileToUpload = files.item(0);
    } else {
      this.uiService.showSnackbar('Please select another', null, {
        duration: environment.snackBarDuration.warning,
        panelClass: 'warn',
      });
      return;
    }
    // this.fileInput.nativeElement.value = null;
    if (fileToUpload) {
      this.replaceBtnBusy = true;
      const formData: FormData = new FormData();
      // formData.append('entityType', EntityType.OCEAN_SHIPMENT);
      // formData.append('entityId', this.document.id + '');

      if (this.documentType.value && this.documentType.value != 'null') {
        formData.append('documentType', this.documentType.value);
      }
      formData.append('documentReference', this.documentReference.value);
      formData.append('file', fileToUpload, fileToUpload.name);

      this.documentService
        .updateShipmentDocument(documentId, formData)
        .pipe(finalize(() => (this.replaceBtnBusy = false)))
        .subscribe(
          (result) => {
            fileToUpload = null;
            this.uiService.showSnackbar('Document replaced successfully!', null, {
              duration: environment.snackBarDuration.success,
              panelClass: 'accent',
            });
            return;
          },
          (error) => {
            fileToUpload = null;
            this.uiService.showSnackbar('Failed to replace document', null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
          }
        );
    }
  }
}
