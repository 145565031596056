import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

export interface IFormData {
  id?: number;
  port: string;
  portCode: string;
  notificationCode: 'CN_EXTENDED_POO_STORAGE' | 'CN_EXTENDED_POD_STORAGE' | 'ARRIVING_SOON';
  defaultToleranceSecs: number;
  mediumSeverityToleranceSecs: number;
  highSeverityToleranceSecs: number;
}

interface IOption {
  mode: 'CREATE' | 'UPDATE' | 'DELETE' | 'CLEAR' | '';
  notificationCode: string;
  ports: any[];
}
@Component({
  selector: 'dp-notification-metric-form-add',
  templateUrl: './metric-form-add.component.html',
  styleUrls: ['./metric-form-add.component.scss'],
})
export class MetricFormAddComponent implements OnInit {
  @Input() currentMode: 'ADD_POO' | 'ADD_POD' | '';
  @Output() currentModeChange = new EventEmitter();

  // option configuration
  private _options: IOption = {
    mode: '',
    notificationCode: '',
    ports: [],
  };

  formGroup = this.fb.group({
    id: [null],
    port: [null],
    portCode: ['', [Validators.required]],
    notificationCode: ['', [Validators.required]],
    toleranceSecs: [null],
    mediumSeverityToleranceSecs: ['', [Validators.required, Validators.required, Validators.min(1), Validators.max(60)]],
    highSeverityToleranceSecs: ['', [Validators.required, Validators.required, Validators.min(1), Validators.max(60)]],
  });

  portFilterCtrl: FormControl = new FormControl();
  filteredPorts = [];

  @Input()
  get options() {
    return this._options;
  }
  set options(config: Partial<IOption>) {
    this._options = { ...this._options, ...config };
    this.filteredPorts = this._options.ports;
  }

  @Input() formData: IFormData;
  @Output() onDispatch = new EventEmitter();

  get mediumSeverityToleranceSecs() {
    return this.formGroup.get('mediumSeverityToleranceSecs').value;
  }

  get highSeverityToleranceSecs() {
    return this.formGroup.get('highSeverityToleranceSecs').value;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.formData) this.formGroup.patchValue(this.formData);

    this.portFilterCtrl.valueChanges.pipe(debounceTime(500)).subscribe((searchText: string) => {
      if (!searchText) {
        this.filteredPorts = this.options.ports;
        return;
      }
      // filter ports
      console.log(this.options.ports, searchText);

      this.filteredPorts = this.options.ports.filter((item) => {
        return (
          item.port.toLowerCase().includes(searchText.toLowerCase()) ||
          item.country_code.toLowerCase().includes(searchText.toLowerCase()) ||
          item.location_code.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      console.log('filtered: ', this.filteredPorts);
    });
  }

  dispatchAction(action: string) {
    console.log('action: ', action);
    let emitObject = {
      options: { ...this.options, mode: action },
      data: this.formGroup.value,
    };
    this.onDispatch.emit(emitObject);
  }

  // For CN_EXTENDED_POO_STORAGE and CN_EXTENDED_POD_STORAGE code
  isFormValid() {
    // let formValue = this.formGroup.value;
    return this.formGroup.valid;
  }

  isDefaultMetric(formGroup): boolean {
    return !formGroup.value.id && !formGroup.value.portCode;
  }
}
