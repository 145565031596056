<div class="tags-items-container" fxLayout="row wrap" fxLayoutAlign="start center" clickOutside (clickOutside)="clickOutside()">

  <span class="mr-4 nowrap" [ngClass]="getTagClass(i)" *ngFor="let tag of tags;let i = index">
    <span class="tagItem" showTooltipIfTruncated matTooltip="{{tag.name}}" [matTooltipPosition]="'above'">
      <!-- {{tag.name}} -->
      <span [innerHTML]="tag.name | highlightSearch: searchTerm"></span>
    </span>
    <span class="closeItem addTag cursor-pointer" (click)="clearTag(tag)" *ngIf="!readOnly">
      <i class="material-icons plusItem">clear</i>
    </span>
  </span>
  <span class="nowrap compactMode" *ngIf="showDots">
    <span class="tagDots" matTooltip="{{getTitle()}}" [matTooltipPosition]="'above'">...</span>
  </span>

  <div [hidden]="editingMode || readOnly">
    <span class="tagItem cursor-pointer" *ngIf="tags?.length===0" (click)="switchToEditing()">Add tag</span>
    <span class="plusItem addTag cursor-pointer" matTooltip="Add Tag" [matTooltipPosition]="'above'" *ngIf="tags?.length > 0"
      (click)="switchToEditing()">
      <i class="material-icons">add</i>
    </span>
  </div>
  <mat-spinner [diameter]="18" class="ml-8" [hidden]="!busy"></mat-spinner>
  <form class="schedule-filter-form inline-block" [hidden]="!editingMode">
    <mat-form-field class="port-field ml-12 w-120" floatLabel='never' style="margin-bottom: -1.25em; margin-top: -0.83em">
      <input type="text" class="w-120" placeholder="" aria-label="Tag" matInput [formControl]="tagEditingCtrl" [matAutocomplete]="auto"
        (keyup.enter)="onEnter($event.target.value)" (keyup.escape)="closeEditing()" (blur1)="onEnter($event.target.value)"
        floatLabel="never" #editTagInput>
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption="true" (optionSelected)="tagSelected($event)" (closed)="closed()">
        <div class="suggestions p-4">Suggestions</div>
        <mat-option *ngFor="let tag of tagFiltered$ | async" [value]="tag">
          {{tag.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>