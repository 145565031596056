<!-- TOP BACKGROUND -->
<div class="top-bg accent"></div>
<!-- / TOP BACKGROUND -->


<div class="center">
  <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
    <!-- APP TITLE -->
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="mr-0 mr-sm-16" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
        shopping_basket
      </mat-icon>
      <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        Purchase Order
      </span>
    </div>
    <!-- / APP TITLE -->

  </div>

  <div *ngIf="group">Selected value: {{group.value}}</div>

  <div class="p-16 pt-0 line-height-1.75" *ngIf="poItems && poItems.length > 0 && poItemSelected !== null">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span class="mr-16 text-bold">Related POs</span>
      <mat-button-toggle-group #group="matButtonToggleGroup" [(value)]="poItemSelected" (change)="selectionChanged($event)">
        <mat-button-toggle *ngFor="let poItem of poItems" [value]="poItem">
          {{poItem.poNumber}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>


  <div class="content-card">
    <div *ngIf="poDetail">
      <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div fxFlex="50" class="sec1" fxFlex.xs="50">
          <div class="title">Purchase Control</div>
          <dp-address-map class="address-lg" [address]="poDetail.organizationAddress"></dp-address-map>
        </div>
        <div fxFlex="50" class="sec2" fxFlex.xs="50">
          <div class="title">Purchase Order</div>
          <div class="address-lg">
            <div><span class="text-bold">PO:</span> {{poDetail.poNumber}}</div>
            <div><span class="text-bold">Date:</span> {{poDetail.poDate}}</div>
            <div><span class="text-bold">Status:</span> {{poDetail.state}}</div>
          </div>
          <mat-divider></mat-divider>
          <div class="address-lg">
            <div><span class="text-bold">incoterm:</span> {{poDetail.incoterm}}</div>
            <div><span class="text-bold">payment term:</span> {{poDetail.paymentTerm}}</div>
            <div><span class="text-bold">total:</span> {{poDetail.totalAmount}}</div>
            <div><span class="text-bold">currency:</span> {{poDetail.totalAmountCurrency}}</div>
            <div><span class="text-bold">PO date:</span> {{poDetail.poDate}}</div>
            <div><span class="text-bold">Expected ship date:</span> {{poDetail.expectedShipDate}}</div>
            <div><span class="text-bold">Expected delivery date:</span> {{poDetail.expectedDeliveryDate}}</div>
            <div><span class="text-bold">Notes:</span> {{poDetail.notes}}</div>
            <div><span class="text-bold">State:</span> {{poDetail.state}}</div>
            <div><span class="text-bold">Flagged:</span> {{poDetail.flagged}}</div>
            <div><span class="text-bold">ExternalSupplierId:</span> {{poDetail.externalSupplierId}}</div>
            <div><span class="text-bold">ReferenceId:</span> {{poDetail.referenceId}}</div>
          </div>
        </div>
      </div>
      <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div fxFlex="50" fxFlex.xs="100">
          <div class="title-highlighted">Supplier</div>
          <dp-address-map class="address" [address]="poDetail.supplierAddress">
          </dp-address-map>
        </div>
        <div fxFlex="50" fxFlex.xs="100">
          <div class="title-highlighted">Delivery Address</div>
          <dp-address-map class="address" [address]="poDetail.shipToAddress">
          </dp-address-map>
        </div>
      </div>
      <div class="content">
        <div class="title-highlighted  ">Notes</div>
        <div class="address">{{poDetail.notes || 'None'}}</div>
      </div>
      <mat-table class="content" [dataSource]="poDetail.items">
        <ng-container matColumnDef="Description">
          <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.productDescription | truncate:[trancateLimit]}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Code">
          <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.productCode}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Quantity">
          <mat-header-cell *matHeaderCellDef> Quantity </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.quantity | number}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Price">
          <mat-header-cell *matHeaderCellDef> Price </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.unitAmount | currency:element.unitAmountCurrency:"symbol-narrow"}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <div *ngIf="!poDetail" fxLayout="row" fxLayoutAlign="center center" style="flex: 1; min-height: 500px;">
      <mat-spinner>
      </mat-spinner>
    </div>

  </div>
</div>