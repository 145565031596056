import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { INotificationMetrics, mock_notificationMetrics } from './nm-model';

export interface INmState {
  notificationMetrics: INotificationMetrics[];
}

const initialState: INmState = {
  notificationMetrics: [],
};

// Notification Metrics Service
@Injectable({
  providedIn: 'root',
})
export class NmApiService {
  private _store = new BehaviorSubject<INmState>(initialState);
  vm$ = this._store.asObservable();

  // URL Endpoints
  private GET_NOTIFICATION_METRICS_URL = environment.rootUrl + 'admin/organizations';
  private NOTIFICATION_METRICS_URL = environment.rootUrl + 'admin/notificationMetrics';

  constructor(private http: HttpClient) {
    this.getNotificationMetrics().subscribe((notificationMetrics: INotificationMetrics[]) => {
      // console.log('convert to days format', notificationMetrics);

      // let transformedNotificationMetrics = notificationMetrics;

      this.update({ notificationMetrics });
    });
  }

  // -------- APIs ---------

  // get all notification metrics
  getNotificationMetrics(): Observable<INotificationMetrics[]> {
    // mock
    // return of(mock_notificationMetrics);
    return this.http.get<any[]>(this.GET_NOTIFICATION_METRICS_URL).pipe(
      map((result: any) => {
        return result.notificationMetrics;
      }),
      catchError(this.handleError<any[]>('get metrics', null))
    );
  }

  deleteNotificationMetric(metricId): Observable<any> {
    let URL = `${this.NOTIFICATION_METRICS_URL}/${metricId}`;
    return this.http.delete<any>(URL).pipe(catchError(this.handleError<any[]>('post metrics', null)));
  }

  // create notification metric
  createNotificationMetrics(request: any): Observable<any> {
    return this.http.post<any[]>(this.NOTIFICATION_METRICS_URL, request).pipe(catchError(this.handleError<any[]>('post metrics', null)));
  }

  // update notification metric
  updateNotificationMetrics(metricId: number, request: any): Observable<any> {
    let URL = this.NOTIFICATION_METRICS_URL + '/' + metricId;
    return this.http.put<any[]>(URL, request).pipe(catchError(this.handleError<any[]>('update metrics', null)));
  }

  update_ARRIVING_SOON_metric($event) {
    console.log('update: ', $event);
  }

  // State related functions
  currentState(): INmState {
    return this._store.getValue();
  }

  update(object: Partial<INmState>) {
    this._store.next({ ...this.currentState(), ...object });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
