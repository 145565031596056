import { AfterViewInit, Directive, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[isEllipsis]',
})
export class IsEllipsisDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.offsetWidth < element.scrollWidth) {
        element.title = element.innerHTML;
        const newStyle = element.getAttribute('style') + 'text-overflow: ellipsis;overflow: hidden;white-space: nowrap;';
        element.setAttribute('style', newStyle);
      }
    }, 100);
  }

  // this way could deal with content change, but might have performance issue
  // @HostListener('mouseenter')
  // onMouseEnter(): void {
  //   setTimeout(() => {
  //     const element = this.elementRef.nativeElement;
  //     if (element.offsetWidth < element.scrollWidth) {
  //       element.title = element.textContent;
  //     }
  //     else if (element.title) element.removeAttribute('title');
  //   }, 500);
  // }
}
