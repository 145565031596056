<!-- TOP BACKGROUND -->
<div class="top-bg accent"></div>
<!-- / TOP BACKGROUND -->


<div class="center">
  <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
    <!-- APP TITLE -->
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="mr-0 mr-sm-16" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <mat-icon fontSet="material-icons-outlined" class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
        directions_boat
      </mat-icon>
      <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        Shipment Information
      </span>
      <span *ngIf="oceanShipmentDetail" class="position-relative">
        <mat-icon matTooltipPosition="above" [color]="oceanShipmentDetail.flagged? 'warn' : null" matTooltip="Flip the flag" class="cursor-pointer" [class.disabled-text]="!oceanShipmentDetail.flagged"  (click)='toggleFlag()'>{{oceanShipmentDetail.flagged? 'flag' : 'outlined_flag'}}</mat-icon>
        <mat-icon class="disabled-text alertIcon" [ngClass]="oceanShipmentDetail.alerts===0? '' : (oceanShipmentDetail.maxSeverity===1? 'normal':'critical')" *ngIf="oceanShipmentDetail.totalAlerts > 0" matBadge="{{oceanShipmentDetail.alerts}}" [matBadgeHidden]="oceanShipmentDetail.alerts===0" fontSet="material-icons-outlined">announcement</mat-icon>
        <span style="position: absolute; width: 50px; height: 40px; right: -25px; top: -15px" *ngIf="oceanShipmentDetail.totalAlerts > 0" (dpHesitate)="openPopover(popoverTrigger)" [duration]="300" [mdePopoverTriggerFor]="alertsPopover" [mdePopoverOverlapTrigger]="true" mdePopoverTriggerOn="none" (mouseleave)="closePopover(popoverTrigger)" #popoverTrigger="mdePopoverTrigger"></span>
      </span>
    </div>
  </div>

  <div class="content-card">
    <div *ngIf="oceanShipmentDetail">
      <div>

        <div class="content position-relative">
          <div class="cards-content" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" fxLayout.xs="column">
              <div>
                <div class="field_row">
                  <label class="field_key">Shipper</label>
                  <label class="field_value">{{oceanShipmentDetail.shipper || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Consignee</label>
                  <label class="field_value">{{oceanShipmentDetail.consignee || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Shipment Type</label>
                  <label class="field_value">{{oceanShipmentDetail.shipmentType || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Shipment #</label>
                  <label class="field_value">{{oceanShipmentDetail.shipmentNumber || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">B/L #</label>
                  <label class="field_value">{{oceanShipmentDetail.mblNumber || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Carrier</label>
                  <label class="field_value">{{oceanShipmentDetail.carrier || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Vessel</label>
                  <label class="field_value">{{oceanShipmentDetail.vessel || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Voyage</label>
                  <label class="field_value">{{oceanShipmentDetail.voyage || ''}}</label>
                </div>
              </div>
              <div>
                <div class="field_row">
                  <label class="field_key">Origin Port</label>
                  <label class="field_value">{{oceanShipmentDetail.portOfDeparture || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">ETD</label>
                  <label class="field_value">{{oceanShipmentDetail.etd || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">ATD</label>
                  <label class="field_value">{{oceanShipmentDetail.atd || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Destination Port</label>
                  <label class="field_value">{{oceanShipmentDetail.portOfArrival || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">ETA</label>
                  <label class="field_value">{{oceanShipmentDetail.eta || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">ATA</label>
                  <label class="field_value">{{oceanShipmentDetail.ata || ''}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Total Weight</label>
                  <label class="field_value">{{oceanShipmentDetail.totalWeight || ''}} {{oceanShipmentDetail.weightUom}}</label>
                </div>
                <div class="field_row">
                  <label class="field_key">Total Volume</label>
                  <label class="field_value">{{oceanShipmentDetail.totalVolume || ''}} {{oceanShipmentDetail.volumeUom}}</label>
                </div>
              </div>
            </div>
            <div class="position-relative" style="min-width:500px; height:300px;">
              <agm-map *ngIf="mapPoints.length > 0 || vesselPoint" style="height: 100%; width: 100%;" [fitBounds]="true" [streetViewControl]="false">
                <agm-marker *ngIf="vesselPoint" [latitude]="vesselPoint.latitude" [longitude]="vesselPoint.longitude" [iconUrl]="vesselIcon" [agmFitBounds]="true" [label]="{text: vesselPoint.name}"></agm-marker>
                <agm-marker *ngFor="let point of mapPoints" [latitude]="point.latitude" [longitude]="point.longitude" [agmFitBounds]="true" [label]="{color: 'white', text: point.label }" [iconUrl]="point.isPast? iconGreen : iconRed"></agm-marker>
              </agm-map>
              <div *ngIf="gettingVesselInfo" class="position-absolute disabled-text p-8 align-left align-top">Loading vessel info...</div>
            </div>
          </div>
        </div>
      </div>



      <div fxLayout="row" fxLayout.xs="column" fxFlexFill>

        <div fxFlex="100" class="sec2 div-height" fxFlex.xs="100">
          <div style="padding-right: 20px">

            <!-- <div class="title">
              Shipment Details
            <div class="flagIcon">
              <mat-icon matTooltipPosition="above" [color]="oceanShipmentDetail.flagged? 'warn' : null" matTooltip="Flip the flag" class="cursor-pointer" [class.disabled-text]="!oceanShipmentDetail.flagged"  (click)='toggleFlag()'>{{oceanShipmentDetail.flagged? 'flag' : 'outlined_flag'}}</mat-icon>
              <mat-icon class="disabled-text" [ngClass]="oceanShipmentDetail.maxSeverity===1? 'normal':'critical'" (dpHesitate)="openPopover(popoverTrigger)" *ngIf="oceanShipmentDetail.alerts > 0" [duration]="300" matBadge="{{oceanShipmentDetail.alerts}}" fontSet="material-icons-outlined" (mouseleave)="closePopover(popoverTrigger)" [mdePopoverTriggerFor]="alertsPopover" mdePopoverTriggerOn="none" #popoverTrigger="mdePopoverTrigger">announcement</mat-icon>
            </div>
            </div>
          </div>
          <div class="p-20 " fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="10px grid">
            <div *ngIf="oceanShipmentDetail.shipper">
              <div fxFlex dp-icon-value-card [iconName]="'shipper.svg'" [label]="'Shipper'" [description]="oceanShipmentDetail.shipper"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.shipmentType">
              <div fxFlex dp-icon-value-card [iconName]="'shipment-type.svg'" [label]="'Shipment Type'" [description]="oceanShipmentDetail.shipmentType"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.shipmentNumber">
              <div fxFlex dp-icon-value-card [iconName]="'shipment-number.svg'" [label]="'Shipment #'" [description]="oceanShipmentDetail.shipmentNumber"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.consignee">
              <div fxFlex dp-icon-value-card [iconName]="'consignee.svg'" [label]="'Consignee'" [description]="oceanShipmentDetail.consignee"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.segments && oceanShipmentDetail.segments[0] && oceanShipmentDetail.segments[0].portOfLoading">
              <div fxFlex dp-icon-value-card [iconName]="'port-of-loading.svg'" [label]="'Origin Port'" [description]="oceanShipmentDetail.segments[0].portOfLoading"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.segments && oceanShipmentDetail.segments[0] && oceanShipmentDetail.segments[0].portOfDischarge">
              <div fxFlex dp-icon-value-card [iconName]="'port-of-discharge.svg'" [label]="'Destination Port'" [description]="oceanShipmentDetail.segments[0].portOfDischarge"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.totalWeight">
              <div fxFlex dp-icon-value-card [iconName]="'total-weight.svg'" [label]="'Total Weight'" [description]="oceanShipmentDetail.totalWeight + oceanShipmentDetail.weightUom"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.volumeUom">
              <div fxFlex dp-icon-value-card [iconName]="'total-volume.svg'" [label]="'Total Volume'" [description]="oceanShipmentDetail.totalVolume + oceanShipmentDetail.volumeUom"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.mblNumber">
              <div fxFlex dp-icon-value-card [iconName]="'mbl-number.svg'" [label]="'B/L #'" [description]="oceanShipmentDetail.mblNumber"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.carrier">
              <div fxFlex dp-icon-value-card [iconName]="'carrier.svg'" [label]="'Carrier'" [description]="oceanShipmentDetail.carrier"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.vessel">
              <div fxFlex dp-icon-value-card [iconName]="'vessel.svg'" [label]="'Vessel'" [description]="oceanShipmentDetail.vessel"></div>
            </div>
            <div *ngIf="oceanShipmentDetail.voyage">
              <div fxFlex dp-icon-value-card [iconName]="'voyage.svg'" [label]="'Voyage'" [description]="oceanShipmentDetail.voyage"></div>
            </div>
          </div> -->

            <div class="segment-padding">
              <div class="title">Segments</div>
              <mat-divider></mat-divider>

              <div *ngIf="oceanShipmentDetail.segments.length > 0">
                <div class="segment-content-padding">
                  <div>
                    <div fxFlex="12" class="ct_DataList">
                      <h4 class="text-bold">
                        Loading Port</h4>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                      <h4 class="text-bold">Discharge Port</h4>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                      <h4 class="text-bold">Vessel</h4>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                      <h4 class="text-bold">Voyage</h4>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                      <h4 class="text-bold">ETD</h4>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                      <h4 class="text-bold">ATD</h4>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                      <h4 class="text-bold">ETA</h4>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                      <h4 class="text-bold">ATA</h4>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div>
                    <div fxFlex="12" class="ct_DataList">
                        <div *ngFor="let segment of oceanShipmentDetail.segments">
                          <p>{{segment.portOfLoading || '&nbsp;'}}</p>
                        </div>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                        <div *ngFor="let segment of oceanShipmentDetail.segments">
                          <p>{{segment.portOfDischarge || '&nbsp;'}}</p>
                        </div>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                        <div *ngFor="let segment of oceanShipmentDetail.segments">
                          <p>{{segment.vessel || '&nbsp;'}}</p>
                        </div>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                        <div *ngFor="let segment of oceanShipmentDetail.segments">
                          <p>{{segment.voyage || '&nbsp;'}}</p>
                        </div>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                        <div *ngFor="let segment of oceanShipmentDetail.segments">
                          <p>{{segment.etd || '&nbsp;'}}</p>
                        </div>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                        <div *ngFor="let segment of oceanShipmentDetail.segments">
                          <p>{{segment.atd || '&nbsp;'}}</p>
                        </div>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                        <div *ngFor="let segment of oceanShipmentDetail.segments">
                          <p>{{segment.eta || '&nbsp;'}}</p>
                        </div>
                    </div>
                    <div fxFlex="12" class="ct_DataList">
                        <div *ngFor="let segment of oceanShipmentDetail.segments">
                          <p>{{segment.ata || '&nbsp;'}}</p>
                        </div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </div>
              </div>
              <div *ngIf="oceanShipmentDetail.segments.length === 0">
                <img class="center" src="assets/no-record.png" alt="">
              </div>


            </div>

            <div style="padding: 20px">

              <div class="title">Related Information</div>
              <mat-divider></mat-divider>
              <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChange($event)">
                <mat-tab label="Containers">
                  <div class="segment-content-padding">
                    <div>
                      <div fxFlex="25">
                        <h4 class="text-bold">Container #</h4>
                      </div>
                      <div fxFlex="25">
                        <h4 class="text-bold">Seal #</h4>
                      </div>
                      <div fxFlex="25">
                        <h4 class="text-bold">Type</h4>
                      </div>
                      <div fxFlex="25">
                        <h4 class="text-bold">Events</h4>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div *ngFor="let element of shipmentContainers">
                      <div class="container-content">
                        <div fxFlex="25" class="container_DataList">
                          <p>
                            <a [routerLink]="['/containers/' + element.containerId]"> {{element.containerNumber || ''}}</a> </p> </div> <div fxFlex="25" class="container_DataList">
                              <p>
                                {{element.sealNumber || ''}}
                              </p>
                        </div>
                        <div fxFlex="25" class="container_DataList">
                          <p>
                            {{element.containerSize || '&nbsp;'}} {{element.containerType || '&nbsp;'}}
                          </p>
                        </div>

                        <div fxFlex="25" class="container_DataList">
                          <p>
                            <ng-container *ngIf="element.containerEvents">
                              <dp-container-events [cntrevents]="element.containerEvents" fxFlex fxLayoutAlign="left center">
                              </dp-container-events>
                            </ng-container>
                          </p>
                        </div>

                      </div>
                      <mat-divider></mat-divider>
                    </div>
                    <p class="right-align">Total Count: {{shipmentContainers.length}}</p>
                  </div>
                </mat-tab>
                <mat-tab label="Events">
                  <div *ngIf="!noData" class="event-content" #eventContent>
                    <div class="main-container">

                      <div fxFlex="15">
                        <div class="tracking-icon-set">
                          <fa-icon class="detail-icon-set events-detail-icon" [icon]="['fal', 'calendar-star']"> </fa-icon>
                          <h4 class="detail-icon-set detail-caption text-bold">Events</h4>
                        </div>
                      </div>

                      <div fxFlex="15">
                        <div class="tracking-icon-set">
                          <fa-icon class="detail-icon-set events-detail-icon" [icon]="['fal', 'map-marked-alt']"> </fa-icon>
                          <h4 class="detail-icon-set detail-caption text-bold">Location</h4>
                        </div>
                      </div>

                      <div fxFlex="15">
                        <div class="tracking-icon-set">
                          <fa-icon class="detail-icon-set events-detail-icon" [icon]="['fal', 'person-dolly']"> </fa-icon>
                          <h4 class="detail-icon-set detail-caption text-bold">Carrier</h4>
                        </div>
                      </div>

                      <div fxFlex="20">
                        <div class="tracking-icon-set">
                          <fa-icon class="detail-icon-set events-detail-icon" [icon]="['fal', 'watch']"> </fa-icon>
                          <h4 class="detail-icon-set detail-caption text-bold">Estimated Time</h4>
                        </div>
                      </div>

                      <div fxFlex="35">
                        <div class="tracking-icon-set">
                          <fa-icon class="detail-icon-set events-detail-icon" [icon]="['fal', 'stopwatch']"> </fa-icon>
                          <h4 class="detail-icon-set detail-caption text-bold">Actual Time</h4>
                        </div>
                      </div>

                    </div>
                    <mat-divider></mat-divider>

                    <div class="timeline-div">
                      <section id="timeline" class="timeline-outer">
                        <div class="container" id="content">
                          <div class="row">
                            <div class="col s12 m12 l12">
                              <ul class="timeline">
                                <li *ngFor="let event of eventSummary" [ngClass]="{'past-line': event.dateStatus == dateStatus.PAST,
                                      'current-line': event.dateStatus == dateStatus.CURRENT,
                                      'future-line': event.dateStatus == dateStatus.FUTURE}">

                                  <div [ngClass]="{'event': event.dateStatus == dateStatus.CURRENT,
                                         'event-past': event.dateStatus == dateStatus.PAST}" class="text-space">

                                    <div fxFlex="15" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.eventType ||''}}</p>
                                      </span>
                                    </div>

                                    <div fxFlex="15" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.location ||''}}</p>
                                      </span>
                                    </div>

                                    <div fxFlex="15" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.carrier ||''}}</p>
                                      </span>
                                    </div>

                                    <div *ngIf="event.minEstimateDate !== event.maxEstimateDate" fxFlex="20" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.minEstimateDate}} .... {{event.maxEstimateDate}}</p>
                                      </span>
                                    </div>
                                    <div *ngIf="event.minEstimateDate === event.maxEstimateDate" fxFlex="20" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.minEstimateDate}}</p>
                                      </span>
                                    </div>

                                    <div *ngIf="event.minActualDate !== event.maxActualDate && (shipmentEventsSummary.totalContainerCount - event.containerCount === 0)" fxFlex="25" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.minActualDate}} .... {{event.maxActualDate}}</p>
                                      </span>
                                    </div>
                                    <div *ngIf="event.minActualDate === event.maxActualDate && (shipmentEventsSummary.totalContainerCount - event.containerCount === 0)" fxFlex="25" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.minActualDate}}</p>
                                      </span>
                                    </div>
                                    <div *ngIf="event.minActualDate !== event.maxActualDate && (shipmentEventsSummary.totalContainerCount - event.containerCount === 1)" fxFlex="35" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.minActualDate}} .... {{event.maxActualDate}} (1 container pending)</p>
                                      </span>
                                    </div>
                                    <div *ngIf="event.minActualDate === event.maxActualDate && (shipmentEventsSummary.totalContainerCount - event.containerCount === 1)" fxFlex="35" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.minActualDate}} (1 container pending)</p>
                                      </span>
                                    </div>
                                    <div *ngIf="event.minActualDate !== event.maxActualDate && (shipmentEventsSummary.totalContainerCount - event.containerCount > 1)" fxFlex="35" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.minActualDate}} .... {{event.maxActualDate}} ({{shipmentEventsSummary.totalContainerCount - event.containerCount}} containers pending)</p>
                                      </span>
                                    </div>
                                    <div *ngIf="event.minActualDate === event.maxActualDate && (shipmentEventsSummary.totalContainerCount - event.containerCount > 1)" fxFlex="35" class="timeline-item">
                                      <span>
                                        <p class="tracking-para">{{event.minActualDate}} ({{shipmentEventsSummary.totalContainerCount - event.containerCount}} containers pending)</p>
                                      </span>
                                    </div>
                                  </div>
                                  <mat-divider></mat-divider>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </section>
                      <br />
                    </div>
                  </div>

                  <div *ngIf="noData">
                    <img class="center" src="assets/no-record.png" alt="">
                  </div>
                </mat-tab>
                <mat-tab label="Documents" disabled>
                  <h1>Documents Tab</h1>
                  <p>...</p>
                </mat-tab>
                <mat-tab label="Items" disabled>
                  <h1>Items Tab</h1>
                  <div class="content-card">
                    <div class="poList" #poList>
                      <div>

                      </div>

                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="!oceanShipmentDetail" fxLayout="row" fxLayoutAlign="center center" style="flex: 1; min-height: 500px;">
        <mat-spinner>
        </mat-spinner>
      </div>

    </div>
  </div>

  <mde-popover #alertsPopover="mdePopover" [mdePopoverOverlapTrigger]="false" mdePopoverOffsetY="0" mdePopoverOffsetX="50">
    <mat-card style="width: 500px" class="mat-elevation-z12">
      <mat-card-header>
        <mat-card-title>Alerts</mat-card-title>
        <mat-card-subtitle>All alerts for this shipment</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content #alertsContent>
        <div class="shipmentsDetailAlert" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let alert of alerts" [class.inactive]="alert.state === 'INACTIVE'" [class.alert-color]="alert.severity > 1">
          <div>{{alert.message}}</div>
          <div>{{alert.createdAt.format('l') }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </mde-popover>
