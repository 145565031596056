import { Component, OnInit, Input } from '@angular/core';
import { DocumentService, DocumentAction } from 'app/shared/document.service';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IDocument } from 'app/shipments2/shipments2.model';
import { AuthService } from 'app/auth/auth.service';
import { User } from 'app/auth/user.model';
@Component({
  selector: 'dp-documents-upload-card',
  templateUrl: './documents-upload-card.component.html',
  styleUrls: ['./documents-upload-card.component.scss'],
})
export class DocumentsUploadCardComponent implements OnInit {
  @Input() document_types;
  @Input() document: IDocument;
  DocumentAction = DocumentAction;
  documentForm: FormGroup;
  user: User;


  constructor(public documentService: DocumentService, private authService: AuthService) {
    this.user = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    console.log('isSelfCreatedDocument', this.isSelfCreatedDocument());

    this.documentForm = new FormGroup({
      documentType: new FormControl({
        value: null,
        disabled: this.isReadOnlyDocument(this.document),
      }),
      documentReference: new FormControl({ value: '', disabled: this.isReadOnlyDocument(this.document) }),
    });

    this.documentType.setValue(this.document.documentType || '');
    this.documentReference.setValue(this.document.documentReference || '');
    this.documentReference.setValue(this.document.isPrivate || '');

    this.documentType.valueChanges.subscribe((documentType) => {
      this.documentForm.updateValueAndValidity();
      this.updateDocument(this.document.id, this.documentForm.value);
    });

    this.documentReference.valueChanges.pipe(debounceTime(1500), distinctUntilChanged()).subscribe((documentReference) => {
      this.documentForm.updateValueAndValidity();
      this.updateDocument(this.document.id, this.documentForm.value);
    });
  }

  isReadOnlyDocument(document: IDocument) {
    return document.isReadOnly || document.isSystemGenerated || !this.isSelfCreatedDocument();
  }

  get documentType() {
    return this.documentForm.get('documentType');
  }

  get documentReference() {
    return this.documentForm.get('documentReference');
  }

  updateDocument(documentId, formData) {
    this.documentService.actionEvent.emit({
      action: DocumentAction.UPDATE,
      documentId: documentId,
      formData,
    });
  }

  previewDocument(document: IDocument) {
    console.log('previewDocument -> document', document);
    const documentCode = this.documentForm.get('documentType').value;
    let lookupDocument = this.document_types.find((item) => item.code === documentCode);
    let documentName = lookupDocument ? lookupDocument.name : '';

    // TODO: this is a dead end, what does this call?
    this.documentService.actionEvent.emit({
      action: DocumentAction.PREVIEW,
      document,
      documentName,
    });
  }

  downloadDocument(document: IDocument) {
    this.documentService.actionEvent.emit({
      action: DocumentAction.DOWNLOAD,
      fileName: document.name,
      documentId: document.id,
    });
  }

  deleteDocument(document: IDocument) {
    this.documentService.actionEvent.emit({
      action: DocumentAction.DELETE,
      documentId: document.id,
      fileName: document.name,
    });
  }

  isSelfCreatedDocument() {
    if (this.user) {
      return this.document.organizationId === this.user.organizationId;
    }
    return false;
  }
}
