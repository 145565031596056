import { Component, OnInit, ViewChild, Output, ElementRef } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { ContainersService } from '../containers.service';
import { Subject, forkJoin } from 'rxjs';
import { ContainerFilters, ALERT_STATE } from '../containers.model';
import { environment } from 'environments/environment';
import { EventEmitter } from '@angular/core';
import deepEqual from 'deep-equal';

import { ProgressService, ProgressRef } from 'app/shared';
import moment from 'moment';
import { TRACKING_STATE, FILTER_FLAG } from '@dp/types/dp-model';


@Component({
  selector: 'dp-container-filters',
  templateUrl: './container-filters.component.html',
  styleUrls: ['./container-filters.component.scss']
})
export class ContainerFiltersComponent implements OnInit {
  origins: string[];
  selectedOrigin: string;
  destinations: string[];
  selectedDestination: string;
  vessels: string[];
  selectedVessel: string;
  voyages: string[];
  selectedVoyage: string;

  emptyFilters = environment.emptyContainerFilters as ContainerFilters;
  selectedState: TRACKING_STATE;
  states: string[];

  alerts: string[];
  selectedAlert: ALERT_STATE;

  selectedFlag: FILTER_FLAG;
  flags: string[]; //  = [environment.uiSetting.select_All_DisplayText, 'flagged', 'not flagged'];
  shipDateStart: moment.Moment;
  shipDateEnd: moment.Moment;
  deliveryDateStart: moment.Moment;
  deliveryDateEnd: moment.Moment;
  savedFilters: ContainerFilters;
  progressRef: ProgressRef;
  isFilterLoaded = false;

  @ViewChild(MdePopoverTrigger, { static: true }) filterPopover: MdePopoverTrigger;
  @ViewChild('filtersContent', { static: true }) filtersContent: ElementRef;
  unsubscribeAll: Subject<any>;

  @Output()
  filterChange = new EventEmitter();

  get filters(): ContainerFilters {
    return {
      flag: this.selectedFlag,
      state: this.selectedState,
      alert: this.selectedAlert,
      departure: this.selectedOrigin,
      arrival: this.selectedDestination,
      vessel: this.selectedVessel,
      voyage: this.selectedVoyage,
      etdStart: this.shipDateStart,
      etdEnd: this.shipDateEnd,
      etaStart: this.deliveryDateStart,
      etaEnd: this.deliveryDateEnd
    };
  }

  set filters(data: ContainerFilters) {
    this.selectedOrigin = data.departure;
    this.selectedDestination = data.arrival;
    this.selectedVessel = data.vessel;
    this.selectedVoyage = data.voyage;
    this.selectedFlag = data.flag;
    this.selectedState = data.state;
    this.selectedAlert = data.alert;
    this.shipDateStart = data.etdStart;
    this.shipDateEnd = data.etdEnd;
    this.deliveryDateStart = data.etaStart;
    this.deliveryDateEnd = data.etaEnd;
  }

  constructor(private containerService: ContainersService, private progressService: ProgressService) {
    this.unsubscribeAll = new Subject();
    this.states = Object.keys(TRACKING_STATE).map(key => TRACKING_STATE[key].toString());
    this.flags = Object.keys(FILTER_FLAG).map(key => FILTER_FLAG[key].toString());
    this.alerts = Object.keys(ALERT_STATE).map(key => ALERT_STATE[key].toString());
    // this.states.unshift(environment.uiSetting.select_All_DisplayText);
  }

  ngOnInit() {
    this.initFilters();
  }

  closePopover() {
    if (this.filterPopover.popoverOpen) {
      this.filterPopover.togglePopover();
      this.applyFilters();
    }
  }

  openPopover(target: MdePopoverTrigger) {
    target.openPopover();

    if (!this.isFilterLoaded) {
      this.progressRef = this.progressService.showProgress(this.filtersContent);
      this.isFilterLoaded = true;

      const observable = forkJoin([
        this.containerService.getOrigins(),
        this.containerService.getDestinations(),
        this.containerService.getVessels(),
        this.containerService.getVoyages()
      ]);

      observable.subscribe({
        next: ([origins, destinations, vessels, voyages]: [string[], string[], string[], string[]]) => {
          this.origins = [environment.uiSetting.select_All_DisplayText, ...origins];
          this.destinations = [environment.uiSetting.select_All_DisplayText, ...destinations];
          this.vessels = [environment.uiSetting.select_All_DisplayText, ...vessels];
          this.voyages = [environment.uiSetting.select_All_DisplayText, ...voyages];
         },
        complete: () => this.progressRef = this.progressService.detach(this.progressRef)
      });

    }
  }

  applyFilters() {
    if (!deepEqual(this.filters, this.savedFilters)) {
      this.saveFilters();
    }
  }

  saveFilters() {
    localStorage.setItem(environment.storage.containerFilters, JSON.stringify(this.filters));
    this.savedFilters = this.filters;
    this.filterChange.emit(this.savedFilters);
  }

  initFilters() {
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    function reviver(key, value) {
      if (typeof value === 'string' && dateFormat.test(value)) {
        return moment(value);
      }

      return value;
    }

    this.savedFilters = {
      ...this.emptyFilters,
      ...(JSON.parse(localStorage.getItem(environment.storage.containerFilters), reviver) as ContainerFilters)
    };

    this.initSelectedValues();

    this.filterChange.emit(this.savedFilters);
  }

  // getSupplierKey(supplier) {
  //   return Object.keys(supplier)[0];
  // }

  initSelectedValues() {
    this.selectedOrigin = this.savedFilters.departure;
    this.selectedDestination = this.savedFilters.arrival;
    this.selectedVessel = this.savedFilters.vessel;
    this.selectedVoyage = this.savedFilters.voyage;
    this.selectedFlag = this.savedFilters.flag;
    this.selectedState = this.savedFilters.state;
    this.selectedAlert = this.savedFilters.alert;
    this.shipDateStart = this.savedFilters.etdStart;
    this.shipDateEnd = this.savedFilters.etdEnd;
    this.deliveryDateStart = this.savedFilters.etaStart;
    this.deliveryDateEnd = this.savedFilters.etaEnd;
  }

  onFiltersChange(event: ContainerFilters) {
    this.filters = event;
    this.saveFilters();
  }
}
