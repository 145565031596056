import { AbstractControl, ValidationErrors } from '@angular/forms';

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {
  let value: string = control.value || '';

  if (!value) {
    return null;
  }

  let upperCaseCharacters = /[A-Z]+/g;
  if (upperCaseCharacters.test(value) === false) {
    return { passwordStrength: `Upper case required` };
  }

  let lowerCaseCharacters = /[a-z]+/g;
  if (lowerCaseCharacters.test(value) === false) {
    return { passwordStrength: `Lower case required` };
  }

  let numberCharacters = /[0-9]+/g;
  if (numberCharacters.test(value) === false) {
    return { passwordStrength: `Number required` };
  }

  // eslint-disable-next-line 
  let specialCharacters = /[!@#$%^&*]+/;
  if (specialCharacters.test(value) === false) {
    return { passwordStrength: `Password must contain at least one special character (!, @, #, $, %, ^, &, *)` };
  }

  if (value.length < 8) {
    return { passwordStrength: 'Password has to be at least 8 characters' };
  }

  if (value.length > 16) {
    return { passwordStrength: 'Password can be maximum of 16 characters' };
  }
  return null;
};
