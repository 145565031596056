import { INotificationItem } from 'app/navigation/header/notification/notification.model';

export enum FILTER_FLAG {
  ALL = 'All',
  FLAGGED = 'FLAGGED',
  NOTFLAGGED = 'NOT FLAGGED',
}

export enum TRACKING_STATE {
  ALL = 'All',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
}

export interface PageData<T> {
  total: number;
  data: T[];
}

export enum Keys {
  DELETE = 8,
  ENTER = 13,
  ESCAPE = 27,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_RIGHT = 39,
  ARROW_DOWN = 40,
}

export interface Pagination {
  pageSize: number;
  currentPage: number;
  pageSizes?: number[];
  pageSizeOptions?: number[];
  total?: number;
}

export interface ToggleFlagInfo {
  id: number;
  flag: boolean;
}

export interface ToggleNotificationInfo {
  id: number;
  subscribed: boolean;
}

export interface Couple {
  parent: number;
  child: number;
}

export interface ListEntity {
  key: string;
  displayText: string;
  children?: ListEntity[];
}

export interface BasicSuccessResponse {
  success: boolean;
}

export enum ACTION_TYPES {
  SHIPMENT,
  SHIPMENT_DETAIL,
}
export interface ChainedAction {
  current: ACTION_TYPES;
  next: ACTION_TYPES;
  payload: Object;
  child?: ChainedAction;
}

//TODO: remove all other Address interface
export interface Address {
  streetLine1: string;
  streetLine2?: string;
  streetLine3?: string;
  city: string;
  stateProv: string;
  postalCode: string;
  countryCode: string;
}

export interface InviteUserResult {
  email: string;
  status: string;
  message: string;
}

export interface ShipmentDropdownFilters {
  carriers?: string[];
  destinationHubs?: string[]; //portsOfArrival?: string[];
  originHubs?: string[]; //portsOfDeparture?: string[];

  consignee?: string[];
  originAddresses?: string[];
  destinationAddresses?: string[];
}

// single container tracking
export interface TrackingEvent {
  id: number;
  eventType: string;
  eventCode: string;
  carrier: string;
  estimateTime: string;
  actualTime: string;
  location: string;
  dpwFlag: boolean;
  isCurrent: boolean;
  containerId: number;
}
export interface Segment {
  vessel: string;
  voyage: string;
  portOfLoading: string;
  portOfDischarge: string;
  carrier: string;
  etd: string;
  atd: string;
  eta: string;
  ata: string;
}

export interface ContainersTracking {
  containerNumber: string;
  containerType: string;
  containerSize: string;
  carrier: string;
  segments: Segment[];
  trackingEvents: TrackingEvent[];
  origin: string;
  destination: string;
  etd: string;
  atd: string;
  eta: string;
  ata: string;
}

export interface Tab {
  id: string;
  label: string;
}

export interface dlTable {
  header: string[];
  rows: string[][];
}

export interface dlItem {
  dt: string;
  dd: string;
  table?: dlTable;
}

export interface IDbFilter {
  shipmentIds?: number[];
  shipmentNumber?: string;
  status?: string;
  exceptionSeverity?: number;
}
export interface IDbInfo {
  locations: any[];
  shipments: IDbShipment[];
  exceptions: Exception[];
  actions?: any[];
}
export interface IDbShipment {
  id: number;
  shipmentNumber: string;
  shipmentName: string;
  shipmentReference: string;
  shipmentType: string;
  shipmentContent: string;
  serviceMode: string;
  oceanCarrier: string;
  carrierInfo: string;
  departureDate: string;
  departureDateType: string;
  arrivalDate: string;
  arrivalDateType: string;
  portOfDeparture: string;
  departureDpwFlag: boolean;
  arrivalDpwFlag: boolean;
  portOfArrival: string;
  totalContainers: number;
  activeContainers: number;
  flagged: boolean;
  bolNumber: null;
  vessel: string;
  voyage: string;
  status: string;
  subStatus1: string;
  subStatus2: string;
  exceptionMessage: string;
  exceptionSeverity: number;
  etd: string;
  atd: string;
  eta: string;
  ata: null;
  incoterm: string;
  instructionsCutOffDate: string;
  trackingTrace: TimelineSpot[];
  shipmentProcedure: string;
  updatedAt?: string;
  originalEta?: string;
  matchingContainerNumbers?: number[];
}
export interface Exception {
  exception: string;
  severity: number;
  message: string;
  delay: string;
  shipmentIds?: number[] | null;
  updatedAt: string;
}

export interface TimelineSpot {
  title: string;
  isCurrent: boolean;
  message: string;
}

export interface Tag {
  id?: number;
  name: string;
}

export interface ItemSelectable {
  name: string;
  //selected?: boolean;
  subItems?: INotificationItem[];
}

export interface tableColumnInfo {
  name?: string;
  allowChange?: boolean;
  order?: number;
  show?: boolean;
  isEnabled?: boolean;
}

export interface tableGroupInfo {
  name: string;
  columns: { [key: string]: tableColumnInfo };
}

export interface tableInfo {
  [groupKey: string]: tableGroupInfo;
}
