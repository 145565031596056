<div fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    {{item.title}} <mat-icon class="material-icons-outlined info-icon" *ngIf="item.description" [matTooltip]="item.description"
      matTooltipPosition="after">
      info
    </mat-icon>
  </div>
  <div>
    <mat-spinner [diameter]="18" class="mr-8" [hidden]="!item.isBusy"></mat-spinner>
    <mat-slide-toggle [checked]="item.isEnabled" [disabled]="item.isBusy || item.allowChange === false" (change)="sliderChange(item)">
      {{item.isEnabled? 'On' : 'Off'}}
    </mat-slide-toggle>
  </div>
</div>