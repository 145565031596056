import { NgModule } from '@angular/core';
import { AddressMapComponent } from './address-map.component';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { environment } from 'environments/environment';
// import { GMapsService } from '@dp/services/geocode.service';

@NgModule({
  declarations: [AddressMapComponent],
  imports: [CommonModule, FlexLayoutModule, AgmCoreModule.forRoot({ apiKey: environment.mapKey })],
  exports: [AddressMapComponent],
  providers: [],
})
export class AddressMapModule {}
