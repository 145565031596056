import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UIService } from '../shared/ui.service';
import { AuthService } from '../auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'environments/environment';
import { STATES } from 'app/auth/user.model';

@Component({
  selector: 'dp-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {
  isExpanded = false;
  isHandset: boolean;
  isHandsetChangedSubscription: Subscription;
  isAuth: boolean;
  showSideNav: boolean;

  constructor(private uiService: UIService, private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.uiService.isHandsetChanged.subscribe(result => (this.isHandset = result));
    this.authService.currentUser.subscribe(user => {
      this.isAuth = this.authService.isAuth();
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        const url = event.urlAfterRedirects;
        const routes = environment.noHeaderRoutes as string[];
        if (routes.find((route) => url.indexOf(route) > 0)) {
          this.showSideNav = false;
        } else {
          this.showSideNav = true;
        }
      }      
    });
  }

  ngOnDestroy(): void {
    this.isHandsetChangedSubscription?.unsubscribe();
  }
}
