<section style="width:100%" *ngIf="steps.length > 0">
  <div class="headings flex" style="margin-left: 25px;">
    <div style="width:50%;">
      <h4>Location activities</h4>
    </div>
    <div style="width:35%">
      <h4>Date & time</h4>
    </div>
    <div style="width:15%; text-align: right">
      <h4>Duration</h4>
    </div>
  </div>
  <br>
  <ul class="ul-timeline" *ngFor="let step of steps">
    <li class="line" [ngClass]="step.isFuture || childrenHaveFuture(step.portActions) ? 'line-dashed' : 'line-solid'">
      <div [ngClass]="getEventClass(step)">
        <dp-port class="text-bold" [name]="step.address" [hasData]="step.dpwFlag" [maxWidth]="'250px'" style="position: relative; top:-5px">
        </dp-port>
        <em class="subtitle2-up" *ngIf="step.title">&nbsp;({{step.title}})</em>
      </div>
    </li>
    <!-- each event step -->
    <li class="line" *ngFor="let row of step.portActions" [ngClass]="row.isFuture ? 'line-dashed' : 'line-solid'">

      <!-- future events -->
      <div *ngIf="row.isFuture" class="future" style="padding: 0; color: gray" fxLayoutAlign="start center">
        <div class="item" style="width:50%">
          {{row.name}}
        </div>
        <div class="item" style="width:35%">
          {{row.date}}
        </div>
        <div *ngIf="row.duration" class="future-duration" style="width:15%">
          {{row.duration}}
        </div>
      </div>

      <!-- past events -->
      <div *ngIf="!row.isFuture" class="flex border-b past" style="padding: 0 0 10px 0px;">
        <div class="item" style="width:50%">
          {{row.name}}
        </div>

        <div class="item" style="width:35%">
          {{row.date}}
        </div>
        <div *ngIf="row.duration" class="duration" style="width:15%">
          {{row.duration}}
        </div>
      </div>
    </li>
  </ul>
</section>