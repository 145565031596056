import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { TimelineSpot } from '@dp/types';
import { SHIPMENT_STATE_TYPE, Shipment } from '../shipments2.model';
import { TimeLine, ContainerBlock } from './shipment-timeline.model';

@Component({
  selector: 'dp-shipment-timeline',
  templateUrl: './shipment-timeline.component.svg',
  styleUrls: ['./shipment-timeline.component.scss'],
})

/**
 * Please use ShipmentTimelineHorizontalComponent for all horizontal mode, this ShipmentTimelineComponent
 * will be renamed as ShipmentTimelineVerticalComponent later.
 * This would take time. 
 */
export class ShipmentTimelineComponent implements OnInit {
  timeLine: TimeLine;

  // for horizontal
  container: ContainerBlock = { w: 455, h: 54, padding: 3 };

  // for vertical
  containerVertical: ContainerBlock = { w: 190, h: 470, padding: 35 };

  // @Input() trackingTrace: TrackingTraceEntity[];
  @Input() shipment: Shipment;
  @Input() spots: TimelineSpot[];
  @Input() state: SHIPMENT_STATE_TYPE; //todo no longer need
  @Input() exceptionSeverity: number;
  @Input() vertical: boolean = false;
  @Input() showTitle = true;
  @Input() height: number = 54;
  @Input() tooltipEnabled: boolean = false;
  @Input() params: any = null;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // if we are first getting our day to display
    if (changes['spots'] && changes['spots'].currentValue) {
      this.setup();
    }
  }

  setup() {
    if (!this.vertical) {
      this.timeLine = new TimeLine(this.container, this.spots, this.state, this.vertical, this.exceptionSeverity);
    } else {
      // override for vertical scenario
      this.containerVertical.h = this.height;
      this.container = this.containerVertical;
      this.timeLine = new TimeLine(this.containerVertical, this.spots, this.state, this.vertical, this.exceptionSeverity, this.params);
    }
  }

  getExceptionSeverityClass(severity: number) {
    switch (severity) {
      case 0:
        return 'success-dark';
      case 1:
        return 'warning-dark';
      case 2:
        return 'error-dark';
      default:
        return 'success-dark';
    }
  }
}
