<dl>
  <ng-container *ngFor="let item of items">
    <dt>{{item.dt}}</dt>
    <dd>{{item.dd}}</dd>
    <div *ngIf="item.table" class="tableContainer" >
      <table  mat-table [dataSource]="item.table.rows" class="mt-8 mb-8">
        <ng-container *ngFor="let testColumn of item.table.header; let i = index ">
          <ng-container matColumnDef={{item.table.header[i]}}>
            <th mat-header-cell *matHeaderCellDef> {{item.table.header[i]}} </th>
            <td mat-cell *matCellDef="let element"> {{element[i]}} </td>
          </ng-container>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="item.table.header" class="compactRow"></tr>
        <tr mat-row *matRowDef="let row; columns: item.table.header;" class="compactRow"></tr>
      </table>
    </div>
  </ng-container>
</dl>