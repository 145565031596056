import { ValidatorFn, AbstractControl, FormGroup, FormArray } from '@angular/forms';

export function uniqueOrEmptyValueValidator(keyName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      //const isDirty = (control.parent as FormGroup)?.dirty;
      //return isDirty? { required: true } : null;
      return { required: true };
    }

    if (!control.parent || !control.parent.parent) return null;

    let fgs = control.parent.parent as FormArray;

    let hasSameValue = !!fgs.controls.some((fg: FormGroup) => {
      // fg.controls[keyName] !== control &&
      //console.log(fg);
      return fg.controls[keyName] !== control && fg.controls[keyName].value?.toUpperCase() === control.value.toUpperCase();
      // return Object.keys(fg.controls).find(
      //   (key) => {
      //     return (
      //       fg.controls[key] !== control &&
      //       typeof fg.controls[key]?.value === 'string' &&
      //       fg.controls[key]?.value?.toUpperCase() === control.value?.toUpperCase()
      //     ); });
    });
    if (!hasSameValue) {
      control.setErrors(null);
      return null;
    }
    control.setErrors({ hasDuplicates: true });
    return { hasDuplicates: true };
  };
}
