<button mat-raised-button color="primary" [mdePopoverTriggerFor]="filterPopover" mdePopoverTriggerOn="none" (click)="openPopover(popoverTrigger)" #popoverTrigger="mdePopoverTrigger">Filters</button>
<dp-filter-label [(filters)]="filters" (filterChange)="onFiltersChange($event)"></dp-filter-label>

<mde-popover id="po-filters" #filterPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false" (close)="applyFilters()">
  <mat-card style="width: 500px" class="mat-elevation-z12">
    <mat-card-header>
      <mat-card-title>Filters</mat-card-title>
      <mat-card-subtitle>All filters you can change.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content fxLayout="row wrap" fxLayoutGap="20px" #filtersContent>
      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedFlag">
          <mat-option *ngFor="let flag of flags" value={{flag}}>{{flag}}</mat-option>
        </mat-select>
        <mat-hint>Flag</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedState">
          <mat-option *ngFor="let state of states" value={{state}}>{{state}}</mat-option>
        </mat-select>
        <mat-hint>State</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedSupplierKey" (selectionChange)="supplierChanged($event)">
          <mat-option *ngFor="let supplier of suppliers | keyvalue" value={{supplier.key}}>{{supplier.key}}</mat-option>
        </mat-select>
        <mat-hint>Supplier</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="standard" fxFlex="45">
        <mat-select [(value)]="selectedDivisionCode">
          <mat-option *ngFor="let code of divisionCodes" value={{code}}>{{code}}</mat-option>
        </mat-select>
        <mat-hint>Division Code</mat-hint>
      </mat-form-field>

      <!-- date start-->
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="poStart" [(ngModel)]="dateStart">
        <mat-datepicker-toggle matSuffix [for]="poStart"></mat-datepicker-toggle>
        <mat-datepicker #poStart></mat-datepicker>
        <mat-hint>date range starting from</mat-hint>
      </mat-form-field>
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="poEnd" [(ngModel)]="dateEnd">
        <mat-datepicker-toggle matSuffix [for]="poEnd"></mat-datepicker-toggle>
        <mat-datepicker #poEnd></mat-datepicker>
        <mat-hint>date range ending at</mat-hint>
      </mat-form-field>
      <!-- date end -->
      <!-- shipDate start-->
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="poShipStart" [(ngModel)]="shipDateStart">
        <mat-datepicker-toggle matSuffix [for]="poShipStart"></mat-datepicker-toggle>
        <mat-datepicker #poShipStart></mat-datepicker>
        <mat-hint>ship date range starting from</mat-hint>
      </mat-form-field>
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="poShipEnd" [(ngModel)]="shipDateEnd">
        <mat-datepicker-toggle matSuffix [for]="poShipEnd"></mat-datepicker-toggle>
        <mat-datepicker #poShipEnd></mat-datepicker>
        <mat-hint>ship date range ending at</mat-hint>
      </mat-form-field>
      <!-- shipDate end -->
      <!-- date start-->
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="poDeliveryStart" [(ngModel)]="deliveryDateStart">
        <mat-datepicker-toggle matSuffix [for]="poDeliveryStart"></mat-datepicker-toggle>
        <mat-datepicker #poDeliveryStart></mat-datepicker>
        <mat-hint>delivery date from</mat-hint>
      </mat-form-field>
      <mat-form-field fxFlex="45">
        <input matInput [matDatepicker]="poDeliveryEnd" [(ngModel)]="deliveryDateEnd">
        <mat-datepicker-toggle matSuffix [for]="poDeliveryEnd"></mat-datepicker-toggle>
        <mat-datepicker #poDeliveryEnd></mat-datepicker>
        <mat-hint>delivery date to</mat-hint>
      </mat-form-field>
      <!-- date end -->

    </mat-card-content>
    <mat-card-actions align="right">
      <button (click)="closePopover()" mat-button>Close</button>
    </mat-card-actions>
  </mat-card>
</mde-popover>