import { Component, OnInit } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { UIService } from 'app/shared';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../users/users.service';
import { Feedback } from '../users/users.model';
import { finalize } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { StaticDataService } from '@dp/services/static-data.service';
import { AuthService } from 'app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dp-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  animations: dpAnimations,
})
export class ContactUsComponent implements OnInit {
  f: FormGroup;
  isBusy = false;
  submitted = false;
  feedback: Feedback = {
    questionCode: '',
    phone: '',
    description: '',
  };
  questions: Object;
  //  = [
  //   'I want to report a product issue',
  //   'I want to give improvement suggestion',
  //   'I want to understand which account type is suitable for my organization',
  //   'I want to discuss business development opportunities',
  //   'Others',
  // ];
  constructor(
    private usersService: UsersService,
    private uiService: UIService,
    private formBuilder: FormBuilder,
    private staticDataService: StaticDataService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let qa = this.route.snapshot.queryParams.qa;
    if (qa) {
      //todo: assign a right questionCode here
      this.feedback.questionCode = qa;
    }
    this.questions = this.staticDataService.getStaticDataDirect()['user_questions'];
    this.f = this.formBuilder.group({
      questionCode: [this.feedback.questionCode, Validators.required],
      description: [this.feedback.description, Validators.required],
      phone: [this.feedback.phone],
    });
  }

  sendFeedback() {
    const payload: Feedback = {
      questionCode: this.f.value.questionCode,
      phone: this.f.value.phone,
      description: this.f.value.description,
      email: this.authService.currentUserValue.userEmail,
    };
    this.isBusy = true;
    this.usersService
      .sendFeedback(payload)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(
        () => {
          this.submitted = true;
          // console.log('mike');
        },
        () => {
          this.uiService.showSnackbar("We can't take feedback right now. Please try again later.", null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  asIsOrder() {
    // return a.value < b.value ? 1 : -1;
    return 1;
  }
}
