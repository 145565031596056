import { Component, OnInit, HostBinding, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ContainerDetail, ContainerEvent, ContainerAlert, CONTAINER_ALERT_STATE } from '../containers.model';
import { ContainersService } from '../containers.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, timer, Subscription } from 'rxjs';
import { tap, takeUntil, finalize } from 'rxjs/operators';
import { DpProgressBarService } from '@dp/components/progress-bar/progress-bar.service';
import { dpAnimations } from '@dp/animations';
import { environment } from 'environments/environment';
import { UIService, ProgressService, ProgressRef } from 'app/shared';
import { DATE_STATUS } from '../containers.model';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faTasks as falTasks,
  faMapMarkerAlt as falMapMarkerAlt,
  faMapMarkedAlt as falMapMarkedAlt,
  faBallotCheck as falBallotCheck,
  faShoppingCart as falShoppingCart,
  faCalendarAlt as falCalendarAlt,
  faPersonDolly as falPersonDolly,
  faCalendarCheck as falCalenderCheck,
  faBoxAlt as falBoxAlt
} from '@fortawesome/pro-light-svg-icons';
import { MdePopoverTrigger } from '@material-extended/mde';

//todo: delete
@Component({
  selector: 'dp-container-detail',
  templateUrl: './container-detail.component.html',
  styleUrls: ['./container-detail.component.scss'],
  animations: dpAnimations
})
export class ContainerDetailComponent implements OnInit {

  containerDetail: ContainerDetail = null;
  containerDetailItems: ContainerEvent[] = [];
  containerDetailItemSelected: ContainerEvent;
  unsubscribeAll: Subject<any>;
  trancateLimit = environment.lineTrancateLimit.poDetail;
  dateStatus = DATE_STATUS;
  noData = false;

  alerts: ContainerAlert[];
  alertsProgressRef: ProgressRef;
  countDown: Subscription;
  popoverTrigger: MdePopoverTrigger;

  @ViewChild('group', { static: true }) group: ElementRef;
  @ViewChild('alertsContent', { static: true }) alertsContent: ElementRef;
  @HostBinding('class') class = 'page-layout carded fullwidth innder-scroll detailPage';
  displayedColumns = ['EventType', 'Location', 'Carrier', 'EstimateTime'];

  constructor(
    private containerService: ContainersService,
    private activatedRoute: ActivatedRoute,
    private uiService: UIService,
    private location: Location,
    private dpProgressBarService: DpProgressBarService,
    private progressService: ProgressService,
    library: FaIconLibrary
  ) {
    this.unsubscribeAll = new Subject();
    library.addIcons(
      falTasks,
      falMapMarkerAlt,
      falMapMarkedAlt,
      falShoppingCart,
      falCalendarAlt,
      falCalenderCheck,
      falPersonDolly,
      falBallotCheck,
      falBoxAlt
    );
  }

  ngOnInit() {
    this.alerts = null;
    this.activatedRoute.params
      .pipe(
        takeUntil(this.unsubscribeAll),
        tap(params => {
          //console.log('params=', JSON.stringify(params, null, 4));
          this.dpProgressBarService.show();
          this.containerService.getContainerEvntsById(params.id).subscribe(result => {
            this.containerDetailItems = window.history.state.containerDetailItems;
            if (this.containerDetailItems) {
              this.containerDetailItemSelected = this.containerDetailItems.find(item => item.id === +params.id);
            }
            this.dpProgressBarService.hide();
            this.containerDetailItems = result;

            if (this.containerDetailItems.length > 0) {
              if (!this.containerDetailItems[0].actualTime) {
                this.containerDetailItems[0].dateStatus = DATE_STATUS.FUTURE;
              } else {
                this.containerDetailItems[0].dateStatus = DATE_STATUS.CURRENT;
              }
              this.containerDetailItems.reduce((prev, cur) => {
                if (!cur.actualTime) {
                  cur.dateStatus = DATE_STATUS.FUTURE;
                } else if (!prev.actualTime) {
                  cur.dateStatus = DATE_STATUS.CURRENT;
                } else {
                  cur.dateStatus = DATE_STATUS.PAST;
                }
                return cur;
              });
            } else {
              this.noData = true;
            }
          });

          this.containerService.getContainerDetail(params.id).subscribe(result => {
            this.containerDetail = result;
            this.dpProgressBarService.hide();
          });
        })
      )
      .subscribe();
  }

  selectionChanged(item) {
    //console.log(JSON.stringify(item.value, null, 4));
    // this.router.navigateByUrl('/containers/' + item.value.id, { state: { containerDetailItems: this.containerDetailItems } });
  }

  toggleFlag() {
    // TODO: containers page has the same function. Consolidate it.
    this.containerService.updateContainer(this.containerDetail.id, { flagged: !this.containerDetail.flagged }).subscribe(
      () => {
        this.containerDetail.flagged = !this.containerDetail.flagged;
      },
      () => {
        this.uiService.showSnackbar('Failed to flip the flag!', null, {
          duration: 3000,
          panelClass: 'warn'
        });
      }
    );
  }
  // TODO: put this function to the interface?

  getContainerTypeSize(): string {
    return (
      (this.containerDetail.hasOwnProperty('containerSize') && this.containerDetail.containerSize !== null
        ? this.containerDetail.containerSize + ' '
        : '') +
      (this.containerDetail.hasOwnProperty('containerType') && this.containerDetail.containerType !== null
        ? this.containerDetail.containerType
        : '')
    );
  }

  openPopover(trigger: MdePopoverTrigger) {
    this.popoverTrigger = trigger;
    this.popoverTrigger.openPopover();

    if (!this.alerts) {
      this.alertsProgressRef = this.progressService.showProgress(this.alertsContent);
      this.dpProgressBarService.show();

      this.containerService
        .getContainerAlerts(this.containerDetail.id)
        .pipe(
          finalize(() => {
            this.alertsProgressRef = this.progressService.detach(this.alertsProgressRef);
            this.dpProgressBarService.hide();
          })
        )
        .subscribe(
          alerts => {
            this.alerts = alerts;
            this.setCountdown();
          },
          () => {}
        );
    } else {
      this.setCountdown();
    }
  }

  setCountdown() {
    if (this.containerDetail.alerts && this.popoverTrigger.popoverOpen) {
      this.countDown = timer(environment.alertRemoveTimer).subscribe(() => {
        this.containerService.alertsAreRead(this.containerDetail.id, this.containerDetail.oceanShipment.shipmentId).subscribe(result => {
          this.containerDetail.alerts = 0;
          this.alerts.map(alert => (alert.state = CONTAINER_ALERT_STATE.INACTIVE));
        });
      });
    }
  }

  closePopover(trigger: MdePopoverTrigger) {
    trigger.closePopover();
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  goBack() {
    this.location.back();
  }

}
