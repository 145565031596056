import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { DpConfigService } from '@dp/services/config.service';
import { DOCUMENT } from '@angular/common';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SplashScreenService } from '@dp/services/splash-screen.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PolicyTypes } from 'app/documents/documents.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  dpConfig: any;
  navigation: any;

  PolicyTypes = PolicyTypes;

  private _unsubscribeAll: Subject<any>;

  title = 'CARGOES Flow';
  constructor(
    @Inject(DOCUMENT) private document: any,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dpConfigService: DpConfigService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.matIconRegistry.addSvgIcon('site_logo', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cargoflow-new-square.svg'));
    this.matIconRegistry.addSvgIcon('site_logo_long', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cargoflow-new.svg'));
    this.matIconRegistry.addSvgIcon(
      'upload_containers',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/side-nav/polished/upload-containers.svg')
    );
    this.matIconRegistry.addSvgIcon('chevron_down', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/chevron_down.svg'));
    this.matIconRegistry.addSvgIcon('chevron_up', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/chevron_up.svg'));

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // Subscribe to config changes
    this.dpConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.dpConfig = config;

      // Boxed
      if (this.dpConfig.layout.width === 'boxed') {
        this.document.body.classList.add('boxed');
      } else {
        this.document.body.classList.remove('boxed');
      }

      // Color theme - Use normal for loop for IE11 compatibility
      for (let i = 0; i < this.document.body.classList.length; i++) {
        const className = this.document.body.classList[i];

        if (className.startsWith('theme-')) {
          this.document.body.classList.remove(className);
        }
      }

      this.document.body.classList.add(this.dpConfig.colorTheme);
    });

    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return appTitle + ' - ' + child.snapshot.data['title'];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openLink(para: string) {
    window.open(`/documents/${para}`, '_blank');
  }
}
