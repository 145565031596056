<mat-card class="mat-elevation-z2 wrapper dp-card">
  <div class="header">
    <div class="title">{{ entityName }}</div>
    <dp-address-create infoType={{entityName}}></dp-address-create>
  </div>
  <mat-card-content>
    <div *ngIf="selectAddress">
      <mat-form-field class="pl-24 pr-24">
        <mat-select [(value)]="selectAddress" required >
          <mat-option *ngFor="let address of addresses" [value]="address"> {{ address.locationName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-list>
        <mat-list-item>
          <mat-icon class="material-icons-outlined" mat-list-icon>perm_contact_calendar</mat-icon>
          <h4 mat-line >{{selectAddress.contactPerson}}</h4>
        </mat-list-item>
        <mat-list-item>
          <mat-icon class="material-icons-outlined" mat-list-icon>location_city</mat-icon>
          <h4 mat-line>{{ selectAddress.streetLine1 }}</h4>
          <h4 mat-line>{{ selectAddress.city + ', ' +  selectAddress.stateProv + ' ' + selectAddress.postalCode}}</h4>
          <h4 mat-line>{{ selectAddress.countryCode }}</h4>
        </mat-list-item>
        <mat-list-item>
          <mat-icon class="material-icons-outlined" mat-list-icon>phone_iphone</mat-icon>
          <h4 mat-line>{{selectAddress.phone}}</h4>
        </mat-list-item>
        <mat-list-item>
          <mat-icon class="material-icons-outlined" mat-list-icon>email</mat-icon>
          <h4 mat-line>{{selectAddress.email}}</h4>
        </mat-list-item>
      </mat-list>
    </div>
    <div *ngIf="!selectAddress">Click on "Add Shipper" button to create your first {{ entityName }} </div>
  </mat-card-content>
</mat-card>