import { User } from 'app/auth/user.model';
import * as _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';

//import 'moment-timezone';
// import { StaticDataService } from '@dp/services/static-data-remote.service';
import { StaticData } from '@dp/services/static-data.model';
import { TruckPage } from 'app/truck-shipments/trucks-models';

// export enum PastInRange {
//   Current_Month = 'Current month', //new
//   Past_3_months = 'Past 3 months',
//   Past_6_months = 'Past 6 months',
//   Past_12_months = 'Past 12 months',
//   Custom_range = 'Past 3 months',
//   Last_calendar_year = 'Last calendar year', //Last Calendar year
// }

export interface pastDateRange {
  start: string; //format: YYYY-MM-DD
  end: string; //format: YYYY-MM-DD
  endAtToday: boolean;
}

export class Utility {
  public static getInitialsByFullName(fullName: string): string {
    if (fullName.indexOf(' ') === -1) return fullName.slice(0, 2);

    let names = fullName.split(' ');
    let initials = names[0].charAt(0) + names[names.length - 1].charAt(0);
    return initials.toUpperCase();
  }

  public static getInitials(user: User): string {
    if (!user || (!user.firstName && !user.lastName)) {
      return 'N/A';
    }
    let initials = '';
    if (user.firstName && user.firstName.length > 0) {
      initials += user.firstName.charAt(0);
    }
    if (user.lastName && user.lastName.length > 0) {
      initials += user.lastName.charAt(0);
    }
    return initials.toUpperCase();
  }

  public static getFullName(user: User): string {
    let fullName =
      (user.firstName ? user.firstName : '') + (user.middleName ? ' ' + user.middleName : '') + (user.lastName ? ' ' + user.lastName : '');
    if (!user.firstName && !user.lastName) {
      fullName = 'Not Available';
    }
    return fullName;
  }

  public static getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  //get rid of json data property with null, undefined, empty
  public static cleanupData(o: any): Object {
    // return _.omitBy(o, v => _.isNil(v) || _.isEmpty(v));

    if (Object.prototype.toString.call(o) == '[object Array]') {
      for (let key = 0; key < o.length; key++) {
        this.cleanupData(o[key]);
        if (Object.prototype.toString.call(o[key]) == '[object Object]' || o[key] === '') {
          if (Object.keys(o[key]).length === 0) {
            o.splice(key, 1);
            key--;
          }
        }
      }
    } else if (Object.prototype.toString.call(o) == '[object Object]') {
      for (let key in o) {
        let value = this.cleanupData(o[key]);
        if (value === null || value === '' || value === undefined) {
          delete o[key];
        }
        if (Object.prototype.toString.call(o[key]) == '[object Object]') {
          if (Object.keys(o[key]).length === 0) {
            delete o[key];
          }
        }
        if (Object.prototype.toString.call(o[key]) == '[object Array]') {
          if (o[key].length === 0) {
            delete o[key];
          }
        }
      }
    }
    return o;
  }
  public static cleanupDataAndTrimSpace(o: any): Object {
    // return _.omitBy(o, v => _.isNil(v) || _.isEmpty(v));

    if (Object.prototype.toString.call(o) == '[object Array]') {
      for (let key = 0; key < o.length; key++) {
        this.cleanupData(o[key]);
        if (Object.prototype.toString.call(o[key]) == '[object Object]' || o[key] === '') {
          if (Object.keys(o[key]).length === 0) {
            o.splice(key, 1);
            key--;
          }
        }
      }
    } else if (Object.prototype.toString.call(o) == '[object Object]') {
      for (let key in o) {
        let value = this.cleanupData(o[key]);
        if (value === null || value === '' || value === undefined) {
          delete o[key];
        } else if (typeof value === 'string') {
          o[key] = (o[key] as string).trim();
        }
        if (Object.prototype.toString.call(o[key]) == '[object Object]') {
          if (Object.keys(o[key]).length === 0) {
            delete o[key];
          }
        }
        if (Object.prototype.toString.call(o[key]) == '[object Array]') {
          if (o[key].length === 0) {
            delete o[key];
          }
        }
      }
    }
    return o;
  }

  public static getPastDate(starting: string): moment.Moment {
    if (starting === 'LAST_3_MONTH') {
      return moment().startOf('month').subtract(2, 'months');
    } else if (starting === 'LAST_6_MONTH') {
      return moment().startOf('month').subtract(5, 'months');
    } else if (starting === 'LAST_12_MONTH') {
      return moment().startOf('month').subtract(11, 'months');
    } else if (starting === 'YEAR_TO_DATE') {
      return moment().startOf('year');
    } else {
      return moment();
    }
  }

  public static getPastDateInRange(pastInRange: string): pastDateRange {
    let result: pastDateRange;
    let start, end: moment.Moment;
    let endAtToday = true;
    let today: string = undefined;
    let thisMonth = moment(today).month();
    let monthsPassedThisQuarter = thisMonth % 3;
    // console.log(`thisMonth=${thisMonth}, monthsPassedThisQuarter=${monthsPassedThisQuarter} `);
    switch (pastInRange) {
      case 'TODAY':
        start = moment();
        end = moment();
        endAtToday = true;
        break;
      case 'YESTERDAY':
        start = moment().subtract(1, 'days');
        end = start;
        endAtToday = false;
        break;
      case 'Last03Days':
        start = moment().subtract(3, 'days');
        end = moment();
        endAtToday = true;
        break;
      case 'Last07Days':
        start = moment().subtract(7, 'days');
        end = moment();
        endAtToday = true;
        break;
      case 'Last14Days':
        start = moment().subtract(14, 'days');
        end = moment();
        endAtToday = true;
        break;
      case 'Last28Days':
        start = moment().subtract(28, 'days');
        end = moment();
        endAtToday = true;
        break;
      case 'Next07Days':
        start = moment().add(1, 'days');
        end = moment().add(7, 'days');
        endAtToday = false;
        break;
      case 'Next14Days':
        start = moment().add(1, 'days');
        end = moment().add(14, 'days');
        endAtToday = false;
        break;
      case 'Next28Days':
        start = moment().add(1, 'days');
        end = moment().add(28, 'days');
        endAtToday = false;
        break;

      case 'CURRENT_MONTH':
      case 'THIS_MONTH':
        start = moment().startOf('month');
        end = moment();
        endAtToday = true;
        break;
      case 'LAST_MONTH':
        start = moment().startOf('month').subtract(1, 'months');
        end = moment().startOf('month').subtract(1, 'months').endOf('month');
        endAtToday = true;
        break;
      case 'LAST_3_MONTH':
        start = moment().startOf('month').subtract(3, 'months');
        end = moment();
        endAtToday = true;
        break;
      case 'THIS_QUARTER':
        start = moment().startOf('month').subtract(monthsPassedThisQuarter, 'months');
        end = moment();
        endAtToday = false;
        break;

      case 'LAST_QUARTER':
        start = moment()
          .startOf('month')
          .subtract(3 + monthsPassedThisQuarter, 'months');
        end = moment()
          .subtract(monthsPassedThisQuarter + 1, 'months')
          .endOf('month');
        endAtToday = false;
        break;
      case 'LAST_6_MONTH':
        start = moment().startOf('month').subtract(6, 'months');
        end = moment(today);
        endAtToday = true;
        break;
      case 'LAST_12_MONTH':
        start = moment().startOf('month').subtract(12, 'months');
        end = moment();
        endAtToday = true;
        break;
      case 'YEAR_TO_DATE':
        start = moment().startOf('year');
        end = moment();
        endAtToday = true;
        break;
      case 'LAST_CALENDAR_YEAR':
        start = moment().subtract(1, 'years').startOf('year');
        end = moment().subtract(1, 'years').endOf('year');
        endAtToday = false;
        break;
      default:
        break;
    }
    return { start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), endAtToday };
  }

  public static detectedTimezone(staticData: StaticData): string {
    if (!moment.tz) {
      return '';
    }

    let guess = moment.tz.guess();

    if (!staticData.timezones_v2.find((timezone) => timezone.code === guess)) {
      return '';
    }
    return guess;
  }

  public static detectedLocale(existingLocales: Object): string {
    return navigator.languages.find((item) => existingLocales[item]) || 'en-US';
  }

  /**
   * Add an item to a localStorage() object
   * @param {String} name  The localStorage() key
   * @param {String} key   The localStorage() value object key
   * @param {String} value The localStorage() value object value
   */
  public static addToLocalStorageObject = function (name, key, value) {
    // Get the existing data
    let existing = localStorage.getItem(name);

    // If no existing data, create an array
    // Otherwise, convert the localStorage string to an array
    let data = existing ? JSON.parse(existing) : {};

    // Add new data to localStorage Array
    data[key] = value;

    // Save back to localStorage
    localStorage.setItem(name, JSON.stringify(data));
  };

  public static getLocalStorageObjectValue(storageKey: string, objKey: string) {
    let dataString = localStorage.getItem(storageKey);
    let data = dataString ? JSON.parse(dataString) : {};
    if (!data) return null;

    return data[objKey];
  }

  /// check two objects has same keys
  public static hasSameKeys(o1: Object, o2: Object) {
    const keys1 = Object.keys(o1).sort();
    const keys2 = Object.keys(o2).sort();
    return JSON.stringify(keys1) === JSON.stringify(keys2);
  }

  //TODO: unit test
  //reference: https://stackoverflow.com/questions/32911630/how-do-i-deal-with-localstorage-in-jest-tests
  public static removeFromLocalStorageObject = function (name, key) {
    // Get the existing data
    let existing = localStorage.getItem(name);

    // If no existing data, create an array
    // Otherwise, convert the localStorage string to an array
    let data = existing ? JSON.parse(existing) : {};

    if (!data) return;

    // Add new data to localStorage Array
    delete data[key];

    // Save back to localStorage
    localStorage.setItem(name, JSON.stringify(data));
  };

  public static getUTCFromTimezone(localDate, timezone): string {
    return moment.tz(localDate, timezone).utc(false).toISOString();
  }

  // public static getTemperatureSvg(temp: number, temperatureInfo: TruckPage.TemperatureInfo): string {
  //   let SVGs = ['assets/svg/temp-cold.svg', 'assets/svg/temp-ok.svg', 'assets/svg/temp-hot.svg'];
  //   return temp >= +temperatureInfo.maxTolerantTemperature ? SVGs[2] : temp <= +temperatureInfo.minTolerantTemperature ? SVGs[0] : SVGs[1];
  // }

  public static getTemperatureSvg(state: TruckPage.SHIPMENT_TEMPERATURE_STATES): string {
    let SVGs = ['assets/svg/temp-cold.svg', 'assets/svg/temp-ok.svg', 'assets/svg/temp-hot.svg'];
    let result = '';
    switch (state) {
      case TruckPage.SHIPMENT_TEMPERATURE_STATES.LOW:
        result = SVGs[0];
        break;
      case TruckPage.SHIPMENT_TEMPERATURE_STATES.NORMAL:
        result = SVGs[1];
        break;
      case TruckPage.SHIPMENT_TEMPERATURE_STATES.HIGH:
        result = SVGs[2];
        break;

      default:
        break;
    }
    return result;
  }

  public static getTemperatureText(temp: number, uom): string {
    return `${temp > 0 ? '+' : ''}${temp} ${uom === 'C' ? '°C' : '°F'}`;
  }

  public static getRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }

  // public static getLast6Month():moment.Moment {
  // }
  // public static getLast3Month():moment.Moment {
  //   return ;
  // }
  // public static getLast12Month():moment.Moment {
  //   return moment().startOf('month').subtract(11, 'months');
  // }

  // public static getYearToDate():moment.Moment {
  //   return moment().startOf('year');
  // }

  //'2020-08-18T23:46:33.696Z' => '18/08/2020 16:46:33'
  // public static isoStringToLocalDateTimeString(isoString: string): string {
  //   return moment(isoString).format('L') + ' ' + moment(isoString).format('LTS');
  // }
  // public static isoStringToLocalTimeString(isoString: string): string {
  //   return moment(isoString).format('LT');
  // }
}
