import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Keys } from '@dp/types';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface keyInfo {
  value: string;
  keyCode: Keys;
}
@Component({
  selector: 'dp-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class DpSearchBoxComponent implements OnInit {
  debounced: Subject<keyInfo> = new Subject<keyInfo>();

  @Input() placeholder: string;
  @Input() value: string = '';
  @Input() hasBorder: boolean;

  @Output() valueChange = new EventEmitter<string>();
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() keyUp: EventEmitter<string> = new EventEmitter();
  @Output() cancelClicked = new EventEmitter<any>();

  constructor() {
    this.debounced.pipe(debounceTime(environment.debounceTime)).subscribe((info: keyInfo) => this.handleChange(info));
  }

  ngOnInit() {
    if (this.value) {
      this.search.emit(this.value);
    }
  }

  // for paste by Mouse
  onModelChange($event) {
    this.valueChange.emit($event);
  }

  onKeyUp(event: KeyboardEvent) {
    let str = (event.target as HTMLInputElement).value;
    let printable_ASCII_only_str = str.replace(/[^ -~]+/g, '');
    this.debounced.next({
      value: printable_ASCII_only_str,
      keyCode: event.keyCode,
    });
  }

  handleChange(info: keyInfo) {
    this.value = info.value;
    if (info.keyCode === Keys.ENTER) {
      this.search.emit(this.value);
    }
    this.keyUp.emit(this.value);
    // this.valueChange.emit(this.value);
  }

  clear() {
    this.debounced.next({
      value: '',
      keyCode: null,
    });
    this.cancelClicked.emit();
  }
}
