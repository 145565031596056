/* eslint-disable for-direction */
import { Component, OnInit, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { containerValidator } from '@dp/validators/dp-container-validator';
import { StaticDataService } from '@dp/services/static-data.service';
import { ContainerService } from './container.service';
import { finalize, debounceTime, takeUntil, delay } from 'rxjs/operators';
import { AddContainerResponse, Currency } from './container.model';
import { UIService } from 'app/shared';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { ContainerTypes, DpContainerExistValidator } from '@dp/validators/dp-container-exist-validator';
import { Utility } from '@dp/utilities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { dpAnimations } from '@dp/animations';
import { uniqueOrEmptyValueValidator } from '@dp/validators/dp-unique-value-validator';
import { requiredByOtherFieldValidator } from '@dp/validators/dp-required-by-other-field-validator';
import moment from 'moment';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';
import { StaticData } from '@dp/services/static-data.model';
import { AddContainerByTypes } from 'app/navigation/nav.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MilestoneService } from '@dp/services/milestone.service';

@UntilDestroy()
@Component({
  selector: 'dp-add-container-dlg',
  templateUrl: './add-container-dlg.component.html',
  styleUrls: ['./add-container-dlg.component.scss'],
  animations: dpAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AddContainerDlgComponent implements OnInit {
  //modes: string[] = ['Container Number Only', 'Advance'];
  keys = Object.keys;
  environment = environment;
  AddContainerByTypes = AddContainerByTypes;
  staticData: StaticData;
  carriers: string[];
  public carrierFilterCtrl: FormControl = new FormControl();
  @ViewChild('carrierSelect', { static: true }) carrierSelect: MatSelect;
  public filteredCarriers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  carriersContainer: string[];
  carriersBK: string[];
  carriersMBL: string[];
  f: FormGroup = null;
  isBusy = false;
  scrollDown: number;
  dateFormat: string;
  addContainerByType: AddContainerByTypes = AddContainerByTypes.ByContainer; //set first tab selected

  currencies: Currency[];
  public filteredCurrencies: ReplaySubject<Currency[]> = new ReplaySubject<Currency[]>(1);
  @ViewChild('currencySelect', { static: true }) currencySelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  currencyFilterCtrl = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<AddContainerDlgComponent>,
    public formBuilder: FormBuilder,
    private staticDataService: StaticDataService,
    private containerService: ContainerService,
    private uiService: UIService,
    private router: Router,
    private dpContainerExistValidator: DpContainerExistValidator,
    private gaService: GoogleAnalyticsService,
    private milestoneService: MilestoneService
  ) {
    this.dateFormat = moment(new Date()).localeData().longDateFormat('L').toLowerCase();
  }

  ngOnInit(): void {
    // console.log('mike, data=', this.data);
    this.staticData = this.staticDataService.getStaticDataDirect();
    this.processStaticData();

    this.carriersContainer = this.staticData['carriers']
      .filter((carrier) => carrier['shipment_type'] === 'INTERMODAL_SHIPMENT')
      .map((carrier) => carrier.carrier_name);
    this.carriersBK = this.staticData['carriers']
      .filter(
        (carrier) =>
          carrier.supports_search_by_bk &&
          !carrier.requires_container_number_on_search_by_bk &&
          carrier['shipment_type'] === 'INTERMODAL_SHIPMENT'
      )
      .map((carrier) => carrier.carrier_name);
    this.carriersMBL = this.staticData['carriers']
      .filter(
        (carrier) =>
          carrier.supports_search_by_bol &&
          !carrier.requires_container_number_on_search_by_bol &&
          carrier['shipment_type'] === 'INTERMODAL_SHIPMENT'
      )
      .map((carrier) => carrier.carrier_name);

    this.f = this.formBuilder.group({
      containers: this.formBuilder.array([this.createContainerFormGroup()]),
    });

    this.addContainerByTypeChange();
  }

  get formContainers() {
    return this.f?.get('containers') as FormArray;
  }

  getRequiredFieldLabel() {
    return this.addContainerByType === AddContainerByTypes.ByContainer
      ? 'Container # '
      : this.addContainerByType === AddContainerByTypes.ByBooking
      ? 'Booking # '
      : 'MBL # ';
  }

  getSubTitle() {
    const label =
      this.addContainerByType === AddContainerByTypes.ByContainer
        ? 'container #'
        : this.addContainerByType === AddContainerByTypes.ByBooking
        ? 'booking #'
        : 'MBL #';
    return `Simply enter the ${label} and we will do the rest.`;
  }

  closeDlg(): void {
    this.dialogRef.close();
  }

  test() {
    console.log('test');
  }

  processStaticData() {
    this.currencies = this.staticData['currencies'];
    this.filteredCurrencies.next(this.currencies.slice());
    // let currencyFilterCtrl = new FormControl();
    this.currencyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCurrencies();
    });
  }

  addContainers() {
    this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadTemplate);

    let formData = this.getFormData();
    let uploadType =
      this.addContainerByType === AddContainerByTypes.ByContainer
        ? 'FORM_BY_CONTAINER_NUMBER'
        : this.addContainerByType === AddContainerByTypes.ByMBL
        ? 'FORM_BY_MBL_NUMBER'
        : 'FORM_BY_BOOKING_NUMBER';
    const payload = {
      formData,
      uploadType,
    };
    this.isBusy = true;

    this.containerService
      .addContainers(payload)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        (result: AddContainerResponse) => {
          console.log(result);
          if (result.totalError) {
            const firstMsg = result.flag === 'SUCCESS' ? 'There are warnings in the process:' : 'Submit failed!';

            if (result.flag === 'SUCCESS') {
              this.milestoneService.checkForEvent(environment.gaEvents.milestones.uploadSuccess);
            }

            result.errorDetail.unshift(firstMsg);
            this.uiService.showMultiLinesSnackbar(
              result.errorDetail,
              environment.snackBarDuration.warning,
              result.flag === 'SUCCESS' ? 'warn' : 'error'
            );
          } else {
            this.uiService.showSnackbar('Data is saved!', null, {
              duration: environment.snackBarDuration.success,
            });
          }
          this.closeDlg();
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromDlg}_${environment.gaEvents.labels.success}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.success);
          if (result.flag === 'SUCCESS') {
            this.navigateToContainerUploadPage(result);
          }
        },
        () => {
          this.uiService.showSnackbar("We can't process this right now. Please try again later.", null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
          const action = `${environment.gaEvents.categories.addContainer}_${environment.gaEvents.actions.addContainerFromDlg}_${environment.gaEvents.labels.failure}`;
          this.gaService.event(action, environment.gaEvents.categories.addContainer, environment.gaEvents.labels.failure);
        }
      );
  }

  getFormData() {
    let containers = this.formContainers?.value;
    if (!containers || !containers.length) {
      return null;
    }

    for (let i = containers.length - 1; i >= 0; i--) {
      if (
        (!containers[i].container_number && this.addContainerByType === AddContainerByTypes.ByContainer) ||
        (!containers[i].booking_number && this.addContainerByType === AddContainerByTypes.ByBooking) ||
        (!containers[i].mbl_number && this.addContainerByType === AddContainerByTypes.ByMBL)
      ) {
        containers.splice(i, 1);
      } else if (containers[i].products && containers[i].products.length > 1) {
        for (let j = containers[i].products.length - 1; j >= 0; j--) {
          if (!containers[i].products[j].product_number && containers[i].products.length > 1) {
            containers[i].products.splice(j, 1);
          }
        }
      }
    }

    containers = containers.flatMap((container) => {
      container = Utility.cleanupDataAndTrimSpace(container);
      let parentLevel = { ...container };
      delete parentLevel.products;
      delete parentLevel.showProducts;
      if (!container.products || !container.products.length) {
        return { ...parentLevel };
      } else {
        return container?.products.map((product) => {
          product = Utility.cleanupDataAndTrimSpace(product);
          return {
            ...parentLevel,
            ...product,
            ...{
              expiration_date: product.expiration_date ? product.expiration_date.format('YYYY-MM-DD') : null,
              production_date: product.production_date ? product.production_date.format('YYYY-MM-DD') : null,
            },
          };
        });
      }
    });
    return containers;
  }

  navigateToContainerUploadPage(result) {
    let currentUrl = this.router.url;
    if (currentUrl.indexOf('upload-history') === -1) {
      // this.router.navigate(['/upload-history']);
      this.router.navigate(['/upload-history'], {
        queryParams: {
          report: true,
          ...result,
        },
      });
    } else {
      //note: simplest trick to reload current page
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // this.router.navigate([currentUrl]);

        this.router.navigate([currentUrl], {
          queryParams: {
            report: true,
            ...result,
          },
        });
      });
    }
  }

  addContainerFormGroup() {
    const containers = this.f.get('containers') as FormArray;
    containers.push(this.createContainerFormGroup());

    //todo: scroll to bottom
    // this.scrollDown = this.containerList.nativeElement.scrollHeight;
  }

  addProductFormGroup(i) {
    const currentContainer = this.formContainers.controls[i];
    (currentContainer.get('products') as FormArray).push(this.createProductFormGroup());
  }
  expandContainerDetail(i: number) {
    const currentContainer = this.formContainers.controls[i];
    const showProductsCtl = currentContainer.get('showProducts');
    showProductsCtl.setValue(!showProductsCtl.value);
  }
  getExpandIcon(i: number): string {
    const current = this.formContainers.controls[i];
    const showProductsCtl = current.get('showProducts');
    return (showProductsCtl.value as boolean) ? 'expand_less' : 'expand_more';
  }

  shouldShowContainerDetailRows(i: number): boolean {
    const current = this.formContainers.controls[i];
    return this.shouldShowExpandBtn() && (current.get('showProducts').value as boolean);
  }

  shouldShowExpandBtn(): boolean {
    return this.addContainerByType === AddContainerByTypes.ByContainer ? true : false;
  }
  removeOrClearContainer(i: number) {
    const containers = this.f.get('containers') as FormArray;
    if (containers.length > 1) {
      containers.removeAt(i);
    } else {
      containers.reset();
    }
  }

  removeOrClearProduct(i: number, j: number) {
    const currentContainer = this.formContainers.controls[i];
    const products = currentContainer.get('products') as FormArray;
    if (products.length > 1) {
      products.removeAt(j);
    } else {
      products.reset();
    }
  }

  addContainerByTypeChange() {
    this.carriers =
      this.addContainerByType === AddContainerByTypes.ByContainer
        ? this.carriersContainer
        : this.addContainerByType === AddContainerByTypes.ByBooking
        ? this.carriersBK
        : this.carriersMBL;

    this.filteredCarriers.next(this.carriers.slice());
    this.carrierFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterCarriers();
    });

    const containers = this.f.get('containers') as FormArray;
    for (let i = containers.length - 1; i >= 1; i--) {
      containers.removeAt(i);
    }

    let fgs = this.f.controls.containers['controls'] as FormGroup[];

    fgs.forEach((fg) => {
      fg.reset();
      this.adjustValidators(fg);
    });
  }

  private filterCarriers() {
    if (!this.carriers) {
      return;
    }
    let search = this.carrierFilterCtrl.value;
    if (!search) {
      this.filteredCarriers.next(this.carriers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    console.log(
      this.carriers,
      this.carriers.filter((carrier) => carrier.toLowerCase().includes(search))
    );
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.toLowerCase().includes(search)));
  }
  private adjustValidators(fg: FormGroup) {
    if (this.addContainerByType === AddContainerByTypes.ByContainer) {
      fg.get('container_number').enable();
      fg.get('products').enable();
      fg.get('booking_number').setValidators(null);
      fg.get('booking_number').setAsyncValidators(null);
      fg.get('mbl_number').setValidators(null);
      fg.get('mbl_number').setAsyncValidators(null);
    } else if (this.addContainerByType === AddContainerByTypes.ByBooking) {
      fg.get('container_number').disable();
      fg.get('products').disable();
      fg.get('booking_number').setValidators([uniqueOrEmptyValueValidator('booking_number')]);
      fg.get('booking_number').setAsyncValidators([this.dpContainerExistValidator.containerExistValidator(ContainerTypes.BK)]);
      fg.get('mbl_number').setValidators(null);
      fg.get('mbl_number').setAsyncValidators(null);
    } else if (this.addContainerByType === AddContainerByTypes.ByMBL) {
      fg.get('container_number').disable();
      fg.get('products').disable();
      fg.get('booking_number').setValidators(null);
      fg.get('booking_number').setAsyncValidators(null);
      fg.get('mbl_number').setValidators([uniqueOrEmptyValueValidator('mbl_number')]);
      fg.get('mbl_number').setAsyncValidators([this.dpContainerExistValidator.containerExistValidator(ContainerTypes.MBL)]);
    }

    fg.updateValueAndValidity();
  }

  createContainerFormGroup(): FormGroup {
    let f = new FormGroup({
      container_number: new FormControl(
        '',
        [containerValidator(), uniqueOrEmptyValueValidator('container_number')],
        this.dpContainerExistValidator.containerExistValidator(ContainerTypes.CONTAINER)
      ),
      mbl_number: new FormControl(''),
      booking_number: new FormControl(''),
      shipment_reference: new FormControl(''),
      // ocean_line: new FormControl('', [requireBOLValidator(this.staticData)]),
      ocean_line: new FormControl(''),
      showProducts: new FormControl(false),
      products: this.formBuilder.array([this.createProductFormGroup()]),
    });

    this.adjustValidators(f);

    f['that'] = this;
    const container_number = f.get('container_number');
    const mbl_number = f.get('mbl_number');
    const booking_number = f.get('booking_number');
    if (this.addContainerByType !== AddContainerByTypes.ByContainer) {
      container_number.disable();
    }
    container_number.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
      this.containerFormAutoAppend(f);
    });
    mbl_number.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
      this.containerFormAutoAppend(f);
    });
    booking_number.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
      this.containerFormAutoAppend(f);
    });

    f.valueChanges.subscribe((val) => {
      let carrierRequires_bol = val.ocean_line
        ? this.staticData.carriers.find((carrier) => carrier.carrier_name === val.ocean_line).requires_bol
        : false;
      if (carrierRequires_bol && !f.controls.mbl_number.validator) {
        this.setMBLRequired(f.controls.mbl_number, true);
      } else if (!carrierRequires_bol && f.controls.mbl_number.validator && this.addContainerByType !== AddContainerByTypes.ByMBL) {
        this.setMBLRequired(f.controls.mbl_number, false);
      }
    });

    return f;
  }

  setMBLRequired(control: AbstractControl, isRequired: boolean) {
    control.setValidators(isRequired ? Validators.required : null);
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  getKeyField(f: FormGroup): AbstractControl {
    return this.addContainerByType === AddContainerByTypes.ByContainer
      ? f.controls['container_number']
      : this.addContainerByType === AddContainerByTypes.ByBooking
      ? f.controls['booking_number']
      : this.addContainerByType === AddContainerByTypes.ByMBL
      ? f.controls['mbl_number']
      : null;
  }

  containerFormAutoAppend(f: FormGroup) {
    //if it is the last one, add new one
    const keyField = this.getKeyField(f);
    if (keyField.status !== 'INVALID' && keyField.value) {
      // it is the last row
      const parentArray = f.parent as FormArray;
      const lastF = parentArray.controls[parentArray.length - 1];
      if (lastF === f && parentArray.length < 10) {
        // make sure the row is not empty
        const rowData = Utility.cleanupData(f.value);
        if (rowData) {
          // append an new record
          parentArray.push(f['that'].createContainerFormGroup());
        }
      }
    }
    return null;
  }

  createProductFormGroup(): FormGroup {
    let f = new FormGroup({
      po_number: new FormControl(''),
      product_number: new FormControl('', [requiredByOtherFieldValidator()]),
      product_description: new FormControl(''),
      hs_code: new FormControl(''),
      product_quantity: new FormControl('', [requiredByOtherFieldValidator()]),
      quantity_uom: new FormControl(''),
      unit_price: new FormControl(''),
      price_currency: new FormControl(''),
      lot_number: new FormControl(''),
      production_date: new FormControl(''),
      expiration_date: new FormControl(''),
    });
    f.setValidators(this.triggerValidate);
    f['that'] = this;
    return f;
  }

  triggerValidate(f: FormGroup) {
    Object.keys(f.controls).forEach((key) => {
      if (f.controls[key].validator) {
        f.controls[key].validator(f.controls[key]);
      }
    });
    //if it is the last one, add new one
    if (f.valid && f.touched && f.dirty) {
      // it is the last row
      const parentArray = f.parent as FormArray;
      const lastF = parentArray.controls[parentArray.length - 1];
      if (lastF === f && parentArray.length < 10) {
        // make sure the row is not empty
        const rowData = Utility.cleanupData(f.value);
        if (rowData) {
          // append an new record
          parentArray.push(f['that'].createProductFormGroup());
        }
      }
    }
    return null;
  }

  getErrorMessage(ctrl: FormControl, fieldName?: 'mbl') {
    if (!ctrl.errors || !ctrl.touched || ctrl.parent.pristine) return null;

    if (ctrl.errors?.required) {
      return fieldName === 'mbl' ? 'MBL # is required for the selected carrier' : 'This # is required';
      // let subFormData = Utility.cleanupData(ctrl.parent.getRawValue());
      // delete subFormData['showProducts'];
      // if (!_.isEmpty(subFormData)) {
      // }
    } else if (ctrl.errors['containerError']) {
      return ctrl.errors['containerError'];
    } else if (ctrl.errors['containerExists']) {
      return 'This # already exists';
    } else if (ctrl.errors['hasDuplicates']) {
      return 'This # is already in your form';
    }

    return null;
  }

  getProdErrorMessage(ctrl: FormControl) {
    if (ctrl.errors && ctrl.errors['hasDuplicates']) {
      return 'This # has to be unique';
    }
    return null;
  }

  filterCurrencies() {
    // get the search keyword
    let search = this.currencyFilterCtrl.value;
    if (!search) {
      this.filteredCurrencies.next(this.currencies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCurrencies.next(this.currencies.filter((currency) => currency.name.toLowerCase().indexOf(search) > -1));
  }

  invalidForm(): boolean {
    let fgs = this.f?.controls.containers['controls'] as FormGroup[];
    let isInvalid = false;
    let hasData = false;
    for (let i = 0; i < fgs?.length; i++) {
      if (fgs[i].dirty) {
        hasData = true;
        if (fgs[i].status === 'INVALID') {
          isInvalid = true;
        }
      }
    }
    if (!hasData) {
      isInvalid = true;
    }
    return isInvalid;
  }

  deleteCarrier(e: PointerEvent, container: FormGroup) {
    container.get('ocean_line').setValue('');
    e.stopPropagation();
  }
}
