<div class="top-bg accent"></div>

<div class="center">

  <div class="header accent">
    <div class="sub-header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" fxLayoutGap.gt-sm="100px">
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          departure_board
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          Create Shipment
        </span>
      </div>
    </div>
  </div>
  <div class="content-card">
    <mat-vertical-stepper (selectionChange)="stepClick($event)" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Starting point and ending point for your shipment</ng-template>
          <dp-address-select [entityName]="'Shipper'" [addresses]="shipperAddresses"></dp-address-select>
          <dp-address-select [entityName]="'Consignee'" [addresses]="shipperAddresses"></dp-address-select>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Step 2 details</ng-template>
          <div style="margin-bottom: 20px">
            <dp-shipment-origin-destination (selectedAddress)="onOriginAddressChange($event)" [entityName]="'Ship From'" [addresses]="shipperAddresses"></dp-shipment-origin-destination>
            <dp-shipment-origin-destination (selectedAddress)="onDestinationAddressChange($event)" [entityName]="'Ship To'" [addresses]="shipperAddresses"></dp-shipment-origin-destination>
          </div>
          <dp-shipment-route [selectedOrigin]="selectedOrigin" [selectedDestination]="selectedDestination" [ports]="ports"></dp-shipment-route>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Final step</ng-template>
        You are now done.
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>