import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { INotificationItem } from '../../../app/navigation/header/notification/notification.model';

@Component({
  selector: 'dp-notification-slider',
  templateUrl: './notification-slider.component.html',
  styleUrls: ['./notification-slider.component.scss'],
})
export class NotificationSliderComponent implements OnInit {
  @Input() item: INotificationItem;
  @Output() onChange: EventEmitter<INotificationItem> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.item.isBusy = false;
  }

  sliderChange(item) {
    this.item.isBusy = true;
    this.onChange.emit(item);
  }
}
