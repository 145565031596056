import { EventEmitter, Output } from '@angular/core';
import { Component, ElementRef, Input, AfterViewInit, ViewChild } from '@angular/core';
import { UtilsService } from '@dp/services/utils.service';
import { ProgressRef, ProgressService } from 'app/shared';
import { CalendarDayDetail, ScheduleEventTypes, ScheduleFacadePageTypes } from 'app/schedule/schedule.model';

@Component({
  selector: 'dp-schedule-detail',
  templateUrl: './schedule-detail.component.html',
  styleUrls: ['./schedule-detail.component.scss'],
})
export class ScheduleDetailComponent implements AfterViewInit {
  loadingProgressRef: ProgressRef = null;
  @ViewChild('contentZone') contentZone?: ElementRef;

  @Input() calendarDayDetail: CalendarDayDetail;

  @Input() set loadingDetail(loading: boolean) {
    if (loading) {
      this.loadingProgressRef = this.progressService.showProgress(this.contentZone);
    } else if (this.loadingProgressRef) {
      this.progressService.detach(this.loadingProgressRef);
    }
  }
  @Input() eventTypeActive: ScheduleEventTypes;
  @Input() eventTypes: ScheduleEventTypes[];
  @Input() pageType: ScheduleFacadePageTypes;
  @Output() close = new EventEmitter();

  constructor(private progressService: ProgressService, private utils: UtilsService) {}

  ngAfterViewInit(): void {}

  getDateStamp() {
    return this.utils.getDateWithPattern(this.calendarDayDetail.date);
  }

  closeClicked() {
    this.close.emit();
  }
}
