<div class="p-16">

  <div class="dp-h6">{{ user.firstName + "'s Profile" }}</div>
  <mat-slide-toggle id="enableAdminBtn" *ngIf="isAdmin && currentUser.id !== user.id" class="example-margin" [labelPosition]="'after'" [color]="'accent'" [(ngModel)]="user.isAdmin" (change)="onChange()">
    Admin user
  </mat-slide-toggle>

  <div fxLayout="row" fxLayoutAlign="start start" class="mt-8">
    <div class="profile-icon-lg m-16">
      {{ user.initials }}
    </div>
    <div class="m-8">
      <div class="caption"> {{ user.userEmail }}</div>
      <div class="subTitle">{{ user.fullName }}</div>
      <div class="subTitle" *ngIf="user.jobTitle">{{ user.jobTitle}} </div>
      <div class="subTitle" *ngIf="user.organizationName">{{ user.organizationName}} </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="entities" *ngIf="staticData">
    <div>
      <span class="entity-label">Title: </span>
      <span class="entity-property"> {{ user.role }} </span>
    </div>
    <div>
      <span class="entity-label">Department: </span>
      <span class="entity-property"> {{ user.department }} </span>
    </div>
    <div>
      <span class="entity-label">Phone: </span>
      <span class="entity-property"> {{ user.phone }} </span>
    </div>
    <div>
      <span class="entity-label">Language: </span>
      <span class="entity-property"> {{ staticData['locales'][user.locale]}} </span>
    </div>
    <div>
      <span class="entity-label">Timezone: </span>
      <span class="entity-property"> {{ getTimezoneName() }} </span>
    </div>


  </div>
</div>