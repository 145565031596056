import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dp-tracking-step',
  templateUrl: './tracking-step.component.html',
  styleUrls: ['./tracking-step.component.scss'],
})
export class TrackingStepComponent implements OnInit {
  @Input() steps;
  constructor() {}

  ngOnInit(): void {}

  getEventClass(event) {
    if (event.isCurrent) {
      return 'event-current';
    }
    if (event.isFuture) {
      return 'event-future';
    }
    return 'event-past';
  }

  childrenHaveFuture(steps: any[]): boolean {
    return steps.some(step => step.isFuture);
  }
}
