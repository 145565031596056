<mat-card class="mat-elevation-z2 wrapper dp-card">
  <div class="header">
    <div class="title">{{ entityName }}</div>
    <dp-address-create infoType={{entityName}}></dp-address-create>
  </div>
  <mat-card-content>
    <div>
      <mat-form-field class="pl-24 pr-24">
        <mat-select [(value)]="selectAddress" (selectionChange)="selectedAddressChange($event)" required>
          <mat-select-trigger>
            <mat-icon *ngIf="selectAddress && selectAddress.locationType === 'LP'">directions_boat</mat-icon>
            <mat-icon *ngIf="selectAddress && selectAddress.locationType === 'FC'">home</mat-icon>
            <span *ngIf="selectAddress">
              {{ selectAddress.streetLine1 }}, {{ selectAddress.city }}, {{ selectAddress.stateProv }}, {{ selectAddress.postalCode }}
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let address of addresses" [value]="address">
            <mat-icon *ngIf="address.locationType === 'LP'">directions_boat</mat-icon>
            <mat-icon *ngIf="address.locationType === 'FC'">home</mat-icon>
            {{ address.streetLine1 }}, {{ address.city }}, {{ address.stateProv }}, {{ address.postalCode }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>