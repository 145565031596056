<!-- TOP BACKGROUND -->
SHIPMENTS-OCEAN
<div class="top-bg accent"></div>
<!-- / TOP BACKGROUND -->

<!-- CENTER -->
<div class="center">

  <div class="header accent">
    <div class="sub-header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
      <!-- APP TITLE -->
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon fontSet="material-icons-outlined" class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          directions_boat
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          Shipments
        </span>
      </div>
      <!-- / APP TITLE -->

      <!-- SEARCH -->
      <div class="search-wrapper mx-32 mx-md-0">
        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>search</mat-icon>
          <input matInput #input placeholder="Search Shipment #, B/L #, Carrier">
        </div>
      </div>
      <!-- / SEARCH -->

      <div>
        <div class="button-set query-mark">
          <mat-icon matTooltip="Download Template File" (click)="downloadTemplate()">help_outline</mat-icon>
        </div>

        <!-- ADD BUTTON -->
        <form class="button-set">
          <button mat-raised-button [dpBusy]='isBusy' class="mt-24 mt-md-0 mr-4 button-set primary-800"  type="button" onclick="upfile.click()">
            Upload Shipments
          </button>
          <input #fileInput type="file" accept=".csv,.xlsx" id="upfile" (change)="fileChangeEvent($event)" style="visibility: hidden; position: absolute;">
          <button mat-raised-button [dpBusy]="downloadBusy" class="mt-24 mt-md-0 mr-4 primary-800" type="button" (click)="downloadShipments()">
            Download Shipments
          </button>
          
        </form>


        <!-- / ADD BUTTON -->
      </div>
    </div>
    <div class="sub-header" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="space-between center">
      <!-- <div fxLayoutAlign="center center">
        <span class="mr-16 text-bold">Suppliers:</span>
          <mat-select [(value)]="selectedSupplier" (selectionChange)="supplierChanged($event)" style="width: 200px" >
            <mat-option *ngFor="let supplier of suppliers | keyvalue" value={{supplier.value}}>{{supplier.key}}</mat-option>
          </mat-select>
      </div> -->

      <a *ngIf="!productionHosting" [routerLink]="['/shipments/create']">
        <button mat-raised-button class="mt-24 mt-md-0 mr-4" color="primary" type="button">
          Shipping Request
        </button>
      </a>

      <dp-shipment-filters fxFlex fxLayoutAlign="left center" (filterChange)="setfilters($event)">
      </dp-shipment-filters>
    </div>
  </div>



  <div class="content-card">
    <div class="shipmentList" #shipmentList>
      <div [hidden]="noData">
        <mat-paginator class="marginless-paginator-range-label" #paginator [pageSize]="pageSize"></mat-paginator>
        <div style="overflow-x: auto;">
          <mat-table [dataSource]="dataSource" matSort style="min-width: 1400px;">
            <ng-container matColumnDef="flagged-alerts-totalAlerts">
              <mat-header-cell fxFlex="100px" *matHeaderCellDef mat-sort-header start="desc"> Flag/Alert </mat-header-cell>
              <mat-cell fxFlex="100px" *matCellDef="let element" class="position-relative">
                <button mat-icon-button [color]="element.flagged? 'warn' : null" [class.disabled-text]="!element.flagged" (click)="toggleFlag($event, element)">
                  <mat-icon>{{element.flagged? 'flag' : 'outlined_flag'}}</mat-icon>
                </button>
                <mat-icon class="disabled-text" [ngClass]="element.alerts===0? '' : (element.maxSeverity===1? 'normal':'critical')" *ngIf="element.totalAlerts > 0" matBadge="{{element.alerts}}" [matBadgeHidden]="element.alerts===0" fontSet="material-icons-outlined">announcement</mat-icon>
                <span style="position: absolute; width: 50px; height: 48px; right: 0;" *ngIf="element.totalAlerts > 0" (dpHesitate)="openPopover(popoverTrigger, element)" [duration]="300" [mdePopoverTriggerFor]="alertsPopover" [mdePopoverOverlapTrigger]="true" mdePopoverTriggerOn="none" (mouseleave)="closePopover(popoverTrigger)" #popoverTrigger="mdePopoverTrigger"></span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="shipmentNumber">
              <mat-header-cell fxFlex="90px" *matHeaderCellDef mat-sort-header> Shipment # </mat-header-cell>
              <mat-cell fxFlex="90px" *matCellDef="let element" [innerHTML]="(element.shipmentNumber || '') | highlightSearch: filterText"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalContainers">
              <mat-header-cell fxFlex="50px" *matHeaderCellDef mat-sort-header class="header-width"> Ctnr </mat-header-cell>
              <mat-cell fxFlex="50px" class="cell-container" *matCellDef="let element"><span class="flex-right">{{element.totalContainers || ''}}</span></mat-cell>
            </ng-container>

            <ng-container matColumnDef="portOfDeparture">
              <mat-header-cell class="hasMinWidth" *matHeaderCellDef mat-sort-header> Origin port </mat-header-cell>
              <mat-cell class="hasMinWidth" *matCellDef="let element" [innerHTML]="element.portOfDeparture || ''"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="portOfArrival">
              <mat-header-cell class="hasMinWidth" *matHeaderCellDef mat-sort-header> Destination port </mat-header-cell>
              <mat-cell class="hasMinWidth" *matCellDef="let element" [innerHTML]="element.portOfArrival || ''"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="mblNumber">
              <mat-header-cell fxFlex="120px" *matHeaderCellDef mat-sort-header> BL # </mat-header-cell>
              <mat-cell fxFlex="120px" *matCellDef="let element" [innerHTML]="(element.mblNumber || '') | highlightSearch: filterText"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="carrier">
              <mat-header-cell fxFlex="90px" *matHeaderCellDef mat-sort-header> Ocean line </mat-header-cell>
              <mat-cell fxFlex="90px" *matCellDef="let element" [innerHTML]="(element.carrier || '') | highlightSearch: filterText"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="vessel">
              <mat-header-cell class="hasMinWidth" *matHeaderCellDef mat-sort-header> Vessel </mat-header-cell>
              <mat-cell class="hasMinWidth" *matCellDef="let element" [innerHTML]="element.vessel || ''"></mat-cell>
            </ng-container>


            <ng-container matColumnDef="voyage">
              <mat-header-cell fxFlex="60px" *matHeaderCellDef mat-sort-header> Voyage </mat-header-cell>
              <mat-cell fxFlex="60px" *matCellDef="let element" [innerHTML]="element.voyage || ''"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="etd">
              <mat-header-cell fxFlex="90px" *matHeaderCellDef mat-sort-header> ETD </mat-header-cell>
              <mat-cell fxFlex="90px" *matCellDef="let element" [innerHTML]="element.etd || ''"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="atd">
              <mat-header-cell fxFlex="90px" *matHeaderCellDef mat-sort-header> ATD </mat-header-cell>
              <mat-cell fxFlex="90px" *matCellDef="let element" [innerHTML]="element.atd || ''"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="eta">
              <mat-header-cell fxFlex="90px" *matHeaderCellDef mat-sort-header> ETA </mat-header-cell>
              <mat-cell fxFlex="90px" *matCellDef="let element" [innerHTML]="element.eta || ''"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="ata">
              <mat-header-cell fxFlex="90px" *matHeaderCellDef mat-sort-header> ATA </mat-header-cell>
              <mat-cell fxFlex="90px" *matCellDef="let element" [innerHTML]="element.ata || ''"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="trackingState">
              <mat-header-cell fxFlex="90px" *matHeaderCellDef mat-sort-header> State </mat-header-cell>
              <mat-cell fxFlex="90px" *matCellDef="let element" [innerHTML]="element.trackingState || ''"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <mat-header-cell fxFlex="110px" *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
              <mat-cell fxFlex="110px" *matCellDef="let element">{{element.createdAt.format('l')}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]="selectedRow === row" (click)="onRowClicked(row)"></mat-row>
          </mat-table>
        </div>
      </div>

      <div *ngIf="noData">
        <img class="center" src="assets/no-record.png" alt="">
      </div>

    </div>
  </div>
</div>
<!-- / CENTER -->

<mde-popover id="containerAlerts" #alertsPopover="mdePopover" mdePopoverOffsetY="0" mdePopoverOffsetX="50">
  <mat-card style="width: 500px" class="mat-elevation-z12">
    <mat-card-header>
      <mat-card-title>Alerts</mat-card-title>
      <mat-card-subtitle>All alerts for this shipment</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content #alertsContent>
      <div class="containerAlert" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let alert of alerts" [class.inactive]="alert.state === 'INACTIVE'" [class.alert-color]="alert.severity > 1">
        <div>{{alert.message}}</div>
        <div>{{alert.createdAt.format('l') }}</div>
      </div>
    </mat-card-content>
  </mat-card>
</mde-popover>

<!--
<button mat-raised-button [routerLink]="'/shipments/create'" class="mt-24 mt-md-0" color="primary">
  Create a shipment
</button>
<img src="assets/images/pageSamples/Shipments.png" alt="">
-->
