import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import {
  Container,
  ContainerFilters,
  ContainerEvent,
  ContainerAlert,
  SuperUploadResult,
  SUPER_UPLOAD_FLAG,
  SUPER_UPLOAD_COLOR,
  CONTAINER_ALERT_STATE
} from './containers.model';
import { ContainersService } from './containers.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, MatSortable } from '@angular/material/sort';
import { fromEvent, merge, Subject, Subscription, timer } from 'rxjs';
import { ContainersDataSource } from './containers.datasource';
import { tap, debounceTime, distinctUntilChanged, takeUntil, finalize } from 'rxjs/operators';
import { ProgressService, ProgressRef } from '../../shared/progress-container/progress.service';
import { Router } from '@angular/router';
import { dpAnimations } from '@dp/animations';
import { environment } from 'environments/environment';
import { UIService } from 'app/shared';
import { MdePopoverTrigger, MdePopover } from '@material-extended/mde';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBoxAlt as falBoxAlt } from '@fortawesome/pro-light-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

//todo: delete this
@UntilDestroy()
@Component({
  selector: 'dp-po',
  templateUrl: './containers.component.html',
  styleUrls: ['./containers.component.scss'],
  animations: dpAnimations
})
export class ContainersComponent implements OnInit, AfterViewInit {
  CONTAINER_ALERT_STATE = CONTAINER_ALERT_STATE;

  uploadResponse: SuperUploadResult = {
    overAll: '',
    flag: SUPER_UPLOAD_FLAG.ALL_FAILED,
    color: SUPER_UPLOAD_COLOR.WARN,
    errorDetail: [],
    totalError: 0
  };
  unsubscribeAll: Subject<any>;
  downloadedContainers: Container[];
  alerts: ContainerAlert[] = [];
  isHandset: boolean;
  isHandsetChangedSubscription: Subscription;

  dataSource: ContainersDataSource;
  displayedColumns: string[];
  alertsCache = {};
  selectedRow: Container = null;
  progressRef: ProgressRef;
  alertsProgressRef: ProgressRef;
  filterText = '';
  poFilters: ContainerFilters = null;
  noData = false;
  uploadBusy = false;
  downloadBusy = false;
  beforeLoading: boolean;
  containerDetailItems: ContainerEvent[];
  trancateLimit = environment.lineTrancateLimit.poList;
  pageSize = environment.tablePageSize;
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    headers: ['container number', 'container size', 'container type', 'seal number', 'tracking state'],
    showTitle: true,
    useBom: true,
    removeNewLines: false,
    keys: []
  };

  countDown: Subscription;
  popoverTrigger: MdePopoverTrigger;

  @ViewChild(MdePopover, { static: true }) alertsPopover: MdePopover;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('containerList', { static: true }) containerList: ElementRef;
  @ViewChild('alertsContent', { static: true }) alertsContent: ElementRef;
  @ViewChild('input', { static: true }) input: ElementRef;
  @ViewChild('fileInput', { static: true }) fileInputVariable: ElementRef;
  @ViewChild('downloadBtn', { static: true }) downloadBtn: ElementRef;
  @HostBinding('class') class = 'page-layout carded fullwidth innder-scroll';
  @ViewChild(MdePopoverTrigger, { static: true }) filterPopover: MdePopoverTrigger;
  // previousRef: MdePopoverTrigger;

  constructor(
    private uiService: UIService,
    private containerService: ContainersService,
    private progressService: ProgressService,
    private router: Router,
    private library: FaIconLibrary
  ) {
    this.unsubscribeAll = new Subject();
    library.addIcons(falBoxAlt);
  }

  ngOnInit() {
    this.sort.sort({
      id: 'flagged-alerts-totalAlerts',
      start: 'desc'
    } as MatSortable);

    this.alertsCache = {};
    this.beforeLoading = true;

    // TODO: remove pageType
    // this.pageType = POPageType.PO;

    this.uiService.isHandsetChanged.subscribe(result => (this.isHandset = result));
    // this.purchaseOrderService.getSuppliers().subscribe(suppliers => {
    //   this.suppliers = suppliers;
    // });

    this.setTableColumns(true);
    this.dataSource = new ContainersDataSource(this.containerService, this.paginator, this.uiService);
    // this.loadPurchaseOrdersPage();  // this.dataSource.loadPurchaseOrders();
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading && !this.progressRef) {
        this.progressRef = this.progressService.showProgress(this.containerList);
        this.beforeLoading = false;
      } else if (!isLoading) {
        this.progressRef = this.progressService.detach(this.progressRef);
        this.noData = this.paginator && this.paginator.length === 0 && !this.beforeLoading;
      }
    });

    // TODO: unsubscribe it
    // this.dataSource.connect(null).subscribe(data => {
    //   this.poItems = data.map(item => ({ id: item.id, poNumber: item.poNumber }));
    // });
  }

  setTableColumns(isFull) {
    this.displayedColumns = this.isHandset
      ? [
        'flagged-alerts-totalAlerts',
        'containerNumber',
        'shipmentNumber',
        'portOfDeparture',
        'portOfArrival',
        'carrier',
        'originalEta',
        'eta',
        'ata'
      ]
      : [
        'flagged-alerts-totalAlerts',
        'containerNumber',
        'shipmentNumber',
        'containerType',
        'portOfDeparture',
        'portOfArrival',
        'carrier',
        'mblNumber',
        'originalEta',
        'vessel',
        'voyage',
        'etd',
        'atd',
        'eta',
        'ata',
        'trackingState',
        'createdAt'
      ];
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        untilDestroyed(this),
        debounceTime(environment.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadContainers();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: Sort | PageEvent) => {
          if (data.hasOwnProperty('pageIndex')) {
            this.paginator.pageIndex = (data as PageEvent).pageIndex;
            this.paginator.pageSize = (data as PageEvent).pageSize;
          } else {
            this.sort.active = (data as Sort).active;
            this.sort.direction = (data as Sort).direction;
          }
          this.loadContainers();
        })
      )
      .subscribe();
  }

  loadContainers() {
    this.filterText = this.input.nativeElement.value;
    this.dataSource.loadContainers(
      this.filterText,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.poFilters
    );
  }

  downloadContainers() {
    this.filterText = this.input.nativeElement.value;
    this.downloadBusy = true;
    this.containerService
      .downloadCSV(this.filterText, this.sort.active, this.sort.direction, this.poFilters)
      .pipe(finalize(() => (this.downloadBusy = false)))
      .subscribe(
        result => {
          this.downloadedContainers = result.map(container => {
            const oceanShipment = container.oceanShipment;
            delete container.oceanShipment;
            delete container.inlandShipments;
            delete container.id;
            if (oceanShipment) {
              container = {
                ...container,
                ...{
                  oceanShipmentNumber: oceanShipment.shipmentNumber,
                  vessel: oceanShipment.vessel || 'N/A',
                  voyage: oceanShipment.voyage || 'N/A',
                  portOfDeparture: oceanShipment.portOfDeparture || 'N/A',
                  portOfArrival: oceanShipment.portOfArrival || 'N/A',
                  carrier: oceanShipment.carrier || 'N/A',
                  mblNumber: oceanShipment.mblNumber || 'N/A',
                  originalEta: oceanShipment.originalEta || 'N/A',
                  etd: oceanShipment.etd || 'N/A',
                  atd: oceanShipment.atd || 'N/A',
                  eta: oceanShipment.eta || 'N/A',
                  ata: oceanShipment.ata || 'N/A'
                }
              };
            }

            Object.keys(container).map(k => {
              container[k] = container[k] || 'N/A';
            });

            return container;
          });

          this.csvOptions.headers = Object.keys(this.downloadedContainers[0]);

          setTimeout(() => {
            
          }, 0);
        },
        error => {
          this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
        }
      );
  }

  onRowClicked(row: Container) {
    this.selectedRow = row as Container;
    this.router.navigateByUrl('/containers/' + this.selectedRow.id, { state: { ContainerDetailItems: this.containerDetailItems } });
  }

  openPopover(target: MdePopoverTrigger, container: Container) {
    this.popoverTrigger = target;
    this.popoverTrigger.openPopover();
    if (!this.alertsCache[container.id]) {
      this.alertsProgressRef = this.progressService.showProgress(this.alertsContent);
      this.containerService.getContainerAlerts(container.id).subscribe(
        alerts => {
          this.alertsProgressRef = this.progressService.detach(this.alertsProgressRef);
          this.alertsCache[container.id] = alerts;
          this.loadAlerts(container, this.alertsCache[container.id]);
        },
        error => { }
      );
    } else {
      this.loadAlerts(container, this.alertsCache[container.id]);
    }
  }

  closePopover(target: MdePopoverTrigger) {
    target.closePopover();
    if (this.countDown) {
      this.countDown.unsubscribe();
      this.countDown = null;
    }
  }

  loadAlerts(container: Container, alerts: ContainerAlert[]) {
    if (this.popoverTrigger.popoverOpen) {
      this.setCountdown(container);
      this.alerts = alerts;
    }
  }

  setCountdown(container: Container) {
    if (container.alerts > 0) {
      this.countDown = timer(environment.alertRemoveTimer).subscribe(() => {
        if (this.popoverTrigger.popoverOpen) {
          this.containerService.alertsAreRead(container.id, container.oceanShipment.shipmentId).subscribe(result => {
            container.alerts = 0;
            this.alerts.map(alert => (alert.state = CONTAINER_ALERT_STATE.INACTIVE));
            this.alertsCache[container.id] = this.alerts;
          });
        }
      });
    }
  }

  fileChangeEvent(event) {
    this.uploadBusy = true;
    const formData: FormData = new FormData();
    formData.append('fileName', event.target.files.item(0), event.target.files.item(0).name);
    formData.append('uploadType', 'CONTAINERS_CSV');

    this.containerService
      .uploadContainerFile(formData)
      .pipe(finalize(() => (this.uploadBusy = false)))
      .subscribe(
        result => {
          this.uploadResponse.overAll = result.overAll;
          this.uploadResponse.errorDetail = result.errorDetail;
          this.uploadResponse.totalError = result.totalError;

          if (result.flag === 'Success') {
            this.uiService.showSnackbar('Upload Is Successful!', null, {
              duration: 3000,
              panelClass: 'accent'
            });
          } else if (result.flag === 'Failed') {
            this.uploadResponse.flag = SUPER_UPLOAD_FLAG.ALL_FAILED;
            this.uploadResponse.color = SUPER_UPLOAD_COLOR.PRIMARY;
            this.uiService.openDialog(this.uploadResponse);
          } else {
            this.uploadResponse.flag = SUPER_UPLOAD_FLAG.PARTIAL_PASSED;
            this.uploadResponse.color = SUPER_UPLOAD_COLOR.WARN;
            this.uiService.openDialog(this.uploadResponse);
          }

          this.loadContainers();
        },
        error => {
          this.uiService.showSnackbar(error.message, null, {
            duration: 3000,
            panelClass: 'warn'
          });
        }
      );

    this.fileInputVariable.nativeElement.value = '';
  }

  toggleFlag(event, container: Container) {
    event.stopPropagation();
    this.containerService.updateContainer(container.id, { flagged: !container.flagged }).subscribe(
      success => {
        container.flagged = !container.flagged;
      },
      error => {
        this.uiService.showSnackbar('Failed to flip the flag!', null, {
          duration: 3000,
          panelClass: 'warn'
        });
      }
    );
  }

  getContainerTypeSize(container: Container): string {
    return (
      (container.hasOwnProperty('containerSize') && container.containerSize !== null ? container.containerSize + ' ' : '') +
      (container.hasOwnProperty('containerType') && container.containerType !== null ? container.containerType : '')
    );
  }
  setfilters(filters: ContainerFilters) {
    this.poFilters = filters;
    this.loadContainers();
  }

}
