import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from 'app/auth/user.model';
import { UIService } from 'app/shared';
import { AuthService } from 'app/auth/auth.service';
import { StaticDataService } from '@dp/services/static-data.service';
import { StaticData } from '@dp/services/static-data.model';

@Component({
  selector: 'dp-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  currentUser: User;
  staticData: StaticData;
  @Input() user: User;
  @Input() isAdmin: boolean;
  @Output() permissionChanged: EventEmitter<User> = new EventEmitter();
  constructor(private dataService: StaticDataService, private authService: AuthService) {}

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue;
    this.staticData = this.dataService.getStaticDataDirect();
  }

  onChange() {
    this.permissionChanged.emit(this.user);
  }

  getTimezoneName(): string {
    // if (this.user.timezone === 'Pacific Standard Time') this.user.timezone = 'America/Los_Angeles';

    return this.staticData['timezones_v2']?.find((timezone) => timezone.code === this.user.timezone)?.name;
  }
}
