import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GroupByKey } from '../../app/tracking-by-group/models/tbg-models';

export enum ContainerTypes {
  CONTAINER,
  BK,
  MBL,
}

@Injectable({
  providedIn: 'root',
})
export class DpContainerExistValidator {
  url: string;
  groupUrl: string;
  constructor(private http: HttpClient) {
    this.url = environment.rootUrl + environment.urls.containerExist;
    this.groupUrl = environment.rootUrl + environment.urls.groupUploadValidate;
  }
  containerExists(container: string, containerType: ContainerTypes): Observable<Object> {
    if (containerType === ContainerTypes.CONTAINER) {
      const containerUrl = `${this.url}?container=${container}`;
      return this.http.get<Object>(containerUrl);
    } else {
      const params = {
        groupValue: container,
        groupKey: containerType === ContainerTypes.BK ? 'bkNumber' : 'blNumber',
      };
      return this.http.get<Object>(this.groupUrl, { params });
    }
  }

  //validate by ContainerType
  containerExistValidator(containerType: ContainerTypes): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      return this.containerExists(control.value, containerType).pipe(
        map((res) => {
          console.log(res);
          return !res?.['valid'] ? { containerExists: true } : null;
        })
      );
    };
  }
}
