import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Address } from 'app/explorer/po';

@Component({
  selector: 'dp-shipment-origin-destination',
  templateUrl: './shipment-origin-destination.component.html',
  styleUrls: ['./shipment-origin-destination.component.scss']
})
export class ShipmentOriginDestinationComponent implements OnInit {
  @Input()
  defaultAddress: Address;
  @Input()
  addresses: Address[];
  @Input()
  entityName: string;

  @Output() selectedAddress: EventEmitter<Address> = new EventEmitter();

  selectAddress: Address;

constructor() {}

  ngOnInit() {
  }
  
  selectedAddressChange(event) {
    this.selectedAddress.emit(event.value);
  }

}