<mat-tab-group id="notificationDlg">
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="withDot tabLabel">Events</div>
    </ng-template>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill class="tabContainer">
      <div fxFlex class="content">
        <ng-container *ngIf="infiniteEvents | async as events">
          <cdk-virtual-scroll-viewport itemSize="100" minBufferPx="500" maxBufferPx="1000"
            (scrolledIndexChange)="nextBatch($event, events.length)" class="eventList">
            <!-- itemSize="100" is the height of row -->
            <div class="notificationItem" [ngClass]="{'read': event.read}"
              *cdkVirtualFor="let event of events; let i = index; let count= count;">
              <div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="eventTitle">
                    {{event.title}}
                  </div>
                  <div class="eventTime">
                    {{event.timeLabel}} : {{ i }} / {{ count }}
                  </div>
                </div>
              </div>
              <dp-event-detail [event]="event" (dismiss)="dismissIt()"></dp-event-detail>
            </div>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </div>
      <div fxFlex="none" class="footer">
        <a>Mark all read</a>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="withDot tabLabel">Exceptions</div>
    </ng-template>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill>
      <div fxFlex class="content">
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
        <p>blah blah blah</p>
      </div>
      <div fxFlex="none" class="footer"><a>Mark all read</a></div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tabLabel">Account</div>
    </ng-template>

    Content 3
  </mat-tab>
  <mat-tab label="">
    <ng-template mat-tab-label>
      <div class="tabLabel">System</div>
    </ng-template>
    <dp-system-tab fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill class="tabContainer"></dp-system-tab>
  </mat-tab>
</mat-tab-group>