import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { User } from 'app/auth/user.model';
import moment from 'moment';
import { ProgressService, ProgressRef } from 'app/shared';

@Component({
  selector: 'dp-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit, AfterViewInit {
  invitedAt = '';
  progressRef: ProgressRef = null;
  @Input() user: User;
  @Input() allowAction: boolean;
  @Input() selected: boolean;
  @Output() removeUser = new EventEmitter();
  @Output() resendUser = new EventEmitter();

  @ViewChild('cardZone') cardZone: ElementRef;
  constructor(private progressService: ProgressService) {}

  ngOnInit() {
    // this.invitedAt = `Last invitation was sent on ${this.user.invitedAt}`;
  }

  ngAfterViewInit() {}

  remove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.allowAction) {
      this.removeUser.emit(this.cardZone);
    }
  }
  resend(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (this.allowAction) {
      this.resendUser.emit(this.cardZone);
    }
  }
}
