import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DocumentService, DocumentAction } from 'app/shared/document.service';
import { UIService } from 'app/shared';
import { environment } from 'environments/environment';
import { finalize } from 'rxjs/operators';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { StaticDataService } from '@dp/services/static-data.service';
import { ShipmentDetail, IDocument } from 'app/shipments2/shipments2.model';
import { Subscription } from 'rxjs';
import { UsersService } from 'app/settings/users/users.service';
import { DocumentPreviewDialogComponent } from 'app/shared/components/document-preview-dialog/document-preview-dialog.component';
import { DocumentUploadDialogComponent } from '../document-upload-dialog/document-upload-dialog.component';
import { FileUtils } from '@dp/utilities/file-utils';
import { AuthService } from 'app/auth/auth.service';
import { User } from 'app/auth/user.model';

export enum EntityType {
  CONTAINER = 'CONTAINER',
  OCEAN_SHIPMENT = 'OCEAN_SHIPMENT',
  TRACKING_SHIPMENT = 'TRACKING_SHIPMENT',
  SHIPMENT_GROUPS = 'SHIPMENT_GROUPS',
}
@Component({
  selector: 'dp-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss'],
})
export class DocumentsUploadComponent implements OnInit, OnDestroy {
  @Input() shipmentDetail: ShipmentDetail;
  @Input() entityType = EntityType.OCEAN_SHIPMENT; //default to EntityType OCEAN_SHIPMENT
  @Input() entityId;
  @Input() groupId; // for tracking by group, upload to a group of shipments

  document_types = []; // static data
  DocumentAction = DocumentAction;
  uploadBusy: boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef;
  attachedFiles = null;
  failedDocumentNames = [];
  sub: Subscription;
  user: User;

  constructor(
    private documentService: DocumentService,
    private uiService: UIService,
    public dialog: MatDialog,
    private staticDataService: StaticDataService,
    private usersService: UsersService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.document_types = this.staticDataService.staticData.document_types;
    this.user = this.authService.currentUserValue;

    this.sub = this.documentService.actionEvent.subscribe((event) => {
      switch (event.action) {
        case DocumentAction.DELETE:
          this.deleteDocument(event.documentId, event.fileName);
          break;
        case DocumentAction.DOWNLOAD:
          this.downloadDocument(event.documentId, event.fileName, event.documentVersion);
          break;
        case DocumentAction.UPDATE:
          this.updateDocument(event.documentId, event.formData);
          break;
        case DocumentAction.PREVIEW:
          this.previewDocument(event.document, event.documentName, event.documentVersion);
          break;
      }
    });

    if (!this.entityId && this.shipmentDetail.id) {
      this.entityId = this.shipmentDetail.id;
    } else if (this.groupId) {
      console.warn('DocumentUpload - Using groupId', this.groupId);
    } else if (!this.entityId && !this.shipmentDetail && !this.shipmentDetail.id) {
      console.error('Missing EntityId', this.shipmentDetail);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  previewDocument(document: IDocument, documentName?: string, documentVersion?: number) {
    console.log('DocumentUploadComponent.previewDocument;');

    const dialogRef = this.dialog.open(DocumentPreviewDialogComponent, {
      width: '75%',
      height: '90%',
      data: {
        document,
        documentName,
        documentVersion,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      console.log('Upload component closed preview dialog', dialogResult);
    });
  }

  updateDocument(documentId, formData) {
    this.documentService.updateShipmentDocument(documentId, formData).subscribe(
      (result) => {
        this.uiService.showSnackbar('Document saved successfully!', null, {
          duration: environment.snackBarDuration.success,
          panelClass: 'accent',
        });
      },
      (error) => {
        this.uiService.showSnackbar('Failed to  update document!', null, {
          duration: environment.snackBarDuration.warning,
          panelClass: 'warn',
        });
      }
    );
  }

  // for traditional file input
  handleFileInput(files: FileList) {
    if (files.length === 0) {
      console.log('File is empty');
    } else if (files.length > FileUtils.ALLOWED_DOCUMENTS_LIMIT_PER_UPLOAD) {
      this.uiService.showSnackbar(`Uploading exceeds the maximum of ${FileUtils.ALLOWED_DOCUMENTS_LIMIT_PER_UPLOAD} files.`, null, {
        duration: environment.snackBarDuration.warning,
        panelClass: 'warn',
      });
    } else {
      this.uploadBusy = true;
      this.attachedFiles = files;
      const formData: FormData = new FormData();
      formData.append('entityType', this.entityType);

      // only append if available, for groupId, don't append entityId
      if (this.entityId) formData.append('entityId', this.entityId + '');
      for (let i = 0; i < files.length; i++) {
        formData.append(files.item(i).name, files.item(i), files.item(i).name);
      }

      this.documentService
        .uploadShipmentDocument(formData, this.entityId, this.groupId)
        .pipe(
          finalize(() => {
            this.uploadBusy = false;
            // this.fileInput.nativeElement.value = null;
          })
        )
        .subscribe(
          (result: any) => {
            if (result && result.uploadedDocuments?.length > 0) {
              this.uiService.showSnackbar('File uploaded successfully!', null, {
                duration: environment.snackBarDuration.success,
                panelClass: 'accent',
              });

              this.shipmentDetail.documents = [...this.shipmentDetail.documents, ...result.uploadedDocuments];
            } else {
              this.uiService.showSnackbar('Failed to  upload!', null, {
                duration: environment.snackBarDuration.warning,
                panelClass: 'warn',
              });
            }
          },
          (error) => {
            this.uiService.showSnackbar('Failed to  upload!', null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
          }
        );
    }
  }

  get numOfDocuments() {
    return this.shipmentDetail?.documents?.length || 0;
  }

  downloadDocument(documentId, fileName, documentVersion: number) {
    this.documentService.getShipmentDocument(documentId, documentVersion).subscribe(
      (result) => {
        const csvDownloadAnchor: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        const templateURL = URL.createObjectURL(new Blob([result]));
        csvDownloadAnchor.href = templateURL;
        csvDownloadAnchor.download = fileName;
        csvDownloadAnchor.click();
        URL.revokeObjectURL(templateURL);
      },
      (error) => {
        console.error('Download file error', error);
        this.uiService.showSnackbar('There was an issue downloading the file. Please try again', null, {
          duration: 3000,
          panelClass: 'warn',
        });
      }
    );
  }

  deleteDocument(documentId, fileName) {
    // open confirm dialog first
    const message = `Are you sure you want to delete this document - ${fileName}?`;
    const dialogData = new ConfirmDialogModel('Delete document', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '450px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      //true: delete
      if (dialogResult) {
        this.deleteDocumentHelper(documentId);
      }
    });
  }

  private deleteDocumentHelper(documentId) {
    this.documentService.deleteShipmentDocument(documentId).subscribe(
      (res) => {
        this.shipmentDetail.documents = this.shipmentDetail.documents.filter((item) => {
          return item.id !== documentId;
        });

        this.uiService.showSnackbar('Document was deleted successfully!', null, {
          duration: environment.snackBarDuration.success,
          panelClass: 'accent',
        });
      },
      (error) => {
        this.uiService.showSnackbar('There was an issue deleting the file. Please try again', null, {
          duration: 3000,
          panelClass: 'warn',
        });
      }
    );
  }

  // File Drag and Drop
  public files: NgxFileDropEntry[] = [];
  uploadShipmentDocument;

  public dropped(files: NgxFileDropEntry[]) {
    console.warn('-> dropped -> files', files);
    this.files = files;
    this.handleFilesDropped(files);
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  // Support both drag and drop, and file input
  handleFilesDropped(files: NgxFileDropEntry[]) {
    // 1) check ALLOWED_DOCUMENTS_LIMIT_PER_UPLOAD
    if (this.files.length > FileUtils.ALLOWED_DOCUMENTS_LIMIT_PER_UPLOAD) {
      this.uiService.showSnackbar(`Uploading exceeds the maximum of ${FileUtils.ALLOWED_DOCUMENTS_LIMIT_PER_UPLOAD} files.`, null, {
        duration: environment.snackBarDuration.warning,
        panelClass: 'warn',
      });
      return;
    }

    let validFiles = 0;

    // 2) Check Files Extension
    for (let file of this.files) {
      // Is it a file?
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;

        if (FileUtils.isFileExtensionAllowed(fileEntry.name)) {
          // how to deal with Async
          fileEntry.file((file: File) => {
            // file.size // in byte
            // isFileSizeAllowed
            if (FileUtils.isFileSizeAllowed(file.size)) {
              validFiles++;
            } else {
              this.uiService.showSnackbar(`File size is over the limited: 5Mb`, null, {
                duration: environment.snackBarDuration.warning,
                panelClass: 'warn',
              });
              return;
            }
          });
        } else {
          // file extension not allowed
          this.uiService.showSnackbar(`File extension not supported: ${FileUtils.getFileExtension(fileEntry.name)}`, null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
          return;
        }
      } else {
        // It was a directory (empty directories are added, otherwise only files)
      }
    }
    setTimeout(() => {
      if (validFiles == this.files.length) {
        // if datachain enabled, show a dialog preview the documents
        if (this.isOrgDatachainStatusEnabled()) {
          this.openUploadDetailDialog(files);
        } else {
          this.proceedUploading(files);
        }
      }
    }, 700);
  }

  openUploadDetailDialog(files: NgxFileDropEntry[]) {
    const dialogRef = this.dialog.open(DocumentUploadDialogComponent, {
      width: '650px',
      minHeight: '600px',
      height: 'auto',
      data: {
        files,
        formData: createFormData(files, this.entityType, this.entityId),
        documentsDataMap: generateDocumentsDataMap(files),
        entityId: this.entityId,
        groupId: this.groupId,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult: any[]) => {
      // combine with newly uploaded documents
      if (Array.isArray(dialogResult)) {
        this.shipmentDetail.documents = [...dialogResult, ...this.shipmentDetail.documents];
      }
    });

    function generateDocumentsDataMap(files) {
      let documentsDataMap = {};
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          // async
          fileEntry.file((file: File) => {
            documentsDataMap[file.name] = dataMapFactory();
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          console.log(droppedFile.relativePath, fileEntry);
        }
      }
      return documentsDataMap;
    }

    function dataMapFactory() {
      return {
        // documentType: '', // not required
        documentReference: '',
        channelCode: '',
        isPrivate: false, //default share to all channels
      };
    }

    function createFormData(files, entityType, entityId) {
      const formData: FormData = new FormData();
      formData.append('entityType', entityType);

      if (entityId) formData.append('entityId', entityId + '');

      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          // async
          fileEntry.file((file: File) => {
            // Here you can access the real file
            formData.append(file.name, file, file.name);
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          console.log(droppedFile.relativePath, fileEntry);
        }
      }
      return formData;
    }
  }

  proceedUploading(files: NgxFileDropEntry[]) {
    // build formData for sending to DocumentService
    const formData: FormData = new FormData();
    formData.append('entityType', this.entityType);
    if (this.entityId) formData.append('entityId', this.entityId + '');

    console.log('proceedUploading', files);

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        // async
        fileEntry.file((file: File) => {
          // Here you can access the real file
          formData.append(file.name, file, file.name);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }

    this.uploadBusy = true;
    // need this because fileEntry.file is async code
    setTimeout(() => {
      this.uploadToDocumentService(formData);
    }, 2000);
  }

  uploadToDocumentService(formData) {
    this.failedDocumentNames = [];
    this.documentService
      .uploadShipmentDocument(formData, this.entityId, this.groupId)
      .pipe(
        finalize(() => {
          this.uploadBusy = false;
          // this.fileInput.nativeElement.value = null;
          this.files = [];
        })
      )
      .subscribe(
        (result: any) => {
          // Todo, if there are any fail-to-upload files
          if (result && result.failedDocumentNames?.length > 0) {
            console.log('failed: ', result.failedDocumentNames);
            this.failedDocumentNames = result.failedDocumentNames;
          }
          if (result && result.uploadedDocuments?.length > 0) {
            this.uiService.showSnackbar('File uploaded successfully!', null, {
              duration: environment.snackBarDuration.success,
              panelClass: 'accent',
            });

            this.shipmentDetail.documents = [...this.shipmentDetail.documents, ...result.uploadedDocuments];
          } else {
            this.uiService.showSnackbar('Failed to  upload!', null, {
              duration: environment.snackBarDuration.warning,
              panelClass: 'warn',
            });
          }
        },
        (error) => {
          this.uiService.showSnackbar('Failed to  upload!', null, {
            duration: environment.snackBarDuration.warning,
            panelClass: 'warn',
          });
        }
      );
  }

  isOrgDatachainStatusEnabled(): boolean {
    if (this.user && this.user.accountCapabilities) {
      return this.user.accountCapabilities.orgDatachainStatus === 'ENABLED';
    }
    return false;
  }
}
