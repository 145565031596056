import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { PurchaseOrder, PurchaseOrderDetail, POPageType, POFilters, Supplier } from '../po.model';
import { PurchaseOrderService } from '../po.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
import { PurchaseOrdersDataSource } from './po.datasource';
import { tap, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ProgressService, ProgressRef } from '../../../shared/progress-container/progress.service';
import { Router } from '@angular/router';
import { dpAnimations } from '@dp/animations';
import { environment } from 'environments/environment';
import { UIService } from 'app/shared';

@Component({
  selector: 'dp-po',
  templateUrl: './po.component.html',
  styleUrls: ['./po.component.scss'],
  animations: dpAnimations
})
export class PoComponent implements OnInit, AfterViewInit {

  POPageType = POPageType; // do not remove this!
  pageType: POPageType;
  unsubscribeAll: Subject<any>;

  isHandset: boolean;
  isHandsetChangedSubscription: Subscription;
  selectedSupplier: Supplier = { All: 0 }; // TODO: delete?

  dataSource: PurchaseOrdersDataSource;
  displayedColumns: string[];
  selectedRow: PurchaseOrder = null;
  poDetail: PurchaseOrderDetail = null;
  progressRef: ProgressRef;
  filterText = '';
  poFilters: POFilters = null;
  noData = false;
  beforeLoading: boolean;
  poItems: { id: number; poNumber: string }[];
  trancateLimit = environment.lineTrancateLimit.poList;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('poList', { static: true }) poList: ElementRef;
  @ViewChild('input', { static: true }) input: ElementRef;
  @HostBinding('class') class = 'page-layout carded fullwidth innder-scroll';

  // TODO: no need anymore
  @HostBinding('class.hasSelection') hasSelection() {
    return this.selectedRow != null;
  }

  constructor(
    private uiService: UIService,
    private purchaseOrderService: PurchaseOrderService,
    private progressService: ProgressService,
    private router: Router,
    private location: Location
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.beforeLoading = true;
    if (this.location.path().indexOf('/purchase-orders') > -1) {
      this.pageType = POPageType.PO;
    } else {
      this.pageType = POPageType.CustomerPO;
    }

    //console.log('location=', this.pageType);
    this.uiService.isHandsetChanged.subscribe(result => (this.isHandset = result));
    // this.purchaseOrderService.getSuppliers().subscribe(suppliers => {
    //   this.suppliers = suppliers;
    // });

    this.setTableColumns(true);
    this.dataSource = new PurchaseOrdersDataSource(this.purchaseOrderService, this.paginator, this.uiService, this.pageType);
    // this.loadPurchaseOrdersPage();  // this.dataSource.loadPurchaseOrders();
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading && !this.progressRef) {
        this.progressRef = this.progressService.showProgress(this.poList);
        this.beforeLoading = false;
      } else if (!isLoading) {
        this.progressRef = this.progressService.detach(this.progressRef);
        this.noData = this.paginator && this.paginator.length === 0 && !this.beforeLoading;
      }
    });

    this.dataSource.connect(null).subscribe(data => {
      this.poItems = data.map(item => ({ id: item.id, poNumber: item.poNumber }));
    });
  }


  setTableColumns(isFull) {
    this.displayedColumns = this.isHandset
      ? ['flagged', 'alerts', 'poNumber', 'referenceId', 'poDate', 'state', 'notes']
      : [
          'flagged',
          'alerts',
          'poNumber',
          'incoterm',
          'paymentTerm',
          'referenceId',
          'poDate',
          'state',
          'notes',
          'deliveryLocationName',
          'divisionCode',
          'expectedShipDate',
          'expectedDeliveryDate',
          'supplierName'
        ];
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        takeUntil(this.unsubscribeAll),
        debounceTime(environment.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadPurchaseOrders();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: Sort | PageEvent) => {
          if (data.hasOwnProperty('pageIndex')) {
            this.paginator.pageIndex = (data as PageEvent).pageIndex;
            this.paginator.pageSize = (data as PageEvent).pageSize;
          } else {
            this.sort.active = (data as Sort).active;
            this.sort.direction = (data as Sort).direction;
          }
          this.loadPurchaseOrders();
        })
      )
      .subscribe();
  }

  loadPurchaseOrders() {
    //console.log('loading ', this.poFilters);
    this.filterText = this.input.nativeElement.value;
    this.dataSource.loadPurchaseOrders(
      this.filterText,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.poFilters
    );
  }

  onRowClicked(row: PurchaseOrder) {
    this.selectedRow = row as PurchaseOrder;
    const basePath = this.pageType === POPageType.PO ? '/purchase-orders/' : '/customer-orders/';

    this.router.navigateByUrl(basePath + this.selectedRow.id, { state: { poItems: this.poItems } });

  }

  // supplierChanged(event) {
  //   // //console.log(+event.value);
  //   //console.log(this.selectedSupplier);
  //   this.dataSource.loadPurchaseOrders(
  //     this.filterText,
  //     'poNumber',
  //     this.sort.direction,
  //     this.paginator.pageIndex,
  //     this.paginator.pageSize,
  //     +this.selectedSupplier
  //   );
  // }

  toggleFlag(event, po: PurchaseOrder) {
    event.stopPropagation();
    //console.log(po);
    this.purchaseOrderService
      .updatePurchaseOrder(po.id, { flagged: !po.flagged })
      .pipe(
        takeUntil(this.unsubscribeAll),
        tap(result => {
          //console.log(result);
          if (result.success) {
            po.flagged = !po.flagged;
          } else {
            // todo: show a failure message
          }
        })
      )
      .subscribe();
  }

  setfilters(filters: POFilters) {
    this.poFilters = filters;
    this.loadPurchaseOrders();
  }
}
