import { Component, OnInit, Input } from '@angular/core';
import { ShipmentMap } from 'app/shipments2/shipments2.model';
// import shipmentTest from './data/shipment.json';
import mapsData from './data/mapsData.json';

import { EntityType } from 'app/shared/components/documents-upload/documents-upload.component';

// for portToPort
@Component({
  selector: 'dp-tracking-result',
  templateUrl: './_tracking-result.component.html',
  styleUrls: ['./_tracking-result.component.scss'],
})
export class TrackingResultComponent implements OnInit {
  EntityType = EntityType;
  @Input() payload;
  @Input() isBusy: boolean = false;
  @Input() errorText: string = '';
  @Input() tabs: string[];
  @Input() tokenId: string;
  @Input() showDocumentsInSharedLinks: boolean;
  shipmentMap: ShipmentMap;
  transportJourneys: any;
  segmentDataTable: any;
  // shipmentTest = shipmentTest;

  constructor() {
    // console.log('shipment test data: ', shipmentTest);
  }

  ngOnInit(): void {
    let payload = this.payload;

    // Todo delete test data, does not work shipmentTest does not include MapData
    // payload = { ...shipmentTest, mapsData: mapsData };

    if (payload) {
      if (payload.mapsData) {
        this.shipmentMap = payload.mapsData;
      }
      if (payload.transportJourneys) {
        this.transportJourneys = payload.transportJourneys;
      }

      if (Array.isArray(payload.transportJourneys?.portToPort?.segmentData)) {
        // build segmentTable
        this.segmentDataTable = this.buildShippingSegmentTable(payload.transportJourneys.portToPort.segmentData);
      }

      if (payload.error) {
        console.warn(payload.error);
        this.errorText = payload.error;
      }
    }
  }

  buildShippingSegmentTable(segmentData) {
    if (segmentData.length) {
      return {
        header: ['From', 'To', 'Transport Name', 'Trip #', 'Departure', 'Arrival'],
        rows: this.getOceanLineTableRows(segmentData),
      };
    }
    return null;
  }

  getOceanLineTableRows(segmentData: any[]) {
    return segmentData.map((data) => {
      return [
        {
          data: data.origin,
          className: '',
        },
        {
          data: data.destination,
          className: '',
        },
        {
          data: data.transportName,
          className: '',
        },
        {
          data: data.tripNumber,
          className: '',
        },
        {
          data: data.atd || data.etd,
          className: data.atd ? 'Actl.' : 'Estd.',
        },
        {
          data: data.ata || data.eta,
          className: data.ata ? 'Actl.' : 'Estd.',
        },
      ];
    });
  }
}
