import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { KeyValue } from '@angular/common';
import { UIService } from 'app/shared';
import { DpLocation, LocationType } from 'app/network/locations/locations.model';
import { MdePopoverTrigger } from '@material-extended/mde';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ShipmentService } from 'app/explorer/ocean-shipments/shipments.service';
import { COUNTRY_CODE } from 'app/explorer/ocean-shipments/shipments.model';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'dp-address-create',
  templateUrl: './address-create.component.html',
  styleUrls: ['./address-create.component.scss'],
})
export class DpAddressCreateComponent implements OnInit {
  @Input() infoType: string;
  @ViewChild(MdePopoverTrigger, { static: true }) addressCreatePopover: MdePopoverTrigger;

  isBusy = false;
  locationTypes: KeyValue<string, string>[];
  countryCode: string[];
  country: string = null;
  // sample longitude and latitude - seattle
  longitude = -122.349358;
  latitude = 47.620422;
  locationContactForm: FormGroup;

  constructor(private uiService: UIService, private shipmentService: ShipmentService) {
    this.countryCode = Object.keys(COUNTRY_CODE).map((key) => COUNTRY_CODE[key].toString());
    this.createForm();
  }

  ngOnInit() {}

  createForm() {
    this.locationContactForm = new FormGroup({
      id: new FormControl(null),
      locationName: new FormControl('', [Validators.required, Validators.maxLength(150)]), //company
      locationType: new FormControl(LocationType.FC),
      address: new FormGroup({
        streetLine1: new FormControl('', [Validators.required, Validators.maxLength(150)]),
        streetLine2: new FormControl('', [Validators.maxLength(150)]),
        city: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        stateProv: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        postalCode: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        countryCode: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      }),
      contact: new FormGroup({
        firstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        email: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        phone: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]),
        fax: new FormControl(''),
      }),
    });
  }

  openPopover(target: MdePopoverTrigger) {
    target.openPopover();
  }

  closePopover() {
    this.addressCreatePopover.togglePopover();
  }

  get formValue() {
    return this.locationContactForm.value as DpLocation;
  }

  get contact() {
    return this.locationContactForm.get('contact');
  }

  get address() {
    return this.locationContactForm.get('address');
  }

  onSubmit() {
    this.isBusy = true;
    this.shipmentService
      .createAddress(this.formValue)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isBusy = false))
      )
      .subscribe(
        (data) => {
          this.uiService.showSnackbar('Successful!', null, {
            duration: 3000,
          });
          this.closePopover();
          this.locationContactForm.reset();
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
        }
      );
  }
}
