import { Component, OnInit, HostBinding, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {
  OceanShipmentDetail,
  OceanShipmentDetailItem,
  Product,
  EventSummary,
  ShipmentEventsSummary,
  ShipmentsAlert,
  MapPoint,
  MAP_POINT_TYPES,
  OCEANSHIPMENT_ALERT_STATE,
  ShipmentContainer
} from '../shipments.model';
import { ContainerDetail, DATE_STATUS } from '../../containers/containers.model';
import { ShipmentService } from '../shipments.service';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, timer, Subscription } from 'rxjs';
import { tap, takeUntil, finalize } from 'rxjs/operators';
import { DpProgressBarService } from '@dp/components/progress-bar/progress-bar.service';
import { dpAnimations } from '@dp/animations';
import { environment } from 'environments/environment';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faCalendarStar as falCalendarStar,
  faCalculatorAlt as falCalculatorAlt,
  faStopwatch as falStopwatch,
  faWatch as falWatch,
  faShip as falShip,
  faMapMarkedAlt as falMapMarkedAlt,
  faPersonDolly as falPersonDolly
} from '@fortawesome/pro-light-svg-icons';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ProgressRef, ProgressService, UIService } from 'app/shared';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
  selector: 'dp-shipment-detail',
  templateUrl: './shipment-detail.component.html',
  styleUrls: ['./shipment-detail.component.scss'],
  animations: dpAnimations
})
export class ShipmentDetailComponent implements OnInit {
  alerts: ShipmentsAlert[];
  alertsProgressRef: ProgressRef;
  dateStatus = DATE_STATUS;
  oceanShipmentDetail: OceanShipmentDetail = null;
  oceanShipmentDetailItems: OceanShipmentDetailItem[] = [];
  shipmentContainers: ShipmentContainer[] = [];
  shipmentEventsSummary: ShipmentEventsSummary;
  eventSummary: EventSummary[];
  shipmentItems: Product[];
  oceanShipmentDetailItemSelected: OceanShipmentDetailItem;
  unsubscribeAll: Subject<any>;
  trancateLimit = environment.lineTrancateLimit.poDetail;
  isEventLoaded = false;
  noData = false;
  gettingVesselInfo = false;
  progressRef: ProgressRef;

  mapPoints: MapPoint[] = [];
  iconGreen = {
    url: '/assets/images/icons/map/pin-green.png',
    scaledSize: {
      width: 30,
      height: 50
    },
    labelOrigin: { x: 15, y: 15 }
  };
  iconRed = {
    url: '/assets/images/icons/map/pin-red.png',
    scaledSize: {
      width: 30,
      height: 50
    },
    labelOrigin: { x: 15, y: 15 }
  };
  vesselPoint: MapPoint = null;
  vesselIcon = {
    url: '/assets/images/icons/map/cargo-ship.svg',
    scaledSize: {
      width: 35,
      height: 35
    },
    labelOrigin: { x: 30, y: 35 }
  };
  countDown: Subscription;
  popoverTrigger: MdePopoverTrigger;

  @ViewChild('alertsContent', { static: true }) alertsContent: ElementRef;
  @ViewChild('group', { static: true }) group: ElementRef;
  @ViewChild('eventContent') eventContent: ElementRef;
  @HostBinding('class') class = 'page-layout carded fullwidth innder-scroll detailPage';

  displayedColumns = ['EventType', 'Location', 'Carrier', 'EstimateTime', 'ActualTime'];
  containerColumns = ['containerNumber', 'sealNumber', 'containerType', 'containerEvents'];
  shipmentItemColumns = ['productTitle', 'packageType', 'quantity', 'totalWeight', 'totalVolume'];
  segmentColumns = ['vessel', 'voyage', 'portOfLoading', 'portOfDischarge', 'etd', 'atd', 'eta', 'ata'];

  constructor(
    private shipmentService: ShipmentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dpProgressBarService: DpProgressBarService,
    private library: FaIconLibrary,
    private progressService: ProgressService,
    private uiService: UIService
  ) {
    this.unsubscribeAll = new Subject();
    library.addIcons(falCalendarStar, falCalculatorAlt, falStopwatch, falWatch, falShip, falMapMarkedAlt, falPersonDolly);
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        takeUntil(this.unsubscribeAll),
        tap(params => {
          //console.log('params=', JSON.stringify(params, null, 4));
          this.dpProgressBarService.show();
          this.shipmentService.getOceanShipmentLocations(params.id).subscribe(
            result => {
              this.mapPoints = this.getUnique(result, 'name').filter(point => point.latitude !== null);
              //console.log(this.mapPoints);
              this.mapPoints.forEach((point, index) => {
                point.label = String.fromCharCode(65 + index);
              });
            },
            error => {}
          );

          this.shipmentService.getOceanShipmentDetail(params.id).subscribe(result => {
            this.oceanShipmentDetail = result;
            if (
              this.oceanShipmentDetail.atd &&
              this.oceanShipmentDetail.atd.length > 0 &&
              !this.oceanShipmentDetail.ata &&
              this.oceanShipmentDetail.vessel &&
              this.oceanShipmentDetail.vessel.length > 0
            ) {
              this.gettingVesselInfo = true;
              this.shipmentService.getVesselLocation(this.oceanShipmentDetail.vessel).subscribe(
                vesselInfo => {
                  this.gettingVesselInfo = false;
                  if (vesselInfo) {
                    this.vesselPoint = {
                      latitude: vesselInfo.lat,
                      longitude: vesselInfo.lng,
                      label: '*',
                      name: this.oceanShipmentDetail.vessel,
                      type: MAP_POINT_TYPES.VESSEL,
                      isPast: false
                    };
                  }
                },
                error => {
                  this.gettingVesselInfo = false;
                }
              );
            }
            if (this.oceanShipmentDetail != null) {
              if (this.oceanShipmentDetail.totalVolume) {
                this.oceanShipmentDetail.totalVolume = this.oceanShipmentDetail.totalVolume + ' ';
              }
              if (this.oceanShipmentDetail.totalWeight) {
                this.oceanShipmentDetail.totalWeight = this.oceanShipmentDetail.totalWeight + ' ';
              }

              this.shipmentService.getOceanShipmentContainers(this.oceanShipmentDetail.id).subscribe(containers => {
                this.shipmentContainers = containers;
              });

              this.dpProgressBarService.hide();
            }
          });
        })
      )
      .subscribe();
  }

  // TODO:
  private getUnique<T>(arr: T[], prop): T[] {
    const unique = arr
      .map(e => e[prop])
      .map((e, i, final) => final.indexOf(e) === i && i) // store the keys of the unique objects
      .filter(e => arr[e]) // eliminate the dead keys & store unique objects
      .map(e => arr[e]);

    return unique;
  }

  selectionChanged(item) {
    //console.log(JSON.stringify(item.value, null, 4));
    // this.router.navigateByUrl('/containers/' + item.value.id, { state: { containerDetailItems: this.containerDetailItems } });
  }

  goBack() {
    this.location.back();
  }

  toggleFlag() {
    this.shipmentService.updateShipment(this.oceanShipmentDetail.id, { flagged: !this.oceanShipmentDetail.flagged }).subscribe(
      success => {
        this.oceanShipmentDetail.flagged = !this.oceanShipmentDetail.flagged;
      },
      error => {
        this.uiService.showSnackbar('Failed to flip the flag!', null, {
          duration: 3000,
          panelClass: 'warn'
        });
      }
    );
  }

  openPopover(trigger: MdePopoverTrigger) {
    this.popoverTrigger = trigger;
    this.popoverTrigger.openPopover();

    if (!this.alerts) {
      this.alertsProgressRef = this.progressService.showProgress(this.alertsContent);
      this.dpProgressBarService.show();

      this.shipmentService
        .getOceanShipmentAlerts(this.oceanShipmentDetail.id)
        .pipe(
          finalize(() => {
            this.alertsProgressRef = this.progressService.detach(this.alertsProgressRef);
            this.dpProgressBarService.hide();
          })
        )
        .subscribe(
          alerts => {
            this.alerts = alerts;
            this.setCountdown();
          },
          error => {}
        );
    } else {
      this.setCountdown();
    }
  }

  setCountdown() {
    if (this.oceanShipmentDetail.alerts && this.popoverTrigger.popoverOpen) {
      this.countDown = timer(environment.alertRemoveTimer).subscribe(() => {
        this.shipmentService.alertsAreRead(this.oceanShipmentDetail.id).subscribe(result => {
          this.oceanShipmentDetail.alerts = 0;
          this.alerts.map(alert => (alert.state = OCEANSHIPMENT_ALERT_STATE.INACTIVE));
        });
      });
    }
  }

  closePopover(trigger: MdePopoverTrigger) {
    trigger.closePopover();
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  tabChange(event: MatTabChangeEvent) {
    if (event.tab.textLabel === 'Events') {
      if (!this.isEventLoaded) {
        this.progressRef = this.progressService.showProgress(this.eventContent);
        this.isEventLoaded = true;

        this.shipmentService.getOceanShipmentEventsSummary(this.oceanShipmentDetail.id).subscribe(result => {
          this.shipmentEventsSummary = result;
          this.eventSummary = this.shipmentEventsSummary.eventSummary;
          if (this.eventSummary.length > 0) {
            if (this.eventSummary[0].isFuture) {
              this.eventSummary[0].dateStatus = DATE_STATUS.FUTURE;
            } else {
              this.eventSummary[0].dateStatus = DATE_STATUS.CURRENT;
            }
            this.eventSummary.reduce((prev, cur) => {
              if (cur.isFuture) {
                cur.dateStatus = DATE_STATUS.FUTURE;
              } else if (prev.isFuture) {
                cur.dateStatus = DATE_STATUS.CURRENT;
              } else {
                cur.dateStatus = DATE_STATUS.PAST;
              }
              return cur;
            });

            this.progressRef = this.progressService.detach(this.progressRef);
          } else {
            this.noData = true;
          }
        });
      }
    }
  }
}
