<section class="tracking-info p-8">
  <div *ngIf="errorText" class="mt-12">
    <mat-error>{{errorText}}</mat-error>
  </div>

  <div class="mt-12" *ngIf="payload">

    <div *ngIf="showDocumentsInSharedLinks">
      <mat-tab-group style="overflow-y: auto;">

        <mat-tab [label]="tabs[0]">
          <section class="tab-content mt-12">
            <div *ngIf="transportJourneys">
              <div class="truck-summary" *ngIf="transportJourneys.roadTransport; let roadTransport">
                <h3>Tracking Details</h3>
                <div class="row">
                  <dp-address [address]="roadTransport.originAddressComponents" [addressType]="'Origin'"></dp-address>
                  <dp-address [address]="roadTransport.destinationAddressComponents" [addressType]="'Destination'"></dp-address>
                </div>

                <div class="row">
                  <div class="halfWidth">
                    <span class="propertyCaption">{{roadTransport.ata ? 'ATD': 'ETD'}} </span>
                    <span>{{roadTransport.atd ? roadTransport.atd : roadTransport.etd}}</span>
                  </div>
                  <div class="halfWidth">
                    <span class="propertyCaption">{{roadTransport.ata ? 'ATA': 'ETA'}} </span>
                    <span>{{roadTransport.ata ? roadTransport.ata : roadTransport.eta}}</span>
                  </div>
                </div>
              </div>
            </div>

            <ng-container *ngIf="shipmentMap">
              <h3>Tracking Events</h3>
              <dp-shipment-map [shipmentDetail]="shipmentMap" [maxZoom]="12"></dp-shipment-map>
            </ng-container>

            <p class="latest-updated" *ngIf="payload.trackingUpdatedAt">
              <label class="text-muted">Last updated: </label>
              <strong><span class="cool-secondary-100-text">{{ payload.trackingUpdatedAt | dateAgo }}</span></strong>
            </p>

            <div *ngIf="transportJourneys?.roadTransport?.statusSteps?.steps; let steps" class="mt-12 pl-12">
              <dp-tracking-step [steps]="steps"></dp-tracking-step>
            </div>
          </section>
        </mat-tab>


        <mat-tab [label]="tabs[1]">
          <section class="tab-content mt-12">

            <!-- No document cases -->
            <div *ngIf="payload.documents.length == 0" fxLayout="column" fxLayoutAlign="center center">
              <h2 class="text-muted">No documents available!</h2>
              <img src="assets/images/svg/no-result-found.svg" />
            </div>

            <ng-container *ngIf="payload.documents.length > 0">
              <dp-documents-upload-card-public *ngFor="let document of payload.documents" [document]="document"
                [entityType]="EntityType.TRACKING_SHIPMENT" [tokenId]="tokenId">
              </dp-documents-upload-card-public>
            </ng-container>

          </section>
        </mat-tab>

      </mat-tab-group>

    </div>

    <ng-container *ngIf="!showDocumentsInSharedLinks">
      <div *ngIf="transportJourneys">
        <div class="truck-summary" *ngIf="transportJourneys.roadTransport; let roadTransport">
          <h3>Tracking Details</h3>
          <div class="row">
            <dp-address [address]="roadTransport.originAddressComponents" [addressType]="'Origin'"></dp-address>
            <dp-address [address]="roadTransport.destinationAddressComponents" [addressType]="'Destination'"></dp-address>
          </div>

          <div class="row">
            <div class="halfWidth">
              <span class="propertyCaption">{{roadTransport.ata ? 'ATD': 'ETD'}} </span>
              <span>{{roadTransport.atd ? roadTransport.atd : roadTransport.etd}}</span>
            </div>
            <div class="halfWidth">
              <span class="propertyCaption">{{roadTransport.ata ? 'ATA': 'ETA'}} </span>
              <span>{{roadTransport.ata ? roadTransport.ata : roadTransport.eta}}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showTemperature && payload.temperatureInfo as info">
        <dp-temperature [temperatureInfo]="info" style="display:block;"></dp-temperature>
      </div>

      <ng-container *ngIf="shipmentMap">
        <h3>Tracking Events</h3>
        <dp-shipment-map [shipmentDetail]="shipmentMap" [maxZoom]="12"></dp-shipment-map>
      </ng-container>

      <p class="latest-updated" *ngIf="payload.trackingUpdatedAt">
        <label class="text-muted">Last updated: </label>
        <strong><span class="cool-secondary-100-text">{{ payload.trackingUpdatedAt | dateAgo }}</span></strong>
      </p>

      <div *ngIf="transportJourneys?.roadTransport?.statusSteps?.steps; let steps" class="mt-12 pl-12">
        <dp-tracking-step [steps]="steps"></dp-tracking-step>
      </div>
    </ng-container>


  </div>
</section>