<div class="flex-grid">
  <div class="col">
    <img src="assets/images/tracking.png" width="80px">
    <h2 class="feature-title">End to End Tracking</h2>
    <p class="description">Track your multimodal shipments from origin to destination across all carriers</p>
  </div>
  <div class="col">
    <img src="assets/images/visibility.png" width="80px">
    <h2 class="feature-title">Supply Chain Visibility</h2>
    <p class="description">Understand the availability date of your products in every purchase order</p>
  </div>
  <div class="col">
    <img src="assets/images/exception.png" width="80px">
    <h2 class="feature-title">Manage by Exception</h2>
    <p class="description">Subscribe to email notifications for key milestone events and in-transit exceptions</p>
  </div>

  <div class="col">
    <img src="assets/images/insight.png" width="80px">
    <h2 class="feature-title">Statistical Insights</h2>
    <p class="description">Gain insights on your logistics network's performance and optimization opportunities</p>
  </div>
</div>