<div class="intro-wrapper inner-scroll" fxLayout="row" fxLayoutAlign="start">

  <dp-intro fxFlex></dp-intro>

  <div class="intro-right-wrapper" dpPerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}" #pageZone>
    <form class="intro-right" [hidden]="showSuccessType !== SUCCESS_TYPES.NONE" [formGroup]="f" (ngSubmit)="submit()">
      <div class="title">{{getTitle()}}</div>
      <br>
      <p class="dp-body-2">Hi {{user.fullName}},</p>
      <div *ngIf="user.companyStatus === LoginCompanyStatusType.PENDING">
        <p class="dp-body-2">
          Your applied
          <span class="text-bold">{{user.companyName}} </span> at <span>{{user.companyApplicationTime | localizedDate}}, </span>
          are still pending for administrator approval.
        </p>
        <button [dpBusy]="isResendBusy" mat-raised-button class="submit-button primary-800" (click)="submit(user.companyId)">
          Resend Request
        </button>
      </div>
      <p class="dp-body-2" *ngIf="user.companyStatus === LoginCompanyStatusType.DECLINED">
        Your application sent to
        <span class="text-bold">{{user.companyName}} </span>
        was declined by company account administrator.
      </p>
      <br>
      <p *ngIf="user.companyStatus === LoginCompanyStatusType.PENDING || user.companyStatus === LoginCompanyStatusType.DECLINED">Here are the other options you have.</p>


      <!-- org exist -->
      <div *ngIf="organizations && organizations.length" [@animate]="{value:'*',params:{delay:'100ms',x:'25px'}}">
        <mat-form-field appearance="fill" class=" full-width">
          <mat-label>Does your company have an account?</mat-label>
          <mat-select formControlName="companySelect" [disableOptionCentering]="true" (selectionChange)="adjustControls()">
            <mat-option class="compactOption" *ngFor="let option of CompanyChoiceTypes | keyvalue" [value]="option.key">{{option.value}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="CompanyChoiceTypes[f.value.companySelect] === CompanyChoiceTypes.existing">
          <p class="dp-body-2">Find companies by having the same email suffix users. We will send approval request email to the account owner. </p>
          <dp-org-list [organizations]="organizations" (selected)="companySelected($event)"></dp-org-list>

          <mat-form-field appearance="fill" class=" full-width mt-20">
            <mat-label>Your department in the company</mat-label>
            <input matInput type="text" formControlName="department" autocomplete="off" name="organization">
          </mat-form-field>
          <mat-form-field appearance="fill" class=" full-width">
            <mat-label>Your role in the company</mat-label>
            <input matInput type="text" formControlName="role" autocomplete="organization-title" name="organization-title">
          </mat-form-field>

        </div>
      </div>

      <!-- new user -->
      <div *ngIf="!organizations || organizations.length === 0 || CompanyChoiceTypes[f.value.companySelect] === CompanyChoiceTypes.new" [@animate]=" {value:'*',params:{delay:'100ms',x:'-25px'}}">
        <mat-form-field appearance="fill" class=" full-width">
          <mat-label>Your company name</mat-label>
          <input type="text" matInput formControlName="companyName" name="companyName" required>
          <mat-error *ngIf="f.controls['companyName'].invalid">Field must not be empty.</mat-error>
        </mat-form-field>
        <p class="dp-body-2">Please provide two active container numbers to start tracking.</p>
        <mat-form-field appearance="fill" class=" full-width">
          <mat-label>Container # 1</mat-label>
          <input type="text" matInput formControlName="container1" name="container1" required>
          <mat-error *ngIf="f.controls['container1'].invalid">{{getErrorMessage(f.controls['container1'])}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class=" full-width">
          <mat-label>Container # 2</mat-label>
          <input type="text" matInput formControlName="container2" name="container2" required>
          <mat-error *ngIf="f.controls['container2'].invalid">{{getErrorMessage(f.controls['container2'])}}</mat-error>
        </mat-form-field>
        <div class="mat-error" *ngIf="this.f.errors?.containerMatch">Please enter a different container number.</div>
      </div>


      <button type="submit" [dpBusy]="isFormBusy" mat-raised-button [disabled]="f.invalid" class="submit-button primary-800" (click)="submit()">
        Submit
      </button>

    </form>
    <div class="intro-right" *ngIf="showSuccessType === SUCCESS_TYPES.JOIN_ORG" [@animate]="{value:'*',params:{delay:'100ms',x:'25px'}}">
      <div class="title">You are pending for approval!</div>
      <br>
      <p class="dp-body-2">Hi {{user.fullName}},</p>
      <p class="dp-body-2">We have sent your join request to <span class="text-bold">{{company.organizationName}}</span>
        admin users. Once they approve your request,
        you will receive an email confirmation with
        login permission.
      </p>
    </div>
    <div class="intro-right" *ngIf="showSuccessType === SUCCESS_TYPES.CREATE_ORG" [@animate]="{value:'*',params:{delay:'100ms',x:'25px'}}">
      <div class="title">Request sent successfully!</div>
      <br>
      <p class="dp-body-2">Hi {{user.fullName}},</p>
      <p class="dp-body-2">Your account is created. You will be redirected to login page in <countdown #cd [config]="{leftTime: 5, format: 's'}" (event)="countdownFinished($event)"></countdown> seconds.
      </p>
    </div>
  </div>

</div>