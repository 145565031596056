import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, HostBinding } from '@angular/core';
import { Location } from '@angular/common';
import { OceanShipment, ShipmentPageType, OceanShipmentFilters, OceanShipmentDetailItem, OceanShipmentDetail, ShipmentsAlert, OCEANSHIPMENT_ALERT_STATE } from '../shipments.model';
import { ShipmentService } from '../shipments.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, MatSortable } from '@angular/material/sort';
import { fromEvent, merge, Subject, Subscription, timer } from 'rxjs';
import { ShipmentDataSource } from '../shipments.datasource';
import { tap, debounceTime, distinctUntilChanged, takeUntil, finalize } from 'rxjs/operators';
import { ProgressService, ProgressRef } from '../../../shared/progress-container/progress.service';
import { Router } from '@angular/router';
import { dpAnimations } from '@dp/animations';
import { environment } from 'environments/environment';
import { UIService } from 'app/shared';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
  selector: 'dp-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss'],
  animations: dpAnimations
})
export class ShipmentsComponent implements OnInit, AfterViewInit {
  alerts: ShipmentsAlert[] = [];
  downloadBusy = false;
  downloadedShipments: OceanShipmentDetail[];
  ShipmentPageType = ShipmentPageType;
  pageType: ShipmentPageType;
  unsubscribeAll: Subject<any>;

  isHandset: boolean;
  isHandsetChangedSubscription: Subscription;

  dataSource: ShipmentDataSource;
  displayedColumns: string[];
  alertsCache = {};
  selectedRow: OceanShipment = null;
  progressRef: ProgressRef;
  alertsProgressRef: ProgressRef;
  filterText = '';
  shipmentFilters: OceanShipmentFilters = null;
  noData = false;
  isBusy = false;
  beforeLoading: boolean;
  shipmentDetailItems: OceanShipmentDetailItem[];
  trancateLimit = environment.lineTrancateLimit.poList;
  pageSize = environment.tablePageSize;
  productionHosting = environment.productionHosting;
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    headers: [],
    showTitle: true,
    useBom: true,
    removeNewLines: false,
    keys: []
  };
  countDown: Subscription;
  popoverTrigger: MdePopoverTrigger;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('alertsContent', { static: true }) alertsContent: ElementRef;
  @ViewChild('shipmentList', { static: true }) shipmentList: ElementRef;
  @ViewChild('input', { static: true }) input: ElementRef;
  @HostBinding('class') class = 'page-layout carded fullwidth innder-scroll';
  @ViewChild('fileInput', { static: true }) fileInputVariable: ElementRef;

  // TODO: no need anymore
  @HostBinding('class.hasSelection') hasSelection() {
    return this.selectedRow != null;
  }

  constructor(
    private uiService: UIService,
    private shipmentService: ShipmentService,
    private progressService: ProgressService,
    private router: Router,
    private location: Location
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.sort.sort({
      id: 'flagged-alerts-totalAlerts',
      start: 'desc'
    } as MatSortable);

    this.alertsCache = {};
    this.beforeLoading = true;

    this.pageType = ShipmentPageType.Ocean;

    //console.log('location=', this.pageType);
    this.uiService.isHandsetChanged.subscribe(result => (this.isHandset = result));
    // this.purchaseOrderService.getSuppliers().subscribe(suppliers => {
    //   this.suppliers = suppliers;
    // });

    this.setTableColumns(true);
    this.dataSource = new ShipmentDataSource(this.shipmentService, this.paginator, this.uiService, this.pageType);
    // this.loadPurchaseOrdersPage();  // this.dataSource.loadPurchaseOrders();
    this.dataSource.loading$.subscribe(isLoading => {
      if (isLoading && !this.progressRef) {
        this.progressRef = this.progressService.showProgress(this.shipmentList);
        this.beforeLoading = false;
      } else if (!isLoading) {
        this.progressRef = this.progressService.detach(this.progressRef);
        this.noData = this.paginator && this.paginator.length === 0 && !this.beforeLoading;
      }
    });

    // TODO: unsubscribe it
    // this.dataSource.connect(null).subscribe(data => {
    //   this.poItems = data.map(item => ({ id: item.id, poNumber: item.poNumber }));
    // });
  }

  setTableColumns(isFull) {
    this.displayedColumns = this.isHandset
      ? ['flagged-alerts-totalAlerts', 'shipmentNumber', 'totalContainers']
      : [
          'flagged-alerts-totalAlerts',
          'shipmentNumber',
          'totalContainers',
          'portOfDeparture',
          'portOfArrival',
          'mblNumber',
          'carrier',
          'vessel',
          'voyage',
          'etd',
          'atd',
          'eta',
          'ata',
          'trackingState',
          'createdAt'
        ];
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        takeUntil(this.unsubscribeAll),
        debounceTime(environment.debounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadShipments();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: Sort | PageEvent) => {
          if (data.hasOwnProperty('pageIndex')) {
            this.paginator.pageIndex = (data as PageEvent).pageIndex;
            this.paginator.pageSize = (data as PageEvent).pageSize;
          } else {
            this.sort.active = (data as Sort).active;
            this.sort.direction = (data as Sort).direction;
          }
          this.loadShipments();
        })
      )
      .subscribe();
  }

  loadShipments() {
    //console.log('loading ', this.shipmentFilters);
    this.filterText = this.input.nativeElement.value;
    this.dataSource.loadShipments(
      this.filterText,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.shipmentFilters
    );
  }

  downloadTemplate() {
    this.shipmentService.getTemplateFile().subscribe(
      result => {
        const csvDownloadAnchor: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        const templateURL = URL.createObjectURL(new Blob([result], { type: 'text/csv' }));

        csvDownloadAnchor.href = templateURL;
        csvDownloadAnchor.download = 'template';

        document.body.appendChild(csvDownloadAnchor);

        csvDownloadAnchor.click();

        document.body.removeChild(csvDownloadAnchor);
        URL.revokeObjectURL(templateURL);
      },
      error => {
        this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
      }
    );
  }

  downloadShipments() {
    this.filterText = this.input.nativeElement.value;
    this.downloadBusy = true;
    this.shipmentService
      .downloadCSV(this.filterText, this.sort.active, this.sort.direction, this.shipmentFilters)
      .pipe(finalize(() => (this.downloadBusy = false)))
      .subscribe(
        result => {
          this.downloadedShipments = result.map(shipment => {
            delete shipment.segments;
            delete shipment.id;
            shipment = { ...shipment };
            Object.keys(shipment).map(k => {
              shipment[k] = shipment[k] || 'N/A';
            });

            return shipment;
          });

          this.csvOptions.headers = Object.keys(this.downloadedShipments[0]);


        },
        error => {
          this.uiService.showSnackbar(error.message, null, { duration: 3000, panelClass: 'warn' });
        }
      );
  }

  onRowClicked(row: OceanShipment) {
    this.selectedRow = row as OceanShipment;
    this.router.navigateByUrl('/shipments/' + this.selectedRow.id, { state: { OceanShipmentDetailItems: this.shipmentDetailItems } });
  }

  openPopover(target: MdePopoverTrigger, oceanShipment: OceanShipment) {
    this.popoverTrigger = target;
    this.popoverTrigger.openPopover();
    if (!this.alertsCache[oceanShipment.id]) {
      this.alertsProgressRef = this.progressService.showProgress(this.alertsContent);
      this.shipmentService.getOceanShipmentAlerts(oceanShipment.id).subscribe(
        alerts => {
          this.alertsProgressRef = this.progressService.detach(this.alertsProgressRef);
          this.alertsCache[oceanShipment.id] = alerts;
          this.loadAlerts(oceanShipment, this.alertsCache[oceanShipment.id]);
        },
        error => {}
      );
    } else {
      this.loadAlerts(oceanShipment, this.alertsCache[oceanShipment.id]);
    }
  }

  closePopover(target: MdePopoverTrigger) {
    target.closePopover();
    if (this.countDown) {
      this.countDown.unsubscribe();
      this.countDown = null;
    }
  }

  loadAlerts(oceanShipment: OceanShipment, alerts: ShipmentsAlert[]) {
    if (this.popoverTrigger.popoverOpen) { 
      this.setCountdown(oceanShipment);
      this.alerts = alerts;
    }
  }

  setCountdown(oceanShipment: OceanShipment) {
    if (oceanShipment.alerts > 0) {
      this.countDown = timer(environment.alertRemoveTimer).subscribe(() => {
        if (this.popoverTrigger.popoverOpen) { 
          this.shipmentService.alertsAreRead(oceanShipment.id).subscribe(result => {
            oceanShipment.alerts = 0;
            this.alerts.map(alert => (alert.state = OCEANSHIPMENT_ALERT_STATE.INACTIVE));
            this.alertsCache[oceanShipment.id] = this.alerts;
          });
        }
      });
    }
  }

  // supplierChanged(event) {
  //   // //console.log(+event.value);
  //   //console.log(this.selectedSupplier);
  //   this.dataSource.loadPurchaseOrders(
  //     this.filterText,
  //     'poNumber',
  //     this.sort.direction,
  //     this.paginator.pageIndex,
  //     this.paginator.pageSize,
  //     +this.selectedSupplier
  //   );
  // }

  fileChangeEvent(event) {
    this.isBusy = true;
    const formData: FormData = new FormData();
    formData.append('fileName', event.target.files.item(0), event.target.files.item(0).name);
    formData.append('uploadType', 'CONTAINERS_CSV');

    this.shipmentService
      .uploadOceanShipmentFile(formData)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe(
        result => {
          this.loadShipments();
          this.uiService.showSnackbar('Upload Is Successful!', null, {
            duration: 3000
          });
        },
        error => {
          this.uiService.showSnackbar(error.message, null, {
            duration: 3000,
            panelClass: 'warn'
          });
        }
      );

    this.fileInputVariable.nativeElement.value = '';
  }

  toggleFlag(event, oceanShipment: OceanShipment) {
    event.stopPropagation();
    this.shipmentService.updateShipment(oceanShipment.id, { flagged: !oceanShipment.flagged }).subscribe(
      success => {
        oceanShipment.flagged = !oceanShipment.flagged;
      },
      error => {
        this.uiService.showSnackbar('Failed to flip the flag!', null, {
          duration: 3000,
          panelClass: 'warn'
        });
      }
    );
  }

  setfilters(filters: OceanShipmentFilters) {
    this.shipmentFilters = filters;
    this.loadShipments();
  }
}
