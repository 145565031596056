import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PlaygroundComponent } from './playground/playground.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { ExplorerRoutingModule } from './explorer/explorer-routing.module';
import { SettingsRoutingModule } from './settings/settings-routing.module';
import { SuperAdminGuard } from './auth/super-admin.guard';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
  { path: 'playground', component: PlaygroundComponent, data: { title: 'Playground' } },
  { path: 'faq', component: FaqComponent, data: { title: 'FAQ' } },
  {
    path: 'container-shipments',
    loadChildren: () => import('./ocean-shipment-tabs/ocean-shipment-tabs.module').then((m) => m.OceanShipmentTabsModule),
  },
  {
    path: 'road-shipments',
    loadChildren: () => import('./truck-shipment-tabs/truck-shipment-tabs.module').then((m) => m.TruckShipmentTabsModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./network/products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 'relationship',
    loadChildren: () => import('./network/relationship/relationship.module').then((m) => m.RelationshipModule),
  },
  // {
  //   path: 'shipment-calendar',
  //   loadChildren: () => import('./shipment_calendar/shipment-calendar.module').then((m) => m.ShipmentCalendarModule),
  // },
  {
    path: 'insights-reports',
    loadChildren: () => import('./insights-reports/insights-reports.module').then((m) => m.InsightsReportsModule),
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  // },
  {
    path: 'container-pickup',
    loadChildren: () => import('./delivery-board2/delivery-board.module').then((m) => m.DeliveryBoardModule2),
  },
  {
    path: 'purchase-orders',
    loadChildren: () => import('./explorer/po/po.module').then((m) => m.PurchaseOrderModule),
  },
  {
    path: 'customer-orders',
    loadChildren: () => import('./explorer/po/po.module').then((m) => m.PurchaseOrderModule),
  },
  {
    path: 'shipments',
    loadChildren: () => import('./shipments2/shipments2.module').then((m) => m.Shipments2Module),
  },
  {
    path: 'upload-history',
    loadChildren: () => import('./upload-history/upload-history.module').then((m) => m.UploadHistoryModule),
  },
  {
    path: 'ocean-shipments',
    loadChildren: () => import('./ocean-shipments/ocean-shipments.module').then((m) => m.OceanShipmentsModule),
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
    canLoad: [SuperAdminGuard],
  },
  {
    path: '_tracking', // group shipment share
    loadChildren: () => import('./tracking/tracking.module').then((m) => m.TrackingModule),
  },
  {
    path: '_tracking_shipment',
    data: { title: 'Tracking Shipment' },
    loadChildren: () => import('./tracking-shipment/tracking-shipment.module').then((m) => m.TrackingShipmentModule),
  },
  {
    path: 'truck-shipments',
    loadChildren: () => import('./truck-shipments/truck-shipments.module').then((m) => m.TruckShipmentsModule),
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: 'tracking-by-group',
    loadChildren: () => import('./tracking-by-group/tracking-by-group.module').then((m) => m.TrackingByGroupModule),
  },
  {
    path: 'widget',
    data: { title: 'Widget' },
    loadChildren: () => import('./dp-widget/dp-widget.module').then((m) => m.DpWidgetModule),
  },
  {
    path: 'invoices',
    data: { title: 'Invoice' },
    loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
  },
  {
    path: 'dashboard',
    data: { title: 'Dashboard' },
    loadChildren: () => import('./board/board.module').then((m) => m.Dashboard2Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }), BrowserModule], //TODO: performance improvement
  exports: [RouterModule, SettingsRoutingModule, ExplorerRoutingModule, AuthRoutingModule],
  providers: [AuthGuard, SuperAdminGuard],
})
export class AppRoutingModule {}
