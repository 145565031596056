<div id="users-page" class="page-layout two-column simple left-sidebar inner-sidebar inner-scroll">

  <!-- HEADER -->
  <div class="header2 dp-white p-12 pl-24 mat-elevation-z1" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
      Contact Us
    </span>
  </div>


  <!-- content area -->
  <div class="content px-12 fullHeight pt-12" style="margin-bottom: 10px;">
    <div class="center mat-elevation-z2" dpPerfectScrollbar>
      <div class="content fullHeight dp-white p-52" #contentZone fxLayout="row" fxLayoutAlign="center start">

        <form *ngIf="!submitted" class="w-600 center" [formGroup]="f" autocomplete="off" (ngSubmit)="sendFeedback()">
          <mat-form-field appearance="fill" class="w-600">
            <mat-label>Please select a topic</mat-label>
            <mat-select formControlName="questionCode" [disableOptionCentering]="true" required>
              <mat-option *ngFor="let question of questions | keyvalue: asIsOrder " [value]="question.key">{{question.value}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-600">
            <mat-label>Please describe your question</mat-label>
            <textarea matInput formControlName="description" required #description rows="10"
              placeholder="Please describe your problem here, so we can serve you better. "></textarea>
            <mat-hint align="end">{{description.value.length}} / 2000 max chars</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-600">
            <mat-label>Your phone number (Optional)</mat-label>
            <input matInput type="tel" formControlName="phone" name="phone">
            <mat-icon matSuffix>phone</mat-icon>
            <mat-hint>Include area code</mat-hint>
          </mat-form-field>

          <!-- <button mat-button class="primary-800 mt-40 float-right" type="submit" [dpBusy]="isBusy" [disabled]="!f.valid || f.pristine">SUBMIT</button> -->
          <button mat-button class="primary-800 mt-40 float-right" type="submit" [dpBusy]="isBusy" [disabled]=" f.pristine">SUBMIT</button>
        </form>
        <div *ngIf="submitted" fxLayout="column" fxLayoutAlign="start center">
          <mat-icon class="material-icons-outlined green-600-fg s-80" color="success">check_circle</mat-icon>
          <h1>Sent Successfully! </h1>
          <h2>We will get back to you within 2-3 business days.</h2>
          <p>Thanks for contacting us. Our operation time is from 9:00
            am to 5:00 pm PST Monday to Friday. </p>
        </div>
      </div>
    </div>
  </div>

</div>