import {
  Container,
  PageData,
  ContainerFilters,
  ContainerDetail,
  ContainerEvent,
  ContainerCalendarEvents,
  ContainerAlert,
  ALERT_STATE,
} from './containers.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService } from 'app/shared/message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { FILTER_FLAG, TRACKING_STATE } from '@dp/types/dp-model';
import moment from 'moment';
import { IContainerUpload, ContainerUploadDetail } from '../../upload-history/models/containers.model';
import { AddContainerByTypes, TemplateFileExtensionTypes } from 'app/navigation/nav.model';

@Injectable()
export class ContainersService {
  private origins$: Observable<string[]>;
  private shipmentTypes$: Observable<string[]>;
  private destinations$: Observable<string[]>;
  private vessels$: Observable<string[]>;
  private voyages$: Observable<string[]>;
  private carriers$: Observable<string[]>;

  private containersUrl = environment.rootUrl + environment.urls.containers;
  private alertsUrl = environment.rootUrl + environment.urls.alerts;
  private containerEvntsUrl = environment.rootUrl + environment.urls.containerEvnts;
  private containerCalendarUrl = environment.rootUrl + environment.urls.containerCalendar;
  private FilesUploadUrl = environment.rootUrl + environment.urls.files_upload;
  private FilesDownloadUrl = environment.rootUrl + environment.urls.files_download;
  private FilesTemplateUrl = environment.rootUrl + environment.urls.files_template;
  private ContainerTemplateUrl = environment.rootUrl + environment.urls.container_template;
  private containerVesselUrl = environment.rootUrl + environment.urls.container_vessel;
  private containerVoyagesUrl = environment.rootUrl + environment.urls.container_voyages;
  private containerCarriersUrl = environment.rootUrl + environment.urls.container_carriers;

  constructor(private http: HttpClient, private messageService: MessageService) {}

  getContainers(): Observable<Container[]> {
    return this.http.get<Container[]>(this.containersUrl).pipe(
      tap((_) => this.log('fetched containers')),
      catchError(this.handleError<Container[]>('getContainers', []))
    );
  }

  getContainerAlerts(id: number): Observable<ContainerAlert[]> {
    return this.http.get<ContainerAlert[]>(this.containersUrl + '/' + id + '/alerts').pipe(
      tap((items) => {
        // assumption: newer alerts should be on the top
        // items.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
        items.map((alert, index) => {
          alert.updatedAt = moment(alert.updatedAt);
          alert.createdAt = moment(alert.createdAt);
        });
        //console.log('getContainerAlerts', items);
      })
    );
  }

  updateContainer(id: number, data: object): Observable<any> {
    return this.http.put<Container>(this.containersUrl + '/' + id, data);
  }

  alertsAreRead(containerId: number, oceanShipmentId: number): Observable<any> {
    return this.http.put<any>(
      this.alertsUrl,
      {
        containerId,
        oceanShipmentId,
      },
      { responseType: 'text' as 'json' }
    );
  }

  getContainerDetail(id: string): Observable<ContainerDetail> {
    return this.http.get<ContainerDetail>(this.containersUrl + '/' + id).pipe(
      tap((_) => this.log('fetched single containers')),
      catchError(this.handleError<ContainerDetail>('getContainerDetail', null))
    );
  }

  getContainerCalandarEvents(from: string, to: string): Observable<ContainerCalendarEvents[]> {
    return this.http.get<ContainerCalendarEvents[]>(this.containerCalendarUrl + '/' + from + '/' + to).pipe(
      tap((_) => this.log('fetched  container calndar events')),
      catchError(this.handleError<ContainerCalendarEvents[]>('getContainerCalandarEvents', null))
    );
  }

  getContainerEvntsById(id: number): Observable<ContainerEvent[]> {
    return this.http.get<ContainerEvent[]>(this.containerEvntsUrl + '/' + id);
  }

  getOrigins(): Observable<string[]> {
    if (!this.origins$) {
      this.origins$ = this.http
        .get<string[]>(environment.rootUrl + environment.urls.container_origins)
        .pipe(publishReplay(1), refCount(), catchError(this.handleError<string[]>('getOrigins', [])));
    }
    return this.origins$;
  }

  getShipmentTypes(): Observable<string[]> {
    if (!this.shipmentTypes$) {
      this.shipmentTypes$ = this.http
        .get<string[]>(environment.rootUrl + environment.urls.container_shipmenttypes)
        .pipe(publishReplay(1), refCount(), catchError(this.handleError<string[]>('getShipmentTypes', [])));
    }
    return this.shipmentTypes$;
  }

  getDestinations(): Observable<string[]> {
    if (!this.destinations$) {
      this.destinations$ = this.http
        .get<string[]>(environment.rootUrl + environment.urls.container_destinations)
        .pipe(publishReplay(1), refCount(), catchError(this.handleError<string[]>('getDestinations', [])));
    }
    return this.destinations$;
  }

  getVessels(): Observable<string[]> {
    if (!this.vessels$) {
      this.vessels$ = this.http
        .get<string[]>(this.containerVesselUrl)
        .pipe(publishReplay(1), refCount(), catchError(this.handleError<string[]>('getContainerVessels', [])));
    }
    return this.vessels$;
  }

  getVoyages(): Observable<string[]> {
    if (!this.voyages$) {
      this.voyages$ = this.http
        .get<string[]>(this.containerVoyagesUrl)
        .pipe(publishReplay(1), refCount(), catchError(this.handleError<string[]>('getContainerVoyages', [])));
    }
    return this.voyages$;
  }

  getCarriers(): Observable<string[]> {
    if (!this.carriers$) {
      this.carriers$ = this.http
        .get<string[]>(this.containerCarriersUrl)
        .pipe(publishReplay(1), refCount(), catchError(this.handleError<string[]>('getContainerCarriers', [])));
    }
    return this.carriers$;
  }

  shortDate(date: Date | moment.Moment): string {
    if (moment.isMoment(date)) {
      return date.format('YYYY-MM-DD');
    }
    return date.toISOString().substring(0, 10);
  }
  getSupplierId(obj): string {
    return obj[Object.keys(obj)[0]].toString();
  }

  uploadContainerFile(file: FormData): Observable<any> {
    return this.http.post(this.FilesUploadUrl, file);
  }

  downloadContainerFile(fileId: string): Observable<BlobPart> {
    return this.http.get(this.FilesDownloadUrl + '/' + fileId, { responseType: 'blob' });
  }

  downloadContainerErrorsFile(fileId: string): Observable<BlobPart> {
    return this.http.get(this.FilesDownloadUrl + `/${fileId}/errors`, { responseType: 'blob' });
  }

  downloadTemplateFile(fileExtension: TemplateFileExtensionTypes = TemplateFileExtensionTypes.csv): Observable<BlobPart> {
    return this.http.get(this.FilesTemplateUrl + `?extension=${fileExtension}`, { responseType: 'blob' });
  }

  //todo: rename downloadTemplateFile2 to  downloadTemplateFile
  downloadTemplateFile2(
    type: AddContainerByTypes,
    fileExtension: TemplateFileExtensionTypes = TemplateFileExtensionTypes.csv
  ): Observable<BlobPart> {
    const template =
      type === AddContainerByTypes.ByContainer
        ? 'CONTAINER_NUMBER_TEMPLATE'
        : type === AddContainerByTypes.ByMBL
        ? 'UPLOAD_BY_MBL_NUMBER_TEMPLATE'
        : 'UPLOAD_BY_BOOKING_NUMBER_TEMPLATE';
    const ext = fileExtension === TemplateFileExtensionTypes.csv ? 'csv' : 'xlsx';
    return this.http.get(this.ContainerTemplateUrl + `${template}?extension=${ext}`, { responseType: 'blob' });
  }

  // add pagination
  getUploadedContainers(params?): Observable<any> {
    return this.http
      .get<IContainerUpload[]>(this.FilesUploadUrl, { observe: 'response', params })
      .pipe(
        map((res) => {
          return {
            totalUploads: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
            containersUploadList: res.body,
          };
        }),
        catchError(this.handleError<IContainerUpload[]>('ContainersUpload', null))
      );
  }

  getUploadedContainersById(id: number | string): Observable<ContainerUploadDetail> {
    return this.http
      .get<ContainerUploadDetail>(this.FilesUploadUrl + '/' + id)
      .pipe(catchError(this.handleError<ContainerUploadDetail>('ContainerUploadDetail', null)));
  }

  buildFilterParams(params: HttpParams, containerFilters: ContainerFilters): HttpParams {
    if (containerFilters.flag !== FILTER_FLAG.ALL) {
      params = params.set('flagged', (containerFilters.flag === FILTER_FLAG.FLAGGED).toString());
    }

    if (containerFilters.departure && containerFilters.departure !== 'All') {
      params = params.set('portOfDeparture', containerFilters.departure);
    }

    if (containerFilters.arrival && containerFilters.arrival !== 'All') {
      params = params.set('portOfArrival', containerFilters.arrival);
    }

    if (containerFilters.vessel && containerFilters.vessel !== 'All') {
      params = params.set('vessel', containerFilters.vessel);
    }

    if (containerFilters.voyage && containerFilters.voyage !== 'All') {
      params = params.set('voyage', containerFilters.voyage);
    }

    if (containerFilters.state && containerFilters.state !== TRACKING_STATE.ALL) {
      params = params.set('trackingState', containerFilters.state.toString());
    }

    if (containerFilters.alert && containerFilters.alert !== ALERT_STATE.ALL) {
      params = params.set(
        'alertType',
        Object.keys(ALERT_STATE)
          .find((key) => ALERT_STATE[key] === containerFilters.alert)
          .toString()
      );
    }

    if (containerFilters.etaStart) {
      params = params.set('eta_gte', this.shortDate(containerFilters.etaStart));
    }

    if (containerFilters.etaEnd) {
      params = params.set('eta_lte', this.shortDate(containerFilters.etaEnd));
    }

    if (containerFilters.etdStart) {
      params = params.set('etd_gte', this.shortDate(containerFilters.etdStart));
    }

    if (containerFilters.etdEnd) {
      params = params.set('etd_lte', this.shortDate(containerFilters.etdEnd));
    }

    return params;
  }

  findContainers(filter, sortColumn, sortOrder, page, limit, containerFilters: ContainerFilters): Observable<PageData> {
    page++;
    let params = new HttpParams()
      .set('q', filter)
      .set('_sort', sortColumn)
      .set('_order', sortOrder)
      .set('_page', page.toString())
      .set('_limit', limit.toString());

    params = this.buildFilterParams(params, containerFilters);

    return this.http
      .get<Container[]>(this.containersUrl, {
        observe: 'response',
        params,
      })
      .pipe(
        map((res) => {
          const containers = res.body as Container[];
          containers.map((container) => (container.createdAt = moment(container.createdAt)));
          return {
            total: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
            data: containers,
          };
        })
      );
  }

  downloadCSV(
    filter,
    sortColumn = 'containerNumber',
    sortOrder = 'asc',
    containerFilters = environment.emptyContainerFilters as ContainerFilters
  ) {
    let params = new HttpParams().set('q', filter).set('_sort', sortColumn).set('_order', sortOrder).set('maxPage', 'true');

    params = this.buildFilterParams(params, containerFilters);

    return this.http.get<Container[]>(this.containersUrl, {
      params,
    });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add(`ContainersService: ${message}`);
  }
}
