import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  Inject,
  Renderer2,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { DOCUMENT } from '@angular/common';
import { UIService } from 'app/shared/ui.service';
import { Router, NavigationEnd } from '@angular/router';
import { ApplicationSettings, EditModeType } from './header.model';
import { MdePopoverTrigger } from '@material-extended/mde';
import { User, LoginCompanyStatusType, STATES, WebUpdate, WebUpdateTypes, AuthRole } from 'app/auth/user.model';
import { Name } from 'app/shipments2/shipments2.model';
import { environment } from 'environments/environment';
import { Utility } from '@dp/utilities';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { WelcomeProfileDlgComponent } from './welcome-profile-dlg/welcome-profile-dlg.component';
import { VideoDlgComponent } from './video-dlg/video-dlg.component';
import { SnackbarTemplateComponent } from './snackbar-template/snackbar-template.component';
import { CompanySettingComponent } from 'app/settings/organization/company-setting/company-setting.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UsersService } from 'app/settings/users/users.service';
import { WebUpdateService } from 'app/shared/webUpdate.service';
import { SettingsComponentDialog } from 'app/settings/settings-dialog/settings-dialog.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LoginSsoService } from 'app/auth/login-sso/login-sso.service';

// TODO: use enum for select dropdown
// enum SupportedCountries {
//   us = 'usa',
//   gb = 'British',
//   cn = '中文'
// }
@UntilDestroy()
@Component({
  selector: 'dp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  LoginCompanyStatusType = LoginCompanyStatusType;
  environment = environment;

  @Output() sidenavToggle = new EventEmitter<void>();
  isAuth: boolean;
  Name = Name;
  remindMeRef: MatSnackBarRef<SnackbarTemplateComponent> = null;

  currentUser: User;
  initials: string;
  bannerWebUpdates: WebUpdate[] = null;
  // _EditModeType = EditModeType;
  editMode = EditModeType.NONE;
  isHandsetSubscription: Subscription;
  countrySelected: string;
  applicationSettings: ApplicationSettings = { darkMode: false };
  visible = false;
  isHandset: boolean;
  isProd = environment.productionHosting;

  @ViewChildren(MdePopoverTrigger) trigger: QueryList<MdePopoverTrigger>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private usersService: UsersService,
    private renderer: Renderer2,
    private uiService: UIService,
    private webUpdateService: WebUpdateService,
    private router: Router,
    private dlg: MatDialog,
    private snackBar: MatSnackBar,
    private gaService: GoogleAnalyticsService,
    private loginSsoService: LoginSsoService
  ) {}
  ngAfterViewInit(): void {
    if (environment.enableBeta) {
    console.warn('remove next line');
    // this.trigger.toArray()[1].openPopover();
    }
  }

  ngOnInit() {
    this.uiService.isHandsetChanged.subscribe((result) => (this.isHandset = result));
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe((user) => {
      this.isAuth = this.authService.isAuth();
      this.currentUser = user;
      this.initials = Utility.getInitials(this.currentUser);
      if (this.shouldShowRemindMe()) {
        this.showRemindMe();
      } else if (this.remindMeRef) {
        this.remindMeRef.dismiss();
        this.remindMeRef = null;
      }
      if (!this.isAuth) {
        this.stopWebUpdates();
      }
    });
    this.countrySelected = 'us';
    // TODO: switch to enum laster
    const countriesWithoutUS = ['gb', 'cn', 'us'];
    const newCountry = countriesWithoutUS.find((country) => this.document.location.pathname.startsWith('/' + country + '/'));
    this.countrySelected = newCountry ? newCountry : this.countrySelected;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        const url = event.urlAfterRedirects;
        const routes = environment.noHeaderRoutes as string[];
        if (routes.find((route) => url.indexOf(route) > 0)) {
          this.visible = false;
        } else {
          this.visible = true;
          if (this.shouldShowWelcomeDlg()) {
            console.log('opening dialog', this.currentUser);
            this.dlg.open(WelcomeProfileDlgComponent, { disableClose: true, width: '520px' });
          } else if (this.shouldShowRemindMe()) {
            this.showRemindMe();
          } else if (this.shouldGetWebUpdates()) {
            this.getWebUpdates();
          }
        }
      }
    });

    let localSettings = JSON.parse(localStorage.getItem(environment.storage.applicationSettings));
    if (localSettings !== null) {
      this.applicationSettings = localSettings;
    }

    this.setTheme();

    // const oneHour = 1000 * 60 * 60;
    // // * 100 to bypass timer for now
    // timer(0, oneHour * 100) //first call is immediately
    //   .pipe(
    //     untilDestroyed(this),
    //     switchMap(() => {
    //       return this.usersService.getUserWebUpdates();
    //     })
    //   )
    //   .subscribe((webUpdates: WebUpdate[]) => {
    //     const tutorial = webUpdates.find((webUpdate) => webUpdate.type === WebUpdateTypes.TUTORIAL);
    //     this.showTutorial(tutorial);
    //     this.bannerWebUpdates = webUpdates.filter((webUpdate) => webUpdate.type === WebUpdateTypes.BANNER);

    //     if (this.bannerWebUpdates.length) {
    //       this.webUpdateService.showChainedSnackbars(this.bannerWebUpdates);
    //     }
    //     // from(this.bannerWebUpdates)
    //     //   .pipe(timed(1000))
    //     //   .subscribe((webUpdate) => {
    //     //     this.snackBar.openFromComponent(WebUpdateComponent, {
    //     //       data: webUpdate,
    //     //       verticalPosition: 'bottom',
    //     //       panelClass: 'footer-snack',
    //     //     });
    //     //   });
    //   });

    // console.warn('remove next line');
    // this.openCompanySettingsDlg();
  }

  private showRemindMe() {
    this.remindMeRef = this.snackBar.openFromComponent(SnackbarTemplateComponent, {
      data: 'Your email address is not verified, please confirm your email!',
      verticalPosition: 'bottom',
      panelClass: 'footer-snack',
    });
  }

  private shouldGetWebUpdates() {
    let webUpdatesHandled = Utility.getLocalStorageObjectValue(environment.storage.currentSession, environment.storage.webUpdatesHandled);
    return !webUpdatesHandled && this.authService.isAuth();
  }
  getWebUpdates() {
    this.usersService
      .getUserWebUpdates()
      .pipe(untilDestroyed(this))
      .subscribe(
        (webUpdates: WebUpdate[]) => {
          const tutorial = webUpdates.find((webUpdate) => webUpdate.type === WebUpdateTypes.TUTORIAL);
          this.showTutorial(tutorial);
          this.bannerWebUpdates = webUpdates.filter((webUpdate) => webUpdate.type === WebUpdateTypes.BANNER);

          if (this.bannerWebUpdates.length) {
            this.webUpdateService.showChainedSnackbars(this.bannerWebUpdates);
          }
          Utility.addToLocalStorageObject(environment.storage.currentSession, environment.storage.webUpdatesHandled, true);
        },
        (error) => {
          //no need to show it on the ui
          console.warn('getWebUpdates failed', error);
        }
      );
  }
  stopWebUpdates() {
    this.webUpdateService.stop();
  }

  private showTutorial(webUpdate: WebUpdate) {
    if (!webUpdate) {
      return;
    }
    setTimeout(() => {
      this.playVideo();
      this.usersService
        .setUserWebUpdate({
          webUpdateId: webUpdate.id,
          isFinished: true,
        })
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          //no action here
        });
    }, 1000);
  }

  showSABackBtn() {
    return localStorage.getItem(environment.storage.superAdminUser);
  }
  showSAPageLinkBtn() {
    return this.currentUser?.authRole === AuthRole.SYSTEM_ADMIN;
  }

  goToSAPage() {
    this.router.navigate(['/super-admin']);
  }

  backToSA() {
    let user = JSON.parse(localStorage.getItem(environment.storage.superAdminUser)) as User;
    this.authService.setupNewUserFromStorage(user);
  }

  private shouldShowWelcomeDlg(): boolean {
    // console.error('mike, clean up here');
    // return true;
    return (
      this.authService.isAuth() &&
      this.currentUser.state === STATES.VERIFIED &&
      this.currentUser.companyStatus === LoginCompanyStatusType.VERIFIED &&
      this.currentUser.showUserInformationConfirmationDialog === true
    );
  }

  //todo: unit test needed
  private shouldShowRemindMe() {
    let remindMeLater = Utility.getLocalStorageObjectValue(environment.storage.currentSession, 'remindMeLater');
    return this.authService.isAuth() && this.authService.currentUserValue.pendingUserEmail && !remindMeLater;
  }

  ngOnDestroy() {}

  // onToggleSidenav() {
  //   this.sidenavToggle.emit();
  // }

  switchCountry() {
    const newUrl = this.document.location.origin + '/' + (this.countrySelected === 'us' ? '' : this.countrySelected);
    this.document.location.href = newUrl;
  }

  onLogout() {
    this.authService.logout();
  }

  ngDarkModeChange() {
    this.setTheme();
  }

  setTheme() {
    if (this.applicationSettings.darkMode) {
      this.renderer.addClass(document.body, 'theme-dark');
      this.renderer.removeClass(document.body, 'theme-default');
    } else {
      this.renderer.addClass(document.body, 'theme-default');
      this.renderer.removeClass(document.body, 'theme-dark');
    }

    localStorage.setItem(environment.storage.applicationSettings, JSON.stringify(this.applicationSettings));
  }

  closeProfile() {
    this.trigger.toArray()[2].closePopover();
    this.editMode = EditModeType.NONE;
  }

  closeInviteUsers() {
    this.trigger.toArray()[0].closePopover();
  }

  popoverOpened() {
    this.editMode = EditModeType.NONE;
  }

  editModeChanged(editMode: EditModeType) {
    this.editMode = editMode;
  }

  update(user: User) {
    console.log('user updated from edit component', user);
    this.editMode = EditModeType.NONE;
    this.currentUser = user;
    this.authService.userUpdated(user);
  }

  closeHelp() {
    // this.trigger.toArray()[1].closePopover();
  }

  closeNotification() {
    this.trigger.toArray()[1].closePopover();
  }
  contactUs() {
    this.closeHelp();
    this.router.navigate(['contact-us']);
  }

  playVideo() {
    this.closeHelp();
    let dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'video-dlg-container';

    dialogConfig = { ...dialogConfig, ...this.getDlgDimension() };

    this.dlg.open(VideoDlgComponent, dialogConfig);
  }

  getDlgDimension() {
    let width = window.innerWidth * 0.8;
    let height = window.innerHeight * 0.8;
    if ((width * 9) / 16 > height) {
      width = (height * 16) / 9;
    } else {
      height = (width * 9) / 16;
    }
    return { width: width + 'px', height: height + 'px' };
  }

  openCompanySettingsDlg() {
    this.gaService.event('openCompanySettingsDlg', 'CompanySettings');
    this.dlg.open(CompanySettingComponent, { width: '730px' });
  }

  openSettingsDlg() {
    this.gaService.event('openSettingsDlg', 'UserSettings');
    this.dlg.open(SettingsComponentDialog, { width: '600px' });
  }

  openManageUserDlg() {
    this.router.navigate(['users']);
  }

  openFAQ() {
    this.router.navigate(['faq']);
  }

  navigateTo(page) {
    this.router.navigate([page]);
  }

  logout() {
    if (environment.SSO && !environment.isProd) {
      this.loginSsoService.ssoLogout();
    }
    this.authService.logout();
  }
}
