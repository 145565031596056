import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganizationComponent } from '.';
import { AuthGuard } from 'app/auth/auth.guard';
import { Users2Component } from './users2/users2.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NewSettingsShellComponent } from './new-settings-shell/new-settings-shell.component';

const routes: Routes = [
  {
    path: 'users',
    component: Users2Component,
    data: { title: 'Users' },
  },
  {
    path: 'setting',
    component: OrganizationComponent,
    data: { title: 'Setting' },
  },
  {
    path: 'settings',
    component: NewSettingsShellComponent,
    data: { title: 'Settings' },
  },
  // {
  //   path: 'profile',
  //   component: UserProfileComponent,
  //   canLoad: [AuthGuard],
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: { title: 'Contact-US' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [],
  providers: [],
})
export class SettingsRoutingModule {}
