import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Utility } from '@dp/utilities';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'app/shared';
import { AddContainerResponse } from './container.model';

@Injectable()
export class ContainerService {
  private addContainerUrl = environment.rootUrl + 'new_container/containers';
  constructor(private http: HttpClient, private messageService: MessageService) {}

  addContainers(payload: Object): Observable<AddContainerResponse> {
    payload = Utility.cleanupData(payload);
    return this.http.post<AddContainerResponse>(this.addContainerUrl, payload);
  }
}
