<div #ContainerRef class="temperatureBlock" *ngIf="temperatureInfo as info">
  <div class="mt-12">
    <div class="tempDescription halfWidth">
      Present Temp: <span class="{{info.lastTemperatureState}}">{{Utility.getTemperatureText(info.lastTemperature, info.uom)}}</span> <img
        class="temperatureIcon" src="{{Utility.getTemperatureSvg(info.lastTemperatureState)}}">
    </div>
    <!-- <div class="tempDescription" *ngIf="info.lastAbnormalTemperature">
      Last Abnormal: <span class="{{info.lastAbnormalTemperatureState}}">{{Utility.getTemperatureText(info.lastAbnormalTemperature,
        info.uom)}}</span> <img class="temperatureIcon" src="{{Utility.getTemperatureSvg(info.lastAbnormalTemperatureState)}}">
    </div> -->
    <div class="tempDescription halfWidth">
      Required Temp: <span class="LOW">{{Utility.getTemperatureText(info.minTolerantTemperature,
        info.uom)}}</span>&nbsp;&nbsp;~&nbsp;&nbsp;<span class="HIGH">{{Utility.getTemperatureText(info.maxTolerantTemperature,
        info.uom)}}</span>
    </div>
  </div>
  <div class="showLogBlock" (click)="showingLog = !showingLog">
    {{showingLog? 'Hide':'See'}} temperature log <mat-icon>{{showingLog? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
  </div>
  <div class="outer" *ngIf="showingLog">
    <div class="inner">
      <ngx-charts-line-chart [scheme]="colorScheme" [legend]="legend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="results"
        [xAxisTicks]="xAxisTicks" [yAxisTicks]="yAxisTicks" [yScaleMin]="yScaleMin" [trimXAxisTicks]="trimXAxisTicks"
        [yScaleMax]="yScaleMax" [showRefLines]="showRefLines" [referenceLines]="referenceLines">
        <ng-template #seriesTooltipTemplate let-model="model">
          <div fxLayout="row" fxFlexFill>
            <dl fxFlex="25">
              <dt>Temp</dt>
              <dd class="{{showStatus(model[0].value)}}">{{Utility.getTemperatureText(model[0].value, temperatureInfo.uom)}}</dd>
            </dl>
            <dl fxFlex="75">
              <dt>Date & time</dt>
              <dd class="ml-0">{{model[0].name | localizedDate:'local-date-time'}}</dd>
            </dl>
          </div>
        </ng-template>
        <ng-template #tooltipTemplate let-model="model">
        </ng-template>
      </ngx-charts-line-chart>
    </div>
    <div class="startAt">{{startAt}}</div>
    <div class="endAt">{{endAt}}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" class="mt-12">


  </div>
</div>

<!-- [view]="[ContainerRef.offsetWidth - 50, 200]" -->