import { Component, OnInit } from '@angular/core';
import { Tag } from '@dp/types';

let google: any;
@Component({
  selector: 'dp-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
})
export class PlaygroundComponent implements OnInit {
  tags1 = [];
  tags2: Tag[] = [{ name: 'tag1' }, { name: 'tag2' }];
  tagSuggestions1 = [{ name: 'tag1' }, { name: 'tag2' }, { name: 'tag3' }, { name: 'foo' }, { name: 'bar' }];
  tagSuggestions2 = [{ name: 'tag1' }, { name: 'tag2' }, { name: 'tag3' }, { name: 'foo' }, { name: 'bar' }];

  currencyCodes = [
    { name: 'USA', code: 'USD' },
    { name: 'Canada', code: 'CAD' },
    { name: 'India', code: 'INR' },
    { name: 'Europe', code: 'EUR' },
    { name: 'UAE (dubai)', code: 'AED' },
    { name: 'Japan', code: 'JPY' },
    { name: 'China', code: 'CNY' },
    { name: 'United Kingdom', code: 'GBP' },
  ];
  constructor() {}

  ngOnInit() {
    // console.log('xxx parent', this.tags);
  }
}
