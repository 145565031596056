
import { environment } from 'environments/environment';
import { FILTER_FLAG } from '@dp/types';
import moment from 'moment';

export enum FILTER_STATE {
    ALL = 'All',
    NEW = 'NEW',
    CONFIRMED = 'CONFIRMED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    DELETED = 'DELETED'
}
// export enum FILTER_FLAG {
//   ALL = 'All',
//   FLAGGED = 'FLAGGED',
//   NOTFLAGGED = 'NOT FLAGGED'
// }

export interface PurchaseOrder {
  id: number;
  externalSupplierId: string;
  poNumber: string;
  supplierOrderNumber: null | string;
  referenceId: null | string;
  supplierReferenceId: null | string;
  divisionCode: null | string;
  poDate: string;
  expectedShipDate: string;
  expectedDeliveryDate: string;
  incoterm: string;
  paymentTerm: string;
  totalAmount: string;
  totalAmountCurrency: string;
  notes: null | string;
  state: string;
  flagged: boolean;
  supplierName: string;
  supplierCountryCode: string;
  deliveryLocationName: string;
  deliveryCity: string;
  deliveryStateProv: string;
  deliveryCountryCode: string;
  alerts: number;
  items: Item[];
}

export interface Item {
  productDescription: string;
  packagingUom: string;
  quantity: number;
  unitAmount: string;
  unitAmountCurrency: string;
  productCode: string;
  categoryOne: string;
  categoryTwo: null | string;
  gtin: null;
  hsCode: null;
  countryTariffCode: null;
}


// export interface PageData {
//   total: number;
//   data: PurchaseOrder[];
// }

export enum POPageType {
  PO,
  CustomerPO
}

// Generated by https://quicktype.io

export interface PurchaseOrderDetail {
  id: string;
  organizationAddress: Address;
  supplierAddress: Address;
  shipToAddress: Address;
  items: PurchaseOrderDetailItem[];
  externalSupplierId: string;
  poNumber: string;
  supplierOrderNumber: string;
  referenceId: string;
  supplierReferenceId: string;
  divisionCode: string;
  poDate: string;
  expectedShipDate: string;
  expectedDeliveryDate: string;
  incoterm: string;
  paymentTerm: string;
  totalAmount: string;
  totalAmountCurrency: string;
  notes: string;
  state: string;
  flagged: boolean;
}

export interface PurchaseOrderDetailItem {
  purchaseOrderId: number;
  productId: number;
  productDescription: string;
  packagingUom: string;
  quantity: number;
  unitAmount: string;
  unitAmountCurrency: string;
  productCode: string;
  categoryOne: string;
  categoryTwo: string;
  gtin: string;
}

export interface Address {
  locationName: string;
  locationType: string;
  streetLine1: string;
  streetLine2?: string;
  streetLine3?: string;
  city: string;
  stateProv: string;
  postalCode: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  contactPerson: string;
  phone: string;
  fax?: string;
  email: string;
  id: number;
  organizationName: string;
}

export interface Supplier {
  [key: string]: number;
}
// export interface NameIdPair {
//   name: string;
//   id: string;
// }

export interface POFilters {
  flag: FILTER_FLAG;
  state: FILTER_STATE;
  supplier: Supplier;
  divisionCode: string;
  dateStart?: moment.Moment;
  dateEnd?: moment.Moment;
  shipDateStart?: moment.Moment;
  shipDateEnd?: moment.Moment;
  deliveryDateStart?: moment.Moment;
  deliveryDateEnd?: moment.Moment;
}

export interface Port {
  id: number,
  portName: string,
  countryCode: string,
  latitude: number,
  longitude: number,
  allowBooking: boolean
}
