import { PurchaseOrder, PurchaseOrderDetail, Supplier, POFilters, FILTER_STATE, POPageType } from './po.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MessageService } from '../../shared';
import { Observable, of, observable } from 'rxjs';
import { catchError, map, tap, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FILTER_FLAG, PageData } from '@dp/types';
import moment from 'moment';

@Injectable()
export class PurchaseOrderService {
  private supplier$: Observable<Supplier>;
  private divisionCodes$: Observable<string[]>;

  private purchaseOrdersUrl = environment.rootUrl + environment.urls.purchaseOrders;
  constructor(private http: HttpClient, private messageService: MessageService) {}

  getPurchaseOrderById(id: number): Observable<PurchaseOrderDetail> {
    return this.http.get<PurchaseOrderDetail>(this.purchaseOrdersUrl + '/' + id);
  }

  updatePurchaseOrder(id: number, data: object): Observable<any> {
    return this.http.put<PurchaseOrder>(this.purchaseOrdersUrl + '/' + id, data);
  }

  // getPurchaseOrders(): Observable<PurchaseOrder[]> {
  //   return this.http.get(this.purchaseOrdersUrl).pipe(map(res => res.payload));
  // }

  getPurchaseOrders(): Observable<PurchaseOrder[]> {
    return this.http.get<PurchaseOrder[]>(this.purchaseOrdersUrl).pipe(
      tap(_ => this.log('fetched purchaseOrders')),
      catchError(this.handleError<PurchaseOrder[]>('getPurchaseOrders', []))
    );
  }

  getRelatedPOsByProductId(id: number): Observable<PurchaseOrder[]> {
    return this.http.get<PurchaseOrder[]>(this.purchaseOrdersUrl + '?getItems=false&productId=' + id);
  }

  getSuppliers(): Observable<Supplier> {
    if (!this.supplier$) {
      this.supplier$ = this.http.get<Supplier>(environment.rootUrl + environment.urls.poSuppliers).pipe(
        publishReplay(1),
        refCount(),
        catchError(this.handleError<Supplier>('getSuppliers', {}))
      );
    }

    return this.supplier$;
  }

  getDivisionCodes(): Observable<string[]> {
    if (!this.divisionCodes$) {
      this.divisionCodes$ = this.http.get<string[]>(environment.rootUrl + environment.urls.poDivisionCodes).pipe(
        publishReplay(1),
        refCount(),
        catchError(this.handleError<string[]>('getDivisionCodes', []))
      );
    }

    return this.divisionCodes$;
  }

  shortDate(date: Date | moment.Moment): string {
    if (moment.isMoment(date)) {
      return date.format('YYYY-MM-DD');
    }
    return date.toISOString().substring(0, 10);
  }

  getSupplierId(obj): string {
    return obj[Object.keys(obj)[0]].toString();
  }

  buildFilterParams(params: HttpParams, poFilters: POFilters): HttpParams {
    if (!poFilters.supplier.hasOwnProperty('All')) {
      params = params.set('supplierOrgId', this.getSupplierId(poFilters.supplier));
    }

    if (poFilters.flag !== FILTER_FLAG.ALL) {
      params = params.set('flagged', (poFilters.flag === FILTER_FLAG.FLAGGED).toString());
    }

    if (poFilters.state !== FILTER_STATE.ALL) {
      params = params.set('state', poFilters.state.toString());
    }

    if (poFilters.divisionCode !== 'All') {
      params = params.set('divisionCode', poFilters.divisionCode);
    }

    if (poFilters.dateStart) {
      params = params.set('poDate_gte', this.shortDate(poFilters.dateStart));
    }

    if (poFilters.dateEnd) {
      params = params.set('poDate_lte', this.shortDate(poFilters.dateEnd));
    }

    if (poFilters.deliveryDateStart) {
      params = params.set('expDeliveryDate_gte', this.shortDate(poFilters.deliveryDateStart));
    }

    if (poFilters.deliveryDateEnd) {
      params = params.set('expDeliveryDate_lte', this.shortDate(poFilters.deliveryDateEnd));
    }

    if (poFilters.shipDateStart) {
      params = params.set('expShipDate_gte', this.shortDate(poFilters.shipDateStart));
    }

    if (poFilters.shipDateEnd) {
      params = params.set('expShipDate_lte', this.shortDate(poFilters.shipDateEnd));
    }

    return params;
  }

  findPurchaseOrders(
    filter,
    sortColumn,
    sortOrder,
    page,
    limit,
    poFilters: POFilters,
    pageType: POPageType
  ): Observable<PageData<PurchaseOrder>> {
    page++;
    let params = new HttpParams()
      .set('q', filter)
      .set('_sort', sortColumn)
      .set('_order', sortOrder)
      .set('_page', page.toString())
      .set('_limit', limit.toString());

    if (pageType === POPageType.CustomerPO) {
      params = params.set('customerPOs', 'true');
    }

    params = this.buildFilterParams(params, poFilters);

    const emptyData = {
      total: 0,
      data: []
    };

    return this.http
      .get<PurchaseOrder[]>(this.purchaseOrdersUrl, {
        observe: 'response',
        params
      })
      .pipe(
        map(res => {
          const purchaseOrders = res.body as PurchaseOrder[];
          return {
            total: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
            data: purchaseOrders
          };
        })
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add(`PurchaseOrderService: ${message}`);
  }
}
