import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DpMatchMediaService } from '@dp/services/match-media.service';

@Directive({
  selector: '.inner-scroll',
})
export class DpInnerScrollDirective implements OnInit, OnDestroy {
  // Private
  private _parent: any;
  private _grandParent: any;
  private _unsubscribeAll: Subject<any>;

  constructor(private _elementRef: ElementRef, private _dpMediaMatchService: DpMatchMediaService, private _renderer: Renderer2) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._parent = this._renderer.parentNode(this._elementRef.nativeElement);

    if (!this._parent) {
      return;
    }

    this._grandParent = this._renderer.parentNode(this._parent);

    this._dpMediaMatchService.onMediaChange.pipe(takeUntil(this._unsubscribeAll)).subscribe((alias) => {
      if (alias === 'xs') {
        this._removeClass();
      } else {
        this._addClass();
      }
    });
  }

  ngOnDestroy(): void {
    if (!this._parent) {
      return;
    }

    this._removeClass();

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _addClass(): void {
    if (this._grandParent) {
      this._renderer.addClass(this._grandParent, 'inner-scroll');
    }
  }

  private _removeClass(): void {
    if (this._grandParent) {
      this._renderer.removeClass(this._grandParent, 'inner-scroll');
    }
  }
}
