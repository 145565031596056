<div class="p-12">
  <div class="sub-title cool-primary-100-text">Dear Dubai Trade user from {{user.organizationName}}, welcome to CARGOES Flow</div>
  <h1 mat-dialog-title class="cool-primary-100-text">Please confirm your information</h1>

  <form [formGroup]="f" autocomplete="on" (ngSubmit)="createAccount()" class="mt-16">

    <mat-dialog-content>
      <!-- <mat-form-field appearance="fill" class="full-width">
        <mat-label>Work Email</mat-label>
        <input matInput type="email" formControlName="newEmail" name="email" autocomplete="email" required>
        <mat-spinner matSuffix [diameter]="24" *ngIf="f.pending"></mat-spinner>
        <mat-error *ngIf="f.controls['newEmail'].invalid">
          {{ getErrorMessage(f.controls['newEmail'])}}</mat-error>
      </mat-form-field>
      <div class="text-italic sub-title">We will send you an email to verify your email address.</div> -->
      <div fxLayout="row" fxLayoutAlign="space-between center" class="nameRow">
        <mat-form-field appearance="fill">
          <mat-label>First Name</mat-label>
          <input matInput type="text" formControlName="firstName" name="fname" autocomplete="given-name" required>
          <mat-error *ngIf="f.controls['firstName'].invalid">
            {{ getErrorMessage(f.controls['firstName']) }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" formControlName="lastName" name="lname" required>
          <mat-error *ngIf="f.controls['lastName'].invalid">
            {{ getErrorMessage(f.controls['lastName']) }}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Language</mat-label>
        <mat-select formControlName="locale" type="datetime-local" [disableOptionCentering]="true" required>
          <mat-option *ngFor="let locale of staticData.locales | keyvalue" [value]="locale.key">{{ locale.value }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Timezone</mat-label>
        <mat-select formControlName="timezone" type="datetime-local" #timezoneSelect [disableOptionCentering]="true" required>
          <mat-option>
            <ngx-mat-select-search [formControl]="timezoneFilterCtrl" placeholderLabel="Find timezone..."
              noEntriesFoundLabel="'no matching timezone found'"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone.code">{{ timezone.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Date Format</mat-label>
        <mat-select formControlName="datePattern" type="datetime-local" [disableOptionCentering]="true" required>
          <mat-option *ngFor="let datePattern of datePatterns " [value]="datePattern">{{getStamp(datePattern)}} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Time Format</mat-label>
        <mat-select formControlName="timePattern" type="datetime-local" [disableOptionCentering]="true" required>
          <mat-option *ngFor="let timePattern of timePatterns" [value]="timePattern">{{ getStamp(timePattern) }}</mat-option>
        </mat-select>
      </mat-form-field>

      <p class="text-italic sub-title">By click the button below, you agree our <a (click)="openLink(PolicyTypes.TERMS_OF_SERVICE)">User
          Agreement</a>,
        <a (click)="openLink(PolicyTypes.PRIVACY_POLICY)">Privacy Policy</a> and <a (click)="openLink(PolicyTypes.COOKIE_POLICY)">Cookie
          Policy</a>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="createAccount()" class="primary-800 medium center" type="submit" [dpBusy]="isBusy"
        [disabled]="f.invalid || f.pending">Create Account</button>
    </mat-dialog-actions>

  </form>
</div>