<div class="p-16">

  <div class="dp-h6">{{ user.userEmail }}</div>

  <mat-divider></mat-divider>
  <div style="min-height: 500px; padding-top: 200px">
    <div fxLayoutAlign="center center" fxLayout="column" fxFlexFill>
      <h2>No data available! </h2>
      <fa-icon [icon]="['fal', 'user-alt-slash']" [size]="'4x'" ></fa-icon>
      <div class="info">
        <p>User information is not available until the user accepts the email invitation.</p>
      </div>
    </div>
  </div>
</div>
