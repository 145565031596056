import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { UIService } from 'app/shared';
import { Observable } from 'rxjs';
import { PartnersService } from 'app/shared/services/partners/partners.service';

@Component({
  selector: 'dp-add-partner-dialog',
  templateUrl: './add-partner-dialog.component.html',
  styleUrls: ['./add-partner-dialog.component.scss'],
})
export class AddPartnerDialogComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uiService: UIService,
    public dialogRef: MatDialogRef<AddPartnerDialogComponent>,
    private partnersService: PartnersService
  ) {}

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    console.log('dismiss dialog');
    this.dialogRef.close(true);
  }

  getPartners(): Observable<any> {
    return this.partnersService.getPartners();
  }
}
