import { Component, OnInit, Input } from '@angular/core';
import { ISharedContainer } from 'app/tracking/tracking.model';

// similar to container-info component that is used in tracking share
@Component({
  selector: 'dp-container-details',
  templateUrl: './container-details.component.html',
  styleUrls: ['./container-details.component.scss'],
})
export class ContainerDetailsComponent implements OnInit {
  @Input() container: any;

  constructor() {}

  ngOnInit(): void {}
}
