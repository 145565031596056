import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { JourneyStopAndShipments_Port, ScheduleEventTypes, ScheduleFacadePageTypes, Shipment } from 'app/schedule/schedule.model';

@Component({
  selector: 'dp-schedule-detail-list',
  templateUrl: './schedule-detail-list.component.html',
  styleUrls: ['./schedule-detail-list.component.scss'],
})
export class ScheduleDetailListComponent {
  flexWidth = [20, 25, 20, 25, 10];
  @Input() journeyStopAndShipments: JourneyStopAndShipments_Port;
  @Input() eventType: ScheduleEventTypes;
  @Input() trackingPath: string;
  @Input() pageType: ScheduleFacadePageTypes;
  constructor(private router: Router) {}

  getColumnTitle() {
    return this._isComingIn() ? 'Origin' : 'Destination';
  }

  getColumnData(shipment: Shipment) {
    return this._isComingIn()
      ? shipment['truck_shipment.origin'] || shipment['intermodal_shipment.origin_hub']
      : shipment['truck_shipment.destination'] || shipment['intermodal_shipment.destination_hub'];
  }

  _isComingIn() {
    return [ScheduleEventTypes.arrivals, ScheduleEventTypes.deliveries].includes(this.eventType) ? true : false;
  }

  getPortIcon(): string {
    return [ScheduleEventTypes.arrivals, ScheduleEventTypes.departures].includes(this.eventType) ? 'directions_boat' : 'local_shipping';
  }
  getTrackingPath(): string {
    return this.pageType === ScheduleFacadePageTypes.OCEAN ? '/container-shipments/tracking' : '/road-shipments/tracking';
  }
}
