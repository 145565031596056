<section class="">

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="0">

    <mat-tab label="User Profile">
      <section class="tab-content" id="user-profile">
        <dp-user-profile></dp-user-profile>
      </section>
    </mat-tab>

    <mat-tab label="Notifications">
      <section class="tab-content">
        <dp-notification-setting></dp-notification-setting>
      </section>
    </mat-tab>


  </mat-tab-group>
</section>