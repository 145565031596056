import { NgModule } from '@angular/core';

import { TruncatePipe } from './truncate/truncate.pipe';
import { HighlightSearchPipe } from './search-highlight/search-highlight.pipe';
import { KeysPipe } from './keys/keys.pipe';
import { ReplacePipe } from './replace/replace.pipe';
import { LocalizedDatePipe } from './dates/localized-date.pipe';
import { SecuredUrlPipe } from './securedUrl/securedUrl.pipe';
import { DateAgoPipe } from './date-ago/date-ago.pipe';

@NgModule({
  declarations: [TruncatePipe, HighlightSearchPipe, KeysPipe, ReplacePipe, LocalizedDatePipe, SecuredUrlPipe, DateAgoPipe],
  imports: [],
  exports: [TruncatePipe, HighlightSearchPipe, KeysPipe, ReplacePipe, LocalizedDatePipe, SecuredUrlPipe, DateAgoPipe],
  providers: [DateAgoPipe, LocalizedDatePipe],
})
export class DpPipesModule {}
