import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StaticDataService } from '@dp/services/static-data.service';

export function get_settings(staticDataRemoteService: StaticDataService) {
  return () => staticDataRemoteService.getSettings();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    StaticDataService,
    { provide: APP_INITIALIZER, useFactory: get_settings, deps: [StaticDataService], multi: true },
  ],
})
export class AppLoadModule {}
