import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { User, LoginCompanyStatusType } from '../user.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { dpAnimations } from '@dp/animations';
import { UsersService } from 'app/settings/users/users.service';
import { delay, finalize } from 'rxjs/operators';
import { ProgressService, UIService } from 'app/shared';
import { OrganizationSearch, SetCompanyPayload } from 'app/settings/users/users.model';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { CountdownComponent } from 'ngx-countdown';
import { containerValidator } from '@dp/validators/dp-container-validator';

enum CompanyChoiceTypes {
  existing = 'My company has an account',
  new = 'Create a new company account',
}

enum SUCCESS_TYPES {
  NONE,
  JOIN_ORG,
  CREATE_ORG,
}

interface FormData {
  companyChoice: CompanyChoiceTypes | null;
  companyName: string;
  container1: string;
  container2: string;
  department: string;
  role: string;
}

@Component({
  selector: 'dp-set-company',
  templateUrl: './set-company.component.html',
  styleUrls: ['./set-company.component.scss'],
  animations: dpAnimations,
})
export class SetCompanyComponent implements OnInit {
  company: OrganizationSearch; // selected from the org-list
  CompanyChoiceTypes = CompanyChoiceTypes;
  LoginCompanyStatusType = LoginCompanyStatusType;
  SUCCESS_TYPES = SUCCESS_TYPES;
  showSuccessType = SUCCESS_TYPES.NONE;

  data: FormData;
  emptyFormData: FormData = {
    companyChoice: null,
    companyName: '',
    container1: '',
    container2: '',
    role: '',
    department: '',
  };

  requestSent = false;
  user: User;
  f: FormGroup;
  isFormBusy = false;
  isResendBusy = false;
  isFormLoading = true;
  organizations: OrganizationSearch[] = null;

  @ViewChild('pageZone', { static: true }) pageZone: ElementRef;
  @ViewChild('cd', { static: true }) countdown: CountdownComponent;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private progressService: ProgressService,
    private uiService: UIService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.user = this.authService.currentUserValue;
    this.emptyFormData.role = this.user.role;
    this.emptyFormData.department = this.user.department;
    this.data = this.emptyFormData;

    this.buildFormGroup();
    this.reset();

    let progressRef = this.progressService.showProgress(this.pageZone);

    this.usersService
      .companySearch()
      .pipe(
        finalize(() => {
          this.isFormLoading = false;
          this.progressService.detach(progressRef);
        })
      )
      .subscribe(
        (result) => {
          this.organizations = result.organizations;
          this.adjustControls();
        },
        (error) => {
          this.uiService.showSnackbar(error.message, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
        }
      );
  }

  getTitle(): string {
    let title = '';
    if (this.user.companyStatus === LoginCompanyStatusType.NEW) {
      title = 'Tell us about your company'; //tested: no
    } else if (this.user.companyStatus === LoginCompanyStatusType.PENDING) {
      title = 'You are pending for approval';
    } else if (this.user.companyStatus === LoginCompanyStatusType.DECLINED) {
      title = 'Sorry, you were declined';
    }
    return title;
  }

  reset() {
    this.data = this.emptyFormData;
    // this.showLogo = false;
    this.company = null;
    // this.emailStatus = { state: EMAIL_SIGNUP_STATES.UNKNOWN };

    this.f.controls.companySelect.reset();
    this.f.controls.companySelect.enable();
  }

  buildFormGroup() {
    this.f = this.formBuilder.group(
      {
        companySelect: [this.data.companyChoice, Validators.required],
        companyName: [this.data.companyName, Validators.required],
        container1: [this.data.container1, [Validators.required, containerValidator()]],
        container2: [this.data.container2, [Validators.required, containerValidator()]],
        role: [this.data.role],
        department: [this.data.department],
      },
      {
        validator: this.containerUniqueValidator,
      }
    );
  }

  containerUniqueValidator(form: FormGroup) {
    let result = {};
    if (!form.touched || !form.get('container2')?.value) {
      return result;
    }
    if (form.get('container1').value.trim() === form.get('container2').value.trim()) {
      result = {
        containerMatch: true,
      };
    }

    return result;
  }

  adjustControls() {
    if (!this.organizations || !this.organizations.length) {
      this.f.get('companySelect').disable();
    } else if (CompanyChoiceTypes[this.f.value.companySelect] === CompanyChoiceTypes.existing) {
      this.f.get('companyName').disable();
      this.f.get('container1').disable();
      this.f.get('container2').disable();
    } else {
      this.f.get('companyName').enable();
      this.f.get('container1').enable();
      this.f.get('container2').enable();
    }
  }

  companySelected(company: OrganizationSearch) {
    console.log(company);
    this.company = company;
  }

  submit(organizationId?: number) {
    let payload: SetCompanyPayload;
    if (organizationId) {
      this.isResendBusy = true;
      payload = {
        organizationId: organizationId,
        department: this.user.department,
        role: this.user.role,
      };
    } else if (CompanyChoiceTypes[this.f.value.companySelect] === CompanyChoiceTypes.existing) {
      this.isFormBusy = true;
      payload = {
        organizationId: this.company.organizationId,
        department: this.f.value.department,
        role: this.f.value.role,
      };
    } else {
      this.isFormBusy = true;
      payload = {
        organizationName: this.f.value.companyName,
        containerNumbers: [this.f.value.container1.trim(), this.f.value.container2.trim()],
      };
    }

    this.usersService
      .setOrganization(payload)
      .pipe(
        finalize(() => {
          this.isFormBusy = false;
          this.isResendBusy = false;
        })
      )
      .subscribe(
        (result) => {
          this.showSuccessType = result.status === 'CREATED' ? SUCCESS_TYPES.CREATE_ORG : SUCCESS_TYPES.JOIN_ORG;
          // if (this.showSuccessType === SUCCESS_TYPES.CREATE_ORG) {
          //   this.uiService.showSnackbar('Your account is created. Redirecting to login page. ', null, {
          //     duration: environment.snackBarDuration.success,
          //     panelClass: 'accent',
          //   });
          //   this.router.navigate(['/login']);
          // }
        },
        (error) => {
          let msg: string;
          switch (error.status) {
            case 409:
              msg = 'Your company name already exists. Please use a different name. ';
              break;
            case 400:
              msg = organizationId ? 'Your request is failed. Please try again later.' : 'Container # is not valid.';
              break;
            default:
              msg = error.message;
              break;
          }
          this.uiService.showSnackbar(msg, null, { duration: environment.snackBarDuration.warning, panelClass: 'warn' });
        }
      );
  }

  countdownFinished(event) {
    if (event.action === 'done') {
      this.router.navigate(['/login'], { queryParams: { email: this.user.userEmail } });
    }
  }

  getErrorMessage(ctrl: FormControl) {
    if (ctrl.errors?.required) {
      return 'Container # is required.';
    } else if (ctrl.errors?.['containerError']) {
      return ctrl.errors?.['containerError'];
    }
    return null;
  }

}
