import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dp-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss'],
})
export class DpMatTable implements OnInit {
  @Input() headers: string[];

  // TODO, passed already shaped data for rows
  @Input() rows: any[];

  ngOnInit(): void {
    console.log('headers: ', this.headers);
    console.log('rows: ', this.rows);

    if (this.rows) {
      // flatten array of Object into array of values
      /*
      const object1 = {
        a: '1',
        b: 2,
      };
      ==> [[1, 2]]
      */
      this.rows = this.rows.map((row: Object) => {
        return Object.values(row);
      });
    }
  }
}
