<div id="add-containers-dlg" class="advancedMode">

  <button mat-mini-fab class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>

  <title mat-dialog-title>Add Container Shipments</title>
  <div class="mt-16">

    <mat-tab-group [(selectedIndex)]="addContainerByType" (selectedIndexChange)="addContainerByTypeChange()">
      <mat-tab label="By container #"> &nbsp; </mat-tab>
      <mat-tab label="By booking #"> &nbsp; </mat-tab>
      <mat-tab label="By MBL #"> &nbsp; </mat-tab>
    </mat-tab-group>

    <form [formGroup]="f" autocomplete="off">
      <mat-dialog-content>

        <div formArrayName="containers" class="containers" dp-scroll-to-bottom *ngIf="formContainers">
          <div fxLayout="row" fxLayoutAlign="start stretch">
            <div class="required-field">{{getRequiredFieldLabel()}} <span class="gray">( Required )</span></div>
            <div class="optional-field w-560 ml-12 with-end-line ">Reference <span class="gray">( Optional )</span> </div>
          </div>
          <div class="mb-8" fxLayout="row" fxLayoutAlign="start stretch">
            <div class="required-field"></div>
            <div class="optional-field ml-12 suggestion">Provide additional data about your shipment to speed up the tracking and improve
              your experience. </div>
          </div>
          <div *ngFor="let container of formContainers.controls; let i = index" [formGroupName]="i" [@fadeInOut]>
            <div fxLayout="row" fxLayoutAlign="start stretch">

              <mat-form-field class="required-field" appearance="fill" *ngIf="addContainerByType===AddContainerByTypes.ByContainer"
                required>
                <mat-label>Container #</mat-label>
                <input matInput formControlName="container_number" required>
                <mat-error *ngIf="container.get('container_number').invalid">{{ getErrorMessage(container.get('container_number')) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="required-field" appearance="fill" *ngIf="addContainerByType===AddContainerByTypes.ByBooking">
                <mat-label>Booking #</mat-label>
                <input matInput formControlName="booking_number" required>
                <mat-error *ngIf="container.get('booking_number').invalid">{{ getErrorMessage(container.get('booking_number')) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="required-field" [ngClass]="{'h-84': container.get('mbl_number').invalid}" appearance="fill"
                *ngIf="addContainerByType===AddContainerByTypes.ByMBL">
                <mat-label>MBL #</mat-label>
                <input matInput formControlName="mbl_number" required>
                <mat-error *ngIf="container.get('mbl_number').invalid">{{ getErrorMessage(container.get('mbl_number'), 'mbl') }}</mat-error>
              </mat-form-field>

              <div class="optional-field w-560" fxLayout="row" fxFlexFill>
                <mat-form-field appearance="fill" fxFlex="25">
                  <mat-label>Carrier</mat-label>
                  <mat-select formControlName="ocean_line" #carrierSelect [disableOptionCentering]="true" >
                    <mat-option>
                      <ngx-mat-select-search [formControl]="carrierFilterCtrl" placeholderLabel="Find carrier..."
                        noEntriesFoundLabel="'no matching carrier found'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">{{ carrier
                      }}
                    </mat-option>
                  </mat-select>
                  <button mat-button matSuffix *ngIf="container.get('ocean_line').value" mat-icon-button aria-label="Clear" (click)="deleteCarrier($event, container)" class="deleteCarrier">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field [ngClass]="{'h-84': container.get('mbl_number').invalid}" appearance="fill" fxFlex="25"
                  *ngIf="addContainerByType !== AddContainerByTypes.ByMBL">
                  <mat-label>MBL #</mat-label>
                  <input matInput formControlName="mbl_number">
                  <mat-error *ngIf="container.get('mbl_number').invalid">{{ getErrorMessage(container.get('mbl_number'), 'mbl') }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="25" *ngIf="addContainerByType !== AddContainerByTypes.ByBooking">
                  <mat-label>Booking #</mat-label>
                  <input matInput formControlName="booking_number">
                  <mat-error *ngIf="container.get('booking_number').invalid">{{ getErrorMessage(container.get('booking_number')) }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="25">
                  <mat-label>Shipment reference</mat-label>
                  <input matInput formControlName="shipment_reference">
                  <!-- <mat-icon matSuffix matTooltip="Add an optional reference number for easy searching or identifying the containers" matTooltipPosition="above">error_outline</mat-icon> -->
                </mat-form-field>
                <mat-checkbox [hidden]="true" formControlName="showProducts"></mat-checkbox>
              </div>
              <div class="control-field">
                <button type="button" mat-icon-button color="primary" aria-label="Add" (click)="addContainerFormGroup()" matTooltip="Add"
                  [matTooltipPosition]="'above'" *ngIf="i === formContainers.controls.length - 1 && formContainers.controls.length <= 10">
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
                <button type="button" class="float-left optional-field" [hidden]="!shouldShowExpandBtn()" mat-icon-button color="primary"
                  aria-label="Expand" (click)="expandContainerDetail(i)" matTooltip="Expand/Collapse" [matTooltipPosition]="'above'">
                  <mat-icon>{{getExpandIcon(i)}}</mat-icon>
                </button>
                <button type="button" mat-icon-button color="primary" aria-label="Remove/clear" (click)="removeOrClearContainer(i)"
                  matTooltip="Remove" [matTooltipPosition]="'above'" [disabled]="formContainers.controls.length === 1">
                  <mat-icon>highlight_off</mat-icon>
                </button>
              </div>
            </div>
            <div *ngIf="shouldShowContainerDetailRows(i)">
              <div class="po-header" fxLayout="row nowrap" fxLayoutGap="5px">
                <div fxFlex="9">Product #<span>*</span></div>
                <div fxFlex="9">Quantity<span>*</span></div>
                <div fxFlex="9">PO #</div>
                <div fxFlex="9">Description</div>
                <div fxFlex="9">HS code</div>
                <div fxFlex="9">Quantity UOM</div>
                <div fxFlex="9">Unit price</div>
                <div fxFlex="9">Currency</div>

                <div fxFlex="9">Lot code</div>
                <div fxFlex="9">Production date</div>
                <div fxFlex="9">Expiration date</div>
                <!-- <div fxFlex="10">Grand total</div> -->
                <div fxFlex="90px" class="sub-control-field"></div>
              </div>
              <div formArrayName="products" class="products no-hint">
                <div [@fadeInOut] *ngFor="let product of container.controls.products.controls; let j = index" formGroupName="{{j}}"
                  fxLayout="row nowrap" fxLayoutGap="6px" class="product-row">
                  <mat-form-field floatLabel='never' fxFlex="9">
                    <input matInput formControlName="product_number" placeholder="(required)">
                    <mat-error>{{getProdErrorMessage(product.get('product_number'))}}</mat-error>
                  </mat-form-field>
                  <mat-form-field floatLabel='never' fxFlex="9">
                    <input matInput formControlName="product_quantity" type="number" placeholder="(required)">
                  </mat-form-field>
                  <mat-form-field floatLabel='never' fxFlex="9">
                    <input matInput formControlName="po_number">
                  </mat-form-field>
                  <mat-form-field floatLabel='never' fxFlex="9">
                    <input matInput formControlName="product_description">
                  </mat-form-field>
                  <mat-form-field floatLabel='never' fxFlex="9">
                    <input matInput formControlName="hs_code">
                  </mat-form-field>
                  <mat-form-field floatLabel='never' fxFlex="9">
                    <input matInput formControlName="quantity_uom">
                  </mat-form-field>
                  <mat-form-field floatLabel='never' fxFlex="9">
                    <input matInput type="number" formControlName="unit_price">
                  </mat-form-field>

                  <mat-form-field floatLabel='never' fxFlex="9">
                    <mat-select formControlName="price_currency" #currencySelect [disableOptionCentering]="true">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="currencyFilterCtrl" placeholderLabel="Find currency..."
                          noEntriesFoundLabel="'no matching currency found'"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let currency of filteredCurrencies | async" [value]="currency.id">{{ currency.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>


                  <mat-form-field floatLabel='never' fxFlex="9">
                    <input matInput formControlName="lot_number">
                  </mat-form-field>
                  <mat-form-field fxFlex="9" class="showLabel">
                    <mat-label>{{dateFormat}}</mat-label>
                    <input matInput formControlName="production_date" [matDatepicker]="picker1">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field fxFlex="9" class="showLabel">
                    <mat-label>{{dateFormat}}</mat-label>
                    <input matInput formControlName="expiration_date" [matDatepicker]="picker2">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>

                  <div fxFlex="90px" class="sub-control-field">
                    <button type="button" mat-icon-button color="primary" aria-label="Add" (click)="addProductFormGroup(i)" matTooltip="Add"
                      [matTooltipPosition]="'above'"
                      *ngIf="j === container['controls'].products.controls.length - 1 && container['controls'].products.controls.length <= 10">
                      <mat-icon>add_circle_outline</mat-icon>
                    </button>
                    <button type="button" mat-icon-button color="primary" aria-label="Remove/clear" (click)="removeOrClearProduct(i, j)"
                      matTooltip="Remove" [matTooltipPosition]="'above'">
                      <mat-icon>highlight_off</mat-icon>
                    </button>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </mat-dialog-content>

      <mat-dialog-actions class="pull-right">

        <button mat-button class="medium mr-20" type="button" (click)="closeDlg()">
          Cancel
        </button>

        <!-- <button mat-button class="primary-800 medium" (click)="checkForm()">check</button> -->
        <button mat-button class="primary-800 medium" type="button" [dpBusy]="isBusy" (click)="addContainers()"
          [disabled]="invalidForm() || !getFormData()">Submit</button>

      </mat-dialog-actions>
    </form>

  </div>

</div>