<!-- TOP BACKGROUND -->
<div class="top-bg accent"></div>
<!-- / TOP BACKGROUND -->

<div class="center">
  <div
    class="header accent"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center"
  >
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="mr-0 mr-sm-16" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <mat-icon
        fontSet="material-icons-outlined"
        class="logo-icon s-32 mr-16"
        [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }"
      >
        dns
      </mat-icon>
      <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
        Container Information
      </span>
      <span *ngIf="containerDetail" class="position-relative">
        <mat-icon
          matTooltipPosition="above"
          [color]="containerDetail.flagged ? 'warn' : null"
          matTooltip="Flip the flag"
          class="cursor-pointer"
          [class.disabled-text]="!containerDetail.flagged"
          (click)="toggleFlag()"
          >{{ containerDetail.flagged ? 'flag' : 'outlined_flag' }}</mat-icon
        >
        <mat-icon
          class="disabled-text alertIcon"
          [ngClass]="containerDetail.alerts === 0 ? '' : containerDetail.maxSeverity === 1 ? 'normal' : 'critical'"
          *ngIf="containerDetail.totalAlerts > 0"
          matBadge="{{ containerDetail.alerts }}"
          [matBadgeHidden]="containerDetail.alerts === 0"
          fontSet="material-icons-outlined"
          >announcement</mat-icon
        >
        <span
          style="position: absolute; width: 50px; height: 40px; right: -25px; top: -15px"
          *ngIf="containerDetail.totalAlerts > 0"
          (dpHesitate)="openPopover(popoverTrigger)"
          [duration]="300"
          [mdePopoverTriggerFor]="alertsPopover"
          [mdePopoverOverlapTrigger]="true"
          mdePopoverTriggerOn="none"
          (mouseleave)="closePopover(popoverTrigger)"
          #popoverTrigger="mdePopoverTrigger"
        ></span>
      </span>
    </div>
  </div>

  <div class="content-card container-div">
    <div *ngIf="containerDetail; else busy">
      <div class="content">
        <div class="title">
          Container Details
        </div>
        <mat-divider></mat-divider>
        <div class="pt-12 metaProperties">
          <div *ngIf="containerDetail.containerNumber">
            <span class="text-bold">Container #</span> {{ containerDetail.containerNumber || '' }}
          </div>
          <div *ngIf="getContainerTypeSize()"><span class="text-bold">Container Type</span> {{ getContainerTypeSize() }}</div>
          <div *ngIf="containerDetail.sealNumber"><span class="text-bold">Seal #</span> {{ containerDetail.sealNumber || '' }}</div>
          <div *ngIf="containerDetail.trackingState">
            <span class="text-bold">State</span> {{ containerDetail.trackingState || '' }}
          </div>
        </div>
      </div>

      <div class="content" *ngIf="containerDetail.oceanShipment">
        <div class="title">Shipment</div>
        <mat-divider></mat-divider>
        <div class="pt-12 metaProperties">
          <div *ngIf="containerDetail.oceanShipment.shipmentNumber"><span class="text-bold">Shipment #</span> <a [routerLink]="['/shipments/' + containerDetail.oceanShipment.shipmentId]">{{containerDetail.oceanShipment.shipmentNumber}}</a></div>
          <div *ngIf="containerDetail.oceanShipment.shipmentType"><span class="text-bold">Shipment Type</span> {{containerDetail.oceanShipment.shipmentType}}</div>

          <div *ngIf="containerDetail.oceanShipment.carrier"><span class="text-bold">Carrier</span> {{containerDetail.oceanShipment.carrier}}</div>
          <div *ngIf="containerDetail.oceanShipment.mblNumber"><span class="text-bold">B/L #</span> {{containerDetail.oceanShipment.mblNumber}}</div>
          <div *ngIf="containerDetail.oceanShipment.carrierBookingRef"><span class="text-bold">Carrier Booking Reference</span> {{containerDetail.oceanShipment.carrierBookingRef}}</div>
          <div *ngIf="containerDetail.oceanShipment.originalEta"><span class="text-bold">Original ETA</span> {{containerDetail.oceanShipment.originalEta}}</div>
        </div>

        <div class="pt-12 secondary-text"></div>
        <mat-divider></mat-divider>
        <div>
          <div>
            <div fxFlex="12" class="ct_DataList">
              <h4>Loading Port</h4>
            </div>
            <div fxFlex="12" class="ct_DataList">
              <h4>Discharge Port</h4>
            </div>
            <div fxFlex="12" class="ct_DataList">
              <h4>Vessel</h4>
            </div>
            <div fxFlex="12" class="ct_DataList">
              <h4>Voyage</h4>
            </div>
            <div fxFlex="12" class="ct_DataList">
              <h4>ETD</h4>
            </div>
            <div fxFlex="12" class="ct_DataList">
              <h4>ATD</h4>
            </div>
            <div fxFlex="12" class="ct_DataList">
              <h4>ETA</h4>
            </div>
            <div fxFlex="12" class="ct_DataList">
              <h4>ATA</h4>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div>

            <div fxFlex="12" class="ct_DataList">
              <div>
                <span *ngFor="let segment of containerDetail.oceanShipment.segments">
                  <p>{{ segment.portOfLoading || '&nbsp;' }}</p>
                </span>
              </div>
            </div>

            <div fxFlex="12" class="ct_DataList">
              <div *ngFor="let segment of containerDetail.oceanShipment.segments">
                <p>{{ segment.portOfDischarge || '&nbsp;' }}</p>
              </div>
            </div>

            <div fxFlex="12" class="ct_DataList">
              <div *ngFor="let segment of containerDetail.oceanShipment.segments">
                <p>{{ segment.vessel || '&nbsp;' }}</p>
              </div>
            </div>

            <div fxFlex="12" class="ct_DataList">
              <div *ngFor="let segment of containerDetail.oceanShipment.segments">
                <p>{{ segment.voyage || '&nbsp;' }}</p>
              </div>
            </div>

            <div fxFlex="12" class="ct_DataList">
              <div *ngFor="let segment of containerDetail.oceanShipment.segments">
                <p>{{ segment.etd || '&nbsp;' }}</p>
              </div>
            </div>

            <div fxFlex="12" class="ct_DataList">
              <div *ngFor="let segment of containerDetail.oceanShipment.segments">
                <p>{{ segment.atd || '&nbsp;' }}</p>
              </div>
            </div>

            <div fxFlex="12" class="ct_DataList">
              <div *ngFor="let segment of containerDetail.oceanShipment.segments">
                <p>{{ segment.eta || '&nbsp;' }}</p>
              </div>
            </div>

            <div fxFlex="12" class="ct_DataList">
              <div *ngFor="let segment of containerDetail.oceanShipment.segments">
                <p>{{ segment.ata || '&nbsp;' }}</p>
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>

      <div class="content">
        <div class="title">Tracking Events</div>
        <mat-divider></mat-divider>
        <div *ngIf="!noData">
          <div class="main-container">
            <!-- Estimated Time -->
            <div fxFlex="12.5" class="ct_DataList">
              <div class="tracking-icon-set">
                <fa-icon class="detail-icon-set detail-icon" [icon]="['fal', 'calendar-alt']"> </fa-icon>
                <h4 class="detail-icon-set detail-caption">Estimated Time</h4>
              </div>
            </div>
            <!-- Actual Time -->
            <div fxFlex="12.5" class="ct_DataList">
              <div class="tracking-icon-set">
                <fa-icon class="detail-icon-set detail-icon" [icon]="['fal', 'calendar-check']"> </fa-icon>
                <h4 class="detail-icon-set detail-caption">Actual Time</h4>
              </div>
            </div>
            <!-- Events -->
            <div fxFlex="25" class="ct_DataList">
              <div class="tracking-icon-set">
                <fa-icon class="detail-icon-set detail-icon" [icon]="['fal', 'ballot-check']"> </fa-icon>
                <h4 class="detail-icon-set detail-caption">Events</h4>
              </div>
            </div>
            <!-- Location -->
            <div fxFlex="25" class="ct_DataList">
              <div class="tracking-icon-set">
                <fa-icon class="detail-icon-set detail-icon" [icon]="['fal', 'map-marked-alt']"> </fa-icon>
                <h4 class="detail-icon-set detail-caption">Location</h4>
              </div>
            </div>
            <!-- Carrier -->
            <div fxFlex="25" class="ct_DataList">
              <div class="tracking-icon-set">
                <fa-icon class="detail-icon-set detail-icon" [icon]="['fal', 'person-dolly']"> </fa-icon>
                <h4 class="detail-icon-set detail-caption">Carrier</h4>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="timeline-div">
            <section id="timeline" class="timeline-outer">
              <div class="container" id="content">
                <div class="row">
                  <div class="col s12 m12 l12">
                    <ul class="timeline">
                      <li
                        *ngFor="let events of containerDetailItems"
                        [ngClass]="{
                          'past-line': events.dateStatus == dateStatus.PAST,
                          'current-line': events.dateStatus == dateStatus.CURRENT,
                          'future-line': events.dateStatus == dateStatus.FUTURE
                        }"
                      >
                        <!-- attr.data-date="{{events.actualTime==null?events.estimateTime:events.actualTime}}" -->
                        <div
                          [ngClass]="{ event: events.dateStatus == dateStatus.CURRENT, 'event-past': events.dateStatus == dateStatus.PAST }"
                          class="text-space"
                        >
                          <div fxFlex="12.5" class="timeline-item">
                            <span>
                              <p class="tracking-para">{{ events.estimateTime || '' }}</p>
                            </span>
                          </div>
                          <div fxFlex="12.5" class="timeline-item">
                            <span>
                              <p class="tracking-para">{{ events.actualTime || '' }}</p>
                            </span>
                          </div>
                          <div fxFlex="25" class="timeline-item">
                            <span>
                              <p class="tracking-para">
                                <span class="highlightCircle"> </span>
                                {{ events.eventType || '' }}
                                <span class="timeline_Line"></span>
                              </p>
                            </span>
                          </div>
                          <div fxFlex="25" class="timeline-item">
                            <span>
                              <p class="tracking-para">
                                {{ events.location || '' }}
                              </p>
                            </span>
                          </div>
                          <div fxFlex="25" class="timeline-item">
                            <span>
                              <p class="tracking-para">{{ events.carrier || '' }}</p>
                            </span>
                          </div>
                        </div>
                        <mat-divider></mat-divider>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <br />
          </div>
        </div>

        <div *ngIf="noData">
          <img class="center" src="assets/no-record.png" alt="" />
        </div>
      </div>
    </div>
    <div *ngIf="!containerDetail" fxLayout="row" fxLayoutAlign="center center" style="flex: 1; min-height: 200px;">
      <mat-spinner> </mat-spinner>
    </div>
  </div>
</div>
<ng-template #busy>
  <div fxLayout="row" fxLayoutAlign="center center" style="flex: 1; min-height: 500px;">
    <mat-spinner> </mat-spinner>
  </div>
</ng-template>

<mde-popover #alertsPopover="mdePopover" [mdePopoverOverlapTrigger]="false" mdePopoverOffsetY="0" mdePopoverOffsetX="50">
  <mat-card style="width: 500px" class="mat-elevation-z12">
    <mat-card-header>
      <mat-card-title>Alerts</mat-card-title>
      <mat-card-subtitle>All alerts for this container</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content #alertsContent>
      <div
        class="containerDetailAlert"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngFor="let alert of alerts"
        [class.inactive]="alert.state === 'INACTIVE'"
        [class.alert-color]="alert.severity > 1"
      >
        <div>{{ alert.message }}</div>
        <div>{{ alert.createdAt.format('l') }}</div>
      </div>
    </mat-card-content>
  </mat-card>
</mde-popover>
