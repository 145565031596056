import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { OceanShipment, PageData, OceanShipmentFilters, ShipmentPageType, OceanShipmentDetail } from './shipments.model';
import { ShipmentService } from './shipments.service';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from '../../../environments/environment.mock';
import { HttpErrorResponse } from '@angular/common/http';
import { UIService } from '../../shared';

export class ShipmentDataSource implements DataSource<OceanShipmentDetail> {
  private oceanShipmentSubject = new BehaviorSubject<OceanShipmentDetail[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private shipmentService: ShipmentService,
    private paginator: MatPaginator,
    private uiService: UIService,
    private pageType: ShipmentPageType
  ) {
    //console.log('PAGE TYPE IS' + this.pageType);
  }

  loadShipments(
    filter = '',
    sortColumn = 'shipmentNumber',
    sortDirection = 'asc',
    pageIndex = 0,
    pageSize = environment.tablePageSize,
    oceanShipmentFilters = environment.emptyshipmentFilters as OceanShipmentFilters
  ) {
    this.loadingSubject.next(true);

    this.shipmentService
      .findShipments(filter, sortColumn, sortDirection, pageIndex, pageSize, oceanShipmentFilters, this.pageType)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          result.data.forEach(oceanshipment => {
            for (const key in oceanshipment) {
              if (oceanshipment[key] === null) {
                oceanshipment[key] = '';
              }
            }
          });

          this.oceanShipmentSubject.next((result as PageData).data);
          if (this.paginator) {
            this.paginator.length = (result as PageData).total;
          }
        },
        (error: HttpErrorResponse) => {
          this.uiService.showSnackbar(error.message, null, {
            duration: 3000,
            panelClass: 'warn'
          });
        }
      );
  }

  connect(collectionViewer: CollectionViewer): Observable<OceanShipmentDetail[]> {
    return this.oceanShipmentSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.oceanShipmentSubject.complete();
    this.loadingSubject.complete();
  }
}
