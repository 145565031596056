<div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px">
  <div [fxFlex]="address.latitude? 50 : 100">
    <div><span class="text-bold">{{address.organizationName}}</span></div>
    <div>{{address.locationName}}</div>
    <div>{{address.streetLine1}}
      {{address.streetLine2}}</div>
    <div>{{address.city}},
      {{address.stateProv}} {{address.postalCode}}
    </div>
    <div>{{address.countryCode}}</div>
    <br />
    <div><span class="text-bold">Contact:</span> {{address.contactPerson}}</div>
    <div><span class="text-bold">Phone:</span> {{address.phone}}</div>
    <div *ngIf="address.email"><span class="text-bold">Email:</span> {{address.email}}</div>
  </div>
  <div fxFlex="50" *ngIf="address.latitude">
    <agm-map [latitude]="address.latitude" [longitude]="address.longitude">
      <agm-marker [latitude]="address.latitude" [longitude]="address.longitude">
        <agm-info-window>{{address.organizationName + '-' + address.locationName}}</agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
</div>