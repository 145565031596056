import { Component, OnInit, HostBinding, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { PurchaseOrderDetail } from '../po.model';
import { PurchaseOrderService } from '../po.service';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { DpProgressBarService } from '@dp/components/progress-bar/progress-bar.service';
import { dpAnimations } from '@dp/animations';
import { environment } from 'environments/environment';


@Component({
  selector: 'dp-po-detail',
  templateUrl: './po-detail.component.html',
  styleUrls: ['./po-detail.component.scss'],
  animations: dpAnimations
})
export class PoDetailComponent implements OnInit {
  poDetail: PurchaseOrderDetail = null;
  poItems: { id: number; poNumber: string }[] = [];
  poItemSelected: { id: number; poNumber: string };
  unsubscribeAll: Subject<any>;
  trancateLimit = environment.lineTrancateLimit.poDetail;

  @ViewChild('group', { static: true }) group: ElementRef;
  @HostBinding('class') class = 'page-layout carded fullwidth innder-scroll detailPage';

  displayedColumns = ['Description', 'Code', 'Quantity', 'Price'];

  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dpProgressBarService: DpProgressBarService,
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        takeUntil(this.unsubscribeAll),
        tap(params => {
          // //console.log('params=', JSON.stringify(params, null, 4));
          this.dpProgressBarService.show();
          // this.poIdSelected = params.id;
          this.purchaseOrderService.getPurchaseOrderById(params.id).subscribe(result => {
            this.poItems = window.history.state.poItems;
            if (this.poItems) {
              this.poItemSelected = this.poItems.find(item => item.id === +params.id);
            }
            this.poDetail = result;
            this.dpProgressBarService.hide();

            // this.poDetail.shipToAddress.latitude = 31.131701;
            // this.poDetail.shipToAddress.longitude = 121.929400;
            // this.poDetail.supplierAddress.latitude = 2.025231;
            // this.poDetail.supplierAddress.longitude = 45.331556;
            // this.poDetail.organizationAddress.latitude = 51.598369;
            // this.poDetail.organizationAddress.longitude = -0.189546;

          });
        })
      )
      .subscribe();
  }

  selectionChanged(item) {
    //console.log(JSON.stringify(item.value, null, 4));
    this.router.navigateByUrl('/purchase-orders/' + item.value.id, { state: { poItems: this.poItems } });
  }

  goBack() {
    this.location.back();
  }
}
