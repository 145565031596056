import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UtilsService } from '@dp/services/utils.service';
import { Utility } from '@dp/utilities';
import { LineChartComponent } from '@swimlane/ngx-charts';
import { TruckPage } from 'app/truck-shipments/trucks-models';

@Component({
  selector: 'dp-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemperatureComponent implements OnInit {
  Utility = Utility;
  showingLog = false;
  @ViewChild(LineChartComponent) lineChart: LineChartComponent;

  @Input() temperatureInfo: TruckPage.TemperatureInfo;
  results: object[] = null;

  //view: any[] = [550, 300];
  //multi: any[];

  colorScheme = {
    domain: ['#377531', '#F57600', '#3C92EF'],
  };

  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = false;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'time';
  yAxisLabel: string = 'degree';
  timeline: boolean = false;
  yAxisTicks = [];
  xAxisTicks = [];
  yScaleMin = -20;
  yScaleMax = 10;
  showRefLines = true;
  trimXAxisTicks = false;
  referenceLines = [];
  startAt = '';
  endAt = '';

  constructor(private utils: UtilsService) {}

  ngOnInit(): void {
    let series = this.temperatureInfo.temperatureHistory.map((item) => {
      let name = this.utils.isoStringToLocalDateTimeString(item.recordedAt);
      this.yScaleMin = Math.min(this.yScaleMin, item.temperature);
      this.yScaleMax = Math.max(this.yScaleMax, item.temperature);
      return {
        name,
        value: item.temperature,
      };
    });
    this.yScaleMax = Math.max(this.yScaleMax, +this.temperatureInfo.maxTolerantTemperature) + 2;
    this.yScaleMin = Math.max(this.yScaleMin, +this.temperatureInfo.minTolerantTemperature) - 2;

    let seriesTooHot = [
      { name: series[0].name, value: this.temperatureInfo.maxTolerantTemperature },
      { name: series[series.length - 1], value: this.temperatureInfo.maxTolerantTemperature },
    ];
    let seriesTooCold = [
      { name: series[0].name, value: this.temperatureInfo.minTolerantTemperature },
      { name: series[series.length - 1], value: this.temperatureInfo.minTolerantTemperature },
    ];

    //this.xAxisTicks = [series[0].name, series[series.length - 1].name];
    this.startAt = `Start at: ${series[0].name}`;
    this.endAt = `Latest update: ${series[series.length - 1].name}`; 
    this.results = [
      {
        name: 'Temperature Log',
        series,
      },
      {
        name: 'Too hot',
        series: seriesTooHot,
      },
    ];

    if (this.temperatureInfo.minTolerantTemperature) {
      this.results.push({
        name: 'Too cold',
        series: seriesTooCold,
      });
    }

    if (this.temperatureInfo.minTolerantTemperature) {
      this.referenceLines.push({
        name: Utility.getTemperatureText(+this.temperatureInfo.minTolerantTemperature, this.temperatureInfo.uom),
        value: +this.temperatureInfo.minTolerantTemperature,
      });
    }
    if (this.temperatureInfo.maxTolerantTemperature) {
      this.referenceLines.push({
        name: Utility.getTemperatureText(+this.temperatureInfo.maxTolerantTemperature, this.temperatureInfo.uom),
        value: +this.temperatureInfo.maxTolerantTemperature,
      });
    }
    // this.colorScheme.domain[0] =
    //   this.temperatureInfo.lastAbnormalTemperatureState === TruckPage.SHIPMENT_TEMPERATURE_STATES.HIGH
    //     ? '#F57600'
    //     : this.temperatureInfo.lastAbnormalTemperatureState === TruckPage.SHIPMENT_TEMPERATURE_STATES.LOW
    //     ? '#3C92EF'
    //     : '#000000';
  }

  showStatus(temp: number) {
    let status =
      temp >= +this.temperatureInfo.maxTolerantTemperature ? 'HIGH' : temp <= +this.temperatureInfo.minTolerantTemperature ? 'LOW' : 'OK';
    return status;
  }
}
