import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { SuperUploadResult } from 'app/explorer/containers/containers.model';
import { DpDialogComponent } from './dp-dialog/dp-dialog.component';
import { MultiLinesSnackbarComponent } from '@dp/components/';

@Injectable()
export class UIService {
  remindMeClosed = false;
  isHandsetChanged = new BehaviorSubject<boolean>(false);

  constructor(private snackbar: MatSnackBar, private dialog: MatDialog) {}

  showSnackbar(message: string, action: string, config: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbar.open(message, action, { verticalPosition: 'top', ...config });
  }
  showMultiLinesSnackbar(messages: string[], duration: number, panelClass: string) {
    this.snackbar.openFromComponent(MultiLinesSnackbarComponent, {
      verticalPosition: 'top',
      data: messages,
      panelClass: panelClass,
      duration: duration,
    });
  }

  openDialog(message: SuperUploadResult) {
  }

}
