<ng-container *ngIf="headers">
  <table mat-table [dataSource]="rows" class="full-width">
    <ng-container *ngFor="let col of headers; let i = index ">
      <ng-container matColumnDef={{col}}>
        <th mat-header-cell *matHeaderCellDef style="min-width: 60px;"> {{ col }} </th>
        <td mat-cell *matCellDef="let element">
          {{element[i] }}
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="headers" class="compactRow"></tr>
    <tr mat-row *matRowDef="let row; columns: headers;" class="compactRow"></tr>
  </table>
</ng-container>