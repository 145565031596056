import { Component, OnInit, ViewChild, Output, Input } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { ShipmentService } from '../shipments.service';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { ContainerEvent, EVENT_FIELDS } from '../../containers/containers.model';

import { EventEmitter } from '@angular/core';

@Component({
  selector: 'dp-container-events',
  templateUrl: './container-events.component.html',
  styleUrls: ['./container-events.component.scss']
})

export class ContainerEventsComponent implements OnInit {
  @Input() name: any;
  @Input() cntrevents: ContainerEvent[];
  divisionCodes: string[];
  selectedSupplierKey: string;
  origins: string[];
  selectedOrigin: string;
  destinations: string[];
  selectedDestination: string;
  shipmentTypes: string[];
  selectedshipmentType: string;
  noData = false;

  selectedDivisionCode = environment.uiSetting.select_All_DisplayText;
  states: string[];
  flags: string[]; //  = [environment.uiSetting.select_All_DisplayText, 'flagged', 'not flagged'];
  dateStart: Date;
  dateEnd: Date;
  shipDateStart: Date;
  shipDateEnd: Date;
  deliveryDateStart: Date;
  deliveryDateEnd: Date;

  @ViewChild(MdePopoverTrigger, { static: true }) filterPopover: MdePopoverTrigger;
  unsubscribeAll: Subject<any>;

  displayedColumnsn = ['EventType', 'Location', 'Carrier', 'EstimateTime', 'ActualTime'];


  constructor(private shipmentService: ShipmentService) {
    this.unsubscribeAll = new Subject();


  }

  ngOnInit() {
    if (this.cntrevents.length > 0) {

      this.cntrevents.map(el => {
        Object.keys(el).filter(key => {
          if (!(key in EVENT_FIELDS)) {
            delete el[key];
          }
        })
      });

    } else {
      this.noData = true;
    }
  }

  closePopover() {
    this.filterPopover.togglePopover();

  }





  getSupplierKey(supplier) {
    return Object.keys(supplier)[0];
  }

  initSelectedValues() {
  }

}