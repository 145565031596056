
import { FILTER_FLAG } from '@dp/types';
import moment from 'moment';
import { TRACKING_STATE } from '@dp/types/dp-model';
import { ContainerEvent } from '../containers';

export enum DATE_STATUS {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
  PAST = 'PAST'
}

export enum FILTER_STATE {
  ALL = 'All',
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED'
}


export enum ALERT_STATE {
  ALL = 'All',
  OS_ARRIVED = 'SHIPMENT ARRIVED',
  OS_ARRIVED_LATE = 'SHIPMENT ARRIVED LATE',
  OS_ARRIVING = 'SHIPMENT ARRIVING SOON',
  OS_ETA = 'SHIPMENT ETA CHANGE',
  OS_ETD = 'SHIPMENT ETD CHANGE',
  OS_LATE = 'SHIPMENT ARRIVING LATE',
  NULL = 'WITHOUT ALERTS'
}

export enum COUNTRY_CODE {
  CHINA = 'CN',
  US = 'US',
  CANADA = 'CA'
}

export interface OceanShipment {
  id: number;
  flagged: boolean;
  alerts: number;
  maxSeverity: number;
  consignee: string;
  shipper: string;
  portOfLoading: string;
  portOfDischarge: string;
  totalWeight: string;
  weightUom: string;
  totalVolume: string;
  volumeUom: string;
  mblNumber: string;
  carrier: string;
  vessel: string;
  voyage: string;
  ata: Date;
  etd: Date;
  createdAt: Date;
  updatedAt: Date;
}


export interface OceanShipmentDetail {
  id: number;
  shipmentNumber: string;
  shipmentType: string;
  totalContainers: number;
  portOfDeparture: string;
  portOfArrival: string;
  shipper: string;
  consignee: string;
  totalWeight: string;
  weightUom: string;
  totalVolume: string;
  volumeUom: string;
  mblNumber: string;
  carrier: string;
  vessel: string;
  voyage: string;
  etd: string;
  atd: string;
  originalEta?: string;
  eta: string;
  ata: string;
  carrierBookingRef?: string;
  flagged: boolean;
  alerts: number;
  totalAlerts: number;
  maxSeverity?: number;
  trackingState: string;
  segments?: (SegmentsEntity)[];
  createdAt: moment.Moment;
}
export interface SegmentsEntity {
  portOfLoading: string;
  portOfDischarge: string;
  vessel: string;
  voyage: string;
  etd: string;
  atd: string;
  eta: string;
  ata: string;
}

export interface OceanShipmentDetailItem {
  id: number;
  eventType: string;
  carrier: string;
  estimateTime: string;
  actualTime: string;
  location: string;
  containerId: number;
  updatedAt: string;
  createdAt: string;
}


export interface Product {
  productId: number;
  productTitle: string;
  packageType: string;
  quantity: number;
  totalWeight: string;
  weightUom: string;
  totalVolume: string;
  volumeUom: string;
}



//TODO: this PageData is deprecated, use src/@dp/types/dp-model.ts
export interface PageData {
  total: number;
  data: OceanShipmentDetail[];
}

export enum ShipmentPageType {
  Ocean,
  InLand
}

export interface OceanShipmentFilters {
  flag: FILTER_FLAG;
  state: TRACKING_STATE;
  alert: ALERT_STATE;
  departure: string;
  arrival: string;
  vessel: string;
  voyage: string;
  carrier: string;
  etdStart?: moment.Moment;
  etdEnd?: moment.Moment;
  etaStart?: moment.Moment;
  etaEnd?: moment.Moment;
}

export enum OCEANSHIPMENT_ALERT_STATE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface ShipmentsAlert {
  type: string;
  message: string;
  state: OCEANSHIPMENT_ALERT_STATE;
  severity: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
}


export interface ShipmentEventsSummary {
  totalContainerCount: number;
  eventSummary: EventSummary[];
}

export interface EventSummary {
  eventType: string;
  maxActualDate: Date;
  minActualDate: Date;
  maxEstimateDate: Date;
  minEstimateDate: Date;
  containerCount: number;
  dateStatus: DATE_STATUS;
  isFuture: boolean;
}

export enum MAP_POINT_TYPES {
  PORT = 'PORT',
  CUSTOMER_FACILITY = 'CUSTOMER_FACILITY',
  VESSEL = 'VESSEL'
}
export interface MapPoint {
  latitude: number;
  longitude: number;
  name: string;
  isPast: boolean;
  type: MAP_POINT_TYPES;
  label: string; // A, B, C, etc
}

export interface VesselInfo {
  lat: number;
  lng: number;
  mmsi: string;
  poo: string;
  pod: string;
  atd: string;
  ata: string;
  position_recived: string;
  vessel_local_time: string;
  area: string;
  status: string;
  speed: string;
  track_status: string;
  track_status_detail: string;
}

export interface ShipmentContainer {
  containerId: number;
  containerNumber: number;
  containerSize: string;
  containerType: string;
  sealNumber: string;
  containerEvents: ContainerEvent[];
}


