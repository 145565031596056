import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PurchaseOrder, POFilters, POPageType } from '../po.model';
import { PurchaseOrderService } from '../po.service';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'environments/environment.mock';
import { HttpErrorResponse } from '@angular/common/http';
import { UIService } from 'app/shared';
import { PageData } from '@dp/types';

export class PurchaseOrdersDataSource implements DataSource<PurchaseOrder> {
  private purchaseOrdersSubject = new BehaviorSubject<PurchaseOrder[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private paginator: MatPaginator,
    private uiService: UIService,
    private pageType: POPageType
  ) {}

  loadPurchaseOrders(
    filter = '',
    sortColumn = 'poNumber',
    sortDirection = 'asc',
    pageIndex = 0,
    pageSize = 5,
    poFilters = environment.emptyPOFilters as POFilters
  ) {
    this.loadingSubject.next(true);

    this.purchaseOrderService
      .findPurchaseOrders(filter, sortColumn, sortDirection, pageIndex, pageSize, poFilters, this.pageType)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          this.purchaseOrdersSubject.next((result as PageData<PurchaseOrder>).data);
          if (this.paginator) {
            this.paginator.length = (result as PageData<PurchaseOrder>).total;
          }
        },
        (error: HttpErrorResponse) => {
          this.uiService.showSnackbar(error.message, null, {
            duration: 3000,
            panelClass: 'warn'
          });
        }
      );
  }

  connect(collectionViewer: CollectionViewer): Observable<PurchaseOrder[]> {
    return this.purchaseOrdersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.purchaseOrdersSubject.complete();
    this.loadingSubject.complete();
  }
}
