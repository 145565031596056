import { Component, OnInit, Input } from '@angular/core';
import { dlItem } from '@dp/types';

@Component({
  selector: 'dp-description-list',
  templateUrl: './description-list.component.html',
  styleUrls: ['./description-list.component.scss'],
})
export class DescriptionListComponent implements OnInit {
  @Input() items: dlItem[];
  @Input() skipEmptyCase: boolean = true;
  constructor() {}
  
  ngOnInit(): void {
    if (this.skipEmptyCase) {
      this.items = this.items.filter((item) => item.dd);
    }
  }
}
