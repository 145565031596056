<div class="container-details">
  <div class="col">
    <label class="label">Container #</label>
    <p>{{container.containerNumber || ''}}</p>
  </div>

  <div class="col" *ngIf="container.containerSize">
    <label class="label">Container Type</label>
    <p><span>
        {{container.containerSize || ''}}
      </span>
      &nbsp;
      <span>
        {{container.containerType || ''}}
      </span>
    </p>
  </div>

  <div class="col" *ngIf="container.status">
    <label class="label">Status</label>
    <p [ngClass]="container.status">{{container.status}}</p>
  </div>

  <div class="col" *ngIf="container.blNumber">
    <label class="label">MBL #</label>
    <p>{{container.blNumber || '' }}</p>
  </div>

  <div class="col" *ngIf="container.bkNumber">
    <label class="label">Booking #</label>
    <p>{{container.bkNumber || '' }}</p>
  </div>

  <div class="col" *ngIf="container.transportJourneys.portToPort; let p">
    <label class="label">Carrier</label>
    <p>{{p.carrier || '' }}</p>
  </div>


</div>