import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { UIService, ProgressRef, ProgressService } from 'app/shared';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddressSelectComponent } from '@dp/components/address-select/address-select.component';
import { Address } from 'app/explorer/po';
//import { testAddresses } from './test/address.test';
import { Port } from 'app/explorer/po';
//import { testPorts } from './test/port.test';

@Component({
  selector: 'dp-create-shipment',
  templateUrl: './create-shipment.component.html',
  styleUrls: ['./create-shipment.component.scss'],
  animations: dpAnimations,
})
export class CreateShipmentComponent implements OnInit {
  progressRef: ProgressRef;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  shipperAddresses: Address[];
  ports: Port[];
  selectedOrigin: Address;
  selectedDestination: Address;

  @HostBinding('class') class = 'page-layout simple right-sidebar detailPage';

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {
    //Todo replace with real address data
    //this.shipperAddresses = testAddresses;
    //this.ports = testPorts;

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
  }

  stepClick(ev) {
    ev.stopPropagation();
  }

  onOriginAddressChange($event) {
    this.selectedOrigin = $event;
  }

  onDestinationAddressChange($event) {
    this.selectedDestination = $event;
  }
}
