import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressSelectComponent } from './address-select.component';
import { DpAddressCreateComponent } from './address-create/address-create.component';
import { AgmCoreModule } from '@agm/core';
import { DpSharedModule } from '@dp/shared.module';
import { environment } from 'environments/environment';

@NgModule({
  declarations: [AddressSelectComponent, DpAddressCreateComponent],
  imports: [CommonModule, DpSharedModule, AgmCoreModule.forRoot({ apiKey: environment.mapKey })],
  exports: [AddressSelectComponent, DpAddressCreateComponent],
})
export class AddressSelectModule {}
