<section>
  <div class="header2 dp-white p-12 pl-24 mat-elevation-z1" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
      User Settings
    </span>
  </div>

  <section class="">
    <dp-new-settings></dp-new-settings>

  </section>

</section>