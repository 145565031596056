import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dp-shipment-segment',
  templateUrl: './shipment-segment.component.html',
  styleUrls: ['./shipment-segment.component.scss'],
})
export class ShipmentSegmentComponent implements OnInit {
  @Input() heading: string;
  @Input() segmentData: any;
  segmentDataTable = null;

  ngOnInit(): void {
    if (Array.isArray(this.segmentData)) {
      // build segmentTable
      this.segmentDataTable = this.buildShippingSegmentTable(this.segmentData);
    }
  }

  buildShippingSegmentTable(segmentData) {
    if (segmentData.length) {
      return {
        header: ['From', 'To', 'Transport Name', 'Trip #', 'Departure', 'Arrival'],
        rows: this.getOceanLineTableRows(segmentData),
      };
    }
    return null;
  }

  getOceanLineTableRows(segmentData: any[]) {
    return segmentData.map((data) => {
      return [
        {
          data: data.origin,
          className: 'first-row',
        },
        {
          data: data.destination,
          className: '',
        },
        {
          data: data.transportName,
          className: '',
        },
        {
          data: data.tripNumber,
          className: '',
        },
        {
          data: data.atd || data.etd,
          className: 'md',
          time: data.atd ? 'Actl.' : 'Estd.',
        },
        {
          data: data.ata || data.eta,
          className: 'md',
          time: data.ata ? 'Actl.' : 'Estd.',
        },
      ];
    });
  }
}
