import { Component, Input, OnInit } from '@angular/core';

export enum LogoMode {
  compact,
  wide,
  full,
}
@Component({
  selector: 'dp-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  LogoMode = LogoMode;
  loadLogoFailed = false;
  @Input() orgCode: string;
  //@Input() isExpanded: boolean;
  @Input() logoMode: LogoMode;
  constructor() {}

  ngOnInit(): void {}

  loadLogoFail() {
    this.loadLogoFailed = true;
  }
}
