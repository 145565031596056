import { Component, OnInit, Input } from '@angular/core';
import { Address } from 'app/explorer/po';

@Component({
  selector: 'dp-address-select',
  templateUrl: './address-select.component.html',
  styleUrls: ['./address-select.component.scss']
})
export class AddressSelectComponent implements OnInit {
  @Input()
  defaultAddress: Address;
  @Input()
  addresses: Address[];
  @Input()
  entityName: string;

  selectAddress: Address;

  constructor() { }

  ngOnInit() {
    this.selectAddress = this.addresses[0];
    //console.log(this.selectAddress);
  }
}
