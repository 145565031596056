<button mat-mini-fab class="close-button" (click)="onConfirm()">
  <mat-icon class="close-icon">close</mat-icon>
</button>

<div>
  <h2 mat-dialog-title>
    Document Preview <span *ngIf="documentName">
      - {{documentName}}
    </span>
  </h2>
  <span *ngIf="document.shipmentNumber">
    <label for="shipmentNumber">Shipment Number: </label> <span class="text-bold">{{document.shipmentNumber}}</span>
  </span>
</div>

<div *ngIf="loading" fxLayoutAlign="center">
  <mat-spinner [diameter]="30"></mat-spinner>
</div>

<!-- images mimetypes -->
<div mat-dialog-content *ngIf="document?.documentExtension !== 'pdf'" fxLayout="column" fxLayoutAlign="start center" class="wrapper">
  <img [attr.src]="fileSrc.url | securedUrl | async" width="100%" (load)="onImageLoaded()" />
</div>

<!-- pdf mimetype -->
<div mat-dialog-content *ngIf="document?.documentExtension === 'pdf'" fxLayoutAlign="center
  center" class="wrapper">
  <main style="width: 100%; max-height: 800px;">
    <pdf-viewer [src]="fileSrc" [autoresize]="true" [original-size]="false" [render-text]="true" (after-load-complete)="loading = false">
    </pdf-viewer>
  </main>
</div>