<!-- TOP BACKGROUND -->
<div class="top-bg accent"></div>
<!-- / TOP BACKGROUND -->

<!-- CENTER -->
<div class="center">

  <div class="header accent" >
    <div class="sub-header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
        <!-- APP TITLE -->
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            shopping_basket
          </mat-icon>
          <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
              {{pageType === POPageType.PO? 'Purchase Orders' : 'Customer Orders'}}
          </span>
        </div>
        <!-- / APP TITLE -->

        <!-- SEARCH -->
        <div class="search-wrapper mx-32 mx-md-0">
          <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>search</mat-icon>
            <input matInput #input placeholder="Search Po # or Reference ID">
          </div>
        </div>
        <!-- / SEARCH -->

        <!-- ADD BUTTON -->
        <button mat-raised-button [routerLink]="'/purchase-orders/new'" class="mt-24 mt-md-0" color="primary">
          Import POs
        </button>
        <!-- / ADD BUTTON -->
    </div>
    <div class="sub-header" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="space-between center">
      <!-- <div fxLayoutAlign="center center">
        <span class="mr-16 text-bold">Suppliers:</span>
          <mat-select [(value)]="selectedSupplier" (selectionChange)="supplierChanged($event)" style="width: 200px" >
            <mat-option *ngFor="let supplier of suppliers | keyvalue" value={{supplier.value}}>{{supplier.key}}</mat-option>
          </mat-select>
      </div> -->
      <dp-po-filters fxFlex fxLayoutAlign="left center" (filterChange)="setfilters($event)"></dp-po-filters>
    </div>
  </div>



  <div class="content-card">
    <div class="poList" #poList>
      <div [hidden]="noData">

        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2">

          <!-- id Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
          </ng-container>

          <!-- poNumber Column -->
          <ng-container matColumnDef="poNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header> PO # </mat-header-cell>
            <mat-cell *matCellDef="let element" [innerHTML]="element.poNumber | highlightSearch: filterText"></mat-cell>
          </ng-container>
          <!-- referenceId Column -->
          <ng-container matColumnDef="referenceId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> reference id </mat-header-cell>
            <mat-cell *matCellDef="let element" [innerHTML]="element.referenceId | highlightSearch: filterText"> </mat-cell>
          </ng-container>

          <!-- incoterm Column -->
          <ng-container matColumnDef="incoterm">
            <mat-header-cell *matHeaderCellDef > incoterm </mat-header-cell>
            <mat-cell *matCellDef="let element" [innerHTML]="element.incoterm "> </mat-cell>
          </ng-container>

          <!-- paymentTerm Column -->
          <ng-container matColumnDef="paymentTerm">
            <mat-header-cell *matHeaderCellDef> payment term </mat-header-cell>
            <mat-cell *matCellDef="let element" [innerHTML]="element.paymentTerm "> </mat-cell>
          </ng-container>

          <!-- alerts Column -->
          <ng-container matColumnDef="alerts">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Alerts </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon class="disabled-text" *ngIf="element.alerts > 0" matBadge="{{element.alerts}}" matBadgeColor="warn" fontSet="material-icons-outlined">alarm</mat-icon>
            </mat-cell>
          </ng-container>

          <!-- poDate Column -->
          <ng-container matColumnDef="poDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> po date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.poDate | date}} </mat-cell>
          </ng-container>

          <!-- state Column -->
          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef mat-sort-header> state </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
          </ng-container>

          <!-- notes Column -->
          <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef > note </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ (element.notes || '') | truncate:[trancateLimit]}} </mat-cell>
          </ng-container>


          <!-- deliveryLocationName Column -->
          <ng-container matColumnDef="deliveryLocationName">
            <mat-header-cell *matHeaderCellDef> delivery location </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.deliveryLocationName }} </mat-cell>
          </ng-container>
          <!-- divisionCode Column -->
          <ng-container matColumnDef="divisionCode">
            <mat-header-cell *matHeaderCellDef> division </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.divisionCode }} </mat-cell>
          </ng-container>
          <!-- expectedShipDate Column -->
          <ng-container matColumnDef="expectedShipDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> shiping date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.expectedShipDate | date }} </mat-cell>
          </ng-container>
          <!-- expectedDeliveryDate Column -->
          <ng-container matColumnDef="expectedDeliveryDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> delivery date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.expectedDeliveryDate | date }} </mat-cell>
          </ng-container>
          <!-- flagged Column -->
          <ng-container matColumnDef="flagged">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Flag </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button [color]="element.flagged? 'warn' : null" [class.disabled-text]="!element.flagged" (click)="toggleFlag($event, element)">
                <mat-icon>{{element.flagged? 'flag' : 'outlined_flag'}}</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <!-- supplierName Column -->
          <ng-container matColumnDef="supplierName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> supplier </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.supplierName }} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]="selectedRow === row" (click)="onRowClicked(row)"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="5"></mat-paginator>
      </div>

      <div *ngIf="noData">
        <img class="center" src="assets/no-record.png" alt="">
      </div>

    </div>
  </div>
</div>
<!-- / CENTER -->
    
