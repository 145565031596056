import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';

interface IShipmentIdPartner {
  shipmentId: number,
  partnersList: any[]
}
@Injectable({
  providedIn: 'root',
})
export class PartnersService {

  sharedShipmentsStore = new Subject<IShipmentIdPartner>();
  sharedShipments$ = this.sharedShipmentsStore.asObservable();


  updateSharedShipmentsSubject(partnerObj: any) {
    this.sharedShipmentsStore.next(partnerObj);
  }

  constructor(private http: HttpClient, private authService: AuthService) {}

  // Get current user's partners
  getPartners(): Observable<any> {
    if (this.authService.currentUserValue.organizationPartnerships) return of(this.authService.currentUserValue.organizationPartnerships);
    return null;
  }

  // Update the shared partners list
  updatePartnersList(request): Observable<any> {
    return this.http.put(environment.rootUrl + 'shipmentV2/shipmentPartners', request);
  }

  // Get shared partners of the shipment
  getSharedPartnersOfTheShipment(shipmentId: number) {
    return this.http.get(environment.rootUrl + 'shipmentV2/shipments/sharedPartners/' + shipmentId);
  }
}
