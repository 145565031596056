import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dp-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  questionsAndAnswers = [
    {
      question: `What features does Cargoes Flow support?`,
      answer: `Cargoes Flow can help you track and manage shipments from all carriers in one stop. You can store or share the shipping info and documents with your internal or external partners. Increase your supply chain visibility by customizing shipping exceptions and event notifications.`
    },
    {
      question: `What sort of stuff can Flow track?`,
      answer: `Cargoes Flow helps you track road shipments (LTL, FTL, Drayage, etc.), and Ocean shipments (FCL, LCL). It supports track by container #, MBL #, and booking # most connected carriers (See the carrier list). You also can expect the air shipment tracking to become available within a year.`
    },
    {
      question: `How do I get started with Cargoes Flow?`,
      answer: `Upload the container # or MBL # to initiate the tracking procedure. We also support upload containers in batch by using templates. `
    },
    {
      question: `Why use Cargoes Flow to track shipments?`,
      answer: `Cargoes Flow offers container tracking and management in a one-stop solution, helping your business and customers track shipments from door to door, and increases your supply chain visibility by detecting any potential risks. Cargoes Flow provides a sophisticated integration solution for your interior and exterior business partners, such as local truck companies, ERP systems, agents, etc. `
    },
    {
      question: `What sources of data does Cargoes Flow currently support? Are there shipping avenues Cargoes Flow doesn't support?`,
      answer: `Cargoes Flow currently supports many carriers (See the carrier list). Contact us please if you find any of your carriers are not supported by Cargoes Flow. It is our pleasure to onboard that carrier for your business. `
    },
    {
      question: `Why can't Cargoes Flow find my shipment?`,
      answer: `Please make sure your tracking key is entered or uploaded correctly within Cargoes Flow. Otherwise, please review the connected carriers (See the carrier list) and see if your carrier is not available by Cargoes Flow yet. You are welcome to contact us with the issue you found. We will respond to you within 48 business hours. `
    },
    {
      question: `Do you offer customer support?`,
      answer: `Cargoes Flow team is always there to support any question or problem you have. Please contact us by submitting a form or send us an email. Our customer service team will get back to you within 48 business hours. `
    },
    {
      question: `How do I report an incorrect tracking issue or system bug? `,
      answer: `You can click the tracking error report button located on each shipment detail. Send us a form with the problem details you identified. We will look into the problem and get back to you within 48 business hours. `
    },
    {
      question: `How do I invite coworkers to use Cargoes Flow?`,
      answer: `Click the invite new user button in the toolbar. Enter the work emails of the people you intend to invite, and set up the role for new users. They will receive your invitation by email. After a quick account registration. They are able to login into your company account. Also, they can register the account by themselves. Find the company by referenced email or company name, they can request to join your company account.`
    },
  ];
}
