<mat-card class="mat-elevation-z1 mx-4 my-12 document-card">

  <section>
    <div class="my-8">
      <label for="fileName">Filename</label>
      <span>
        {{document.fileEntry?.name || 'N/A'}}
      </span>
    </div>
    <form>
      <div fxLayout="row" fxLayoutAlign="start center">
        <label for="blockchainChannel">
          Select blockchain channel (required)
        </label>
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Choose an option</mat-label>
          <mat-select [(ngModel)]="dataMap.channelCode" name="channelCode" required>
            <mat-option *ngFor="let item of channels" [value]="item.channelCode">
              {{item.channelName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="my-12" fxLayout="row" fxLayoutAlign="start center">
        <label for="shareTo">
          Share to (required)
        </label>
        <mat-radio-group aria-label="Select an option" fxFlex="1 1 auto" name="isPrivate-{{document.fileEntry?.name}}"
          [(ngModel)]="dataMap.isPrivate" required>
          <mat-radio-button class="my-4" [value]="false">All channel members</mat-radio-button>
          <mat-radio-button class="my-4" [value]="true">Store privately</mat-radio-button>
        </mat-radio-group>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <label for="documentType">
          Document type (optional)
        </label>
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <mat-label>Choose an option</mat-label>
          <mat-select name="documentType" [(ngModel)]="dataMap.documentType">
            <mat-option *ngFor="let item of document_types" [value]="item.code">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <label for="documentRef">
          Document reference (optional)
        </label>
        <mat-form-field appearance="fill" fxFlex="1 1 auto">
          <input matInput placeholder="Type document reference" name="documentReference" [(ngModel)]="dataMap.documentReference">
        </mat-form-field>
      </div>

    </form>
  </section>

</mat-card>