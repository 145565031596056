<div id="users-page" class="page-layout two-column simple left-sidebar inner-sidebar inner-scroll">

  <!-- HEADER -->
  <div class="header2 dp-white p-12 pl-24 mat-elevation-z1" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="logo-text h1" [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
      Users
    </span>
  </div>

  <!-- top control area -->
  <div fxLayout="row" fxLayoutAlign="space-between center" class="p-12">

    <mat-button-toggle-group class="" color="primary" name="userMode" [(ngModel)]="modeSelected" (change)="userModeChange()" aria-label="unit type">
      <mat-button-toggle value="current_users">Active users</mat-button-toggle>
      <mat-button-toggle value="pending_users">Pending users</mat-button-toggle>
    </mat-button-toggle-group>
    <!-- <button mat-raised-button class="primary-800" [mdePopoverTriggerFor]="pendingUserPopover" mdePopoverTriggerOn="click" *ngIf="isAdmin">INVITE USERS</button> -->
  </div>

  <!-- content area: users exist -->

  <div class="content px-12 fullHeight" *ngIf="!loadingUsers && users.length > 0">
    <!-- left user card list area -->
    <section class="sidebar-wrapper mr-4"  position="left" lockedOpen="gt-md" dpPerfectScrollbar fxFlex="0 0 auto">

      <section class="sidebar pr-16">
        <!-- user card list -->
        <div *ngFor="let user of users">
          <dp-user-card [user]="user" [allowAction]="isAdmin" [selected]="selectedUser.id === user.id" (click)="selectUser(user)" (removeUser)="openRemoveUserPopover($event, popoverTrigger, user)" [mdePopoverBackdropCloseOnClick]="true" (resendUser)="resendUser($event, user)" [mdePopoverTriggerFor]="removeUserPopover" #popoverTrigger="mdePopoverTrigger" mdePopoverTriggerOn="none"></dp-user-card>
        </div>
      </section>
    </section>

    <!-- right content area: detail -->
    <div class="center" dpPerfectScrollbar style="margin-bottom: 10px;">
      <div class="content fullHeight dp-white" #userDetailZone style="border: 1px solid #cccccc;">
        <dp-user-detail-empty class="center mb-12" [user]="selectedUser" *ngIf="modeSelected === UserPageModeTypes.PENDING_USERS"></dp-user-detail-empty>
        <dp-user-detail class="center mb-12" [user]="selectedUser" [isAdmin]="isAdmin" *ngIf="selectedUser && modeSelected === UserPageModeTypes.CURRENT_USERS" (permissionChanged)="permissionChanged($event)"></dp-user-detail>
      </div>
    </div>
  </div>

  <div class="content px-12 fullHeight" *ngIf="!loadingUsers && users.length === 0" fxLayoutAlign="center center" fxLayout="column" fxFlexFill>
    <img src="assets/images/svg/no-result-found.svg" />
    <div class="info">
      <p>No pending users!</p>
    </div>
  </div>
  <div class="content pb-80" [hidden]="!loadingUsers" style="height: 100%;" #pageLoadingZone>
  </div>

</div>

<!-- <mde-popover #pendingUserPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#1565C0'" [mdePopoverCloseOnClick]="false" [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="pendingUser-popover-card">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" class="system-primary-800 p-4">
      <mat-card-title class="m-0">Invite Users</mat-card-title>
      <mat-icon class="material-icons closeBtn" (click)="closePopover(false)">
        close
      </mat-icon>
    </mat-card-header>
    <mat-card-content>
      <dp-invite-user-popover (inviteUserClose)="this.closePopover($event)"></dp-invite-user-popover>
    </mat-card-content>
  </mat-card>
</mde-popover> -->

<!-- [mdePopoverOverlapTrigger]="false" mdePopoverPositionX="after" -->
<mde-popover #removeUserPopover="mdePopover" mdePopoverOffsetY="60" mdePopoverOffsetX="400" [mdePopoverArrowColor]="'#1565C0'" [mdePopoverCloseOnClick]="false" [class]="'pt-0'">
  <mat-card class="p-0 mat-elevation-z12" id="pendingUser-popover-card">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" class="system-primary-800 p-4">
      <mat-card-title class="m-0">Confirmation</mat-card-title>
      <mat-icon class="material-icons closeBtn" (click)="closeRemoveUserPopover(false)">
        close
      </mat-icon>
    </mat-card-header>
    <mat-card-content>
      <p>Are you sure you want to remove this user?</p>
      <div>
        <button mat-raised-button class="primary-800" (click)="closeRemoveUserPopover(true)">Yes</button>
        <button mat-raised-button class="ml-20" (click)="closeRemoveUserPopover(false)">No</button>
      </div>
    </mat-card-content>

  </mat-card>
</mde-popover>
