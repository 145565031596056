<mat-accordion multi displayMode="flat" *ngIf="container">
  <mat-expansion-panel (opened)="onOpened(container)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="row" style="justify-content: space-between;">
          <div class="col" style="flex-grow: 1">
            <label class="label">Container # </label>
            <span>{{ container.containerNumber || ''}}</span>
          </div>

          <div class="col" style="flex-grow: 1">
            <label class="label">Type </label>
            <span>
              {{container.containerSize || ''}}
              &nbsp;
              {{container.containerType || ''}}
            </span>
          </div>
          <div class="col" style="flex-grow: 1">
            <label class="label">Status # </label>
            <span [ngClass]="container.status"> {{ container.status || '' }}</span>
          </div>
        </div>

      </mat-panel-title>
    </mat-expansion-panel-header>

    <div matExpansionPanelContent *ngIf="errorMsg" class="text-center">
      <mat-error>{{errorMsg}}</mat-error>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="min-height: 100px;" *ngIf="loading && !shipmentDetailPayload">
      <mat-spinner diameter="40"></mat-spinner>
    </div>


    <div matExpansionPanelContent *ngIf="shipmentDetailPayload">
      <div *ngIf="shipmentDetailPayload.transportJourneys; let transportJourneys">
        <dp-tracking-details [transportJourneys]="transportJourneys"></dp-tracking-details>
      </div>

      <ng-container *ngIf="shipmentDetailPayload.transportJourneys?.portToPort?.segmentData; let segmentData">
        <dp-shipment-segment [segmentData]="segmentData" heading="Shipping Segments"></dp-shipment-segment>
      </ng-container>

      <ng-container *ngIf="shipmentDetailPayload.mapsData">
        <h3>Tracking Events</h3>
        <dp-shipment-map [shipmentDetail]="shipmentDetailPayload.mapsData" [maxZoom]="12"></dp-shipment-map>
      </ng-container>

      <div *ngIf="shipmentDetailPayload.transportJourneys?.portToPort?.statusSteps?.steps; let steps" class="mt-12 pl-12">
        <dp-tracking-step [steps]="steps"></dp-tracking-step>
      </div>
    </div>


  </mat-expansion-panel>
</mat-accordion>