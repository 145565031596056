import { AfterContentChecked, Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

// https://netbasal.com/disabling-form-controls-when-working-with-reactive-forms-in-angular-549dd7b42110

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective implements AfterContentChecked {
  @Input() disableControl;

  constructor(private ngControl: NgControl) {}

  ngAfterContentChecked() {
    // comment from that article
    const action = this.disableControl ? 'disable' : 'enable';
    if (
      this.ngControl.control.enabled &&
      action == 'disable' &&
      this.ngControl.control.value != null &&
      this.ngControl.control.value != undefined
    ) {
      this.ngControl.control[action]();
    } else if (!this.ngControl.control.enabled && action == 'enable') {
      this.ngControl.control[action]();
    }
  }
}
