import { Component, OnInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { UsersService } from '../users/users.service';
import { LogService } from '@dp/services/log.service';
import { UIService, ProgressService, ProgressRef } from 'app/shared';
import { User, STATES } from 'app/auth/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MdePopoverTrigger } from '@material-extended/mde';
import { faUsersMedical as falUsersMedical } from '@fortawesome/pro-light-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { finalize, delay } from 'rxjs/operators';
import { AuthService } from 'app/auth/auth.service';
import moment from 'moment';

export enum UserPageModeTypes {
  CURRENT_USERS = 'current_users',
  PENDING_USERS = 'pending_users',
}

interface Target {
  user: User;
  zone: ElementRef;
}
@Component({
  selector: 'dp-users2',
  templateUrl: './users2.component.html',
  styleUrls: ['./users2.component.scss'],
  animations: dpAnimations
})
export class Users2Component implements OnInit {
  modeSelected: UserPageModeTypes;
  UserPageModeTypes = UserPageModeTypes;
  loadingUsers = false;
  pendingUsers: User[];
  currentUsers: User[];
  users: User[];
  isAdmin: boolean;
  selectedUser: User = null;
  target: Target = null;
  removeUserTrigger: MdePopoverTrigger;
  pageLoadingZoneRef?: ProgressRef;

  @ViewChildren(MdePopoverTrigger) trigger: QueryList<MdePopoverTrigger>;
  @ViewChild('userDetailZone') userDetailZone?: ElementRef;
  @ViewChild('pageLoadingZone', { static: true }) pageLoadingZone?: ElementRef;

  constructor(
    private usersService: UsersService,
    private log: LogService,
    private uiService: UIService,
    private library: FaIconLibrary,
    private authService: AuthService,
    private progressService: ProgressService
  ) {
    this.library.addIcons(falUsersMedical);
  }

  ngOnInit() {
    this.modeSelected = UserPageModeTypes.CURRENT_USERS;
    console.log('mode', this.modeSelected);
    this.getUsersInOrganization();

    this.isAdmin = this.authService.currentUserValue.isAdmin;
  }

  userModeChange() {
    this.setupUsers();
  }

  getUsersInOrganization() {
    this.loadingUsers = true;
    this.pageLoadingZoneRef = this.progressService.showProgress(this.pageLoadingZone);
    this.usersService
      .getUsersInOrganization('')
      //.pipe(delay(1000))
      .subscribe(
        (users: User[]) => {
          // check if the data isn't valid
          if (users === null || typeof users !== 'object') {
            // inform user there's a server problem
            this.log.logError('getUsersInOrganization; request failed.');
            this.uiService.showSnackbar('getUsersInOrganization request failed.', null, {
              duration: 8000,
              panelClass: 'warn'
            });
            return;
          } else if (users.length === 0) {
            return;
          }

          this.currentUsers = users.filter(user => user.state === STATES.VERIFIED);
          this.pendingUsers = users.filter(user => user.state === STATES.NEW);
          this.setupUsers();
        },
        (error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error && error.error && error.error.errorCode && environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode]) {
            errorMessage = environment.API_RESPONSE_CODES.REASON_CODES[error.error.errorCode];
          }

          this.log.logError('getUsersInOrganization; request failed. ' + errorMessage);
          this.uiService.showSnackbar('getUsersInOrganization request failed. ', null, {
            duration: 8000,
            panelClass: 'warn'
          });
        },
        () => {
          this.loadingUsers = false;
          this.progressService.detach(this.pageLoadingZoneRef);
        }
      );
  }

  setupUsers() {
    if (this.modeSelected === UserPageModeTypes.CURRENT_USERS) {
      this.users = this.currentUsers;
      this.selectedUser = this.users.length > 0 ? this.users[0] : null;
    } else {
      this.users = this.pendingUsers;
      this.selectedUser = this.users.length > 0 ? this.users[0] : null;
    }
    console.log(this.users);
  }

  selectUser(user: User) {
    this.selectedUser = user;
  }

  openRemoveUserPopover(cardZone: ElementRef, trigger: MdePopoverTrigger, user: User) {
    this.target = { user, zone: cardZone };
    if (this.removeUserTrigger) {
      this.removeUserTrigger.closePopover();
    }
    this.removeUserTrigger = trigger;
    this.removeUserTrigger.openPopover();
  }

  removeUser() {
    let currentTarget = this.target; //{ ...this.target };
    let progressRef = this.progressService.showProgress(currentTarget.zone);
    this.usersService
      .removeUser(currentTarget.user)
      .pipe(
        finalize(() => {
          this.progressService.detach(progressRef);
          this.target = null;
        })
      )
      .subscribe(
        result => {
          this.removeUserSucceed(currentTarget);
        },
        () => {
          this.removeUserFailed();
        }
      );
  }
  resendUser(cardZone: ElementRef, user: User) {
    let progressRef = this.progressService.showProgress(cardZone);
    const body = { users: [{
      email: user.userEmail,
      isAdmin: user.isAdmin
    }]};
    this.usersService
      .inviteUsers(body)
      .pipe(
        finalize(() => {
          this.progressService.detach(progressRef);
        })
      )
      .subscribe(
        result => {
          this.resendSucceed(user);
        },
        () => {
          this.resendFailed();
        }
      );
  }

  resendSucceed(user: User) {
    user.ageOfDays = 0;
    user.invitedAt = moment().format('L');
    console.log(user.invitedAt);
    this.uiService.showSnackbar('The invite was just sent!', null, {
      duration: 3000,
      panelClass: 'accent'
    });
  }
  resendFailed() {
    this.uiService.showSnackbar("We can't do this right now. Please try again later.", null, {
      duration: 6000,
      panelClass: 'warn'
    });
  }

  removeUserSucceed(currentTarget: Target) {
    // remove the user from the visual list
    this.currentUsers = this.currentUsers.filter(user => user.id !== currentTarget.user.id);
    this.users = this.currentUsers;

    if (this.selectedUser.id === currentTarget.user.id) {
      this.selectedUser = this.currentUsers[0];
    }

    this.uiService.showSnackbar('The user was removed successfully.', null, {
      duration: 3000,
      panelClass: 'accent'
    });
  }

  removeUserFailed() {
    this.uiService.showSnackbar("We can't remove users right now. Please try again later.", null, {
      duration: 8000,
      panelClass: 'warn'
    });
  }

  closePopover(result) {
    console.log('result', result);
    this.trigger.toArray()[0].closePopover();
    // this.pendingUserPopover.closePopover();
    if (result) {
      this.modeSelected = UserPageModeTypes.PENDING_USERS;
      this.getUsersInOrganization();
    }
  }

  closeRemoveUserPopover(removeUserFlag: boolean) {
    this.removeUserTrigger.closePopover();
    if (removeUserFlag) {
      this.removeUser();
    }
  }

  permissionChanged(user: User) {
    let progressRef = this.progressService.showProgress(this.userDetailZone);

    this.usersService
      .changeAdmin(user.isAdmin, user.userCode)
      .pipe(finalize(() => this.progressService.detach(progressRef)))
      .subscribe(
        result => {
          if (result.success) {
            this.selectedUser.isAdmin = user.isAdmin;
            this.uiService.showSnackbar('User permission is changed! ', null, {
              duration: 3000,
              panelClass: 'accent'
            });
          } else {
            this.permissionChangeFailed(user);
          }
        },
        () => {
          this.permissionChangeFailed(user);
        }
      );
  }

  private permissionChangeFailed(user: User) {
    const oldValue = !user.isAdmin;
    user.isAdmin = oldValue;
    this.selectedUser.isAdmin = oldValue;
    this.uiService.showSnackbar("Could not change user's permission! ", null, {
      duration: 8000,
      panelClass: 'warn'
    });
  }
}
