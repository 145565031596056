import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { OrganizationComponent, UsersComponent } from '.';
import { MaterialModule } from 'app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InviteUsersComponent } from './invite-users/invite-users.component';
//import { ProfileFormComponent } from './profile-form/profile-form.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { Users2Component } from './users2/users2.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { DpPipesModule } from '@dp/pipes/pipes.module';
import { DpSharedModule } from '@dp/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserCardComponent } from './users2/user-card/user-card.component';
import { UserDetailComponent } from './users2/user-detail/user-detail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DpDirectivesModule } from '@dp/directives/directives.module';
import { UserDetailEmptyComponent } from './users2/user-detail-empty/user-detail-empty.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DpComponentsModule } from '@dp/components/components.module';
import { NewSettingsShellComponent } from './new-settings-shell/new-settings-shell.component';
import { NotificationMetricsComponent } from './notification-metrics/notification-metrics.component';
import { MetricFormComponent } from './notification-metrics/metric-form/metric-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MetricFormAddComponent } from './notification-metrics/metric-form-add/metric-form-add.component';
@NgModule({
  declarations: [
    OrganizationComponent,
    UsersComponent,
    InviteUsersComponent,
    //ProfileFormComponent,
    PasswordFormComponent,
    Users2Component,
    UserCardComponent,
    UserDetailComponent,
    UserDetailEmptyComponent,
    ContactUsComponent,
    NewSettingsShellComponent,
    NotificationMetricsComponent,
    MetricFormComponent,
    MetricFormComponent,
    MetricFormAddComponent,
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    FontAwesomeModule,
    MaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    DpDirectivesModule,
    DpPipesModule,
    DpSharedModule,
    DpComponentsModule,
    BrowserModule,
    ReactiveFormsModule,
  ],
  exports: [NotificationMetricsComponent],
})
export class SettingsModule {}
