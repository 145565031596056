import { CalendarEvent } from 'angular-calendar';
import { CalendarDays, ShippingDay } from '@dp/types/shipment_calendar.model';

export enum ScheduleEventTypes {
  arrivals = 'arrivals',
  departures = 'departures',
  pickups = 'pickups',
  deliveries = 'deliveries',
}

export enum ScheduleFacadePageTypes {
  TRUCK = 'TRUCK',
  OCEAN = 'OCEAN',
}

export interface ScheduleState {
  filter: ScheduleFilter;
  loading: boolean;
  loadingDetail: boolean;
  calendarDays: CalendarDay[];
  calendarDay: CalendarDay; //selected day
  openDrawer: boolean;
  calendarDayDetail: CalendarDayDetail;
}

export interface ScheduleFilter {
  port: string;
  month: Date;
  scheduleEventType?: ScheduleEventTypes;
  eventTypes: ScheduleEventTypes[]; //dropdown from the api
}

export enum ScheduleShipmentTypes {
  OCEAN = 'INTERMODAL_SHIPMENT',
  TRUCK = 'TRUCK_SHIPMENT',
}

export interface CalendarDayRaw {
  intermodal_shipment?: CalendarDayShipment;
  truck_shipment?: CalendarDayShipment;
  // drayage_pickup?: Drayage;
  // drayage_delivery?: Drayage;
  date?: string; // from api, delete in the api
  start: Date; // for calendar
  shipment: CalendarDayShipment;
}
export interface CalendarDay {
  start: Date; // for calendar
  shipment?: CalendarDayShipment;
}

export interface CalendarDayShipment {
  [key: string]: number;
}
// export interface Counter {
//   count: number;
// }

export interface CalendarDayDetail {
  date: string;
  arrivals?: Journey;
  departures?: Journey;
  deliveries?: Journey;
  pickups?: Journey;
}

export interface Journey {
  count: number;
  journeyStopAndShipments?: JourneyStopAndShipments_Port;
  _type?: 'arrivals' | 'departures';
}

export interface JourneyStopAndShipments_Port {
  [key: string]: JourneyStopAndShipments_Port_Ship;
}
export interface JourneyStopAndShipments_Port_Ship {
  [key: string]: { shipments: Shipment[] };
}


export interface Shipment {
  'drayage_pickup.origin'?: any;
  'drayage_pickup.destination'?: any;
  'drayage_delivery.origin'?: any;
  'drayage_delivery.destination'?: any;
  'intermodal_shipment.origin_port': string;
  'intermodal_shipment.destination_port': string;
  'drayage_pickup.etd'?: any;
  'drayage_pickup.atd'?: any;
  'drayage_pickup.eta'?: any;
  'drayage_pickup.ata'?: any;
  'drayage_delivery.etd'?: any;
  'drayage_delivery.atd'?: any;
  'drayage_delivery.eta'?: any;
  'drayage_delivery.ata'?: any;
  'intermodal_shipment.port_etd'?: any;
  'intermodal_shipment.port_atd': string;
  'intermodal_shipment.port_eta': string;
  'intermodal_shipment.port_ata'?: any;
  count: number;
  id: number;
  shipment_number: string;
  container_number: string;
  reference_number: string;
  'drayage_pickup.truck_number'?: any;
  'drayage_pickup.booking_number'?: any;
  'drayage_delivery.truck_number'?: any;
  'drayage_delivery.booking_number'?: any;
  'intermodal_shipment.transport_name': string;
  'intermodal_shipment.booking_number'?: any;
}
