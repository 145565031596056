<div id="verify" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
  <dp-intro fxFlex></dp-intro>

  <div id="verify-form-wrapper" dpPerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}" #pageZone>
    <div id="verify-form">
      <div *ngIf="userRetrieved && redirecting">
        <div class="title">Welcome {{getNameOrEmail()}}</div>
        <p>Your account already exists. Redirecting you to login page in
          <countdown #cd [config]="{leftTime: 5, format: 's'}" (event)="countdownFinished($event)"></countdown> seconds.
        </p>
      </div>
      <div *ngIf="userRetrieved && !redirecting">
        <div class="title">Welcome {{getNameOrEmail()}}</div>

        <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" [formGroup]="verifyForm" (ngSubmit)="verifyUser()">

          <mat-form-field>
            <input matInput placeholder="New Password" type="password" autocomplete="off" formControlName="password" required>
            <mat-error *ngIf="verifyForm.controls['password'].invalid">
              {{ getErrorMessage(verifyForm.controls['password']) }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Confirm New Password" type="password" formControlName="confirmPassword" autocomplete="new-password"
              [errorStateMatcher]="errorMatcher" required>
            <mat-error *ngIf="verifyForm.controls['confirmPassword'].invalid">
              {{ getErrorMessage(verifyForm.controls['confirmPassword']) }}
            </mat-error>
            <mat-error *ngIf="verifyForm.hasError('passwordsDoNotMatch')">
              Passwords do not match!
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="First Name" type="text" formControlName="firstName" name="fname" autocomplete="given-name"
              required>
            <mat-error *ngIf="verifyForm.controls['firstName'].invalid">
              {{ getErrorMessage(verifyForm.controls['firstName']) }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Middle Name" type="text" formControlName="middleName" name="mname">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Last Name" type="text" formControlName="lastName" name="lname" required>
            <mat-error *ngIf="verifyForm.controls['lastName'].invalid">
              {{ getErrorMessage(verifyForm.controls['lastName']) }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Date Format</mat-label>
            <mat-select formControlName="datePattern" type="datetime-local" [disableOptionCentering]="true" required>
              <mat-option *ngFor="let datePattern of datePatterns " [value]="datePattern">{{getStamp(datePattern)}} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Time Format</mat-label>
            <mat-select formControlName="timePattern" type="datetime-local" [disableOptionCentering]="true" required>
              <mat-option *ngFor="let timePattern of timePatterns" [value]="timePattern">{{ getStamp(timePattern) }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select formControlName="timezone" type="datetime-local" placeholder="Timezone" required #timezoneSelect>
              <mat-option>
                <ngx-mat-select-search [formControl]="timezoneFilterCtrl" placeholderLabel="Find timezone..."
                  noEntriesFoundLabel="'no matching timezone found'"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone.code">{{ timezone.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <input matInput type="tel" formControlName="phone" placeholder="Phone" name="tel">
          </mat-form-field>

          <p class="text-italic sub-title">By click the button below, you agree our <a (click)="openLink(PolicyTypes.TERMS_OF_SERVICE)">User
              Agreement</a>, <a (click)="openLink(PolicyTypes.PRIVACY_POLICY)">Privacy Policy</a> and <a
              (click)="openLink(PolicyTypes.COOKIE_POLICY)">Cookie Policy</a> </p>

          <button type="submit" mat-raised-button [disabled]="verifyForm.invalid" class="submit-button primary-800" [dpBusy]="isBusy">
            Join</button>
          <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        </form>

        <div class="register" fxLayout="column" fxLayoutAlign="center center">
          <span class="text">Already have an account? </span>
          <a class="link" [routerLink]="'/login'">Login here</a>
        </div>
      </div>
      <div *ngIf="error">
        <div class="title">Oops!</div>
        <p>The invitation email is not valid anymore!</p>
      </div>
    </div>
  </div>
</div>