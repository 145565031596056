import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Container, PageData, ContainerFilters, POPageType } from './containers.model';
import { ContainersService } from './containers.service';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from '../../../environments/environment.mock';
import { HttpErrorResponse } from '@angular/common/http';
import { UIService } from '../../shared';

export class ContainersDataSource implements DataSource<Container> {
         private containerSubject = new BehaviorSubject<Container[]>([]);

         private loadingSubject = new BehaviorSubject<boolean>(false);

         public loading$ = this.loadingSubject.asObservable();

         constructor(
           private containersService: ContainersService,
           private paginator: MatPaginator,
           private uiService: UIService
         ) {
         }

         loadContainers(
           filter = '',
           sortColumn = 'containerNumber',
           sortDirection = 'asc',
           pageIndex = 0,
           pageSize = environment.tablePageSize,
           containerFilters = environment.emptyContainerFilters as ContainerFilters
         ) {
           this.loadingSubject.next(true);

           this.containersService
             .findContainers(filter, sortColumn, sortDirection, pageIndex, pageSize, containerFilters)
             .pipe(finalize(() => this.loadingSubject.next(false)))
             .subscribe(
               result => {
                 this.containerSubject.next((result as PageData).data);
                 if (this.paginator) {
                   this.paginator.length = (result as PageData).total;
                 }
               },
               (error: HttpErrorResponse) => {
                 this.uiService.showSnackbar(error.message, null, {
                   duration: 3000,
                   panelClass: 'warn'
                 });
               }
             );
         }

         connect(collectionViewer: CollectionViewer): Observable<Container[]> {
           return this.containerSubject.asObservable();
         }

         disconnect(collectionViewer: CollectionViewer): void {
           this.containerSubject.complete();
           this.loadingSubject.complete();
         }
       }
