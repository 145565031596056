import { Component, OnInit } from '@angular/core';
import { dpAnimations } from '@dp/animations';

@Component({
  selector: 'dp-new-settings',
  templateUrl: './new-settings.component.html',
  styleUrls: ['./new-settings.component.scss'],
  animations: dpAnimations,
})
export class NewSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
