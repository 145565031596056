<mat-card class="mat-elevation-z2 wrapper route-card">
  <div class="header">
    <div class="title">Shipping Route</div>
  </div>
  <mat-card-content>

<!-- Door to Door -->
    <div *ngIf="origin && destination && origin.locationType === 'FC' && destination.locationType === 'FC'" class="pl-24 pr-24">
      <div class="route-block route-title">Route: (Door to Door)</div>
      <div class="route-block route-segments">
        <div class="route-segment">
          <h4>Origin: City, Country</h4>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOD" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOA" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <h4>Destination: City, Country</h4>
        </div>
      </div>
    </div>

<!-- Door to POA --> 
    <div *ngIf="origin && destination && origin.locationType === 'FC' && destination.locationType === 'LP'" class="pl-24 pr-24">
      <div class="route-block route-title">Route: (Door to POA)</div>
      <div class="route-block route-segments">
        <div class="route-segment">
          <h4>Origin: City, Country</h4>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOD" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOA" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

  <!-- Door to POD -->
    <div *ngIf="origin && destination && origin.locationType === 'FC' && destination.locationType === 'LP'" class="pl-24 pr-24">
      <div class="route-block route-title">Route: (Door to POD)</div>
      <div class="route-block route-segments">
        <div class="route-segment">
          <h4>Origin: City, Country</h4>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOD" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        </div>
    </div>

<!-- POD to Door -->
    <div *ngIf="origin && destination && origin.locationType === 'LP' && destination.locationType === 'FC'" class="pl-24 pr-24">
      <div class="route-block route-title">Route: (POD to Door)</div>
      <div class="route-block route-segments">
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOD" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOA" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <h4>Destination: City, Country</h4>
        </div>
      </div>
    </div>

<!-- POA to Door -->
    <div *ngIf="origin && destination && origin.locationType === 'LP' && destination.locationType === 'FC'" class="pl-24 pr-24">
      <div class="route-block route-title">Route: (POA to Door)</div>
      <div class="route-block route-segments">
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOA" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <h4>Destination: City, Country</h4>
        </div>
      </div>
    </div>

<!-- POD to POA -->
    <div *ngIf="origin && destination && origin.locationType === 'LP' && destination.locationType === 'LP'" class="pl-24 pr-24">
      <div class="route-block route-title">Route: (POD to POA)</div>
      <div class="route-block route-segments">
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOD" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="route-segment">······</div>
        <div class="route-segment">
          <mat-form-field class="pl-24 pr-24">
            <mat-select [(value)]="selectPOA" required>
              <mat-option *ngFor="let port of ports" [value]="port">
                {{port.portName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>


  </mat-card-content>
</mat-card>