import { Component, OnInit } from '@angular/core';
import { dpAnimations } from '@dp/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dp-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
  animations: dpAnimations,
})
export class SettingsComponentDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<SettingsComponentDialog>) {}

  ngOnInit(): void {}

  onConfirm(): void {
    console.log('close dialog');
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    console.log('dismiss dialog');
    this.dialogRef.close(true);
  }
}
