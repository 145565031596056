<p class="text-italic black-medium-text">You may invite up to 10 users at once.</p>

<form [formGroup]="emailForm" autocomplete="off" (ngSubmit)="inviteUsers()">
  <div formArrayName="emails">
    <div *ngFor="let email of formEmails.controls; let i = index" [formGroupName]="i" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <mat-form-field>
          <input matInput formControlName="email" placeholder="Email" />
          <mat-error *ngFor="let validation of validationMsgs.email">
            <div *ngIf="email.get('email').hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="none" class="slider">
          <!-- <mat-label>Label</mat-label> -->
          <mat-slide-toggle formControlName="isAdmin" [labelPosition]="'after'" [color]="'accent'">Admin</mat-slide-toggle>
          <textarea matInput hidden></textarea>
        </mat-form-field>
      </div>
      <div>
        <button class="float-left" style="margin-left: 5px; margin-right: 5px;" type="button" mat-icon-button color="primary" aria-label="Add" (click)="addEmailFormGroup()" matTooltip="Add" [matTooltipPosition]="'above'" *ngIf="i === formEmails.controls.length - 1 && formEmails.controls.length <= 9">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <button mat-icon-button type="button" color="primary" aria-label="Remove/clear" (click)="removeOrClearEmail(i)" matTooltip="Remove" [matTooltipPosition]="'above'" [disabled]="formEmails.controls.length === 1">

          <mat-icon>highlight_off</mat-icon>
        </button>
      </div>

    </div>
  </div>

  <button mat-button class="primary-800 medium" type="submit" [dpBusy]="isBusy" [disabled]="!emailForm.valid || emailForm.pristine">SEND INVITATION</button>
</form>
