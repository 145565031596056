import { Component, OnInit, Input } from '@angular/core';

// similar as tracking-summary, with extra info
// Todo remove tracking-summary, after tracking-detail is well-tested
@Component({
  selector: 'dp-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.scss'],
})
export class TrackingDetailsComponent implements OnInit {
  @Input() transportJourneys;
  constructor() {}

  ngOnInit(): void {}
}
