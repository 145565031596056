import { Component } from '@angular/core';

@Component({
  selector: 'dp-cargoes-features',
  templateUrl: './cargoes-features.component.html',
  styleUrls: ['./cargoes-features.component.scss'],
})
export class CargoesFeaturesComponent {
  constructor() {}

}
